<template>
<div class="ams-content">
    <h3 class="ams-tit"><i @click="$router.go(-1)" class="icon-left-arrow ams-vertical-md ams-mr20"></i>Asset History - Details</h3>
    <div class="ams-row">
        <div class="ams-col4 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <div class="ams-card">
                <h3 class="ams-tit">Assignment History</h3>
                <ul v-if="assignmentHistory && assignmentHistory.length" class="ams-asset-history">
                    <li v-for="(a, i) in assignmentHistory" :key="i" :class="(a.status == true) ? 'ams-blue-dot' : 'ams-red-dot'">
                        <p class="ams-header-border">{{a.date}}</p>
                        <div class="ams-assign-card">
                            <em v-if="a.status == true">Assigned to {{a.emp}}</em>
                            <em v-if="a.status == false">Deassinged from {{a.emp}}</em>
                        </div>
                    </li>
                </ul>
                <ul v-else>Assignment history not found</ul>
            </div>
        </div>
        <div class="ams-col4 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <div class="ams-card">
                <h3 class="ams-tit">Repair History</h3>
                 <ul v-if="repairHistory && repairHistory.length" class="ams-asset-history">
                    <li v-for="(h, j) in repairHistory" :key="j" class="ams-blue-dot">
                        <p class="ams-header-border">{{h.repair_date}}</p>
                        <div class="ams-assign-card">
                            <em>Failure type: {{h.fail_type}}<br/><br/></em>
                            <em>Repair date: {{h.repair_date}}<br/><br/></em>
                            <em>Repair comments: {{h.comment}}<br/><br/></em>
                        </div>
                    </li>
                </ul>
                <ul v-else>Repair history not found</ul>
            </div>
        </div>
        <div class="ams-col4 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <div class="ams-card">
                <h3 class="ams-tit">Audit History</h3>
                <ul v-if="auditHistory && auditHistory.length" class="ams-asset-history">
                    <li v-for="(h, j) in auditHistory" :key="j" class="ams-blue-dot">
                        <p class="ams-header-border">{{h.date}}</p>
                        <div class="ams-assign-card">
                            <em>Audit by: {{h.audit_by}}<br/><br/></em>
                            <em>Audit Status: {{h.status}}<br/><br/></em>
                            <em>Audit Comments: {{h.comment}}<br/><br/></em>
                        </div>
                    </li>
                </ul>
                <ul v-else>Audit history not found</ul>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            assignmentHistory: [],
            auditHistory: [],
            repairHistory:[]
        };
    },
    mounted: function () {
        this.getProductHistory()
    },
    methods: {
        getProductHistory: function () {
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('product/FETCH_PRODUCT_HISTORY', {prod_tag: _self.$route.query.prod_tag}).then(function(res){
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.assignmentHistory = res.data.assign
                    _self.auditHistory = res.data.audit
                    _self.repairHistory = res.data.repair
                } else {
                    this.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: '',
                        class: 'ams-error-danger'
                    })
                }
            })
        }
    }
};
</script>
