<template>
<div class="ams-auth ams-version">
    <div class="ams-row ams-gutter30">
        <div class="ams-col8">
            <template v-if="selectYourPlan">
                <h1>
                    <span class="ams-d-block">Select</span>
                    your <strong class="ams-dark-text">plans.</strong>
                </h1>
                <p class="h2 ams-mt15">For customized enterprise plan, Get in touch <span class="ams-d-block">with our executive.</span></p>
                <button class="ams-btn ams-btn-primary ams-d-block ams-mt15">Get quote</button>
            </template>
            <template v-else>
                <h1>
                    <span class="ams-d-block">Start now</span>
                    your <strong class="ams-dark-text">free version.</strong>
                </h1>
            </template>

            <img src="https://img.litedev.com/ams-icons-images/images/plan.svg" alt="authentic" class="ams-img-responsive ams-mt50">
        </div>
        <div class="ams-col4">
            <select-your-plan v-if="selectYourPlan" />
            <pro-version @proVersionPlan="proVersionPlan" v-else />
        </div> 
    </div>
</div>
</template>

<script>
import ProVersion from "../../components/pro/ProVersion.vue"
import SelectYourPlan from "../../components/pro/SelectYourPlan.vue"

export default {
    components: {
        ProVersion,
        SelectYourPlan        

    },
    data() {
        return {
            selectYourPlan: false
        }

    },
    methods: {
        toggleBodyClass: function (addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        proVersionPlan: function () {
            this.selectYourPlan = true
        }
    },

    mounted() {
        this.toggleBodyClass("addClass", "ams-auth-bg");
        this.toggleBodyClass("addClass", "ams-ver-bg");
    },
    unmounted() {
        this.toggleBodyClass("addClass", "ams-auth-bg");
        this.toggleBodyClass("removeClass", "ams-ver-bg");
    },

};
</script>
