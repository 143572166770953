/**
 * @description reports Store Module
 * @author Harikrishna
 */

 import axios from "../../util/axios";
 export default {
     namespaced: true,
     state: {
     },
     mutations: {
     },
     actions:{
        
        FETCH_LICENSE_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/report/license', payload).then(function (res) {
                return res
            })
        },

        FETCH_AUDIT_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/report/audit', payload).then(function (res) {
                return res
            })
        },
        FETCH_MAINTENANCE_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/report/maintenance', payload).then(function (res) {
                return res
            })
        },
        FETCH_TICKET_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/report/tickets', payload).then(function (res) {
                return res
            })
        },
        FETCH_DEPRECIATION_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/report/depreciation', payload).then(function (res) {
                return res
            })
        },
        
        DOWNLOAD_AUDIT({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/report/auditreport?status='+payload.status).then(function (res) {
                return res
            })
        },
        DOWNLOAD_TICKET({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/report/ticketsreport?from_date='+payload.from_date+'&to_date='+payload.to_date).then(function (res) {
                return res
            })
        },
        DOWNLOAD_DEPRECIATION({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/report/depreciationreport?from_date='+payload.from_date+'&to_date='+payload.to_date).then(function (res) {
                return res
            })
        },
        DOWNLOAD_MAINTENANCE({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/report/maintenancereport?from_date='+payload.from_date+'&to_date='+payload.to_date).then(function (res) {
                return res
            })
        },
        DOWNLOAD_LICENSE({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/report/licensereport?from_date='+payload.from_date+'&to_date='+payload.to_date).then(function (res) {
                return res
            })
        },
    }
    }