<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead v-if="accessoriesList && accessoriesList.length">
            <tr>
                <th>S.No</th>
                <th>Accessory Tag</th>
                <th>Accessory Type</th>
                <th>DOP</th>
                <th>Accessory Status</th>
                <th>Employee ID</th>
                <th>Audit Date</th>
                <th>Audit Status</th>
                <th>History</th>
            </tr>
        </thead>
        <tbody>
            <tr @click.prevent="viewProduct(a.prod_tag, a.ptd)" v-for="(a, i) in accessoriesList" :key="i">
                <td data-title="S.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Accessory Tag">{{ a.prod_tag }}</td>
                <td data-title="Accessory Type">{{parsedData[a.ptd]}}</td>
                <td data-title="DOP">{{getDate(a.dop)}}</td>
                <td data-title="Accessory Status">{{ a.status }}</td>
                <td data-title="Employee ID">{{ a.emp_id }}</td>
                <td data-title="Audit Date">{{getDate(a.audit_date) ? getDate(a.audit_date) : '-'}}</td>
               <td data-title="Audit Status"> {{a.audit_status ? a.audit_status : 'NA'}} </td>
                <!-- <td data-title="Action"><i @click.stop="productHistory(a.prod_tag)" class="ams-fw600 icon-edit-box ams-primary-text"></i></td> -->
                <td data-title="Action"><i @click.stop="productHistory(a.prod_tag)" class=" ams-history ams-fw600  icon-History ams-primary-text"></i></td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
import moment from "moment";
export default {
    components: {},
    props: ["accessoriesList",'paginationData'],
    data() {
        return {};
    },
    methods: {
        productHistory: function (prod_tag) {
            this.$router.push('/assets-history-details?prod_tag='+ prod_tag)
        },
        viewProduct: function (prod_tag, ptd) {
            this.$router.push( "/accessories/edit/" + prod_tag + "/" + ptd);
        },
         getDate : function(date){

            return moment(date).format('DD-MM-YYYY');

        },
        indexMethod(pageNumber, pageSize) {
            
            return pageNumber * pageSize - (pageSize - 1);
        },

    },
    mounted() {},
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return { ...parsedPT[2] }
        }
    }
};
</script>
