<template>
<div>
    <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/inventory/assign')"></i>Inventory - Assigned Item </h3>

    <form class="ams-card">
        <div class="ams-row">
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Asset / Accessory / License name</label>
                    <input type="text" v-model="parsedData[inventoryData.pType]" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensetype" id="licensetype" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Asset / Accessory / License tag</label>
                    <input type="text" v-model="inventoryData.prod_tag" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensename" id="licensename" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Model number</label>
                    <input type="text" v-model="inventoryData.model_number" class="ams-form-control ams-custom-control-dark" placeholder="" name="licenseid" id="licenseid" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Employee Id</label>
                    <input type="text" v-model="inventoryData.emp_id" class="ams-form-control ams-custom-control-dark" placeholder="" name="Password" id="Password" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Assigning date</label>
                    <input type="text" v-model="inventoryData.assign_date" class="ams-form-control ams-custom-control-dark" placeholder="" name="Productkey" id="Productkey" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Asset condition while assigning</label>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="Manufacturename" id="Manufacturename" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Vendor name</label>
                    <input type="text" v-model="inventoryData.ven_name" class="ams-form-control ams-custom-control-dark" placeholder="" name="vendorname" id="vendorname" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Vendor contact number</label>
                    <input type="text" v-model="inventoryData.ven_number" class="ams-form-control ams-custom-control-dark" placeholder="" name="ponumber" id="ponumber" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Vendor email Id</label>
                    <input type="text" v-model="inventoryData.ven_email" class="ams-form-control ams-custom-control-dark" placeholder="" name="renewaldate" id="renewaldate" :disabled="inflag" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Price</label>
                    <input type="text" v-model="inventoryData.price" class="ams-form-control ams-custom-control-dark" placeholder="" name="cost" id="cost" :disabled="inflag" />
                </div>
            </div>

        </div>
        <upload-invoice v-if="fileData && fileData.filename" :showcancel="false" :fileData="fileData" @invoice-close="fileData = $event" />
        <div v-else class="ams-col12">
            <div class="ams-form-group">
                <label>Invoice</label>
                <div class="amss-row">
                    <div class="ams-col3">
                        <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />
                    </div>
                </div>
            </div>
        </div>
        <div class=" ams-table-filter">
            <button v-if="fileFlag" class="ams-btn ams-btn-danger ams-mr20" @click="$router.push('/inventory/assign')">Cancel</button>
            <button v-if="fileFlag" class="ams-btn ams-btn-primary" @click.prevent="updateAssignItem">Update</button>
        </div>
    </form>
</div>
</template>

<script>
import FileUpload from "../../components/common/FileUpload.vue"
import moment from "moment"
import UploadInvoice from "../common/UploadInvoice.vue"

export default {
    components: {
        FileUpload,
        UploadInvoice
    },
    data() {
        return {
            inflag: false,
            inventoryData: {},
            utext: 'Click to add your file',
            fileData: {},
            fileFlag: false
        };
    },
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            console.log(parsedPT[1], "parseddd");
            console.log(parsedPT[2], "parseddd2222");
            return {
                ...parsedPT[1],
                ...parsedPT[2],
                ...parsedPT[3]
            }
        },
        getDate() {
            let self = this
            console.log('inventoryData.assign_date', self.inventoryData.assign_date)
            return moment(self.inventoryData.assign_date).format('DD-MMM-YYYY')
        }
    },
    methods: {
        updateAssignItem() {
            let _self = this
            let updateReq = {
                prod_tag: _self.$route.params.prodid,
                cType: _self.inventoryData.cType,
                filedata: (_self.fileData) ? _self.fileData : {}

            }
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('inventory/UPDATE_ASSIGN_ITEM', updateReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Item updated successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/inventory/assign')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })

        },

    },
    mounted() {
        let ptag = this.$route.params.prodid;
        console.log('ptag', ptag)
        let _self = this
        _self.$store.dispatch("common/SET_LOADER", true);
        _self.$store.dispatch('inventory/INVENTORY_ID', ptag).then(function (res) {
            _self.$store.dispatch("common/SET_LOADER", false);
            console.log('res', res)
            if (res && res.status == 1000) {
                _self.inventoryData = res.data
                _self.fileData = res.data.filedata
                // _self. filedata=(_self.fileData) ? _self.fileData : {}
                _self.inflag = true
                if (_self.fileData && !_self.fileData.filename) {
                    _self.fileFlag = true
                }
            } else {
                _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Technical Error',
                    class: 'ams-error-danger'
                })
            }
        })

    },
    watch: {
        fileData: {
            immediate: false,
            handler() {
                let self = this
                if (self.fileData && !self.fileData.filename) {
                    self.fileFlag = true
                }
            }
        }
    }
};
</script>
