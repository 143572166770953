<template>
<div v-if="vflag" class="ams-content">
    <company-details :profileData="profileData"/>
    <!-- <company-profile-details v-if="!sflag"/> -->
    <div class="ams-row">
    <!-- <div v-if="vflag && eventid['1241'] && uflag" class="ams-col6 ams-col-md6 ams-col-sm12 ams-col-xs12">
        <profile-details :userData="userData" :profileData="profileData"/>
    </div> -->
        <div class="ams-col6 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <user-profile-detail :userData="userData" :profileData="profileData"/>
        </div>
        <div class="ams-col6 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <active-plan :packData="packData" />
        </div>
    </div>
</div>
</template>

<script>
import CompanyDetails from "../../components/profile/CompanyDetails.vue"
// import CompanyProfileDetails from "../../components/profile/CompanyProfileDetails.vue"
import UserProfileDetail from "../../components/profile/UserProfileDetail.vue"
// import ProfileDetails from "../../components/profile/ProfileDetails.vue"
import ActivePlan from "../../components/pro/ActivePlan.vue"
export default {
    components: {
        CompanyDetails,
        // CompanyProfileDetails,
        UserProfileDetail,
        // ProfileDetails,
        ActivePlan
    },
    data() {
        return {
            // sflag: false,
            vflag: false,
            profileData: null,
            userData: null,
            packData: null
        };
    },
    mounted() {
        let self = this
        // let userDetails = localStorage.getItem('userDetails')
        // self.sflag = (userDetails && (JSON.parse(userDetails).superuser))
        if (self.eventid['1241']) {
            self.companyProfile();
        }
    },
    methods: {
        companyProfile: function () {
            let _self = this;
            _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch("profile/COMPANY_PROFILE", ).then(function (response) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (response && response.status === 1000) {
                    _self.profileData = response.data.companyInfo;
                    _self.packData = response.data.packInfo;
                    _self.userData = response.data.userInfo;
                    _self.$store.dispatch("user/SET_USERDETAILS", _self.userData)
                    localStorage.setItem('userDetails', JSON.stringify(_self.userData))
                    _self.vflag = true
                }
            });
        }
    },
    computed: {
        eventid() {
            return JSON.parse(localStorage.getItem("eventData"));
        }
    }
};
</script>
