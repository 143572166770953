<template>
<div>
    <Form :validation-schema="vendorValidate" v-slot="{ handleSubmit }">

        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/vendor')"></i> Add New Vendor</h3>
        <div class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Vendor name</label>
                        <Field v-model="vendor.ven_name" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Vendor Name" name="vendorname" id="vendorname" />
                        <ErrorMessage name="vendorname" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-vendor-phone-no">
                        <label>Vendor contact number</label>
                        <div class="ams-phone-no">
                            <input @click="openPopup()" placeholder="+91" :value="crescode" readonly/>
                            <Field v-model="vendor.ph_num" type="text" placeholder="Vendor contact number" name="vendornumber" id="vendornumber" />
                        </div>
                        <ErrorMessage name="vendornumber" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Vendor email id</label>
                        <Field v-model="vendor.email" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Vendor email id" name="vendoremail" id="vendoremail" />
                        <ErrorMessage name="vendoremail" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Registration number</label>
                        <Field v-model="vendor.gst_num" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Registration Number" name="Registrationnumber" id="gstnumber" />
                        <ErrorMessage name="Registrationnumber" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col12">
                    <div class="ams-form-group">
                        <label>Vendor address</label>
                        <Field v-model="vendor.add" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Vendor address" name="vendoraddress" id="vendoraddress" />
                        <ErrorMessage name="vendoraddress" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-danger" @click="$router.push('/vendor')">Cancel</button>
                <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(addVendor)">Save</button>
            </div>
        </div>
    </Form>
</div>
<country-code v-if="ccflag" @save-code="saveCode" @close-pop="ccflag = false" />
</template>

<script>
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as Yup from "yup";
import CountryCode from "../../components/home/CountryCode.vue"

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        CountryCode
    },
    data() {
        const vendorValidate = Yup.object().shape({
            vendorname: Yup.string().matches(/^[aA-zZ\s]+$/,"Special Characters Not Allowed ").required("Please Enter Vendor Name"),
            vendornumber: Yup.string().max(10, "Maximum length is 10").matches(/^[0-9]+$/,"Special Characters Not Allowed ").required("Please Enter Contact Number"),
            vendoremail: Yup.string().email("please Enter Valid Email").required("Please Enter Email"),
            Registrationnumber: Yup.string().min(15).max(15).matches(/^[a-zA-Z0-9]+$/,"special characters not allowed ").required("Please Enter Registration number").nullable(),
            vendoraddress: Yup.string().required("Please Enter  Address")
        });

        return {
            ccflag: false,
            vendorValidate,
            vendor: {
                ven_name: "",
                ph_num: "",
                email: "",
                gst_num: "",
                add: "",
                ccode: "91",

            },
            crescode: '+91'
        }
    },
    methods: {
        saveCode: function (countryFilter) {
            console.log(countryFilter, 'countryFilter')
            let val = countryFilter.label.split(' ')
            this.crescode = val[0]
            this.vendor.ccode = countryFilter.value.toString()
            this.ccflag = false
        },
        addVendor: function () {
            console.log('df')
            let _self = this

            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('vendor/ADD_VENDOR', _self.vendor).then(function (res) {
                console.log('RES', res)
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'vendor added successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/vendor')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
         openPopup: function () {
            this.ccflag = true
        },
    }

}
</script>
