<template>
    <div class="modal-mask">
      <div class="modal-body modal-body-img">
        <button class="btn-close" @click="$emit('close-pop')">
          <i class="icon-cancel"></i>
        </button>
        <img :src="imageUrl" class="ams-img-cover" alt="image"/>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: ["imageUrl"],
    data() {
      return {};
    },
  };
  </script>