<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead v-if="porderList && porderList.length">
            <tr>
                <th>SI.No</th>
                <th>AssetType</th>
                <th>Dop</th>
                <th>Vendor Name</th>
                <th>Quantity</th>
                <th>Item Cost</th>
                <th>P O Number</th>
                <th>P O Slip</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(a,i) in porderList" :key="i">
                <tr>
                    <td data-title="SI.NO">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                    <td data-title="AssetType">{{parsedData[a.pType]}}</td>
                    <td data-title="Dop">{{getDate(a.dop)}}</td>
                    <td data-title="Vendor Name">{{a.ven_name}}</td>
                    <td data-title="Quantity">{{a.quantity}}</td>
                    <td data-title="Item Cost">{{a.cost}}</td>
                    <td data-title="P O Number">{{a.po_number}}</td>
                    <td data-title="P O Slip">
                        <!-- <a :href="fileData.url" download><i class="icon-download"></i></a> -->
                        <a v-if="a.filedata && a.filedata.filename" class="ams-href ams-primary-text" :href="a.filedata.url" download> {{a.filedata && a.filedata.filename ? a.filedata.filename : ''}} </a>
                    </td>
                    <td data-title="Action" class="ams-text-right"><i :class="indexVal === i ? 'icon-circle-minus ' : 'icon-circle-plus'" class=" ams-f-size30 ams-gray-light-text ams-cursor" v-on:click="openrow(i)"></i></td>
                </tr>
                <tr v-if="indexVal === i" id="hide">
                    <td colspan="10">
                        <div class="ams-accord">
                            <div class="ams-row ams-d-sbetween ams-d-f-a-center">
                                <div class="ams-col8">
                                    <span class="ams-f-size16 ams-gray-text"> Order status</span>
                                    <h5 v-if="a.purchase_status === 'Purchase'" class="ams-yellow-text">Order Placed</h5>
                                    <h5 v-else class="ams-green-text">Recieved</h5>
                                </div>
                                <div class="ams-col4 ams-text-right">
                                    <!-- <input type="checkbox" id="Drop down" class=""><label for="Drop down">Mark it as recieved</label> -->
                                    <button v-if="a.purchase_status === 'Purchase'" class="ams-btn ams-btn-lg ams-btn-primary ams-ml20" @click="recieveOrder(a.pur_id)">Recieve</button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
    <div class="ams-row ams-d-f-jend ams-mt20">
        <div class="ams-col3 ams-col-md3 ams-col-sm4 ams-col-xs12 ams-mr20">
            <div class="ams-form-group">
            <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
            </div>
        </div>
       
    </div>
    <!-- <inventory-purchase /> -->
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue"
import moment from "moment";
import {
    pagination_limit
} from "../../../config/endpoint";
// import InventoryPurchase from "../../components/inventory/InventoryPurchase.vue";
export default {
    components: {
        Pagination,
        // InventoryPurchase
    },
    data() {
        return {
            isShow: false,
            porderList: null,
            page: 1,
            paginationData: null,
            indexVal: null
        };
    },
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            console.log(parsedPT[1], "parseddd");
            console.log(parsedPT[2], "parseddd2222");
            return {
                ...parsedPT[1],
                ...parsedPT[2],

            }
        },
        limit: function () {
            return pagination_limit
        }

    },
    methods: {
        openrow(i) {
            let self = this
            if (self.indexVal === i) {
                self.indexVal = null
            } else {
                self.indexVal = i
            }
        },
        // addAccessories:function(){
        //     this.$emit('add-accessories', '')
        // }
        openPurchaseDetails: function () {
            this.$router.push('/inventory/purchase/add')
        },
        listporder: function () {
            let _self = this

            let reqObj = {
                limit: this.limit,
                page: this.page

            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("inventory/FETCH_PORDER_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        console.log('res', res)
                        _self.porderList = res.data.purchaseItems
                        _self.paginationData = res.data.metadata[0]
                        console.log("PORDERList", res.data);
                    }
                    // else {
                    //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    //         msg: "Technical error",
                    //         class: "ams-error-danger",
                    //     });
                    // }
                });

        },
        getDate: function (date) {

            return moment(date).format('DD-MM-YYYY');

        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.listporder()
        },
        recieveOrder(val) {
            let _self = this
            _self.$store.dispatch("common/SET_LOADER", true);
            let reqObj = {
                pur_id: val,
            }
            _self.$store.dispatch("inventory/PORDER_STATUS", reqObj)
                .then(function (res) {
                    if (res && res.status == 1000) {
                        console.log("responseeee", res);
                        _self.listporder()
                    }
                    _self.$store.dispatch("common/SET_LOADER", false);
                })
        }
    },
    mounted() {
        this.listporder();
    }
};
</script>
