<template>
<div>
    <h4 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/vendor')"></i>Edit Vendor <span v-if="$route.params.venid"> - {{$route.params.venid}} </span> </h4>
    <div class="ams-card">
        <Form class="ams-auth-form" :validation-schema="ponumberValidate" v-slot="{ handleSubmit }">
            <vendor-name-list ref="vendor" />
            <!-- <ul class="ams-tabs ams-cust-tabs" :class="amsdropdownview? 'ams-dropd-show': 'ams-dropd-hide'">
            <li @click="changeTab('quotation')" v-bind:class="[tabSelection === 'quotation' ? 'ams-active-btn' : '',]">
            <button class="ams-btn ams-btn-outline-info"> Ask Quotation</button>
            </li>
            <li @click="changeTab('porder')" v-bind:class="[tabSelection === 'porder' ? 'ams-active-btn' : '',]">
            <button class="ams-btn ams-btn-outline-info"> Place Order</button>
            </li>
        </ul> -->
            <!-- <button class="ams-btn ams-btn-outline-info ams-mb20"> Place Order</button> -->
            <div class="ams-tab-cont">
                <vendor-type-assets ref="assets" />
                <vendor-type-accessories ref="accessories" />
                <!-- <vendor-order/> -->
                <!-- <vendor-order v-if="tabSelection === 'Place Order'" /> -->
            </div>
            <div class="ams-row ams-d-f-sbetween ams-mt20">
                <div class="ams-col9 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/>
                    <div v-else class="ams-form-group ams-col4 ams-col-md6 ams-col-sm12 ams-col-xs12">
                        <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" v-model="ponumber" class="ams-form-control" placeholder="Po Number" name="ponumber" id="version" />
                        <ErrorMessage name="ponumber" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-danger ams-mr20" @click="$router.push('/vendor')">Cancel</button>
                <button type="button" class="ams-btn ams-btn-outline-info" @click.prevent="handleSubmit(placeorder)">Place Order</button>
            </div>
            <!-- <vendor-type-assets />
        <vendor-type-accessories /> -->
        </Form>
    </div>
</div>
</template>

<script>
import VendorTypeAssets from "../../components/vendor/VendorTypeAssets.vue"
import VendorTypeAccessories from "../../components/vendor/VendorTypeAccessories.vue"
// import VendorOrder from "./VendorOrder.vue"
import VendorNameList from '../../components/vendor/VendorNameList.vue';
import FileUpload from "../common/FileUpload.vue"
import UploadInvoice from "../common/UploadInvoice.vue"
import _ from 'lodash'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    components: {
        VendorTypeAssets,
        VendorTypeAccessories,
        VendorNameList,
        // VendorOrder,
        FileUpload,
        Form,
        Field,
        ErrorMessage,
        UploadInvoice
    },
    data() {
        const ponumberValidate = Yup.object().shape({
            ponumber: Yup.string().matches(/^[a-zA-Z0-9]+$/,"Special Characters Not Allowed ").required("Please Enter PO Number"),
        });
        return {
            tabSelection: 'quotation',
            ponumber: '',
            ponumberValidate,
            fileData: {},
            utext: 'Upload PO Slip'
        };
    },
    methods: {
        placeorder() {
            console.log('req')
            let self = this
            let vendordata = self.$refs['vendor']
            let assetRef = self.$refs['assets']
            let accesRef = self.$refs['accessories']
            if (assetRef.asset_item.length || accesRef.accessories_item.length) {
                let arr1 = assetRef.asset_item.length ? assetRef.asset_item : []
                let arr2 = accesRef.accessories_item.length ? accesRef.accessories_item : []
                let obj = [...arr1, ...arr2]
                let arr = []
                console.log('OBJ', vendordata.vendorData.ven_id)
                _.each(obj, (e) => {
                    console.log('EEEE', e)
                    arr.push({
                        cType: e.cType,
                        pType: e.pType,
                        ven_id: vendordata.vendorData.ven_id,
                        quantity: parseInt(e.quantity),
                        po_number: self.ponumber,
                        cost: parseInt(e.price),
                        comment: e.comment,
                        mnumber: e.mnumber,
                        brand: e.brand,
                        filedata: self.fileData ? self.fileData : {}
                    })
                })
                console.log('arr', arr)
                let reqobj = {
                    products: arr
                }
                self.$store.dispatch('common/SET_LOADER', true)
                self.$store.dispatch('vendor/PLACE_ORDER', reqobj).then(function (res) {
                    console.log('RES', res)
                    self.$store.dispatch('common/SET_LOADER', false)
                    if (res && res.status == 1000) {
                        self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Order Placed successfully',
                            class: 'ams-error-success'
                        })
                        self.$router.push('/vendor')
                    } else {
                        self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Technical Error',
                            class: 'ams-error-danger'
                        })
                    }
                })
            } else {
                self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    msg: "Please add products",
                    class: "ams-error-danger",
                });
            }
        },
        changeTab(val) {
            let _self = this
            if (val != _self.tabSelection) {
                _self.tabSelection = val
            }
            _self.activeclass = undefined;
        },
    }
};
</script>
