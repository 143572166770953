/**
 * @description repair Store Module
 * @author Harikrishna
 */

 import axios from "../../util/axios";
 export default {
     namespaced: true,
     state: {
     },
     mutations: {
     },
     actions: {
         ADD_ITEM({ commit }, payload) {
             console.log('commit', commit)
             return axios.post('/repair/add', payload).then(function (res) {
                 return res
             })
         },
         REPAIR_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/repair/table', payload).then(function (res) {
                return res
            })
        },
        REPAIR_ID({ commit},payload){
            console.log('commit', commit)
            return axios.get('/repair/id?repair_id='+ payload).then(function (res) {
                return res
            })
        },
        UPDATE_ITEM({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/repair/update', payload).then(function (res) {
                return res
            })
        },
       
        },
    }