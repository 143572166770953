<template>
<div class="ams-dialog">
    <div class="ams-dialog-body ams-w30">
        <i class="ams-close ams-close-circle icon-close-circ ams-f-size30 ams-mt10" @click="closePop"></i>
        <div class="ams-dialog-cont">
            <h3 class="ams-tit">Confirm Deletion</h3>
            <div class="ams-col12 ams-col-md6 ams-col-sm12 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Are you sure want to remove ?</label>
                </div>
            </div>
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-sm ams-btn-primary ams-btn-block ams-mr20" @click.prevent="deleteid()">Delete</button>
                <button @click="closePop" class="ams-btn ams-btn-sm ams-btn-primary ams-btn-block">Cancel</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['uid'],
    components: {},
    data() {
        return {};
    },
    methods: {
        closePop: function () {
            this.$emit('close-popup', '')
        },
        deleteid: function () {
            let _self = this
            _self.$parent.deleteFunc(_self.uid)
            //  _self.$parent.deleteVendor(_self.uid)
            // let reqObj = {}
            // if (_self.uid) {
            //     reqObj.ven_id = vendorId;
            // }
            // if (empId) {
            //     reqObj.emp_id = empId;
            // }
            // if (subuserId) {
            //     reqObj.usr_id = subuserId;
            // }

            // _self.$store.dispatch("common/SET_LOADER", true)
            // if (empId) {
            //     this.$store.dispatch("employee/DELETE_EMPLOYEE", reqObj)
            //         .then(function (res) {
            //             _self.$store.dispatch("common/SET_LOADER", false);
            //             if (res && res.status == 1000) {
            //                 //_self.pageData()
            //                 _self.$store.dispatch("common/SET_ALERTMESSAGE", {
            //                     msg: "EMPLOYEE deleted successfully",
            //                     class: "ams-error-success",
            //                 });
            //                 _self.$emit('close-popup', '')
            //             } else {
            //                 _self.$store.dispatch("common/SET_ALERTMESSAGE", {
            //                     msg: "Technical error",
            //                     class: "ams-error-danger",
            //                 });
            //             }
            //         });
            // }
            // if (vendorId) {
            //     this.$store.dispatch("vendor/DELETE_VENDOR", reqObj)
            //         .then(function (res) {
            //             console.log('res', res)
            //             _self.$store.dispatch("common/SET_LOADER", false);
            //             if (res && res.status == 1000) {
            //                 _self.$store.dispatch("common/SET_ALERTMESSAGE", {
            //                     msg: "Vendor deleted successfully",
            //                     class: "ams-error-success",

            //                 });
            //                 _self.$emit('close-popup', '')

            //             } else {
            //                 _self.$store.dispatch("common/SET_ALERTMESSAGE", {
            //                     msg: "Technical error",
            //                     class: "ams-error-danger",
            //                 });
            //             }
            //         });
            // }
            // if(subuserId){
            //      _self.$store.dispatch("common/SET_LOADER", true)
            //     this.$store.dispatch("settings/DELETE_SUBUSER", reqObj)
            //     .then(function (res) {
            //         console.log('res', res)
            //         _self.$store.dispatch("common/SET_LOADER", false);
            //         if (res && res.status == 1000) {
            //             _self.$store.dispatch("common/SET_ALERTMESSAGE", {
            //                 msg: "Subuser deleted successfully",
            //                 class: "ams-error-success",

            //             });
            //             _self.$emit('close-popup', '')

            //         } else {
            //             _self.$store.dispatch("common/SET_ALERTMESSAGE", {
            //                 msg: "Technical error",
            //                 class: "ams-error-danger",
            //             });
            //         }
            //     });
            // }

        },
    }
};
</script>
