<template>
<div class="ams-allocate">
    <h3 class="ams-tit">Asset Details</h3>
    <div class="ams-row ams-a-center">
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <label>Asset name for allocate</label>
                <Multiselect @change="fetchAssetTags" @clear="clearTag" v-model="productType" :options="productTypes" class="ams-form-control ams-custom-control-dark" :searchable="true" track-by="label" placeholder="Asset Name"></Multiselect>
            </div>
        </div>
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <label>Asset tag for allocate</label>
                <Multiselect v-model="productTag" :options="productTags" class="ams-form-control ams-custom-control-dark" placeholder="Asset Tag"></Multiselect>
            </div>
        </div>
        <div class="ams-col2 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <button @click.prevent="allocateProduct" class="ams-btn ams-btn-outline-primary ams-btn-block ams-alc-btn">
                    Allocate
                </button>
            </div>
        </div>
        <div class="ams-col8 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <ul class="ams-alloc-list" v-if="(selectedProducts && selectedProducts.length)">
                <li v-for="(p, i) in selectedProducts" :key="i">
                    <span><label class="ams-gray-text">Allocated Asset name : </label> {{ p.productType }}</span>
                    <span><label class="ams-gray-text">Allocated Asset tag : </label> {{ p.productTag }}</span>
                    <span class="ams-remove-text" @click="deleteAsset(i)">Remove</span>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
    components: {
        Multiselect,
    },
    props: ['assetlistprop'],
    computed: {
        productTypes: function () {
            // if(this.assetlistprop){

            // }
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return parsedPT && parsedPT["1"];
        },
    },
    data() {
        return {
            filterFlag: false,
            options: ["CSV", "MS-WORD", "MS-EXCEL", "PDF"],
            productTags: null,
            productTag: null,
            productType: null,
            selectedProducts: [],
        };
    },
    methods: {
        fetchAssetTags: async function (pid) {
            let _self = this;
            let response = await this.$store.dispatch(
                "product/FETCH_PRODUCT_TAGS",
                pid
            );
            if (response && response.status == 1000) {
                _self.productType = pid;
                _self.productTags = response.data;
            }
        },
        allocateProduct: function () {
            if (this.productType && this.productTag) {
                if (this.selectedProducts && this.selectedProducts.length) {
                    let selectedValues = this.selectedProducts.map(sp => sp.productTag)
                    if (selectedValues.indexOf(this.productTag)>-1) {
                        this.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Already allocated this asset",
                            class: "ams-error-danger",
                        });
                    } else {
                        this.selectedProducts.push({
                            productType: this.productTypes[this.productType],
                            productTag: this.productTag,
                        });
                        this.productType = null
                        this.productTag = null 
                    }
                } else {
                    this.selectedProducts.push({
                        productType: this.productTypes[this.productType],
                        productTag: this.productTag,
                    });
                    this.productType = null
                    this.productTag = null
                }
            }
        },
        deleteAsset: function (i) {
            this.selectedProducts.splice(i, 1)
        },
        clearTag: function() {
            this.productTag = null
        }
    },
    watch: {
        assetlistprop: function (val) {
            if (val && val.length) {
                val.forEach(element => {
                    this.selectedProducts.push({
                        productType: this.productTypes[element.type],
                        productTag: element.tag
                    });
                });
            }
        }
    }
};
</script>
