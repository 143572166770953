<template>
<Form :validation-schema="forgotValidate" v-slot="{ handleSubmit }">
<form class="ams-auth-form">
    <div class="ams-form-group">
            <i class="ams-f-size12 icon-email ams-form-icon ams-form-icon-left"></i>
            <Field type="text" name="email" class="ams-form-control ams-custom-control" placeholder="Email Id" v-model="email" />
            <ErrorMessage name="email" class="ams-val-err" />
    </div>
    <div class="ams-form-group">
        <button type="button" class="ams-btn ams-btn-primary-grad ams-btn-block" @click="handleSubmit(registerFlag)">Send to registered mail</button>
    </div>
</form>
</Form>
</template>

<script>
import API from '../../servcies/api.js';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from "yup";
export default {
    data() {
        const forgotValidate=Yup.object().shape({
           email: Yup.string().email("Please Enter Valid Email").required("Please Enter Email"),
        });
        return {
            email: '',
            forgotValidate
        };
    },
    methods: {
        registerFlag() {
            let self = this
            self.$store.dispatch('common/SET_LOADER', true)
            let reqObj = {
                email: self.email
            }
            API.forget(reqObj, function(response) {
                console.log("response", response)
                if (response && response.status === 1000) {
                    self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "Forget Password Send Successfully to your Email",
                        class: "ams-error-success",
                    });
                    self.$emit('forgetEvent', false)
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', { msg: response.message || 'Something went wrong. Please try again', class: 'ams-error-danger'})
                }
                self.$store.dispatch('common/SET_LOADER', false)
            });
            // this.$emit('send-register', '')
        }
    },
    components: {
        Form,Field,ErrorMessage
    }
};
</script>
