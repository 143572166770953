<template>
<div>
    <Form :validation-schema="subUserValidate" v-slot="{ handleSubmit }">

        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/users')"></i>Users - Create new sub user login</h3>
        <form class="ams-card">
            <div class="ams-row">
                <!-- <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Sub user Id</label>
                        <Field v-model="subuser.usr_id" type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="userid" id="licensetype" />
                        <ErrorMessage name="userid" class="ams-val-err" />
                    </div>
                </div> -->
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Name</label>
                        <Field type="text" v-model="subuser.fn" class="ams-form-control ams-custom-control-dark" placeholder="Name" name="name" id="licensetype" />
                        <ErrorMessage name="name" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12" >
                    <div class="ams-form-group">
                        <label>Email Id</label>
                        <Field type="text" v-model="subuser.email" :readonly="disabled == 1" class="ams-form-control ams-custom-control-dark" placeholder="Email Id" name="email" id="licensetype" />
                        <ErrorMessage name="email" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-phn-no">
                        <label>Contact number</label>
                        <div class="ams-phone-no">
                         <input @click="openPopup()" placeholder="+91" :value="crescode" readonly/>
                        <Field type="number" v-model="subuser.ph_num" placeholder="Contact number" name="number" id="licensename" />
                        </div>
                        <ErrorMessage name="number" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Designation</label>
                        <Field type="text" v-model="subuser.desg" class="ams-form-control ams-custom-control-dark" placeholder="Designation" name="designation" id="licenseid" />
                        <ErrorMessage name="designation" class="ams-val-err" />
                    </div>
                </div>
                <!-- <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Password</label>
                        <Field type="text" v-model="subuser.pwd" class="ams-form-control ams-custom-control-dark" placeholder="" name="password" id="Password" />
                        <ErrorMessage name="password" class="ams-val-err" />
                    </div>
                </div> -->
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Role</label>
                        <Multiselect :options="roleList" v-model="subuser.role_id" name="assettag" placeholder="Role" class="ams-form-control ams-custom-control-dark"></Multiselect>
                        <Field type="text" v-model="subuser.role_id" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="Role" name="rolename" />
                        <ErrorMessage name="rolename" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-danger ams-mr20" @click="$router.push('/users/user')">Cancel</button>
                <button class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(createSubUser)" v-if="!flag1">Save</button>
                <button class="ams-btn ams-btn-primary" @click.prevent="updateUser" v-if="flag1">Update</button>
            </div>
        </form>
    </Form>
    <country-code v-if="ccflag" @save-code="saveCode" @close-pop="ccflag = false" />
</div>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
import Multiselect from "@vueform/multiselect";
import CountryCode from "../../components/home/CountryCode.vue"
export default {
    components: {
        Multiselect,
        Field,
        Form,
        ErrorMessage,
        CountryCode
    },
    data() {
        const subUserValidate = Yup.object().shape({
            rolename: Yup.string().required("Please Select Role").nullable(),
            // userid: Yup.string().trim().required("Please Enter user id"),
            number: Yup.string().min(10).max(10).required("Please Enter Contact Number"),
            name: Yup.string().trim().matches(/^[aA-zZ\s]+$/, "Only Alphabets Are Allowed").required("Please Enter Name"),
            email: Yup.string().email("Please Enter Valid Email").required("Please Enter Email"),
            designation: Yup.string().matches(/^[aA-zZ\s]+$/, "Only Characters Are Allowed").required("Please Enter Designation"),
            // password: Yup.string().required("Please Enter  Password")
        });
        return {
            subUserValidate,
            roleList: {},
            ccflag:false,
            flag1: false,
           disabled:0,
            subuser: {
                fn: "",
                desg: "",
                //usr_id: "",
                ph_num: "",
                email: "",
                // pwd: "",
                role_id: "",
                ccode:"91"

            },
            crescode: '+91'

        }

    },
    mounted() {
        let _self = this
        this.$store.dispatch("settings/ROLE_LIST").then(function (res) {
            if (res && res.status == 1000) {
                _self.roleList = res.data
            }
        });
        let self = this
        if (self.$route.params.roleid) {
            self.disabled=1
            self.getEditData()
        }

    },
    methods: {
         saveCode: function (countryFilter) {
            console.log(countryFilter, 'countryFilter')
            let val = countryFilter.label.split(' ')
            this.crescode = val[0]
            this.subuser.ccode= countryFilter.value.toString()
            this.ccflag = false
        },
         openPopup: function () {
            this.ccflag = true
        },
        createSubUser: function () {
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('settings/ADD_SUBUSER', _self.subuser).then(function (res) {
                console.log('RES', res)
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'subuser added successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/users')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        getEditData: function () {
            let _self = this
            let user = _self.$route.params.roleid;
            _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch('settings/USER_ID', user).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    _self.flag1 = true
                    let rdata = res.data
                    _self.subuser.fn = rdata.fn
                    _self.subuser.email = rdata.email
                    _self.subuser.desg = rdata.desg
                    _self.subuser.ph_num = rdata.ph_num
                    _self.subuser.role_id = rdata.role_id
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical Error',
                        class: 'ams-error-danger'
                    })
                }
            })

        },

        updateUser() {
            let _self = this
            let updateReq = {
                email: _self.subuser.email,
                fn: _self.subuser.fn,
                desg: _self.subuser.desg,
                ph_num: _self.subuser.ph_num,
                role_id: _self.subuser.role_id,
                ccode:_self.subuser.ccode
            }
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('settings/UPDATE_SUBUSER', updateReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    // _self.disabled="true"
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Item updated successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/users')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })

        },

    }
};
</script>
