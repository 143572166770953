<template>
<div v-if="ticketData && ticketData.length">
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>Sl.No</th>
                <th>Ticket No.</th>
                <th>Raised by Employee ID</th>
                <th>Date of Ticket Generation</th>
                <th>Expected Date to Close</th>
                <th>Ticket Assigned To</th>
                <th>Priority</th>
            </tr>
        </thead>
        <tbody>
            <tr @click="getData(item.tid)" v-for="(item, i) in ticketData" :key="i">
                <td data-title="SI.NO">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Ticket No."> {{item.tid}} </td>
                <td data-title="Raised by Employee ID"> {{item.emp_id}} </td>
                <td data-title="Date of Ticket Generation"> {{getDate(item.cDate)}} </td>
                <td data-title="Expected Time to Close"> {{getDate(item.expt_time)}} </td>
                <td data-title="Ticket Assigned To"> {{item.assignee}} </td>
                <td data-title="Priority"><span class="ams-btn ams-btn-xs" :class="getcolor(item.priority)"> {{item.priority}} </span></td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total > limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue"
import moment from 'moment'
import {
    pagination_limit
} from "../../../config/endpoint"
export default {
    props: ['ticketData', 'paginationData'],
    data() {
        return {};
    },
    methods: {
        getcolor (color) {
            console.log('color', color)
            if (color === 'High') {
                return 'ams-btn-warning'
            } else if (color === 'Low') {
                return 'ams-btn-success'
            } else if (color === 'Critical') {
                return 'ams-btn-danger'
            }
        },
        getDate(val) {
            return moment(val).format('DD-MM-YYYY')
        },
        getData(val) {
            let self = this
            self.$router.push('/tickets/pending/' + val)
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
    },
    computed: {
        limit() {
            return pagination_limit
        }
    },
    components: {
        Pagination
    }
};
</script>
