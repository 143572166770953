<template>
<div class="ams-card ams-product-filter-card">
    <div class="ams-row ams-d-f-sbetween ams-mb20">
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <input type="text" v-model="searchKey" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Field Name" />
            <i class="icon-search ams-form-icon ams-form-icon-right"></i>
        </div>
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <button type="text" class="ams-btn ams-btn-outline-info ams-btn-sm ams-btn-block" placeholder="Add New Field" @click.prevent="openpopup">
                Add New Field
            </button>
        </div>
    </div>
    <div class="ams-table-height">
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>
                    <!-- <input type="checkbox" id="SI.No" v-model="checked" /> -->
                    <!-- <label for="SI.No"><span class="ams-pl30 ams-mt0"></span></label> -->
                    SI.No
                </th>
                <th>Field Name</th>
                <th>Field Type</th>
                <th>Text Type</th>
                <th>Mandatory</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, i) in countryFilter" :key="item">
                <td>
                    <input type="checkbox" @change="getId(item.field_id, i)" :id="i" v-model="select[i]" :disabled="item.default" />
                    <label :for="i"><span class="ams-pl30 ams-mt0"></span></label>
                    {{ i + 1 }}
                </td>
                <td data-title="Field Name">{{ item.field_name }}</td>
                <td data-title="Field Type">{{ capitalLetters(item.field_type)}}</td>
                <td data-title="Text-Type">{{ item.input_type ? getText(item.input_type): '-' }}</td>
                <td data-title="Mandatory">{{ item.mand ? "Yes" : "No" }}</td>
                <!-- <td data-title="Mandatory"><i class="icon-delete-1 ams-red-text"></i></td> -->
            </tr>
        </tbody>
    </table>
    </div>
  </div>
  <add-new-field v-if="oflag" v-on:close-popup="closepopup" />
</template>

<script>
// import Multiselect from '@vueform/multiselect'
import AddNewField from "../../components/settings/AddNewField.vue";
import _ from "lodash";

export default {
    data() {
        return {
            select: {},
            oflag: false,
            allfields: [],
            result: [],
            searchKey: "",
            textval: ''
        };
    },
    mounted() {
        let self = this;
        self.getData();
    },
    methods: {
        closepopup (event) {
            console.log('CLOSE', event)
            let self = this
            self.oflag = event
            let vm = document.body
			vm.classList.remove('hide-scrollbar');
        },
        openpopup () {
            let vm = document.body
			vm.classList.add('hide-scrollbar');
            let self = this
            self.oflag=true
        },
        getText (val) {
            console.log('val', val)
            // let self = this
            if (val === 'number') {
                return 'Number'
            } else if (val === 'alpha') {
                return 'Alphabet'
            } else if (val === 'alpha_num') {
                return 'Alphabet'
            } else if (val === 'any') {
                return 'Any'
            }
            // switch (val) {
            //     case "number":
            //         self.textval = "Number"
            //         break;
            //     case "alpha":
            //         self.textval = "Alphanate"
            //         break;
            //     case "alpha_num":
            //         self.textval = "Alpha Numberic"
            //         break;
            //     case "any":
            //         self.textval = "Any"
            //         break;
            // }
            // return self.textval
        },
        getId(id, index) {
            let self = this;
            console.log("ID", id, index);
            if (self.select[index]) {
                self.result.push(id);
            } else {
                const index = self.result.indexOf(id);
                if (index > -1) {
                    self.result.splice(index, 1);
                }
                console.log(self.result);
            }
        },
        getData() {
            let self = this;
            self.$store.dispatch("settings/GET_ALL_FIELDS").then(function (res) {
                console.log("RES", res);
                if (res && res.status === 1000) {
                    self.allfields = res.data;
                    _.each(res.data, (e) => {
                        // console.log("E", e);
                        if (e.default) {
                            self.result.push(e.field_id);
                        }
                    });
                }
            });
        },
        capitalLetters: (str) => {
            return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        }
    },
    computed: {
        countryFilter() {
            let _self = this;
            // const serchKey = _self.searchKey.toLowerCase();
            // const cc = serchKey.toString().replace("+", "");
            return _self.allfields.filter((country) => {
                // if((country.label.toLowerCase().indexOf(cc) === 0)){
                // return true;
                // }
                return country.field_name
                    .toLowerCase()
                    .includes(
                        this.searchKey.toLowerCase()
                    );
            });
        },
    },
    components: {
        // Multiselect,
        AddNewField,
    },
};
</script>
