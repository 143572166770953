<template>
<table class="ams-table" cellspacing="0" cellpadding="0">
    <thead>
        <tr>
            <th>SI.No</th>
            <th>Product Name</th>
            <th>Product Category</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="(a,i) in prodTypes" :key="i">
            <td data-title="SI.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
            <td data-title="Product Name">{{a.pName}}</td>
            <td data-title="Product Category">{{catData[a.cType]}}</td>
            <td data-title="Action">
                <button class="ams-btn ams-btn-sm ams-btn-primary" @click="customize(a.pType)">Customzie</button>
                <!-- <i class="icon-delete-1 ams-red-text ams-ml20" @click.stop="showPopup(a.emp_id)"></i> -->
            </td>

        </tr>
    </tbody>
</table>
<!-- <delete-confirmation v-if="vflag" v-on:close-popup="vflag = $event" />  -->
</template>

<script>
//import DeleteConfirmation from "../common/DeleteConfirmation.vue"
export default {
    components: {
        //DeleteConfirmation
    },

    data() {
        return {
            vflag: false,

            cType: undefined,
            catData:{
                1:"Assets",
                2:"Accessories",
                3:"Licenses"
            }

        };
    },
    props: ['prodTypes','paginationData'],
    methods: {
        customize(pType) {
            let self = this
            console.log('pType, cType', pType)
             self.$router.push('/settings/product/edit/' + pType)
        },
        showPopup(val) {
            let self = this
            self.vflag = true
            console.log('vak', val)
        },
         indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
    },
    mounted() {

    },
    
};
</script>
