<template>
	<div class="ams-content">
        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/inventory')"></i>Inventory - Item in Stock - {{parsedData[stockData.pType]}}</h3>

        <form class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset / Accessory name</label>
                        <input type="text" v-model="parsedData[stockData.pType]" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensetype" id="licensetype" :disabled="inflag" />
                    </div>
                </div>
                
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Model number</label>
                        <input type="text" v-model="stockData.model_number" class="ams-form-control ams-custom-control-dark" placeholder="" name="licenseid" id="licenseid" :disabled="inflag" />
                    </div>
                </div>               
           
              
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Vendor name</label>
                        <input type="text"  v-model="stockData.ven_name" class="ams-form-control ams-custom-control-dark" placeholder="" name="vendorname" id="vendorname" :disabled="inflag" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Vendor contact number</label>
                        <input type="text"  v-model="stockData.ven_number" class="ams-form-control ams-custom-control-dark" placeholder="" name="ponumber" id="ponumber" :disabled="inflag" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Vendor email Id</label>
                        <input type="text"  v-model="stockData.ven_email" class="ams-form-control ams-custom-control-dark" placeholder="" name="renewaldate" id="renewaldate" :disabled="inflag" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>No. Of Items</label>
                        <input type="text" v-model="stockData.item" class="ams-form-control ams-custom-control-dark" placeholder="" name="cost" id="cost" :disabled="inflag"/>
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Price</label>
                        <input type="text"  v-model="stockData.price" class="ams-form-control ams-custom-control-dark" placeholder="" name="cost" id="cost" :disabled="inflag"/>
                    </div>
                </div>
                  <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                     <label>Invoice</label>
                      <file-upload :show-upload="true" :show-delete="true" :disText="utext" />
                    </div>
                  </div>
            
                </div> 
                     <div class="ams-text-right ams-table-filter">
                <button class="ams-btn ams-btn-danger ams-mr20">Cancel</button>
                <button class="ams-btn ams-btn-primary">Save</button>
            </div>        
         </form>
	</div>
</template>
<script>
import FileUpload from "../../components/common/FileUpload.vue"
export default {
	components: {
        FileUpload
	},
	data() {
		return {
            utext: 'Click to Add your file',
            inFlag: false,
            stockData:{}
		};
	},
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return {
                ...parsedPT[1],
                ...parsedPT[2],
                ...parsedPT[3]
            }
        },
    },
    methods: {},
        mounted() {
        let ptag = this.$route.params.prodid;
        let _self = this
        _self.$store.dispatch("common/SET_LOADER", true);
        _self.$store.dispatch('inventory/INVENTORY_ID', ptag).then(function (res) {
            _self.$store.dispatch("common/SET_LOADER", false);
            console.log('res', res)
            if (res && res.status == 1000) {
                _self.stockData = res.data
                _self.inflag = true
            } else {
                _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Technical Error',
                    class: 'ams-error-danger'
                })
            }
        })

    
    }
};
</script>
