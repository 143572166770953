<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Support No.</th>
                <th>Support Email</th>
                <!-- <th>Status</th> -->
                <!-- <th>Edit</th> -->
                <th>Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in manufactureList" :key="i" @click.stop="manufactureId(a.man_id,a.status)">
                <td data-title="1">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Name">
                    <span><img :src="a.man_logo" alt="avtar" class="ams-table-img"> </span>
                    {{a.man_name}}</td>
                <td data-title="Contact No.">{{a.ph_num}}</td>
                <td data-title="Email ID">{{a.email}}</td>
                <!-- <td data-title="Action"><i class="icon-edit-box ams-f-size18" ></i></td> -->
                <td data-title="Action"><i class="ams-f-size20 icon-delete-1 ams-red-text ams-f-size20" @click.stop="showPopup(a.man_id)"></i></td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
        <delete-confirmation v-if="vflag" v-on:close-popup="closePopup" :uid='manID' />
    </div>
</div>
</template>

<script>
import pagination from "../common/Pagination.vue"
import DeleteConfirmation from "../common/DeleteConfirmation.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
export default {
    props: ["man_name"],
    components: {
        pagination,
        DeleteConfirmation
    },
    data() {
        return {
            page: 1,
            manuafctureList: null,
            paginationData: null,
            manId: '',
            vflag: false,
        };
    },
    methods: {
        listManufacture: function (val) {
            let _self = this
            let reqObj = {
                limit: this.limit,
                page: this.page
            }
            if (_self.man_name) {
                reqObj.man_name = _self.man_name
            }
            console.log('qwerty', reqObj)
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("manufacture/FETCH_MANUFACTURE_TABLE", reqObj).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    _self.manufactureList = res.data.manufacturers
                    _self.paginationData = res.data.metadata[0]
                } else {
                    if (val === 'filter' && res.data && !res.data.length) {
                        _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'No Data Exist',
                            class: 'ams-error-danger'
                        })
                    }
                }
            });

        },
        closePopup() {
            this.vflag = false;
            this.listManufacture();
            let vm = document.body
            vm.classList.remove('hide-scrollbar');
        },
        showPopup(val) {
            let self = this
            self.vflag = true
            self.manID = val
            let vm = document.body
            vm.classList.add('hide-scrollbar');
        },
        deleteFunc: function (manId) {
            let _self = this
            let reqObj = {
                man_id: manId
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("manufacture/MANUFACTURE_DELETE", reqObj)
                .then(function (res) {
                    console.log('res', res)
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.listManufacture();
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Manufacture deleted successfully",
                            class: "ams-error-success",

                        });
                        _self.vflag = false

                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });

        },
        manufactureId: function (a) {
            this.$router.push('/manufacture/edit/' + a)
        },
        indexMethod(pageNumber, pageSize) {
            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.listManufacture()
        },
        changeStatus: function (man_id, active) {
            let _self = this
            _self.$store.dispatch("common/SET_LOADER", true);
            let reqObj = {
                man_id: man_id,
                active: (active == true) ? false : true
            }
            _self.$store.dispatch("manufacture/STATUS_MANUFACTURE", reqObj)
                .then(function (res) {
                    if (res && res.status == 1000) {
                        console.log("responseeee", res);
                        _self.listManufacture()
                    }
                    _self.$store.dispatch("common/SET_LOADER", false);
                })

        },
    },

    mounted() {
        this.listManufacture();
    },
    computed: {
        limit: function () {
            return pagination_limit
        }
    }
};
</script>
