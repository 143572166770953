<template>
<div class="ams-card ams-card-border">
    <h3 class="ams-tit">Plan Details</h3>
    <ul class="ams-pay-det-list">
        <li>Plan : <strong>Monthly Plan</strong></li>            
        <li>Payable Amount : <strong>Rs. 750.00</strong></li>            
        <li>Designation : <strong>IT Admin</strong></li>   
        <li>Email Id : <strong>itadmin@logitechsolutions.com</strong></li>
        <li> Contact Number :<strong> +91 9765 433 424</strong></li>         
    </ul>
</div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
