<template>
	<div>
        <table class="ams-table" cellspacing="0" cellpadding="0">
            <thead>
                <tr>
                    <th>SI.No</th>
                    <th>Role Name</th>
                    <th>Modified Date</th>
                    <th>Modified By</th>
                </tr>
            </thead>
            <tbody> 
                <tr v-for="(a,i) in roleManagement" :key="i"  @click="roleManage(a.role_id)">
                    <td data-title="SI.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                    <td data-title="Role Name">{{a.role_name}}</td>
                    <td data-title="Modified Date">{{getDate(a.modify_date)}}</td>
                    <td data-title="Modified By">{{a.modify_by ? a.modify_by : '-'}}</td>
                </tr>
            </tbody>
        </table>
       <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
	</div>
</template>

<script>
import moment from "moment";
import Pagination from "../common/Pagination.vue"
import {
    pagination_limit
} from "../../../config/endpoint";

export default {
    props: ["role_name"],
    components: {
        Pagination,
    },
    data() {
        return {
            roleManagement: [],
            page: 1,
            paginationData: null,
        };
    },
    methods: {
        roleData: function (val) {
            let _self = this
            let reqObj = {
                page: this.page,
                limit: this.limit
            }
            if (_self.role_name) {
                reqObj.role_name = _self.role_name
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("settings/ROLE_TABLE",reqObj)
                .then(function (res) {
                    console.log('res', res)
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.roleManagement = res.data.roles
                        _self.paginationData = res.data.metadata[0]

                    } else {
                        _self.roleManagement = []
                        if (val === 'filter' && res.data && res.data.roles && !res.data.roles.length) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'No Data Exist',
                        class: 'ams-error-danger'
                    })
                }
                    }
                    // else {
                    //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    //         msg: "Technical error",
                    //         class: "ams-error-danger",
                    //     });
                    // }
                });
        },
        setPageData: function (page) {
            this.page = page
            this.roleData()
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
         getDate : function(date){
             console.log('DATE', date)
             if (date) {
                return moment(date).format('DD-MM-YYYY');
             } else {
                 return '-'
             }

        },
         roleManage: function (val) {
            this.$router.push('/users/role/edit/' + val) 
        }

       

    },
     computed: {
            limit: function () {
                return pagination_limit
            }
        },
        mounted() {

            this.roleData();
        },
};
</script>
