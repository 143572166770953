<template>
  <div>
    <div class="ams-form-group" v-if="!passwordFlag">
      <h5 class="ams-mb10">Enter your code !</h5>
      <small
        >Please enter your code you have received in your registered mobile
        number.</small
      >
      <div class="ams-row ams-mt20">
        <div class="ams-col3">
          <input
            ref="digit_0"
            type="tel"
            v-model="digit_0"
            class="ams-form-control ams-custom-control ams-text-center"
            @input="focusInput('0')"
          />
        </div>
        <div class="ams-col3">
          <input
            ref="digit_1"
            type="tel"
            v-model="digit_1"
            class="ams-form-control ams-custom-control ams-text-center"
            @input="focusInput('1')"
            @keyup.delete="$refs.digit_0.focus()"
          />
        </div>
        <div class="ams-col3">
          <input
            ref="digit_2"
            type="tel"
            v-model="digit_2"
            class="ams-form-control ams-custom-control ams-text-center"
            @input="focusInput('2')"
            @keyup.delete="$refs.digit_1.focus()"
          />
        </div>
        <div class="ams-col3">
          <input
            ref="digit_3"
            type="tel"
            maxlength="1"
            v-model="digit_3"
            class="ams-form-control ams-custom-control ams-text-center"
            @keyup.delete="$refs.digit_2.focus()"
          />
        </div>
      </div>
      <div class="ams-text-right ams-mtb20">
        <small
          >You will get OTP in -
          <span class="ams-blue-text">{{ timer }}</span></small
        >
      </div>
      <button
        :disabled="btnflag"
        type="button"
        class="ams-btn ams-btn-primary ams-btn-block"
        @click="confirmOTP"
      >
        Confirm
      </button>
    </div>
    <new-password v-if="passwordFlag" />
  </div>
</template>
<script>
import API from "../../servcies/api.js";
import NewPassword from "./NewPassword.vue";
export default {
  props: ["userReq"],
  data() {
    return {
      digit_0: "",
      digit_1: "",
      digit_2: "",
      digit_3: "",
      passwordFlag: false,
      countDown: 500,
      flag: false,
      one: true,
      two: true,
      three: true,
      four: true
    };
  },
  methods: {
    removeComp() {
      let self = this
      let userVal = JSON.parse(localStorage.getItem("userInfo"));
      let ureq =  {
        ccode: userVal.ccode,
        ph_num: userVal.ph_num,
        comp_id: userVal.comp_id
      }
      API.removeComp(ureq, function (response) {
        console.log("res", response);
        if (response && response.status === 1000) {
          self.$emit("counter-stop", false);
        } else {
          self.$store.dispatch("common/SET_ALERTMESSAGE", {
            msg: response.message || "Something went wrong. Please try again",
            class: "ams-error-danger",
          });
        }
      });
    },
    focusInput(fd) {
      if (this["digit_" + fd].length > 1) {
        let val = this["digit_" + fd].charAt(1);
        this["digit_" + fd] = this["digit_" + fd].slice(0, 1);
        this["digit_" + (1 + parseInt(fd))] = val;
        this.$refs["digit_" + (1 + parseInt(fd))].focus();
      }
      if (this["digit_" + fd] != "" && parseInt(this["digit_" + fd]) > -1) {
        this.$refs["digit_" + (1 + parseInt(fd))].focus();
      } else {
        this["digit_" + fd] = null;
      }
    },
    confirmOTP() {
      let self = this;
      if (self.otpCode) {
        let userVal = JSON.parse(localStorage.getItem("userInfo"));
        console.log(userVal);
        let ureq = {
          ccode: userVal.ccode,
          ph_num: userVal.ph_num,
          comp_id: userVal.comp_id,
          otp: self.otpCode,
        };
        console.log("ureq", ureq);
        API.verifyOtp(ureq, function (response) {
          console.log("res", response);
          if (response && response.status === 1000) {
            // localStorage.setItem('userInfo', response.data)
            self.passwordFlag = true;
          } else {
            self.$store.dispatch("common/SET_ALERTMESSAGE", {
              msg: response.message || "Something went wrong. Please try again",
              class: "ams-error-danger",
            });
          }
        });
      }
    },
    otpCountdown() {
      console.log("countDown mounted", this.countDown);
      if (this.countDown) {
        this.otpTimer = setInterval(() => {
          this.countDown--;
        }, 1000);
      }
    },
  },
  computed: {
    timer() {
      if (this.countDown >= 0) {
        console.log("countdown", this.countDown);
        var minutes = Math.floor(this.countDown / 60);
        // console.log('minute', minutes)
        var seconds = this.countDown - minutes * 60;
        // console.log('seconds', seconds)
        let time = `0${minutes}:${seconds >= 10 ? seconds : "0" + seconds}`;
        // if (time === "00:00") {
        //   //   this.eflag = true
        //   return "00:00";
        // } else {
        return time;
        // }
      } else {
        return "00:00";
      }
    },
    otpCode() {
      let self = this;
      if (self.digit_0 && self.digit_1 && self.digit_2 && self.digit_3) {
        return `${this.digit_0}${this.digit_1}${this.digit_2}${this.digit_3}`;
      } else {
        return "";
      }
    },
    btnflag () {
      let self = this
      if (!self.one && !self.two && !self.three && !self.four) {
        return false
      } else {
        return true
      }
    }
  },
  created() {
    this.otpCountdown();
  },
  watch: {
    timer: {
      immediate: true,
      handler() {
        let self = this;
        if (self.timer === "00:00") {
          console.log("COUNTER STOP");
          self.removeComp();
        } else {
          console.log("STILL RUNNING");
        }
      },
    },
    digit_0: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_0) {
          self.one = false
        } else {
          self.one = true
        }
      }
    },
    digit_1: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_1) {
          self.two = false
        } else {
          self.two = true
        }
      }
    },
    digit_2: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_2) {
          self.three = false
        } else {
          self.three = true
        }
      }
    },
    digit_3: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_3) {
          self.four = false
        } else {
          self.four = true
        }
      }
    }
  },
  components: {
    NewPassword,
  },
};
</script>
