<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">

        <thead>
            <tr>
                <th>Sl.No</th>
                <!-- <th>Sub User ID</th> -->
                <th>Field Name</th>
                <th>Field Type</th>
                <th>Text Type</th>
                <th>Mandatory</th>
                <!-- <th>Password</th> -->
                <!-- <th>Action</th> -->
            </tr>
        </thead>
        <tbody>
           <tr v-for="(a,i) in fieldList" :key="i" @click.stop="fieldId(a.field_id)">
                <td data-title="1">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Field Name">{{a.field_name}}</td>
                <td data-title="Field Type">{{capitalLetters(a.field_type)}}</td>
                <td data-title="Text-Type">{{a.input_type ? getText(a.input_type): '-'}}</td>
                <td data-title="Mandatory">{{a.mand ? 'Yes' : 'No'}}</td>
                <!-- <td data-title="Mandatory"><i class="icon-delete-1 ams-red-text" @click.stop="deleteFunc(a.field_id)"></i></td> -->
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <add-new-field v-if="oflag" :oflag="oflag" :field_id="field_id" v-on:close-popup="oflag = $event" v-on:table-api="tableApi"/>
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
<!-- //<delete-confirmation v-if="vflag" v-on:close-popup="closePopup" :uid='subuserID' /> -->
</template>

<script>
import AddNewField from "../../components/settings/AddNewField.vue";
import pagination from "../common/Pagination.vue";
import {
    pagination_limit
} from "../../../config/endpoint";

export default {
    props: ['field_name'],
    components: {
        AddNewField,
        pagination,
    },
    data() {
        return {
            oflag: false,
            page: 1,
            fieldList: [],
            paginationData: null,
            field_id: null,
            textval: ''
        };
    },
    mounted() {
        this.fieldListing();
        console.log('fieldgffggiddd',this.field_id)
    },
    computed: {
        limit: function () {
            return pagination_limit

        }
    },
    methods: {
        getText (val) {
            console.log('val', val)
            // let self = this
            if (val === 'number') {
                return 'Number'
            } else if (val === 'alpha') {
                return 'Alphabet'
            } else if (val === 'alpha_num') {
                return 'Alphabet'
            } else if (val === 'any') {
                return 'Any'
            }
            // switch (val) {
            //     case "number":
            //         self.textval = "Number"
            //         break;
            //     case "alpha":
            //         self.textval = "Alphabet"
            //         break;
            //     case "alpha_num":
            //         self.textval = "Alpha Numberic"
            //         break;
            //     case "any":
            //         self.textval = "Any"
            //         break;
            // }
            // return self.textval
        },
        capitalLetters: (str) => {
            return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        },
        // addingfeild () {
        //     let self = this
        //     self.field_id = null
        //     self.oflag = true
        // },
        tableApi(){
            this.fieldListing();
        },
        fieldListing: function (val) {
            let _self = this
            let reqObj = {
                // limit: this.limit,
                limit: 8,
                page: this.page
            }
            if(_self.field_name){
                reqObj.field_name = _self.field_name
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("settings/FIELD_TABLE", reqObj).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    _self.fieldList = res.data.fields
                    _self.paginationData = res.data.metadata[0]
                } else {
                    if (val === 'filter' && res.data && !res.data.length) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'No Data Exist',
                        class: 'ams-error-danger'
                    })
                }
                }
            });
        },
        deleteFunc: function (field_id) {
            let _self = this
            let reqObj = {
                field_id: field_id
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("settings/FIELD_DELETE", reqObj)
                .then(function (res) {
                    console.log('res', res)
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.fieldListing();
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Field Deleted Successfully",
                            class: "ams-error-success",

                        });
                       // _self.vflag = false

                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });
        },
        indexMethod(pageNumber, pageSize) {
            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.fieldListing()
        },
         fieldId: function (a) {
            // this.$router.push('/settings/product/add/' + a);
            this.oflag=true
            this.field_id = a
            
        },

    }
};
</script>

