/**
 * @description User Store Module
 * @author Harikrishna
 */

export default {
    namespaced: true,
    state: {
        userDetails: null,
        token: null,
        compDetails: null
    },
    mutations: {
        SET_USERDETAILS: (state, userDetails) => {
            state.userDetails = userDetails
        },
        SET_COMPDETAILS: (state, compDetails) => {
            state.compDetails = compDetails
        },
        SET_USERTOKEN: (state, token) => {
            state.token = token
        }
    },
    actions: {
        SET_USERDETAILS({ commit }, payload) {
            console.log('USER', payload)
            commit('SET_USERDETAILS', payload);
        },
        SET_COMPDETAILS({ commit }, payload) {
            console.log('USER', payload)
            commit('SET_COMPDETAILS', payload);
        },
        SET_USERTOKEN({ commit }, payload) {
            commit('SET_USERTOKEN', payload);
        }
    },
    getters: {
        getUserDetails: state => state.userDetails,
        getUserToken: state => state.token,
        getCompDetails: state =>  state.compDetails
    }
}
