<template>
    <h3 class="ams-tit ams-mt10">Close Ticket</h3>
    <form>
    <div class="ams-row">
        <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
                <Multiselect v-model="iss_status" :options="options" class="ams-form-control ams-custom-control-dark" :disabled='dflag' placeholder="Close Ticket"></Multiselect>
            </div>
        </div>
        <!-- <div class="ams-col9 ams-col-md9 ams-col-sm12 ams-col-xs12 ams-text-right">
            <button type="button" class="ams-btn ams-btn-outline-info">Assign ticket</button>
        </div> -->
        <div class="ams-col12">
            <div class="ams-form-group">
                <textarea v-model="comment" class="ams-form-control" :disabled='dflag'/>
            </div>
        </div>
    </div>
    <assign-ticket v-if="AssetFlag"/>
    </form>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import AssignTicket from "../../components/tickets/AssignTicket.vue"

export default {
    props: ['closData'],
	data() {
		return {
            dflag: false,
            iss_status: '',
            comment: '',
            options: [
                'Issue Resolved',
                'Item Replaced',
                'Others',
            ] 
		};
	},
    mounted () {
        let self = this
        let query = self.$route.query
        if (query.type === 'close') {
            self.dflag = true
        }
    },
    watch: {
        closData: {
            immediate: true,
            handler () {
                let self = this
                self.iss_status = self.closData.iss_status
                self.comment = self.closData.comment
            }
        }
    },
    methods: {
    },
    components: {
        Multiselect,
        AssignTicket
	}
};
</script>