<template>
<div>
    <Form :validation-schema="productValidation" v-slot="{ handleSubmit }" class="ams-product-filter-btn">
        <div  v-if="$route.params.action =='edit'">
          <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/settings')"></i>Edit Product</h3>
          </div>
        <h3 class="ams-tit"  v-if="$route.params.action !='edit'"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/settings')"></i>Add Product</h3>
        <div class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Name</label>
                        <Field type="text" class="ams-form-control ams-custom-control-dark" :disabled="eflag"  placeholder="Name" name="productname" id="name" v-model="productName" />
                        <ErrorMessage name="productname" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Category Type</label>
                        <Multiselect v-model="value" :options="options" placeholder="Category Type" class="ams-form-control ams-custom-control-dark" name="category" id="type" :disabled="eflag"/>
                        <Field type="text" v-model="value" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="Category Type" name="category" :disabled="eflag"/>
                        <!-- <Field v-model="value" type="category" :options="options"  class="ams-form-control ams-custom-control-dark" name="category" id="type"/> -->
                        <ErrorMessage name="category" class="ams-val-err" />
                    </div>

                </div>
            </div>

        </div>
        <!-- <default-fields /> -->
        <Add-fields ref="addFields" />
        <div class="ams-table-filter ams-filter-card-btn">
            <button class="ams-btn ams-btn-danger ams-mr20" @click.prevent="$router.push('/settings/product')">Cancel</button>
            <button v-if="eflag" class="ams-btn ams-btn-primary ams-mr20" @click.prevent="handleSubmit(updateProduct)">Save</button>
            <button v-else class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(addProduct)">Save</button>
        </div>
    </Form>
</div>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
import AddFields from "./AddFields.vue"
import Multiselect from '@vueform/multiselect'
export default {
    data() {
        const productValidation = Yup.object().shape({
            productname: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Special Characters Not Allowed ").required('Please Enter Field Name'),
            category: Yup.string().required('Please Select Category').nullable()
        })
        return {
            productValidation,
            productName: "",
            value: "",
            options: {
                1: 'Assets',
                2: 'Accessories',
                //3: 'License',
            },
            eflag: false
        };
    },
    mounted () {
        let self = this
        if(self.$route.params.roleid) {
            self.getData(self.$route.params.roleid)
        }
    },
    methods: {
        getData (id) {
            console.log('IDDDDD', id)
            let self = this
            // let req = {
            //     prod_tag: id
            // }
            // console.log('req', req)
            self.$store.dispatch("settings/GET_BY_ID", id).then(function (res) {
                console.log("ID RES", res);
                if (res && res.status === 1000) {
                    console.log('res', res)
                    self.productName = res.data.pName
                    self.value = res.data.cType
                    self.eflag = true
                }
            });
        },
        addProduct() {
            let self = this
            let addFd = self.$refs['addFields']
            let req = {
                name: self.productName,
                cType: self.value,
                fields: addFd.result
            }
            console.log('REQ', req)
            self.$store.dispatch("settings/ADD_PRODUCT", req).then(function (res) {
                console.log("RES", res);
                if (res && res.status === 1000) {
                    console.log('res')
                    let pid = res.data.prod_id.toString()
                    let prodData = JSON.parse(localStorage.getItem('pt'))
                    let obj = prodData[self.value]
                    obj[pid] = self.productName
                    prodData[self.value] = obj
                    localStorage.setItem('pt', JSON.stringify(prodData))
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Product added successfully',
                        class: 'ams-error-success'
                    })
                    self.$router.push('/settings/product')
                }
            });
        },
        updateProduct() {
            let self = this
            let addFd = self.$refs['addFields']
            let req = {
                pName: self.productName,
                cType: self.value,
                prop: addFd.result,
                pType: self.$route.params.roleid
            }
            console.log('REQ', req)
            self.$store.dispatch("settings/UPDATE_PRODUCT", req).then(function (res) {
                console.log("RES", res);
                if (res && res.status === 1000) {
                    let pid = self.$route.params.roleid
                    let prodData = JSON.parse(localStorage.getItem('pt'))
                    let obj = prodData[self.value]
                    obj[pid] = self.productName
                    prodData[self.value] = obj
                    localStorage.setItem('pt', JSON.stringify(prodData))
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Product updated successfully',
                        class: 'ams-error-success'
                    })
                    self.$router.push('/settings/product')
                }
            });
        }
        // getDefaultFields (val) {
        //     console.log('se', val)
        //     let self = this
        //     let req = {
        //         cType:  val
        //     }
        //     self.$store.dispatch("settings/GET_DEFAULT_FIELDS", req)
        //         .then(function (res) {
        //             console.log('RES', res)
        //             if (res && res.status === 1000) {
        //                 self.defaultfields = res.data
        //             }
        //         })
        // }
        // cancel: function (){
        // let _self=this
        // _self.$router.push('/settings/products')
        // },
        // saveProduct: function () {
        //     let _self = this;
        //     let addFields = _self.$refs['addFields']
        //     let arr = addFields.newArray
        //     let addPro = {
        //         name: _self.productName,
        //         cType: _self.value,
        //         fields: arr

        //     };
        //     console.log(addPro, 'hhhhhhhhhh');
        //     _self.$store.dispatch("common/SET_LOADER", true);
        //     this.$store.dispatch("settings/ADD_PRODUCT", addPro)
        //         .then(function (res) {
        //             console.log('res', res)
        //             _self.$store.dispatch("common/SET_LOADER", false);
        //             if (res && res.status == 1000) {
        //                 let pid = res.data.prod_id.toString()
        //                 let prodData = JSON.parse(localStorage.getItem('pt'))
        //                 let obj = prodData[_self.value]
        //                 obj[pid] = _self.productName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        //                 prodData[_self.value] = obj
        //                 localStorage.setItem('pt', JSON.stringify(prodData))
        //                 _self.$store.dispatch('common/SET_ALERTMESSAGE', {
        //                     msg: 'Product added successfully',
        //                     class: 'ams-error-success'
        //                 })
        //                 _self.$router.push("/settings/product");
        //                 } else {
        //             _self.$store.dispatch("common/SET_ALERTMESSAGE", {
        //                 msg: "Technical error",
        //                 class: "ams-error-danger",
        //             })
        //             }
        //         });
        // }
    },
    components: {
        AddFields,
        Multiselect,
        Field,
        Form,
        ErrorMessage
    }
};
</script>
