<template>
<Form :validation-schema="assigndesign" v-slot="{ handleSubmit }">
    <div class="ams-card">
        <h3 class="ams-tit">Assign / Deassign Asset</h3>
        <div class="ams-row">
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Employee Name </label>
                    <Multiselect v-model="empName" name="assignstatus" @change="setEmpId" :options="empList" class="ams-form-control ams-custom-control-dark" placeholder="Employee Name"></Multiselect>
                    <ErrorMessage name="assignstatus" class="ams-val-err" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Employee Id </label>
                    <input  type="text"  readonly="readonly" v-model="emp_id" class="ams-form-control ams-custom-control-dark" placeholder="Employee Id"  />
                </div>
            </div>
            <!-- <div class="ams-col3">
            <div class="ams-switch">
                <input type="checkbox" :checked="isEnabled" id="isEnabled" name="isEnabled" />
                <label for="isEnabled"> <span class="ams-input-box"></span> </label>
            </div>
        </div> -->
            <!-- <div class="ams-col12">
                        <div class="ams-form-group">
                            <input type="textarea" class="ams-form-control" placeholder="" name="software" id="software" />
                        </div>
                    </div> -->

        </div>
        <div class="ams-table-filter">
            <button v-if="$route.params.prodid" class="ams-btn ams-btn-danger" @click="$router.push('/assets')">Cancel</button>
            <button v-if="$route.params.prodid" class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(saveAsset)">Save</button>
        </div>
    </div>
</Form>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { Form, ErrorMessage } from 'vee-validate';
import * as Yup from "yup";

export default {
    // props: ['viewProduct', 'empList'],
    data() {
        const assigndesign = Yup.object().shape({
           // assignstatus: Yup.string().required("please select assign/deassign")
        })
        return {
            empName: null,
            emp_id: '',
            assigndesign,
            empList: []
        }
    },
    mounted () {
        let self = this
        self.getEmployees()
    },
    methods: {
        setEmpId: function (id) {
            // this.$parent.emp_id = id
            let self = this
            self.emp_id = id
        },
        saveAsset: function () {
            let _self = this
            let productReq = {
                prod_tag: _self.$route.params.prodid,
                emp_id: _self.emp_id ? [_self.emp_id] : [],
                cType: '1'
            }
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('product/UPDATE_PRODUCT_ASSIGN', productReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Product Updated Successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/assets')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        getEmployees: async function () {
            let _self = this
            let empResponse = await _self.$store.dispatch("employee/FETCH_EMPLOYEE_SELECT")
            if (empResponse && empResponse.status == 1000) {
                _self.empList = empResponse.data
            }
        },
    },
    components: {
        Multiselect,
        Form,
        ErrorMessage
    }
}
</script>
