<template>
<div>
   <!-- // <report-download /> -->
   <div class="ams-card">
    <div v-if="depreciationList && depreciationList.length" class="ams-row ams-d-f-sbetween">
        <div class="ams-col4 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-two-calender ams-d-f-a-center">
                <label class="ams-two-cal-label">Select DOP Date</label>
                <datepicker v-model="date" @cleared="clearDate" range twoCalendars :enableTimePicker="false" autoApply :closeOnAutoApply="true" class="ams-form-control ams-custom-control" placeholder="Start Date - End Date" />
                <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
        <div class="ams-col2 ams-col-md3 ams-col-sm3 ams-col-xs12">
            <div class="ams-form group">
               <a v-if="date && date.length" class="ams-btn ams-btn-outline-info" download :href="downloadDepreciationDetails"><i class="icon-download ams-primary-color ams-cursor ams-mr10"></i> Download</a>
            </div>
        </div>
    </div>
</div>
    <table v-if="depreciationList && depreciationList.length" class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>SI.No</th>
                <th>Product Tag</th>
                <th>Product Name</th>
                <th>DOP ( Date of Purchase )</th>
                <th>No. of Years</th>
                <th>Maintenance Cost</th>
                <th>Purchase Cost</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in depreciationList" :key="i">
                <td data-title="SI.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Product Tag">{{a.prod_tag}}</td>
                <td data-title="Product Name">{{a.prod_name}}</td>
                <td data-title="DOP ( Date of Purchase )">{{getDate(a.dop)}}</td>
                <td data-title="No. of Years">{{a.no_of_years}}</td>
                <td data-title="Maintenance Cost">{{a.maintenance_cost}}</td>
                <td data-title="Purchase Cost">{{a.purchase_cost}}</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
    <div class="ams-table-filter ams-mt20">
        <pagination />
        <!-- <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
                <Multiselect v-model="value" :options="options" class="ams-form-control ams-custom-control"></Multiselect>
            </div>
        </div> -->
    </div>
</div>
</template>

<script>
//import ReportDownload from "../common/ReportDownload.vue"
import Pagination from "../common/Pagination.vue"
//import Multiselect from '@vueform/multiselect'
import Datepicker from 'vue3-date-time-picker';
import moment from "moment";
import {
    pagination_limit
} from "../../../config/endpoint";

import {
    ams_hub
} from "../../../config/endpoint";

export default {
    components: {
       // ReportDownload,
        Pagination,
       // Multiselect,
        Datepicker

    },
    data() {
        return {
            isShow: false,
            page: 1,
            date:[],
            depreciationList: [],
            paginationData: null,
        };
    },
     mounted() {
        this.listDepreciation();

    },
    computed: {
        limit: function () {
            return pagination_limit
        },
         downloadDepreciationDetails: function () {
            let _self = this
            let reqObj = {
                to_date: moment(_self.date[1]).format('YYYY-MM-DD'),
                from_date: moment(_self.date[0]).format('YYYY-MM-DD')
            }
            return ams_hub + '/report/depreciationreport?from_date=' + reqObj.from_date + '&to_date=' + reqObj.to_date + '&token=' + encodeURIComponent(localStorage.getItem('token'))
        },
         
    },
    methods: {
        clearDate (){
         this.listDepreciation()
        },
        listDepreciation: function (val) {
            let _self = this

            let reqObj = {
                limit: this.limit,
                page: this.page
            }
            if (_self.date && _self.date.length) {
                reqObj.from_date = _self.date[0]
                reqObj.to_date = _self.date[1]
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("reports/FETCH_DEPRECIATION_TABLE", reqObj)
                .then(function (res) {
                    console.log('RES', res)
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.depreciationList = res.data.data
                        _self.paginationData = res.data.metadata[0]
                    } else {
                        if(val === 'filter' && res.data && !res.data.length) {
                            _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                                msg: "No Data exits",
                                class: "ams-error-danger",
                            });
                        }
                    }
                });

        },
         getDate : function(date){

            return moment(date).format('DD-MM-YYYY');

        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.listDepreciation()
        },
       
    },
    watch: {
        date: {
            immediate: false,
            handler () {
                let self = this
                if(self.date && self.date.length) {
                    self.listDepreciation('filter')
                }
            }
        }
    }
};
</script>
