<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>Sl.No</th>
                <th>Ticket No.</th>
                <th>Raised by Employee ID</th>
                <th>Date of Ticket Generation</th>
                <th>Date to Close</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, i) in ticketData" :key="i" @click="getData(item.tid)">
                <td data-title="SI.NO">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Ticket No."> {{item.tid}} </td>
                <td data-title="Raised by Employee ID"> {{item.emp_id}} </td>
                <td data-title="Date of Ticket Generation"> {{getDate(item.cDate)}} </td>
                <td data-title="Date to Close"> {{getDate(item.expt_time)}} </td>
                <td data-title="Status" class="ams-red-text">Close</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total > limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
import moment from 'moment'

export default {
    props: ['ticketData', 'paginationData'],
    data() {
        return {};
    },
    methods: {
        getDate(val) {
            return moment(val).format('DD-MM-YYYY')
        },
        getData(val) {
            let self = this
            self.$router.push('/tickets/close/' + val)
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
    },
    computed: {
        limit() {
            return pagination_limit
        }
    },
    components: {
        Pagination
    }
};
</script>
