<template>
	<div>      
       <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/checkin-checkout-data')"></i>Check In / Check Out data</h3>
        <form class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset name</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensetype" id="licensetype" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset tag</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensename" id="licensename" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Employee Id</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licenseid" id="licenseid" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Checkin Date & Time</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="Password" id="Password" />
                    </div>
                </div>
           
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Checkout Date & Time</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="vendorname" id="vendorname" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Location</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="ponumber" id="ponumber" />
                    </div>
                </div>
            </div>               
                
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-primary ">Save</button>
            </div>
        </form>
	</div>
</template>
<script>
export default {
	components: {
	},
	data() {
		return {
            filterFlag: false
		};
	},
    methods: {
    }
};
</script>
