<template>

  <div class="ams-dialog" v-if="!getQoutePop">
    <div class="ams-dialog-body ams-pro-plan">
        <i class="icon-close-circ ams-close ams-close-circle" @click="closePop"></i>
        <div class="ams-card">
            <h3 class="ams-tit">Change Your Plan</h3>
            <div class="ams-d-flex">
                <div class="ams-plan ams-mo-plan">
                    <h4 class="ams-tit">Monthly Plan</h4>
                    <p>
                        Starter plan, Selected quality resources at an amazing price</p>
                    <span class="ams-price-tag"><small class="icon-rupee"></small> <strong>750</strong> <small>/ Month</small></span>
                    <h6 class="ams-mt30">Billed monthly</h6>
                    <p>as one payment of <small class="icon-rupee"></small> 750*</p>
                    <small class="ams-price-vat">*VAT & Local taxes may apply</small>
                    <button class="ams-btn ams-btn-outline-info ams-btn-lg">
                    Buy Now
                    </button>
                </div>
                <div class="ams-plan ams-qrt-plan">
                    <h4 class="ams-tit">Quarterly Plan</h4>
                    <p>
                        Starter plan, Selected quality resources at an amazing price</p>
                    <span class="ams-price-tag"><small class="icon-rupee"></small> <strong>1500</strong> <small>/ Quarter</small></span>
                    <h6 class="ams-mt30">Billed Quater (3 months)</h6>
                    <p>as one payment of <small class="icon-rupee"></small> 750*</p>
                    <small class="ams-price-vat">*VAT & Local taxes may apply</small>
                    <button class="ams-btn ams-btn-outline-info ams-btn-lg">
                    Buy Now
                    </button>
                </div>
                <div class="ams-plan ams-semi-ann-plan">
                    <h4 class="ams-tit">Semi - Annual Plan</h4>
                    <p>
                        Starter plan, Selected quality resources at an amazing price</p>
                    <span class="ams-price-tag"><small class="icon-rupee"></small> <strong>7500</strong> <small>/ Semi Annual</small></span>
                    <h6 class="ams-mt30">Billed semi annual (6 months)</h6>
                    <p>as one payment of <small class="icon-rupee"></small> 750*</p>
                    <small class="ams-price-vat">*VAT & Local taxes may apply</small>
                    <button class="ams-btn ams-btn-outline-info ams-btn-lg">
                    Buy Now
                    </button>
                </div>
                <div class="ams-plan ams-yrl-plan">
                    <h4 class="ams-tit">Yearly Plan</h4>
                    <p>
                        Starter plan, Selected quality resources at an amazing price</p>
                    <span class="ams-price-tag"><small class="icon-rupee"></small> <strong>12000</strong> <small>/ Year</small></span>
                    <h6 class="ams-mt30">Billed Yearly</h6>
                    <p>as one payment of <small class="icon-rupee"></small> 750*</p>
                    <small class="ams-price-vat">*VAT & Local taxes may apply</small>
                    <button class="ams-btn ams-btn-outline-info ams-btn-lg">
                    Buy Now
                    </button>
                </div>
            </div>
        </div>
        <div class="ams-card ams-mt30 ams-a-center">
            <div class="ams-row">
                <div class="ams-col8"><h3>For customized enterprise plan, Get in touch with our executive.</h3></div>
                <div class="ams-col4 ams-text-right"><button class="ams-btn ams-btn-primary"  @click="getQoute">Get Qoute</button></div>                 
            </div>
        </div>   
    </div>   
  </div>  
  <get-qoute v-if="getQoutePop" v-on:close-popup="closeQoutePop"/>              
</template>
<script>
import getQoute from './getQoute.vue';
export default {
  components: {
      getQoute
      },
  data() {
    return {
        getQoutePop: false
    };
  },
  methods: {
      closeQoutePop:function(){
          this.getQoutePop = false
      },
      getQoute:function(){
          this.getQoutePop = true;
      },
      closePop:function(){
          this.$emit('close-popup','')
      },
  },
};
</script>