<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">

        <thead>
            <tr>
                <th>Sl.No</th>
                <!-- <th>Sub User ID</th> -->
                <th>Name</th>
                <th>Email ID</th>
                <th>Contact Number</th>
                <th>Role Name</th>
                <th>Designation</th>
                <!-- <th>Password</th> -->
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in subuserList" :key="i" @click="userId(a.usr_id)">
                <td data-title="S.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Name">{{a.fn}}</td>
                <td data-title="Email ID">{{a.email}}</td>
                <td data-title="Contact Number">+ {{a.ccode}} {{a.ph_num}}</td>
                <td data-title="Role Name">{{a.role_id}}</td>
                <td data-title="Designation">{{a.desg}}</td>
                <!-- <td data-title="Password">SAN1234</td> -->
                <td data-title="Action">
                    <!-- <i class="icon-edit-box ams-f-size18 ams-primary-text" ></i> -->
                    <i class="icon-delete-1 ams-red-text ams-f-size20 ams-ml20" @click.stop="showPopup(a.usr_id)"></i></td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
<delete-confirmation v-if="vflag" v-on:close-popup="closePopup" :uid='subuserID' />
</template>

<script>
import Pagination from "../common/Pagination.vue"
import DeleteConfirmation from "../common/DeleteConfirmation.vue"
import {
    pagination_limit
} from "../../../config/endpoint";

export default {
     props: ["user_name"],
    components: {
        Pagination,
        DeleteConfirmation
    },
    data() {
        return {
            vflag: false,
            subuserList: null,
            page: 1,
            paginationData: null,
            subuserID: ''

        };
    },

    methods: {
        closePopup() {
            this.vflag = false;
            this.subuserData();
            let vm = document.body
			vm.classList.remove('hide-scrollbar');
        },
        subuserData: function (val) {

            let _self = this
            let reqObj = {
                page: this.page,
                limit: this.limit
            }
             if (_self.user_name) {
                reqObj.fn = _self.user_name
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("settings/LIST_SUBUSER", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);

                    if (res && res.status == 1000) {
                        _self.subuserList = res.data.employees
                        _self.paginationData = res.data.metadata[0]

                    } else {
                        if (val === 'filter' && res.data && !res.data.length) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'No Data Exist',
                        class: 'ams-error-danger'
                    })
                }
                    }
                });

        },
        showPopup(val) {
            let self = this
            self.vflag = true
            console.log(val, 'vasrtttttttttt')
            this.subuserID = val
            let vm = document.body
			vm.classList.add('hide-scrollbar');
        },
        deleteFunc: function (subuserId) {
            let _self = this
            let reqObj = {
                usr_id: subuserId
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("settings/DELETE_SUBUSER", reqObj)
                .then(function (res) {
                    console.log('res', res)
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        //    _self.subuserData();
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Subuser deleted successfully",
                            class: "ams-error-success",

                        });
                        _self.vflag = false

                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });

        },
        userId: function (a) {
            this.$router.push('/users/user/edit/' + a)
        },
        setPageData: function (page) {
            this.page = page
            this.subuserData()
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },

    },
    computed: {
        limit: function () {
            return pagination_limit
        }
    },
    mounted() {

        this.subuserData();
    },
};
</script>
