/**
 * @description Axios HTTP Handler Utility
 * @author Fahid Mohammad
 */
// TODO: Need to handle all types of request in case of post
//       Need to clear unwanted consolses once the final development is over
//       Code refactor is needed, need to find a better solution to handle request and response with a rejection
import axios from 'axios'
import config from '../../config/endpoint'
import store from '../store'
let http = axios.create({
  baseURL: config.ams_hub,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  withCredentials: false
})

http.interceptors.request.use(function (req) {
  req.headers['Authorization'] = localStorage.getItem("token") || store.getters["user/getUserToken"] || ""
  return req
})

http.interceptors.response.use(function (res) {
  if (res && res.data && res.data.status == 2000) {
    store.dispatch('common/SET_ALERTMESSAGE', { msg: "User Session expired. Please login again", class: 'ams-error-danger' })
    setTimeout(function () {
      window.location.replace('/home')
    }, 2000)
  } else {
    return res && res.data
  }
})

export default http
    