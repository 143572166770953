<template>
<div v-if="userData && profileData" class="ams-proile-det">
  <div v-if="eflag" class="ams-avtar-profile" style="position:relative">
  <figure class="ams-avtar ams-avtar-lg">
      <img v-if="profilePic" :src="profilePic" class="ams-img-cover" />
      <img v-else src="https://img.litedev.com/images/unknown-avtar.png" alt="avtar" class="ams-img-cover"> 
    </figure>
    <file-upload :show-upload="true" :show-delete="true" :disText="utext" @fileUploadSuccess="fileData =  $event"></file-upload>
  </div>
  <div v-else-if="!eflag" class="ams-avtar-profile">
  <figure class="ams-avtar ams-avtar-lg">
      <img v-if="profilePic" :src="profilePic" class="ams-img-cover" />
    </figure>
  </div>
 <!-- <file-upload v-else-if="eflag" :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />-->
  <h4>
            {{userData.fn}} 

    <small class="ams-edit-pro"><span class="ams-swap-dir">
      <!-- {{userData.superuser ? 'IT Admin' : 'Subuser'}} -->
    </span> 
    <button v-if="!eflag"  @click="editaction" class="ams-btn ams-btn-text ams-blue-text">Edit</button></small>
  </h4>
</div>
</template>

<script>
import FileUpload from '../common/FileUpload.vue'
export default {
  props: ['userData', 'profileData', 'editflag', 'ppic'],
  data () {
    return {
      eflag: false,
      fileData: {},
      profilePic: '',
      imgunknown: 'https://img.litedev.com/images/unknown-avtar.png'
    }
  },
  methods: {
    editaction () {
      let self = this
      self.eflag = true
      // self.profilePic = ''
      self.profilePic = self.ppic ? self.ppic : self.imgunknown
      self.$emit('editActions', true)
    }
  },
  watch: {
    fileData: {
      immediate: true,
      handler() {
        console.log('complogo', this.fileData)
        if (this.fileData && this.fileData.url) {
          this.profilePic = this.fileData.url
        }
      }
    },
    editflag: {
      immediate: true,
      handler () {
        let self = this
        self.eflag = self.editflag
      }
    },
    ppic: {
      immediate: true,
      handler () {
        let self = this
        console.log('PPIC', self.ppic)
        self.profilePic = self.ppic ? self.ppic : self.imgunknown
      }
    }
  },
  components: {
    FileUpload
  }
}
</script>


