<template>
<div class="ams-auth">
    <div class="ams-header ams-text-right">
        <img src="https://img.litedev.com/ams-icons-images/images/logo.svg" alt="logo" />
    </div>
    <h1><span class="ams-d-block">Welcome to</span> <strong class="ams-dark-text">Maren’s</strong> Asset Management</h1>
    <p>The automated software to manage your assets and people more <span class="ams-d-block">effectively than ever</span></p>
	<div class="ams-d-f-jend">
    <div class="ams-auth-tab">
        <ul class="ams-tabs">
            <li @click="changeTab('signup')" v-bind:class="[tabSelection === 'signup' ? 'ams-active' : '']">Sign Up</li>
            <li @click="changeTab('signin')" v-bind:class="[tabSelection === 'signin' ? 'ams-active' : '']">Sign In</li>
        </ul>
        <div class="ams-tab-cont">
            <signup v-if="tabSelection === 'signup'" />
            <signin v-if="tabSelection === 'signin'" />
        </div>
    </div>
	</div>
</div>
</template>

<script>
import signup from '../../components/home/Signup.vue'
import signin from '../../components/home/Signin.vue'
import { mapGetters } from "vuex"
export default {
    data() {
        return {
            tabSelection: "signin",
        };
    },
    mounted() {
        this.toggleBodyClass("addClass", "ams-auth-bg");
    },
    unmounted() {
        this.toggleBodyClass("removeClass", "ams-auth-bg");
    },
    methods: {
        changeTab(val) {
            let _self = this
            if (val != _self.tabSelection) {
                _self.tabSelection = val
            }
            _self.activeclass = undefined;
        },
        toggleBodyClass: function (addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
    },
    watch: {
        'getAuth': {
            immediate: true,
            handler() {
                console.log('watch')
                let self = this
                if (self.getAuth) {
                    console.log('call')
                    self.tabSelection = 'signin'
                }
            }
        }
    },
    computed: mapGetters(['getAuth']),
    components: {
        signup,
        signin
    }
};
</script>
