<template>
	<div class="ams-content">
		<template v-if="!addCheckDataTableFlag">			
			<checkin-checkout-table v-on:add-CheckDataTable='addCheckDataTable' />
		</template>
		<checkin-checkout-form v-if="addCheckDataTableFlag" />		
	</div>
</template>
<script>
import CheckinCheckoutTable from '../../components/checkin-checkout/CheckinCheckoutTable.vue';
import CheckinCheckoutForm from '../../components/checkin-checkout/CheckinCheckoutForm.vue';

export default {
	components: {
		CheckinCheckoutTable,
		CheckinCheckoutForm

	},
	data() {
		return {
			addCheckDataTableFlag: false
		};
	},
	methods: {
		addCheckDataTable:function (){
			this.addCheckDataTableFlag = true
		}
	}
};
</script>
