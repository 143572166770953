<template>
<Form class="ams-auth-form" :validation-schema="signupschema" v-slot="{ handleSubmit }" v-if="!otpverify">
    <div class="ams-row ams-gutter10 ams-signup-text">
        <div class="ams-col12 ams-col-xs12">
            <div class="ams-form-group">
                <i class="ams-sprite ams-language ams-form-icon ams-form-icon-left"></i>
                <Multiselect v-model="compObj.lang" :options="lang" placeholder="Select Language" class="ams-form-control ams-custom-control"></Multiselect>
                <Field type="text" v-model="compObj.lang" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="language" />
                <ErrorMessage name="language" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group ams-multi-icon">
                <i class="icon-country ams-form-icon ams-form-icon-left"></i>
                <Multiselect v-model="compObj.country" :options="countryCode" placeholder="Select Country" class="ams-form-control ams-custom-control" :searchable="true" track-by="label"></Multiselect>
                <Field type="text" v-model="compObj.country" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="country" />
                <ErrorMessage name="country" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group ams-multi-icon">
                <i class="icon-currency ams-form-icon ams-form-icon-left"></i>
                <Multiselect v-model="compObj.cur" :options="cur" :searchable="true" track-by="label" placeholder="Currency" class="ams-form-control ams-custom-control"></Multiselect>
                <Field type="text" v-model="compObj.cur" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="currency" />
                <ErrorMessage name="currency" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
                <Field name="name" v-model="compObj.name" type="text" class="ams-form-control ams-custom-control" placeholder="Name" />
                <ErrorMessage name="name" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
                <Field name="companyname" v-model="compObj.cmp_name" type="text" class="ams-form-control ams-custom-control" placeholder="Company Name" />
                <ErrorMessage name="companyname" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
                <Field name="designation" v-model="compObj.desg" type="text" class="ams-form-control ams-custom-control" placeholder="Designation" />
                <ErrorMessage name="designation" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
                <Field name="email" v-model="compObj.email" type="text" class="ams-form-control ams-custom-control" placeholder="Email Id" />
                <ErrorMessage name="email" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
                <Field name="employeenumber" v-model="compObj.no_of_emp" type="text" class="ams-form-control ams-custom-control" placeholder="Number of Employees" />
                <ErrorMessage name="employeenumber" class="ams-val-err" />
            </div>
        </div>
        <div class="ams-col6 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
                <div class="ams-phone-no">
                    <!-- <Multiselect v-model="compObj.ccode" placeholder="+91" label="countryName.en" :searchable="true" track-by="label">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                {{'+' +  value.value}}
                            </div>
                        </template>
                        <template v-slot:option="{ option }">
                            {{ option.label }}
                        </template>
                    </Multiselect> -->
                    <input @click="openPopup()" placeholder="+91" :value="crescode" readonly />
                    <Field name="contact" v-model="compObj.ph_num" maxlength="10" type="text" placeholder="Contact Number" />
                </div>
                <ErrorMessage name="contact" class="ams-val-err" />
            </div>
        </div>
    </div>
    <button type="button" class="ams-btn ams-btn-primary-grad ams-btn-block" @click="handleSubmit(sendOtp)">Send OTP</button>
</Form>
<otp-verify v-if="otpverify" :userReq="compObj" @counter-stop="otpverify = $event"/>
<country-code v-if="ccflag" @save-code="saveCode" @close-pop="ccflag = false" />
</template>

<script>
import API from '../../servcies/api.js'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
import Multiselect from '@vueform/multiselect'
import otpVerify from './OTPVerify.vue'
// import  _ from 'lodash'
import CountryCode from "../../components/home/CountryCode.vue"

export default {
    data() {
        const signupschema = Yup.object().shape({
            language: Yup.string().required("Please select Language").nullable(),
            currency: Yup.string().required("Please select currency").nullable(),
            country: Yup.string().required("Please select Country").nullable(),
            name: Yup.string().trim().matches(/^[aA-zZ\s]+$/, "Only Alphabets Are Allowed").required("Please Enter Name"),
            email: Yup.string().email("Please Enter Valid Email").required("Please Enter Email"),
            contact: Yup.string().min(10).matches(/^[0-9]+$/, "Only Numbers Are  Allowed").required("Contact Number is Required"),
            companyname: Yup.string().trim().required("Please Enter Company Name"),
            designation: Yup.string().trim().matches(/^[aA-zZ\s]+$/, "Only Characters Are Allowed").required("Please Enter Designation"),
            employeenumber: Yup.string().matches(/^[0-9]+$/, "Only Numbers Are Allowed").required("Please Enter Number of Employees"),
        });
        return {
            ccflag: false,
            lang: {
                "en": "English",
                "ar": "Arabic"
            },
            cur: ["SAR", "INR", "USD"],
            otpverify: false,
            signupschema,
            countryCode: [],
            crescode: "+91",
            compObj: {
                name: "",
                email: "",
                ph_num: "",
                cmp_name: "",
                desg: "",
                no_of_emp: "",
                ccode: "91",
                lang: "",
                cur: "",
                country: ""
            },
            value: ''
        };
    },
    mounted() {
        let self = this
        API.country(function (response) {
            if (response && response.status === 1000) {
                self.countryCode = response.data
            }
        })
    },
    methods: {
        saveCode: function (countryFilter) {
            console.log(countryFilter, 'countryFilter')
            let val = countryFilter.label.split(' ')
            this.crescode = val[0]
            this.compObj.ccode = countryFilter.value.toString()
            this.ccflag = false
        },
        openPopup: function () {
            this.ccflag = true
        },
        searchValue(e) {
            console.log('e', e)
        },
        sendOtp() {
            let self = this
            self.$store.dispatch('common/SET_LOADER', true)
            // self.compObj.ccode = self.compObj.ccode.toString()
            API.signup(self.compObj, function (response) {
                // setTimeout(() => {
                //     self.$store.dispatch('common/SET_LOADER', false)
                // }, 1000);
                console.log('res', response)
                if (response && response.status === 1000) {
                    localStorage.setItem('userInfo', JSON.stringify(response.data))
                    self.otpverify = true
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: response.message || 'Something went wrong. Please try again',
                        class: 'ams-error-danger'
                    })
                }
                self.$store.dispatch('common/SET_LOADER', false)
            });
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        Multiselect,
        otpVerify,
        CountryCode,
    }
};
</script>
