<template>
<div v-if="userData && profileData" class="ams-card">
    <div class="ams-row ams-d-f-sbetween">
        <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <h3 class="ams-tit">My Profile</h3>
        </div>
        <div v-if="profileData.logo" class="ams-prologo ams-col8 ams-col-md6 ams-col-sm6 ams-col-xs12 ams-d-f-a-center ams-d-f-end">
			<img :src="profileData.logo ? profileData.logo : ''" alt="logo" class="ams-md ams-mr20" />
            <span> {{profileData.cmp_name}} </span>
        </div>
    </div>
    <avatar v-if="userData && profileData" :userData="userData" :profileData="profileData" :ppic="ppic" @editActions="uflag = $event" :editflag="uflag" ref="avatar"/>
    <div class="ams-row ams-profile-view">
        <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <label>Contact number</label>
            <strong> +{{userData.ccode}}  {{userData.ph_num}} </strong>
        </div>
        <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <label>Country</label>
            <strong> {{profileData.country}} </strong>
        </div>
        <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <label>Email ID</label>
            <strong> {{userData.email}} </strong>
        </div>
        <!-- <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <label>Business ID</label>
            <strong> {{userData.email}} </strong>
        </div> -->
    </div>
    <div v-if="uflag" class="ams-col12 ams-text-right ams-col-md6 ams-col-sm12 ams-col-xs12 ams-table-filter">
        <button @click="cancel()" class="ams-btn ams-btn-danger ams-mr20">
            Cancel
        </button>
        <button @click="updateProfile()" class="ams-btn ams-btn-primary">
            Save
        </button>
    </div>
</div>
</template>

<script>
import Avatar from '../common/Avatar.vue';
export default {
    data() {
        return {
            // utext: 'Add Logo',
            // fileData: [],
            uflag: false,
            userData: null,
            profileData: null,
            ppic: ''
        };
    },
    created () {
        // console.log('CREATED')
        let self = this
        // let userDetails = localStorage.getItem('userDetails')
        // console.log('user profile userDetails', (userDetails && (JSON.parse(userDetails).superuser)))
        // let sflag = (userDetails && (JSON.parse(userDetails).superuser))
        // if (!sflag) {
        self.userProfile()
        // }
    },
    methods: {
        // companyLogo: function (data) {
        //     if (data && data.url) {
        //         this.fileData.push({
        //             url: data.url,
        //             file_nme: data.filename
        //         })
        //     }
        // },
        cancel () {
            let self = this
            self.uflag = false
            self.$refs['avatar'].profilePic = self.userData.profile_pic ? self.userData.profile_pic : ''
        },
        userProfile () {
            console.log('user API calling')
            let _self = this;
            _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch("profile/USER_PROFILE", ).then(function (response) {
                console.log('responsessssssssss', response)
                _self.$store.dispatch("common/SET_LOADER", false);
                if (response && response.status === 1000) {
                    _self.profileData = response.data.companyInfo;
                    // _self.packData = response.data.packInfo;
                    // console.log('packdataaa', _self.packData)
                    _self.userData = response.data.userInfo;
                    _self.ppic = _self.userData.profile_pic ? _self.userData.profile_pic : ''
                    // console.log('userdataaa', _self.userData)
                    // _self.vflag = true
                }
            });
        },
        updateProfile() {
            console.log('update')
            let self = this;
            let ureq = {}
            if(self.$refs['avatar'].profilePic) {
                ureq.profile_pic = self.$refs['avatar'].profilePic
            }
            console.log('ureq', ureq)
            /** Profile Pic updating in store */
            let udata = JSON.parse(localStorage.getItem('userDetails'))
            console.log('udata', udata)
            udata.profile_pic = self.$refs['avatar'].profilePic
            self.$store.dispatch("common/SET_LOADER", true);
            self.$store.dispatch("profile/EDIT_PROFILE", ureq).then(function (response) {
            console.log("responsessssssssss", response);
            self.$store.dispatch("common/SET_LOADER", false);
            if (response && response.status === 1000) {
                self.ppic = udata.profile_pic ? udata.profile_pic : ''
                self.$store.dispatch("user/SET_USERDETAILS", udata)
                localStorage.setItem('userDetails', JSON.stringify(udata))
                self.uflag = false
                self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Profile updated successfully',
                    class: 'ams-error-success'
                })
            } else {
                self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Technical error',
                    class: 'ams-error-danger'
                })
            }
            });
        },
    },
    components: {
        Avatar
    }
};
</script>
