<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead v-if="scrapList && scrapList.length">
            <tr>
                <th>SI.No</th>
                <th>Asset / Accessory Name</th>
                <th>Asset / Accessory Tag</th>
                <th>Item Status</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in scrapList" :key="i">
                <td data-title="SI.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Asset / Accessory Name">{{parsedData[a.pType]}}</td>
                <td data-title="Asset / Accessory Tag">{{a.prod_tag}}</td>
                <td data-title="Item Status">{{getDate(a.dos)}}</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter ams-mt20">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
      
    </div>
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue"
import moment from "moment";
import {
    pagination_limit
} from "../../../config/endpoint";

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            isShow: false,
            page: 1,

            scrapList: null,
            paginationData: null,
        };
    },
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            console.log(parsedPT[1], "parseddd");
            console.log(parsedPT[2], "parseddd2222");
            return {
                ...parsedPT[1],
                ...parsedPT[2],

            }
        },
        limit: function () {
            return pagination_limit
        }

    },
    methods: {
        // addAccessories:function(){
        //     this.$emit('add-accessories', '')
        // }
        openInventoryScraped: function () {
            this.$router.push('/inventory/scrap/add')
        },
        listScraped: function () {
            let _self = this

            let reqObj = {
                limit: this.limit,
                page: this.page

            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("inventory/FETCH_SCRAPED_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        console.log('res', res)
                        _self.scrapList = res.data.scrapItems
                        console.log("scrapedList", res.data);
                        _self.paginationData = res.data.metadata[0]
                    } 
                    // else {
                    //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    //         msg: "Technical error",
                    //         class: "ams-error-danger",
                    //     });
                    // }
                });

        },
        getDate: function (date) {

            return moment(date).format('DD-MM-YYYY');

        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.listScraped()
        },
    },
    mounted() {
        this.listScraped();

    },
};
</script>
