<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>SI.No</th>
                <th>Asset / Accessory Name</th>
                <th>Asset / Accessory Tag</th>
                <th>Employee ID</th>
                <th>Item Status</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in repairTable" :key="i" @click="repairId(a.repair_id)">
                <td data-title="S.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Asset Name">{{parsedData[a.pType]}}</td>
                <td data-title="Asset Tag">{{a.prod_tag}}</td>
                <td data-title="Employee ID">{{a.emp_id ? a.emp_id : '-'}}</td>
                <td data-title="Item Status">{{a.prod_status}}</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter ams-mt20">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
export default {
    props:["prod_tag"],
    components: {
        Pagination
    },
    data() {
        return {
            repairTable: null,
            page: 1,
            paginationData: null,

        };
    },
    methods: {
        repairData: function (val) {
            let _self = this
            let reqObj = {
                page: _self.page,
                limit: _self.limit
            }
             if(_self.prod_tag){
                reqObj.prod_tag= _self.prod_tag
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            _self.$store.dispatch("repair/REPAIR_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        console.log(res,'main')
                        _self.repairTable = res.data.repairs
                        _self.paginationData = res.data.metadata[0]

                    } else {
                        if (val === 'filter' && res.data && !res.data.length) {
                         _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                         msg: 'No Data Exist',
                         class: 'ams-error-danger'
                    })
                }
                    }
                });
        },
        addMaintenance: function () {
            this.$emit('add-maintenance', '')
        },
        setPageData: function (page) {
            this.page = page
            this.repairData()
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        repairId: function (a) {
            this.$router.push('/maintenance/edit/' + a)
        }
    },
     computed: {
            limit: function () {
                return pagination_limit
            },
             parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return { ...parsedPT[1], ...parsedPT[2] }
        }
        },
        mounted() {
        this.repairData();
    },
};
</script>
