<template>
<div class="ams-card">
    <h3 class="ams-tit">Assign / Deassign License</h3>
    <div class="ams-row">
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
                <label>Employee Name </label>
                <Multiselect v-model="emp_id" mode="tags" :closeOnSelect="false" :searchable="true" :createTag="true" :options="empList" class="ams-form-control ams-custom-control-dark ams-form-control-multi" placeholder="Employee Name"></Multiselect>
            </div>
        </div>
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
                <label>Employee Id </label>
                <input type="text" readonly="readonly" v-model="emp_id" class="ams-form-control ams-custom-control-dark" placeholder="Employee Id" />
            </div>
        </div>
        <!-- <div class="ams-col3">
            <div class="ams-switch">
                <input type="checkbox" :checked="isEnabled" id="isEnabled" name="isEnabled" />
                <label for="isEnabled"> <span class="ams-input-box"></span> </label>
            </div>
        </div> -->
        <!-- <div class="ams-col12">
          <div class="ams-form-group">
            <textarea
              class="ams-form-control"
              placeholder=""
              name="software"
              id="software"
            />
          </div>
        </div> -->
    </div>
    <div class="ams-table-filter ams-ftr-btn">
        <button class="ams-btn ams-btn-danger" @click="$router.push('/licenses')" v-if="flagTwo">Cancel</button>
        <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="assignLicense()" v-if="flagTwo">Save</button>
    </div>

</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
    props: ["flagTwo", "totalseat"],
    components: {
        Multiselect,
    },
    data() {
        return {
            empList: null,
            emp_id: []
        }
    },
    mounted() {
        this.getEmployees()
    },
    methods: {
        setEmpId: function (id) {
            this.$parent.emp_id = id
        },
        assignLicense: function () {
            let _self = this
            console.log('hjhjhj', _self.totalseat)
            console.log('kk', _self.emp_id.length)
            if (parseInt(_self.totalseat) >= parseInt(_self.emp_id.length)) {
                let assignReq = {
                    "emp_id": _self.emp_id,
                    "prod_tag": _self.$route.params.prodid,
                    cType: '3'
                }
                console.log('k', assignReq)
                _self.$store.dispatch('common/SET_LOADER', true)
                _self.$store.dispatch('product/UPDATE_PRODUCT_ASSIGN', assignReq).then(function (res) {
                    _self.$store.dispatch('common/SET_LOADER', false)
                    if (res && res.status == 1000) {
                        _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Employee assign/deassign updated successfully',
                            class: 'ams-error-success'
                        })
                        _self.$router.push("/licenses");
                    } else {
                        _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Technical error while assign/deassign employee',
                            class: 'ams-error-danger'
                        })
                    }
                })
            } else {
                _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    msg: "Seats are not avaliable",
                    class: "ams-error-danger",
                });
            }
        },
        getEmployees: async function () {
            let _self = this
            let empResponse = await _self.$store.dispatch("employee/FETCH_EMPLOYEE_SELECT")
            if (empResponse && empResponse.status == 1000) {
                _self.empList = empResponse.data
            }
        }
    }
}
</script>
