<template>
<div class="ams-content">
    <ticket-details v-if="$route.params.tabname && $route.params.prodid" />
    <!-- <ticket-details v-if="$route.params.tabname ==='pending' && $route.params.prodid"/>
    <ticket-details v-if="$route.params.tabname ==='close' && $route.params.prodid"/>
    <ticket-details v-if="$route.params.tabname ==='reopen' && $route.params.prodid"/> -->
    <div v-if="!$route.params.prodid" class="ams-row ams-mb20">
        <div class="ams-col10 ams-col-md10 ams-col-sm6 ams-col-xs12">
            <h3 class="ams-tit">Services Tickets</h3>
        </div>
        <div class="ams-col2 ams-col-md2 ams-col-sm6 ams-col-xs12 ams-text-right">
            <button @click="copyFormLink()" type="button" class="ams-btn ams-btn-outline-info ams-btn-block "><i class="icon-link ams-mr10 ams-primary-text"></i><span class="ams-italic">Get link</span></button>
        </div>
    </div>
    <div class="ams-row">
    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12 ams-text-right">
        <div v-if="!$route.params.prodid && ticketData && ticketData.length" class="ams-form-group">
            <input @input="ticketFilter(tabSelection)" v-model="tid" type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Ticket Number" />
            <i class="icon-search ams-form-icon ams-form-icon-right"></i>
        </div>
    </div>
    </div>

    <div v-if="!$route.params.prodid" class="ams-row">
        <div class="ams-col7 ams-col-md7 ams-col-sm12 ams-col-xs12">
            <div class="ams-selector" @click="selectDropDown" :class="amsdropdownview? 'ams-active': ''">
                {{ tabSelection }} <i class="icon-downarrow ams-form-icon"></i>
            </div>
            <ul class="ams-tabs ams-cust-tabs" :class="amsdropdownview? 'ams-dropd-show': 'ams-dropd-hide'">
                <li @click="changeTab('opentickets')" v-bind:class="[tabSelection === 'opentickets' ? 'ams-active' : '']">
                    <a><span>Open tickets
                            <!-- <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                                <p class="ams-tooltip ams-tooltip-info ams-tooltip-top">Unlock this screen with our pro version</p>
                            </i> -->
                        </span>
                    </a>
                </li>
                <li @click="changeTab('pendingtickets')" v-bind:class="[tabSelection === 'pendingtickets' ? 'ams-active' : '']"><a><span>Pending tickets</span></a></li>
                <li @click="changeTab('closetickets')" v-bind:class="[tabSelection === 'closetickets' ? 'ams-active' : '']"><a><span>Closed tickets</span></a></li>
                <li @click="changeTab('reopentickets')" v-bind:class="[tabSelection === 'reopentickets' ? 'ams-active' : '']"><a><span>Reopened tickets</span></a></li>
            </ul>
        </div>
    </div>
    <div v-if="!$route.params.prodid" class="ams-tab-cont">
        <open-tickets v-if="ticketData && ticketData.length > 0 && tabSelection === 'opentickets'" :ticketData="ticketData" :paginationData='pageData' />
        <pending-tickets v-if="ticketData && ticketData.length > 0 && tabSelection === 'pendingtickets'" :ticketData="ticketData" :paginationData='pageData' />
        <close-tickets v-if="ticketData && ticketData.length > 0 && tabSelection === 'closetickets'" :ticketData="ticketData" :paginationData='pageData' />
        <reopen-tickets v-if="ticketData && ticketData.length > 0 && tabSelection === 'reopentickets'" :ticketData="ticketData" :paginationData='pageData' />
    </div>
</div>
</template>

<script>
import OpenTickets from "../../components/tickets/OpenTickets.vue"
import PendingTickets from "../../components/tickets/PendingTickets"
import CloseTickets from "../../components/tickets/CloseTickets"
import ReopenTickets from "../../components/tickets/ReopenTickets"
import TicketDetails from "../../components/tickets/TicketDetails"
import {
    ams_client
} from "../../../config/endpoint";

export default {
    data() {
        return {
            tabSelection: null,
            stid: 'ST0',
            t_apts: 0,
            t_inc: 0,
            t_comm: 0,
            ticketData: [],
            pageData: null,
            tstatus: '',
            amsdropdownview: false,
            tid: null

            // pflag: false,
            // cflag: false,
            // rflag: false
        };
    },
    mounted() {
        // let tabSelected = localStorage.getItem("ticketsTab");
        // let parsedPT = tabSelected && JSON.parse(tabSelected);
        this.tabSelection = 'opentickets'
        this.getStatus('opentickets')
        this.getData(this.tstatus)
    },
    methods: {
        getData(val, val2) {
            console.log('CALLING', val)
            let self = this
            let treq = {
                limit: '10',
                page: '1'
            }
            if (val) {
                treq.tck_status = val
            }
            if (self.tid) {
                treq.tid = self.tid
            }
            self.$store.dispatch("common/SET_LOADER", true)
            self.$store.dispatch('ticket/FETCH_TICKETS', treq).then(function (res) {
                console.log('kjkjk', res)
                 self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    self.ticketData = res.data.tickets
                    self.pageData = res.data.metadata[0]
                 } else {
                     if(val2 === 'filter' && res.data && res.data.tickets && !res.data.tickets.length) {
                        self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "No Data exits",
                            class: "ams-error-danger",
                        });
                    }
                 }
                // else {
                //     console.log('no tickets')
                //     self.$store.dispatch("common/SET_ALERTMESSAGE", {
                //         msg: "Technical error",
                //         class: "ams-error-danger",
                //     });
                // }
            })
        },
        copyFormLink: async function () {
            let _self = this
            let formUrl = ams_client + '/tickets-form?token=' + encodeURIComponent(localStorage.getItem('token')) + '&view=true' || undefined
            // async function copyPageUrl() {
            // try {
            //     await navigator.clipboard.writeText(formUrl);
            //     console.log('Page URL copied to clipboard');
            // } catch (err) {
            //     console.error('Failed to copy: ', err);
            // }
            // }
            // document.querySelector("#input");
            // copyText.select();
            // let copyFlag = document.execCommand("copy")
            // console.log('copyFlag', copyFlag, formUrl)
            //     if (copyFlag) {
            //         _self.$store.dispatch('common/SET_ALERTMESSAGE', {
            //             msg: 'Form link copied',
            //             class: 'ams-error-success'
            //         })
            // } else {
            //                     _self.$store.dispatch('common/SET_ALERTMESSAGE', {
            //             msg: 'Technical error while generating form link',
            //             class: 'ams-error-danger'
            //         })
            // }
            navigator.clipboard.writeText(formUrl).then(function () {
                _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Form link copied',
                    class: 'ams-error-success'
                })
            }, function () {
                _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Technical error while generating form link',
                    class: 'ams-error-danger'
                })
            })
        },
        // copyFormLink(){
        //     let offerCode = 'TEXT'
        //     offerCode.select();
        //     offerCode.setSelectionRange(0, 99999);
        //     document.execCommand("copy")
        //     // this.msgCopied = true
        //     // setTimeout(() => this.msgCopied = false, 2000);
        // },
        changeTab(val) {
            let _self = this
            _self.ticketData = []
            _self.pageData = null
            // if (val != _self.tabSelection) {
            _self.tabSelection = val
            //     localStorage.setItem('ticketsTab', JSON.stringify(_self.tabSelection))
            // }
            _self.getStatus(val)
            _self.getData(_self.tstatus)
            // _self.activeclass = undefined;
        },
        selectDropDown() {
            this.amsdropdownview = !this.amsdropdownview
        },
        tabSelecstion(option) {
            this.value = option;
        },
        setAppData(t_apts, t_inc, t_comm) {
            this.t_apts = t_apts
            this.t_inc = t_inc
            this.t_comm = t_comm
        },
        getStatus(tab) {
            let tabname = tab
            let self = this
            console.log('tab', tabname)
            switch (tabname) {
                case "opentickets":
                    self.tstatus = "OPEN"
                    break;
                case "pendingtickets":
                    self.tstatus = "PENDING"
                    break;
                case "closetickets":
                    self.tstatus = "CLOSE"
                    break;
                case "reopentickets":
                    self.tstatus = "REOPEN"
                    break;
            }
        },
        ticketFilter(val) {
            console.log('VAL', val)
            // let tabvalue = this.getStatus(val)
            if (!this.tid.length) {
                this.getData(this.tstatus)
                return
            }
            if (this.tid.length >= 3) {
                this.getData(this.tstatus, 'filter')
            }
        }
    },
    components: {
        OpenTickets,
        PendingTickets,
        CloseTickets,
        ReopenTickets,
        TicketDetails
    }
};
</script>
