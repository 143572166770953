<template>
<div class="ams-ast ams-card">
    <div class="ams-dash-hdng">
        <span class="ams-d-f-a-center">
            <figure class="ams-avtar ams-avtar-md ams-mr15"><i class="icon-license"></i></figure> Licenses
        </span>
        <strong v-if="licenseData" class="h2">{{licenseData.count}}</strong>
    </div>
    <apexchart type="bar" height="180" :options="options" :series="series" class="ams-licenses"></apexchart>
    <ul class="ams-custom-list">
        <li>
            <i class="ams-sqr ams-brd-blue"></i>
            <span class="ams-custom-cnt">Assigned</span>
            <strong v-if="licenseData">{{licenseData.assigned}}</strong>
        </li>
        <li>
            <i class="ams-sqr ams-brd-red"></i>
            <span class="ams-custom-cnt">Unassigned</span>
            <strong v-if="licenseData">{{licenseData.unassigned}}</strong>
        </li>
        <li>
            <i class="ams-sqr ams-brd-yellow"></i>
            <span class="ams-custom-cnt">Scrapped</span>
            <strong v-if="licenseData">{{licenseData.scrapped}}</strong>
        </li>
    </ul>
</div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
export default {
    props: ['licenseData'],
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            options: {
                responsive: [{
                    breakpoint: 1025,
                    options: {
                        chart: {
                            width: 200,
                        },
                    },
                }],
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded',
                        colors: {
                            ranges: [{
                                color: ['#2E93fA', '#66DA26', '#546E7A']
                            }],
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [300, 200, 100],
                    labels: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: true,
                        color: '#F7F7F7'
                    },
                },
                yaxis: {
                    show: true,
                    axisBorder: {
                        show: true,
                        color: '#F7F7F7',
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        align: 'left',
                        offsetX: -14,
                    }
                },
                grid: {
                    show: false,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -1
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
        }
    },
    computed: {
        series: function () {
            if (this.licenseData) {
                return [{
                    name: 'Inflation',
                    data: [this.licenseData['assigned'], this.licenseData['unassigned'], this.licenseData['scrapped']]
                }]
            }
            return [{
                name: 'Inflation',
                data: [0, 0, 0]
            }]
        }
    }
};
</script>
