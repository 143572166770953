/**
 * @description ticket Store Module
 * @author Harikrishna
 */

import axios from "../../util/axios";
export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        RAISE_TICKET({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/ticket/add', payload).then(function (res) {
                return res
            })
        },
        FETCH_TICKETS({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/ticket/table', payload).then(function (res) {
                return res
            })
        },
        FETCH_TICKET_INFO({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/ticket/id?tid=' + payload.tid).then(function (res) {
                return res
            })
        },
        UPDATE_TICKET({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/ticket/update', payload).then(function (res) {
                return res
            })
        },
        UPDATE_STATUS({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/ticket/status', payload).then(function (res) {
                return res
            })
        }
    },
    getters: {
    }
}
