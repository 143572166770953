<template>
<div>

    <table v-if="vendorList && vendorList.length" class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Vendor Name</th>
                <th>Vendor Contact No.</th>
                <th>Vendor Email ID</th>
                <th>Registration Number</th>
                <!-- <th>Edit</th> -->
                <!-- <th>Delete</th> -->
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in vendorList" :key="i" @click="viewVendor(a.ven_id)">
                <td data-title="1">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Existing Vendor">{{a.ven_name}}</td>
                <td data-title="Vendor Contact No.">+ {{a.ccode}} {{a.ph_num}}</td>
                <td data-title="Vendor Email ID">{{a.email}}</td>
                <td data-title="GST number">{{a.gst_num}}</td>
                <!-- <td data-title="Action"><i class="icon-edit-box ams-f-size18" ></i></td> -->
                <td data-title="Action"><i class="ams-f-size20 icon-delete-1 ams-red-text" @click.stop="showPopup(a.ven_id)"></i></td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
<delete-confirmation v-if="vflag" v-on:close-popup="closePopup" :uid='vendorID' />
</template>

<script>
import pagination from "../common/Pagination.vue"
import DeleteConfirmation from "../common/DeleteConfirmation.vue"
import {
    pagination_limit
} from "../../../config/endpoint";

export default {
    props: ["ven_name"],
    components: {
        pagination,
        DeleteConfirmation
    },

    data() {
        return {
            vflag: false,
            page: 1,
            vendorID: '',
            vendorList: [],
            paginationData: null

        };
    },

    methods: {
        closePopup() {
            this.vflag = false;
            // this.listVendor();
            let vm = document.body
			vm.classList.remove('hide-scrollbar');
        },
        listVendor: function (val) {
            let _self = this

            let reqObj = {
                limit: this.limit,
                page: this.page
            }
            if (_self.ven_name) {
                reqObj.ven_name = _self.ven_name
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("vendor/FETCH_VENDOR_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        console.log('response', res)
                        _self.vendorList = res.data.vendors
                        console.log("vendorList", res.data);
                        _self.paginationData = res.data.metadata[0]
                    } else {
                         if (val === 'filter' && res.data && !res.data.length) {
                         _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                         msg: 'No Data Exist',
                         class: 'ams-error-danger'
                    })
                }
                    }
                });

        },
        deleteFunc: function (vendorId) {
            console.log('ven', vendorId)

            let _self = this

            let reqObj = {
                ven_id: vendorId
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("vendor/DELETE_VENDOR", reqObj)
                .then(function (res) {
                    console.log('res', res)
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.listVendor();
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Vendor deleted successfully",
                            class: "ams-error-success",

                        });
                        _self.vflag = false

                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });

        },

        showPopup(val) {
            let self = this
            self.vflag = true
            console.log(val, 'dsfgdt')
            this.vendorID = val
            let vm = document.body
			vm.classList.add('hide-scrollbar');
        },
        viewVendor: function (a) {

            this.$router.push('/vendor/edit/' + a)
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.listVendor()
        },

    },
    mounted() {
        this.listVendor();

    },
    computed: {
        limit: function () {
            return pagination_limit
        }
    }
};
</script>
