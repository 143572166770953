<template>
<div>
    <div class="ams-pro-version">
        <h3>Lite Version</h3>
        <p>Manage your all assets effectively with our free version. For accessing the complete service subscribe to Pro version</p>
        <button class="ams-btn ams-btn-primary">Get Lite Version</button>
        <input type="checkbox" id="data">
        <label for="data" class="ams-version-check">Data can be used for research purpose</label>
    </div>
    <div class="ams-pro-version">
        <h3>Pro Version</h3>
        <p>Get our full-fledged asset management service to manage your assets more efficiently, securely and conveniently.</p>
        <button class="ams-btn ams-btn-primary" @click="proVersion">Get Pro Version</button>
    </div>
</div>
</template>
<script>

export default ({
    components: {
    },
    data() {
        return{

        }
    },
    methods: {
        proVersion: function(){
            this.$emit('pro-version-plan', '')
        }
    }
})
</script>
