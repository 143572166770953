/**
 * @description settings Store Module
 * @author Harikrishna
 */

 import axios from "../../util/axios";
 export default {
     namespaced: true,
     state: {
     },
     mutations: {
     },
     actions:{
        ADD_SUBUSER({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/user/add', payload).then(function (res) {
                return res
            })
        },
        LIST_SUBUSER({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/user/table', payload).then(function (res) {
                return res
            })
        },
        USER_ID({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.get('/user/id?usr_id=' + payload).then(function (res) {
                return res
            })
        },
        UPDATE_SUBUSER({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/user/update', payload).then(function (res) {
                return res
            })
        },
        DELETE_SUBUSER({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/user/delete', payload).then(function (res) {
                return res
            })
        },
        ADD_FIELDS({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/addField', payload).then(function (res) {
                return res
            })
        },
        GET_FEATURES({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.get('/role/feature').then(function (res) {
                return res
            })
        },
        SAVE_ROLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/role/add', payload).then(function (res) {
                return res
            })
        },
        UPDATE_FIELD({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/updateField', payload).then(function (res) {
                return res
            })
        },
        ROLE_LIST({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.get('/role/list').then(function (res) {
                return res
            })
        },
        ROLE_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/role/table', payload).then(function (res) {
                return res
            })
        },
        GET_ROLEINFO({ commit }, payload) {
            console.log('commit', payload, commit)
            return axios.get('/role/id?role_id=' + payload.rid).then(function (res) {
                return res
            })
        },
        UPDATE_ROLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/role/update', payload).then(function (res) {
                return res
            })
        },
        EDIT_AUDIT({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/company/audit', payload).then(function (res) {
                return res
            })
        },
        FETCH_AUDIT({ commit }, payload) {
            console.log('commit', payload, commit)
            return axios.get('/company/fetchaudit').then(function (res) {
                return res
            })
        },
        ASSIGN_USER({commit}, payload){
            console.log('commit', payload, commit)
            return axios.get('/user/list').then(function (res){
                return res
            })
        },
        ADD_PRODUCT({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/settings/addPT', payload).then(function (res) {
                return res
            })
        },
        ADD_FIELD({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/field/add', payload).then(function (res) {
                return res
            })
        },
        FIELD_UPDATE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/field/update', payload).then(function (res) {
                return res
            })
        },
        FIELD_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/field/table', payload).then(function (res) {
                return res
            })
        },
        FIELD_ID({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/field/id?field_id=' +  payload).then(function (res) {
                return res
            })
        },
        FIELD_DELETE({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/field/delete?field_id=' +  payload.field_id).then(function (res) {
                return res
            })
        },
        GET_DEFAULT_FIELDS({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.get('/field/cid?cType=' +  payload.cType).then(function (res) {
                return res
            })
        },
        GET_ALL_FIELDS({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.get('/field/list').then(function (res) {
                return res
            })
        },
        GET_BY_ID({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/settings/id?pType=' +  payload).then(function (res) {
                return res
            })
        },
        UPDATE_PRODUCT({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.post('/settings/update', payload).then(function (res) {
                return res
            })
        },
     }
    }