<template>
  <div class="ams-content">
    <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20"></i> Payment</h3>
    <div class="ams-card">
      <div class="ams-row ams-gutter25">    
        <div class=" ams-col6">
          <user-details />
          <plan-details /> 
        </div>
        <div class="ams-col6">
         <payment-gateway />
        </div>
      </div>        
    </div>    
  </div>
</template>
<script>
import UserDetails from "../../components/payment/UserDetails.vue"
import PlanDetails from "../../components/payment/PlanDetails.vue"
import PaymentGateway from "../../components/payment/PaymentGateway.vue"

export default {
  components: {
    PlanDetails,
    UserDetails,
    PaymentGateway
  },
  data() {
    return {

    }
  },
  methods: {},
};
</script>
