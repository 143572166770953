<template>
<div class="ams-content">
    <h3 class="ams-tit">Dashboard</h3>
    <div class="ams-row ams-gutter15">
        <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
            <dashboard-assets :assetData="assetData" />
        </div>
        <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
            <dashboard-accessories :accessoryData="accessoryData" />
        </div>
        <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
            <dashboard-licenses :licenseData="licenseData" />
        </div>
        <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
            <dashboard-service-tickets :ticketData = "ticketData"/>
        </div>
    </div>
    <all-reports :openTickets="openTickets" :tn="tn" :ae="ae" :rpo="rpo" :vendors="vendors" :audit="audit" :renewal_date="renewal_date"/>
    <div class="ams-row ams-gutter15">
        <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <current-value v-if="pv" :pv="pv"/>
        </div>
        <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <monthly-expenditure v-if="exp" :exp="exp"/>
        </div>
    </div>
</div>
</template>

<script>
import DashboardAssets from "../../components/dashboard/DashboardAssets.vue"
import DashboardAccessories from "../../components/dashboard/DashboardAccessories.vue"
import DashboardLicenses from "../../components/dashboard/DashboardLicenses.vue"
import DashboardServiceTickets from "../../components/dashboard/DashboardServiceTickets.vue"
import CurrentValue from "../../components/dashboard/CurrentValue.vue"
import MonthlyExpenditure from "../../components/dashboard/MonthlyExpenditure.vue"
import AllReports from "../../components/dashboard/AllReports.vue"
export default {
    components: {
        DashboardAssets,
        DashboardAccessories,
        DashboardLicenses,
        DashboardServiceTickets,
        CurrentValue,
        MonthlyExpenditure,
        AllReports
    },
    data() {
        return {
            assetData: null,
            accessoryData: null,
            licenseData: null,
            ticketData: null,
            tn: null,
            ae: 0,
            rpo: '',
            vendors: 0,
            pv: null,
            exp: null,
            openTickets: 0,
            audit: '',
            renewal_date: ''
        };
    },
    mounted: function () {
        let _self = this
        this.$store.dispatch('dashboard/DASHBOARD_DATA').then(function (res) {
            if (res.status == 1000) {
                _self.assetData = res.data.assets
                _self.accessoryData = res.data.accessories
                _self.licenseData = res.data.licenses
                _self.ticketData = res.data.tickets
                _self.openTickets = res.data.tickets.open
                _self.tn = res.data.tn
                _self.ae = res.data.ae || 0
                _self.rpo = res.data.rpo || ''
                _self.vendors = res.data.vendors || 0
                _self.pv = res.data.pv || 0
                _self.exp = res.data.exp || 0
                _self.audit = res.data.audit || ''
                _self.renewal_date = res.data.renewal_date
            }
        })
    }
};
</script>
