<template>
<div>
    <!-- <report-download /> -->
    <div class="ams-card">
        <div v-if="ticketList && ticketList.length" class="ams-row ams-d-f-sbetween">
            <div class="ams-col4 ams-col-md4 ams-col-sm4 ams-col-xs12">
                <div class="ams-two-calender ams-d-f-a-center">
                    <label class="ams-two-cal-label">Select Date</label>
                    <datepicker v-model="date" @cleared="clearDate" range twoCalendars :enableTimePicker="false" autoApply :closeOnAutoApply="true" class="ams-form-control ams-custom-control" placeholder="Start Date - End Date" />
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                </div>
            </div>
            <div class="ams-col2 ams-col-md3 ams-col-sm3 ams-col-xs12 ams-text-right">
                <div class="ams-form group">
                    <a v-if="date && date.length" class="ams-btn ams-btn-outline-info" download :href="downloadAuditDetails"><i class="icon-download ams-primary-color ams-cursor ams-mr10"></i> Download</a>
                </div>
            </div>
        </div>
    </div>
    <table v-if="ticketList && ticketList.length" class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>
                    <!-- <input type="checkbox" id="SI.No" v-model="checked" /> -->
                    <label for="SI.No"><span class="ams-pl30 ams-mt0"></span></label>SI.No</th>
                <th>Product Tag</th>
                <th>Product Name</th>
                <th>Ticket Id</th>
                <th>Ticket Status</th>
                <th>Employee Id</th>
                <th>Issue Status</th>
                <th>Created Date</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in ticketList" :key="i">
                <td>
                    <!-- <input type="checkbox" id="1" v-model="checked" /> -->
                    <!--<label for="1">
                        <span class="ams-pl30 ams-mt0"></span>
                        </label>-->{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Product Tag">{{a.prod_tag}}</td>
                <td data-title="Product Name"> {{(a && a.proddata && a.proddata.length) ? productTypes[a.proddata[0].pType] : '-'}} </td>
                <td data-title="Ticket Id">{{a.tid}}</td>
                <td data-title="Ticket Status">{{a.tck_status}}</td>
                <td data-title="Employee Id"> {{a.emp_id}} </td>
                <td data-title="Issue Status">{{a.iss_status}}</td>
                <td data-title="Issue Status">{{getDate(a.cDate)}}</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
//import ReportDownload from "../common/ReportDownload.vue"
import Pagination from "../common/Pagination.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
import moment from "moment";
import {
    ams_hub
} from "../../../config/endpoint";
import Datepicker from 'vue3-date-time-picker';
// import Multiselect from '@vueform/multiselect'
//import Datepicker from 'vue3-date-time-picker';

export default {
    components: {
        //ReportDownload,
        Pagination,
        // Multiselect,
        Datepicker
    },
    data() {
        return {
            date: [],
            isShow: false,
            page: 1,
            ticketList: [],
            paginationData: null,
        };
    },
    mounted() {
        this.listTicket();
    },
    computed: {
        limit: function () {
            return pagination_limit
        },
        downloadAuditDetails: function () {
            let _self = this
            let reqObj = {
                to_date: moment(_self.date[1]).format('YYYY-MM-DD'),
                from_date: moment(_self.date[0]).format('YYYY-MM-DD')
            }
            return ams_hub + '/report/ticketsreport?from_date=' + reqObj.from_date + '&to_date=' + reqObj.to_date + '&token=' + encodeURIComponent(localStorage.getItem('token'))
        },
        productTypes: function () {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return { ... parsedPT["1"], ...parsedPT["2"] };
        }
    },
    methods: {
         clearDate () {
            this.listTicket()
        },
        listTicket: function (val) {
            let _self = this

            let reqObj = {
                limit: this.limit,
                page: this.page
            }
            if (_self.date && _self.date.length) {
                reqObj.from_date = _self.date[0]
                reqObj.to_date = _self.date[1]
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("reports/FETCH_TICKET_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.ticketList = res.data.tickets
                        _self.paginationData = res.data.metadata[0]
                    } else { 
                        if(val === 'filter' && res.data && !res.data.length) {
                            _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                                msg: "No Data exits",
                                class: "ams-error-danger",
                            });
                        }
                    }
                });

        },
         getDate : function(date){
            return moment(date).format('DD-MM-YYYY');
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.listTicket()
        },

    },
    watch: {
        date: {
            immediate: false,
            handler () {
                let self = this
                console.log('KJKKJKJ')
                if(self.date && self.date.length) {
                    self.listTicket('filter')
                }
            }
        }
    }
};
</script>
