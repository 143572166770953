/**
 * @description manufacture Store Module
 * @author Harikrishna
 */

 import axios from "../../util/axios";
 export default {
     namespaced: true,
     state: {
     },
     mutations: {
     },
     actions:{
        ADD_MANUFACTURE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/manuf/add', payload).then(function (res) {
                return res
            })
        },
        FETCH_MANUFACTURE_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/manuf/table', payload).then(function (res) {
                return res
            })
        },
        MANUFACTURE_ID({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/manuf/id?man_id=' +  payload).then(function (res) {
                return res
            })
        },
        UPDATE_MANUFACTURE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/manuf/update', payload).then(function (res) {
                return res
            })
        },
        MANUFACTURE_DELETE({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/manuf/delete?man_id=' +  payload.man_id).then(function (res) {
                return res
            })
        },
        MANUFACTURE_LIST({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/manuf/list', payload).then(function (res) {
                return res
            })
        },
        STATUS_MANUFACTURE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/manuf/status', payload).then(function (res) {
                return res
            })
        },
        
     }
    }