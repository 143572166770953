<template>
<div>

    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead v-if="assetList && assetList.length">
            <tr>
                <th>S.No</th>
                <th>Asset Tag</th>
                <th>Asset Type</th>
                <th>DOP</th>
                <th>Audited By</th>
                <th>Asset Status</th>
                <th>Employee ID</th>
                <th>Audit Date</th>
                <th>Audit Status</th>
                <th>History</th>
                <!-- <th>Depreciation</th> -->
            </tr>
        </thead>
        <tbody>
            <tr @click.prevent="viewProduct(a.prod_tag, a.ptd)" v-for="(a, i) in assetList" :key="i">
                <td data-title="S.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Asset Tag">{{a.prod_tag}}</td>
                <td data-title="Asset Type">{{parsedData[a.ptd]}}</td>
                <td data-title="DOP">{{getDate(a.dop)}}</td>
                <td data-title="Audited By"> {{a.audit_by ? a.audit_by : '-' }} </td>
                <td data-title="Asset Status">{{a.status}}</td>
                <td data-title="Employee ID">{{a.emp_id}}</td>
                <td data-title="Audit Date">{{getDate(a.audit_date) ? getDate(a.audit_date) : '-'}}</td>
                <td data-title="Audit Status"> {{a.audit_status ? a.audit_status : 'NA'}} </td>
                <td data-title="Action"><i @click.stop="productHistory(a.prod_tag)" class=" ams-history ams-fw600 icon-History ams-primary-text"></i></td>
                <!-- <td data-title="Action"><i @click.stop="depreciationDetails(a.prod_tag,a.ptd)" class="ams-fw600 icon-edit-box ams-mr20 ams-primary-text"></i></td> -->

            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
import moment from "moment";
export default {
    props: ['assetList', 'paginationData'],
    data() {
        return {};
    },
    methods: {
        productHistory: function (prod_tag) {
            this.$router.push('/assets-history-details?prod_tag=' + prod_tag)
        },
        viewProduct: function (prod_tag, ptd) {
            this.$router.push('/assets/edit/' + prod_tag + '/' + ptd)
        },
        getDate: function (date) {
            console.log('date njjjj', date)
            return moment(date).format('DD-MM-YYYY');

        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        depreciationDetails: function (prod_tag, ptd) {
            this.$router.push('/depreciation?prod_tag=' + prod_tag + '&pType=' + ptd)
        }

    },
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return {
                ...parsedPT[1]
            }
        }
    }
};
</script>
