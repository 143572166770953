/**
 * @description employee Store Module
 * @author Harikrishna
 */

import axios from "../../util/axios";
export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        FETCH_EMPLOYEE_LIST({ commit }) {
            console.log('commit', commit)
            return axios.get('/emp/list').then(function (res) {
                return res
            })
        },
        FETCH_EMPLOYEE_SELECT({ commit }) {
            console.log('commit', commit)
            return axios.get('/emp/select').then(function (res) {
                return res
            })
        },
        FETCH_EMPLOYEE_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/emp/table', payload).then(function (res) {
                return res
            })
        },
        SAVE_EMPLOYEE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/emp/add', payload).then(function (res) {
                return res
            })
        },
        UPDATE_EMPLOYEE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/emp/update', payload).then(function (res) {
                return res
            })
        },
        LIST_EMPLOYEE({ commit }, payload) {
            console.log('payload', payload)
            console.log('commit', commit)
            return axios.get('/emp/id?emp_id=' + payload).then(function (res) {
                console.log('res', res)
                return res
            })
        },
        DELETE_EMPLOYEE({ commit }, payload) {
            console.log('commit', payload, commit)
            return axios.get('/emp/delete?emp_id=' + payload.emp_id).then(function (res) {
                return res
            })
        },
        STATUS_EMPLOYEE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/emp/status', payload).then(function (res) {
                return res
            })
        },
        FILE_UPLOAD({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.post('/emp/uploadCSV', payload,{ headers: { "Content-Type": "multipart/form-data" }}).then(function (res) {
                return res
            })
        }

    },
    getters: {
    }
}
