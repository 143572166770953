<template>
<div class="ams-card">
    <div class="ams-row">
        <div class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <!-- <datepicker v-model="picked" :upperLimit="to" :lowerLimit="from" class="ams-form-control ams-a-pink ams-text-center" placeholder=" Current Audit Period - Every month 4 Days ( 9 to 12 )">
            </datepicker> -->
            <!-- <button class="ams-btn ams-btn-block ams-btn-primary ams-mt20">Edit Audit Period</button> -->
            <input type="text" class="ams-form-control ams-pink-control">
            <div class="ams-datepicker-lg">
                <datepicker :minDate="new Date().setDate(new Date().getDate() + 1)" v-model="auditdate" class="date-pick" range inline autoApply />
            </div>
            <button class="ams-btn ams-btn-block ams-btn-primary ams-mb20" @click.prevent="editAuditDate">Edit Audit Period</button>
        </div>
        <div class="ams-col8 ams-col-md6 ams-col-sm6 ams-col-xs12"> 
            <div class="">           
            <div v-if="auditdate && auditdate.length"  class="ams-audit-progress" style="--percentage:50%;">
                <i class="icon-audit"></i>
                <strong> {{daysleft}} </strong>
                <span>Day{{daysleft > 1 ? 's': ''}} </span>
                <p>For next audit</p>
            </div>    
            <p v-if="ldate" class="ams-mt40 ams-text-center">Last audit date : {{ldate}} </p>    
            </div>    
        </div>
    </div>
</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import moment from 'moment';
import 'vue3-date-time-picker/dist/main.css'
// import {
//     ref,
//     onMounted
// } from 'vue';
export default {
    data () {
        return {
            auditdate: [],
            ldate: '',
            daysleft: ''
        }
    },
    mounted () {
        let self = this
        self.getaudit()
    },
    // setup() {
    //     const date = ref();
    //     // For demo purposes assign range from the current date
    //     onMounted(() => {
    //         const startDate = new Date();
    //         const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
    //         date.value = [startDate, endDate];
    //     })
    //     return {
    //         date,
            
    //     }
    // },
    methods: {
        getaudit () {
            let _self = this
            _self.$store.dispatch("common/SET_LOADER", true)
            _self.$store .dispatch("settings/FETCH_AUDIT").then(function (res) {
            _self.$store.dispatch("common/SET_LOADER", false);
            console.log('res', res)
                if (res && res.status == 1000) {
                    let adata = res.data
                    _self.auditdate.push(adata.from_date, adata.to_date)
                    let nextDate = moment(adata.to_date).format('YYYY-MM-DD')
                    let ttdate = moment().format('YYYY-MM-DD')
                    if (ttdate === nextDate) {
                        _self.auditdate = []
                    }
                    _self.ldate = moment(adata.from_date).format('DD - MMM - YYYY')
                    _self.daysleft = moment(adata.from_date).diff(moment(), 'days')
                }
            });  
        },
        editAuditDate: function () {
            let _self = this
            if (_self.auditdate && _self.auditdate.length) {
                let reqObj = {
                    from_date: _self.auditdate[0],
                    to_date: _self.auditdate[1]
                }
                console.log('reqqqqqobjf', reqObj)
                _self.$store.dispatch("common/SET_LOADER", true)
                _self.$store .dispatch("settings/EDIT_AUDIT", reqObj).then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Audit period added successfully',
                            class: 'ams-error-success'
                        })
                        _self.ldate = moment(_self.auditdate[0]).format('DD - MMM - YYYY')
                        _self.daysleft = moment(_self.auditdate[0]).diff(moment(), 'days')
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });
            } else {
                _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    msg: "Please Select Audit Date",
                    class: "ams-error-danger",
                });
            }
        }
    },
    components: {
        Datepicker,
    }
}
</script>
