<template>
<div>
    <h3 class="ams-tit"> {{$parent['productTypes'] && $parent['productTypes'][$parent['prod_id']]}}</h3>
    <Form :validation-schema="assetsSchema" v-slot="{ handleSubmit }">
        <div class="ams-card">
            <div class="ams-row">
                <!-- {{gfgf}}
                    <div class="ams-form-group">
                        <label>dfdfasdfas <span class="ams-red-text"> * </span></label>
                        <datepicker v-model='gfgf' name='gfgf' class="ams-form-control ams-custom-control-dark"></datepicker>
                        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i> 
                        <ErrorMessage name="gfgf" class="ams-val-err" />
                    </div> -->
                <template v-for="(a, i) in assetFields" :key="i">
                    <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                        <div v-if="a.type === 'text'" class="ams-form-group">
                            <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> *</span> </label>
                            <Field :type="a.type" v-model=assetObj[a.key] @keydown="checkInput($event, a.input)" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" />
                            <ErrorMessage :name="a.key" class="ams-val-err" />
                        </div>
                        <div v-else-if="a.type === 'calendar'" class="ams-form-group ams-datepicker-md">
                            <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                            <!-- <datepicker v-model=assetObj[a.key] class="ams-form-control ams-custom-control-dark"></datepicker> -->
                            <datepicker v-model=assetObj[a.key] class="ams-form-control ams-custom-control-dark" :maxDate="a.key === '111' ? new Date() : ''" :minDate="(a.key === '145' || a.key === '151') ? assetObj['111'] : ''" :enableTimePicker="false" monthNameFormat="long" autoApply :placeholder="a.label" :disabled="((a.key === '145' || a.key === '151') && !assetObj['111']) ? true : false"/>
                            <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                            <Field type="text" v-model=assetObj[a.key]  v-show="false" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" />
                            <ErrorMessage :name="a.key" class="ams-val-err" />
                        </div>
                        <div v-else-if="a.type === 'dropdown'" class="ams-form-group">
                            <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                            <multiselect v-if="!a.multiselect" v-model=assetObj[a.key] :options="options[a.key] || a.select" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :searchable="true" track-by="label"></multiselect>
                            <multiselect v-else-if="a.multiselect" v-model=assetObj[a.key] mode="tags" :options="options[a.key] || a.select" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :searchable="true" track-by="label"></multiselect>
                            <Field type="text" v-model=assetObj[a.key] v-show="false" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" />
                            <ErrorMessage :name="a.key" class="ams-val-err" />
                        </div>
                    </div>
                    <div v-if="a.type === 'textarea'" class="ams-col12">
                        <div class="ams-form-group">
                            <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                            <Field type="text" v-model=assetObj[a.key] @keydown="checkInput($event, a.input)" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" :id="a.key" />
                            <ErrorMessage :name="a.key" class="ams-val-err" />
                        </div>
                    </div>
                </template>
            </div>
            <!-- <h3 class="ams-tit">OS License Details</h3>
            <div class="ams-row">
                <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                    <label>Product Key</label>
                    <input type="text" v-model="prod_Key" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>
                <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                    <label>Expriy Date</label>
                    <input type="text" v-model="exp_date" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>
                <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                    <label>OS Name</label>
                    <input type="text" v-model="os_name" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>

            </div> -->

            <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/>
            <div v-else>
                <h3 class="ams-tit">Upload Invoice</h3>
                <div class="ams-row ams-mb20">
                    <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                        <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />
                    </div>
                </div>
            </div>
            <div class="ams-table-filter">
                <button v-if="$route.params.prodid" class="ams-btn ams-btn-danger" @click="$router.push('/assets')">Cancel</button>
                <button v-if="$route.params.prodid" class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(editAsset)">Save</button>
            </div>
        </div>
        <assets-assign-deassign v-if="eventid['1123']" ref="empRef" />
        <!-- <div class="ams-table-filter">
            <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="saveAsset">Save</button>
        </div> -->
        <div class="ams-table-filter">
            <a v-if="$route.params.prodid" :href="generateTag" class="ams-btn ams-btn-outline-info">Generate asset label</a>
            <!-- <button v-if="$route.query.view == 'true'" class="ams-btn ams-btn-primary ams-ml20">Edit</button> -->
            <button v-if="!$route.params.prodid" class="ams-btn ams-btn-danger" @click="$router.push('/assets')">Cancel</button>
            <button v-if="!$route.params.prodid" class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(saveAsset)">Save</button>
        </div>
    </Form>
    <!-- <assets-license-details :flag2="flag2" /> -->
</div>
</template>

<script>
import AssetsAssignDeassign from './AssetsAssignDeassign.vue'
// import AssetsLicenseDetails from './AssetsLicenseDetails.vue'
import FileUpload from "../common/FileUpload.vue"
import Multiselect from '@vueform/multiselect'
// import Datepicker from 'vue3-datepicker'
import Datepicker from 'vue3-date-time-picker';
import UploadInvoice from "../common/UploadInvoice.vue"
import moment from "moment";
import {  ams_hub } from '../../../config/endpoint'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate'
import * as Yup from "yup";
import _ from 'lodash';
import validationMixins from "../../mixins/validations";

// const ruleObj = {}
export default {
    mixins: [validationMixins],
    props: ['assetFields', 'prod_id'],
    data() {
        const assetsSchema = Yup.object().shape({});
        return {
            fileData: {},
            // value: 'Damaged',
            // gfgf: '',
            assetsSchema,
            utext: 'Click to add your file',
            // prod_Key: '',
            // exp_date: '',
            // os_name: '',
            // filterFlag: false,
            assetObj: {
                '115': '111'
            },
            // options: [],
            // employees: [],
            // emp_id: null,
            // auditDate: null,
            // dop: null,            
            options: {
                '115': {
                    '111': 'Working', 
                    '112':'Damaged'
                },
                '120': [],
            },
            // assetStatus: null
        };
    },
    mounted() {
        let _self = this
        _self.dopdownData()
            _self.manufactureDropDown()
    },
    methods: {
        manufactureDropDown() {
              let _self = this
            _self.$store.dispatch("manufacture/MANUFACTURE_LIST").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.options['114'] = res.data
                }
            });

        },
        // changeAsssetStatus: function (st) {
        //     this.assetStatus = st
        // },
        editAsset() {
            let self = this
            console.log('Calling method')
            let productReq = {
                pType: self.$route.params.ptype,
                prod_tag: self.$route.params.prodid,
                cType: "1",
                info: self.assetObj,
                cost: self.assetObj['112'],
                dop: moment(self.assetObj['111']).format('YYYY-MM-DD'),
                // audit_date: this.auditDate && moment(this.auditDate).format('YYYY-MM-DD'),
                prod_status: self.assetObj['115'],
                ven_id: self.assetObj['120'],
                filedata: (self.fileData) ? self.fileData : {}
            }
            console.log('productReq', productReq)
            self.$store.dispatch('common/SET_LOADER', true)
            self.$store.dispatch('product/UPDATE_PRODUCT_PROPERTIES', productReq).then(function (res) {
                self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Product updated successfully',
                        class: 'ams-error-success'
                    })
                    self.$router.push('/assets')
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        saveAsset: async function () {
            console.log('CHILD DATA')
            // const isValid = await this.$refs.AddAsset.validate()
            // console.log('const', isValid)
            let self = this
            // let assetData = this.$refs['AddAsset']
            // console.log('assetData', assetData.emp_id)
            let empRef = self.$refs['empRef']
            let productReq = {
                pType: self.prod_id,
                cType: "1",
                info: self.assetObj,
                cost: self.assetObj['112'],
                emp_ids: empRef.emp_id ? [empRef.emp_id] : [],
                dop: moment(self.assetObj['111']).format('YYYY-MM-DD'),
                //audit_date: null,
                prod_status: self.assetObj['115'],
                ven_id: self.assetObj['120'],
                filedata: (self.fileData) ? self.fileData : {}
            }
            console.log('productReq', productReq)
            // let _self = this
            self.$store.dispatch('common/SET_LOADER', true)
            this.$store.dispatch('product/SAVE_PRODUCT', productReq).then(function (res) {
                self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Product added successfully',
                        class: 'ams-error-success'
                    })
                    self.$router.push('/assets')
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        dopdownData() {
            let _self = this
            _self.$store.dispatch("vendor/LIST_VENDOR").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.options['120'] = res.data
                }
                // else {
                //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                //         msg: "Technical error",
                //         class: "ams-error-danger",
                //     });
                // }
            });
        }
        // somemethod () {
        //     console.log('callinf jkkjkj', this)
        // }
    },
    watch: {
        assetFields: {
            immediate: true,
            handler() {
                let self = this
                let schemaObj = {};
                let allKey = _.keys(self.assetObj)
                _.each(self.assetFields, (e) => {
                    console.log('e', e)
                    if (e.mandatory) {
                        schemaObj[e.key] = Yup.string().required('This field is Required').nullable()
                    }
                    // For Assigning values on edit for date picker
                    if (e.type === 'calendar' && self.$route.params.prodid) {
                        let valueofarr = allKey.includes(e.key)
                        if(valueofarr) {
                            self.assetObj[e.key] = new Date(self.assetObj[e.key])
                        }
                    }
                    if (e.multiselect && !self.$route.params.prodid) {
                        self.assetObj[e.key] = []
                    }
                })
                self.assetsSchema = Yup.object(schemaObj);
                console.log('assetsSchema', self.assetsSchema)
            }
        }
    },
    computed: {
        eventid() {
            return JSON.parse(localStorage.getItem("eventData"));
        },
        generateTag () {
            let _self = this
            let tag = _self.$route.params.prodid
            let token = localStorage.getItem("token") || _self.$store.getters["user/getUserToken"] || ""
            return ams_hub + '/product/generatetag?prod_tag=' + tag + '&token=' + encodeURIComponent(token)
        },
    },
    components: {
        FileUpload,
        Multiselect,
        Datepicker,
        AssetsAssignDeassign,
        Form,
        Field,
        ErrorMessage,
        // AssetsLicenseDetails
        UploadInvoice
    }
};
</script>
