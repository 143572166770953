
export default {
    methods: {
        checkInput: function (e, type) {
            console.log('EEE', e, type)
            let key = e.which || e.keyCode,
                // _num = (key >= 48 && key <= 57) || (key >= 96 && key <= 105),
                _tabSpace = (key == 8 || key == 9 || key == 13 || key == 46),
                // _specialChracter = (key > 105),
                _space = (key == 32),
                _dot= (key==190),
                // _minus=(key==189),
                _char=(String.fromCharCode(key).match(/^[a-zA-Z_]*$/)),
                _charnum = (e.key.match(/^[a-zA-Z0-9_]*$/)),
                _num = (e.key.match(/^[0-9]*$/))
            //  If number
            console.log('key', key)
            if (type === 'number') {
                if (_num || _tabSpace || key == 37 || key == 39 || _dot) { 
                    return true 
                } else {
                    e.preventDefault()
                }
            } 
            else if (type === 'alpha') {
                if (_char || _tabSpace || _space) { 
                    return true
                } else {
                    e.preventDefault()
                }
            }
            else if (type === 'alpha_num') {
                if (_charnum || _tabSpace || key == 37 || key == 39) { 
                    return true
                } else {
                    e.preventDefault()
                }
            }
            else if (type === 'any') {
                return true
            }
            // else if (type === 'positivenumber'&&!_minus) {
            //     console.log('NUM', _num)
            //     if (_num || _tabSpace || key == 37 || key == 39) { 
            //         return true 
            //     } else {
            //         e.preventDefault()
            //     }
            // }
            // else if (type === 'allowspaces' && !_num && !_dot) {
            //     if (String.fromCharCode(e.which).match(/^[a-zA-Z_]+$/) || _tabSpace) return true;
            // }
            // else if (type === 'string' && !_num && !_specialChracter && !_space) {
            //     if (String.fromCharCode(e.which).match(/^[a-zA-Z_ ]*$/) || _tabSpace) return true;
            // } 
            // else if (type === 'dot' && !_dot) {
            //      return true;
            // }
            else {
                e.preventDefault()
            }
            // return true;
        },
        // checkInputnew: function (e, type) {
        //     let key = e.which || e.keyCode,
        //         _num = (key >= 48 && key <= 57 || key >= 96 && key <= 105),
        //         _tabSpace = (key == 8 || key == 9 || key == 13 || key == 46),
        //         _specialChracter = (key > 105)
        //     //  If number
        //     if (type === 'number') {
        //         if (_num || _tabSpace || key == 37 || key == 39 || key == 190) return true;
        //     }
        //     if (type === 'string' && !_num && !_specialChracter) {
        //         if (String.fromCharCode(e.which).match(/^[a-zA-Z_ ]*$/) || _tabSpace) return true;
        //     } else {
        //         e.preventDefault()
        //     }
        //     return true;
        // },
       
        // checkOnlyExp: function (e, val) {
        //     let key = e.keyCode ? e.keyCode : e.which,
        //     _num = (key >= 48 && key <= 57 || key >= 96 && key <= 105),
        //     _tabSpace = (key == 8 || key == 9 || key == 13 || key == 46);
        //     if ((_num || _tabSpace || key == 37 || key == 39)&&val<=99)
        //     {
        //         return true;
        //     } 
        //     else {
        //         e.preventDefault()
        //     }

        // },
    }
}