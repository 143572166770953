<template>
<Form class="ams-auth-form">
    <div class="ams-dialog">
        <div class="ams-dialog-body ams-w20">
            <i class="ams-close ams-close-circle icon-close-circ ams-f-size30 ams-mt10" @click="closePop"></i>
            <div class="ams-dialog-cont ">
                <div class="ams-row ams-d-flex">
                    <div class="ams-checked">
                        <i class="ams-sprite ams-success-icon ams-mb20"></i>
                    </div>
                    <div class="ams-updated">
                        <span>New contact updated successfully</span>
                    </div>
                    <div class="ams-contact">
                        <label>Your new contact number added successfully.</label>
                    </div>
                </div>

            </div>
        </div>
    </div>
</Form>
</template>

<script>
export default {
methods: {
         closePop: function () {
             this.$parent.closePop()
            this.$emit('close-popup', '')
        },
}
};
</script>
