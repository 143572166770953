<template>
<ul class="ams-select-plan">
    <li>
        <span class="ams-price-tag"><i class="icon-rupee"></i> <strong>750 /</strong> <small>Month</small></span>
        <span class="ams-price-vat">*VAT & Local taxes may apply</span>
        <i class="icon-right-arrow"></i>
    </li>
    <li>
        <span class="ams-price-tag"><i class="icon-rupee"></i> <strong>1500 /</strong> <small>3 Month</small></span>
        <span class="ams-price-vat">*VAT & Local taxes may apply</span>
        <i class="icon-right-arrow"></i>
    </li>
    <li>
        <span class="ams-price-tag"><i class="icon-rupee"></i> <strong>7500 /</strong> <small>6 Month</small></span>
        <span class="ams-price-vat">*VAT & Local taxes may apply</span>
        <i class="icon-right-arrow"></i>
    </li>
    <li>
        <span class="ams-price-tag"><i class="icon-rupee"></i> <strong>12000 /</strong> <small>12 Month</small></span>
        <span class="ams-price-vat">*VAT & Local taxes may apply</span>
        <i class="icon-right-arrow"></i>
    </li>
</ul>
</template>
