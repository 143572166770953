<template>
    <div class="ams-dialog">
        <div class="ams-dialog-body ams-db-sm">
            <i class="ams-close ams-close-circle icon-close-circ" @click="closePop"></i>
            <form class="ams-dialog-cont ams-cont-popup">
                <div class="ams-form-group">
                    <label>Sub user name</label>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>
                <div class="ams-form-group">
                    <label>Sub user Id</label>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>
                <button type="button" class="ams-btn ams-btn-primary ams-btn-block" @click="assetsLaptop">Assign</button>
            </form>
        </div>
    </div>
</template>
<script>
export default {
	components: {
	},
	data() {
		return {
		};
	},
    methods: {
        closePop:function(){
          this.$emit('close-popup','')
        }
    }
};
</script>