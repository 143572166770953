<template>
<div class="ams-content">
    <add-repair-maintenance v-if="$route.params.action==='add' || $route.params.action==='edit'" />
    <div v-if="!$route.params.action" class="ams-row ams-mb20">
        <div class="ams-col10 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <h3 class="ams-tit">Repair & Maintenance</h3>
        </div>
        <div class="ams-col2 ams-col-md6 ams-col-sm12 ams-col-xs12 ams-text-right">
            <button type="button" class="ams-btn ams-btn-primary ams-btn-block" @click="$router.push('/maintenance/add')">Add Item</button>
        </div>
    </div>
    <div class="ams-row">
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div  v-if="$route.params.action!= 'add' && $route.params.action!= 'edit'" class="ams-form-group">
                        <input type="text" @input="repFilter" v-model="prod_tag"  class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Asset / Accessory Tag" />
                        <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
    </div>
    <MaintenanceTable v-if="!$route.params.action" v-on:add-Maintenance="addMaintenance" :prod_tag="prod_tag" ref="mt" />
</div>
</template>

<script>
import MaintenanceTable from "../../components/maintenance/MaintenanceTable.vue";
import AddRepairMaintenance from './AddRepairMaintenance.vue';
export default {
    components: {
        MaintenanceTable,
        AddRepairMaintenance,
    },
    data() {
        return {
            prod_tag:null
        };
    },
    methods: {
         repFilter() {
             let repRef = this.$refs['mt']
            if (!this.prod_tag.length) {
                repRef.repairData()
                return
            }
            if (this.prod_tag.length >= 3) {
                repRef.repairData('filter')
            }
        }
    },
};
</script>
