<template>
        <h3 class="ams-tit ams-mt20">Ticket Raised For </h3>
        <div class="ams-alert ams-a-danger ams-alert-space">
            <h6 class="ams-mb10"> {{pname}} </h6>
            <em> {{desc}} </em>                       
        </div>
</template>
<script>

export default {
    props: ['desc', 'pname'],
    data () {
        return  {

        }
    }
}
</script>
