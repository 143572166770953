<template>
<div>
    <Form :validation-schema="purchaseschema" v-slot="{ handleSubmit }">
        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/inventory/purchase')"></i>Inventory - Purchase Order </h3>
        <div class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset / Accessory Type</label>
                        <Multiselect @change="fetchAssetTags" v-model="purchaseOrder.assetType" :options="productTypes" name="assettype" class="ams-form-control ams-custom-control-dark" :searchable="true" track-by="label"></Multiselect>
                        <Field type="text" v-model="purchaseOrder.assetType" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="assettype" />
                        <ErrorMessage name="assettype" class="ams-val-err" />
                        
                    </div>
                </div>
                 <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Vendor name</label>
                        <multiselect :options="vendorList" v-model="purchaseOrder.vendorName" name="vendorname" class="ams-form-control ams-custom-control-dark" :searchable="true" track-by="label"></multiselect>
                        <Field type="text" v-model="purchaseOrder.vendorName" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="vendorname" />
                        <ErrorMessage name="vendorname" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Price</label>
                        <Field v-model="purchaseOrder.price" type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="price" />
                        <ErrorMessage name="price" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Quantity</label>
                        <Field v-model="purchaseOrder.quantity" type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="qty" />
                        <ErrorMessage name="qty" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-datepicker-md">
                        <label>DOP (Date of Purchase)</label>
                        <!-- <datepicker v-model="purchaseOrder.dop" name="dop" type="date" class="ams-form-control ams-custom-control-dark">
                        </datepicker> -->
                        <datepicker v-model="purchaseOrder.dop" name="dop"  class="ams-form-control ams-custom-control-dark" :maxDate="new Date()" :enableTimePicker="false" monthNameFormat="long" autoApply/>
                        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                        <Field type="text" v-model="purchaseOrder.dop" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="dop" />
                        <ErrorMessage name="dop" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>PO number</label>
                        <Field v-model="purchaseOrder.poNumber" type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="ponumber" />
                        <ErrorMessage name="ponumber" class="ams-val-err" />
                    </div>
                </div>
            </div>
   
            <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/>
            <div v-else>
            <div class="ams-row">
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Upload PO slip</label>
                        <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />
                    </div>
                    <div v-if="fileData && fileData.filename">
                        {{fileData.filename}} Uploaded Successfully
                    </div>
                </div>
            </div>
            </div>
            <div class="ams-text-right ams-table-filter">
                <button class="ams-btn ams-btn-danger ams-mr20" @click="$router.push('/inventory/purchase')" >Cancel</button>
                <button class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(savePurchaseOrder)">Save</button>
            </div>
        </div>
    </Form>
</div>
</template>

<script>
/*eslint-disable*/
import FileUpload from "../../components/common/FileUpload.vue"
import Multiselect from '@vueform/multiselect'
import UploadInvoice from "../common/UploadInvoice.vue"
// import moment from 'moment'
// import Datepicker from 'vue3-datepicker'
import Datepicker from 'vue3-date-time-picker';
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
// import _ from 'lodash';
export default {
    components: {
        FileUpload,
        Multiselect,
        Datepicker,
        Form,
        Field,
        ErrorMessage,
        UploadInvoice
    },
    data() {
        const purchaseschema = Yup.object().shape({
            assettype: Yup.string().required("Please Select Asset/Accessory Tag").nullable(),
            vendorname: Yup.string().required(" Please Select Vendor name"),
            price: Yup.string().matches(/^[0-9]+$/, "Only Numbers Are Allowed").required("Please Enter Price"),
            qty: Yup.string().matches(/^[0-9]+$/, "Only Numbers Are Allowed").required("Please Enter Quantity"),
            dop: Yup.string().required("Please Select Date of Purchase").nullable(),
            ponumber: Yup.string().matches(/^[a-zA-Z0-9]+$/,"Special Characters Not Allowed ").required("Please Enter PO Number"),
            // fileup: Yup.string().required().label("Purchase Order Slip"),

        });
        return {
            purchaseOrder: {
                assetType: "",
                vendorName: "",
                vendorNumber: "",
                price: "",
                dop: "",
                poNumber: "",
                quantity: "",
                cType: "1"

            },
            purchaseschema,
            filterFlag: false,
            vendorList: {},
            productType: null,
            utext: 'Upload PO Slip',
            fileData: {},

        };
    },
    computed: {
        productTypes: function () {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return parsedPT && parsedPT["1"];
        },
    },
    methods: {

        fetchAssetTags: async function (pid) {
            console.log(pid)
            let _self = this;
            let response = await this.$store.dispatch(
                "product/FETCH_ALL_PTAG",
                pid
            );
            if (response && response.status == 1000) {
                _self.productType = pid;
                _self.productTags = response.data;
                console.log(_self.productTags, ",,,,,,,,,,,,,");
            }
        },
        savePurchaseOrder: function () {
            let _self = this;
            let invReq = {
                pType: _self.purchaseOrder.assetType,
                ven_id: _self.purchaseOrder.vendorName,
                cost: parseInt(_self.purchaseOrder.price),
                quantity: _self.purchaseOrder.quantity,
                dop: _self.purchaseOrder.dop,
                po_number: _self.purchaseOrder.poNumber,
                cType: _self.purchaseOrder.cType,
                filedata: (_self.fileData) ? _self.fileData : {}
            };
            console.log('req', invReq)
            _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch("inventory/SAVE_PURCHASE_ORDER", invReq).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {

                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "purchase order added successfuly",
                        class: "ams-error-success",
                    });
                    _self.$router.push('/inventory/purchase')
                    // let tabSelected = localStorage.getItem("tabSelected");
                    // let parsedPT = tabSelected && JSON.parse(tabSelected);
                    // _self.$parent.changeTab(parsedPT)

                } else {
                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: res.message,
                        class: "ams-error-danger",
                    });
                }
            });
        }
    },
    mounted() {
        let _self = this

        this.$store
            .dispatch("vendor/LIST_VENDOR")
            .then(function (res) {

                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.vendorList = res.data

                } 
            });
    },
    // watch:{
    //     purchaseOrder:{
    //         immediate:true,
    //         handler(){
    //             let self=this
    //             let purchaseObj={}
    //             _.each(self.purchaseOrder,(v,k)=>{
    //                 // console.log(k,v);
    //                 purchaseObj[k]=Yup.string().required('This field is Required')
    //             })
    //             self.purchaseschema=Yup.object(purchaseObj)
    //         }
    //     }
    // }

};
</script>
