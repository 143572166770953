<template>
<div>

    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead v-if="inventoryList && inventoryList.length">
            <tr>
                <th>SI.No</th>
                <th>Asset / Accessory / License Name</th>
                <th>Asset / Accessory / License Tag</th>
                <th>Employee ID</th>
                <th>Assigning Date</th>
                <th>Vendor Name</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a, i) in inventoryList" :key="i" @click="viewInventory(a.prod_tag)">
                <td data-title="SI.NO">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Asset / Accessory Name">{{parsedData[a.pType]}}</td>
                <td data-title="Asset / Accessory Tag">{{a.prod_tag}}</td>
                <td data-title="Employee ID">{{a.emp_id}}</td>
                <td data-title="Assigning Date">{{viewDate(a.created_date)}}</td>
                <td data-title="Vendor Name">{{a.ven_name || '-'}}</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter ams-mt20">
        <pagination  :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue";
import moment from "moment";
import {
    pagination_limit
} from "../../../config/endpoint";

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            isShow: false,
            page:1, 
            paginationData:null,
            inventoryList: null,


        };
    },
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            console.log(parsedPT[1], "parseddd");
            console.log(parsedPT[2], "parseddd2222");
            return {
                ...parsedPT[1],
                ...parsedPT[2],
                ...parsedPT[3]
            }
        },
         limit: function () {
            return pagination_limit
        }
    },
    methods: {
        viewInventory: function (ptag) {
            this.$router.push("/inventory/assign/" + ptag);
        },
        ListInventory: function () {
            let _self = this;

            let inventoryTable = {
                limit: this.limit,
                page: this.page,
            };
            //   _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch("inventory/FETCH_INVENTORY_TABLE", inventoryTable).then(function (res) {
                //   _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    console.log("res", res);
                    _self.inventoryList = res.data.assignItems;
                     _self.paginationData = res.data.metadata[0]
                    console.log("inventoryList", res.data.assignItems);
                } 
                // else {
                //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                //         msg: "Technical error",
                //         class: "ams-error-danger",
                //     });
                // }
            });
        },
        viewDate: function (date) {

            return moment(date).format('DD-MM-YYYY');

        },
        setPageData: function (page) {
            this.page = page
            this.ListInventory()
        },
        indexMethod(pageNumber, pageSize) {
            
            return pageNumber * pageSize - (pageSize - 1);
        },
    },
    mounted() {
        this.ListInventory();
    },

};
</script>
