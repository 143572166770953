<template>
<div class="ams-content">
    <add-manufacture v-if="$route.params.action==='add' || $route.params.action==='edit'" />
    <div v-if="!$route.params.action" class="ams-row ams-d-f-a-center">
        <div class="ams-col7 ams-col-xs12">
            <h3 class=" ams-tit">Manufacturers</h3>
        </div>
        <div class="ams-col5 ams-col-xs12 ams-text-right">
            <div class="ams-row ams-d-flex">
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs6">
                    <div class="ams-form-group vendor-pro-version-btn">
                        <pro-version-btn />
                    </div>
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs6">
                    <div class="ams-form-group">
                        <button class="ams-btn ams-btn-primary ams-btn-block" @click="$router.push('/manufacture/add')">Add Manufacture</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
                    <div class="ams-form-group">
                        <input type="text" @input="manFilter" v-model="man_name" class="ams-form-control ams-form-control-sm ams-custom-control" placeholder="Search Manufacturer Name" />
                        <i class="icon-search ams-form-icon ams-form-icon-right"></i>
                    </div>
                </div>
    </div>
    <manufacture-table v-if="!$route.params.action" ref="mt" :man_name="man_name" />
</div>
</template>

<script>
import ProVersionBtn from "../../components/pro/ProVersionBtn.vue";
import AddManufacture from "../../components/manufacture/addmanufacture.vue";
import ManufactureTable from "../../components/manufacture/manufacturetable.vue"

export default {
    components: {
        ManufactureTable,
        ProVersionBtn,
        AddManufacture,

    },
    data() {
        return {
              man_name:null
        };
    },
    mounted() {

    },
    methods: {
        manFilter() {
            let manRef = this.$refs['mt']
            if (!this.man_name.length) {
                manRef.listManufacture()
                return
            }
            if (this.man_name.length >= 3) {
                manRef.listManufacture('filter')
            }
        }

    }
};
</script>
