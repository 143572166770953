<template>
<div>
    <Form :validation-schema="quotationValidate" v-slot="{ handleSubmit }">
            <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/vendor')"></i>Ask Quotation</h3>
            <div class="ams-card">
            <vendor-type-assets ref="vtas" />
            <vendor-type-accessories ref="vtac" />
            <div class="ams-row ams-d-f-sbetween">
                <!-- <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
            <file-upload :show-upload="true" :show-delete="true" />
            </div>
        </div> -->
                <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-mt20">
                        <label>Vendor Name</label>
                        <Multiselect v-model="ven_name" mode="tags" :closeOnSelect="false" :searchable="true" :createTag="true" :options="vendorList" class="ams-form-control-multi ams-form-control ams-custom-control-dark" placeholder="Vendor Name"></Multiselect>
                        <Field type="text" v-model="ven_name" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="Vendor Name" name="vendorName" />
                        <ErrorMessage name="vendorName" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md8 ams-col-sm6 ams-col-xs12 ams-table-filter">
                    <button class="ams-btn ams-btn-danger " @click="$router.push('/vendor')">Cancel</button>
                    <button type="button" class="ams-btn ams-btn-primary ams-ml20" @click="handleSubmit(sendquot)">Send mail</button>
                </div>
            </div>
        </div>
    </Form>
</div>
</template>

<script>
import VendorTypeAssets from "../../components/vendor/VendorTypeAssets.vue"
import VendorTypeAccessories from "../../components/vendor/VendorTypeAccessories.vue"
import Multiselect from '@vueform/multiselect'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";

export default {
    components: {
        VendorTypeAssets,
        VendorTypeAccessories,
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        const quotationValidate = Yup.object().shape({
            vendorName: Yup.array().min(1).required().label('Vendor Name is Required'),
        });
        return {
            vendorList: null,
            ven_name: [],
            quotationValidate
        };
    },
    mounted() {
        let _self = this
        _self.vendorNameData()
    },
    methods: {
        vendorNameData() {
            let _self = this
            _self.$store.dispatch("vendor/LIST_VENDOR").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.vendorList = res.data
                }

            });
        },
        sendquot() {
            let self = this
            let assetRef = self.$refs['vtas']
            let accesRef = self.$refs['vtac']
            if (assetRef.asset_item.length || accesRef.accessories_item.length) {
                console.log('Calll API')
                let arr1 = assetRef.asset_item.length ? assetRef.asset_item : []
                let arr2 = accesRef.accessories_item.length ? accesRef.accessories_item : []
                let obj = [...arr1, ...arr2]
                console.log(obj, 'obj');
                let reqObj = {
                    products: obj,
                    vendors: self.ven_name
                }
                console.log('req', reqObj)
                self.$store.dispatch("common/SET_LOADER", true)
                this.$store.dispatch("vendor/QUOTATION_VENDOR", reqObj).then(function (res) {
                    self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        console.log('resssssssssss', res)
                        self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Quotation Send Successfully",
                            class: "ams-error-success",
                        });
                        self.$router.push('/vendor')

                    } else {
                        self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Mail Not Send",
                            class: "ams-error-danger",
                        });
                    }
                });
            } else {
                self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    msg: "Please add products",
                    class: "ams-error-danger",
                });
            }
        }
    }
};
</script>