<template>
<div>
    <Form :validation-schema="repairValidate" v-slot="{ handleSubmit }">
        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/maintenance')"></i>Repair & Maintenance</h3>
        <form class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset / Accessory name</label>
                        <Multiselect @change="fetchAssetTags" @clear="clearEmp" v-model="repairItem.pType" :options="productTypes" class="ams-form-control ams-custom-control-dark" placeholder="Asset / Accessory Name" name="software" id="software" :searchable="true" track-by="label"/>
                        <Field type="text" v-model="repairItem.pType" v-show="false" class="ams-form-control ams-custom-control-dark"  name="assetName" />
                        <ErrorMessage name="assetName" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset / Accessory tag</label>
                        <Multiselect @change="getEmployees($event)" @clear="clearEmp" v-model="repairItem.prod_tag" :options="productTags" class="ams-form-control ams-custom-control-dark" name="version" id="version" placeholder="Asset /Accessory Tag" />
                        <Field type="text" v-model="repairItem.prod_tag" v-show="false" class="ams-form-control ams-custom-control-dark"  name="assetTag" />
                        <ErrorMessage name="assetTag" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Employee Id</label>
                        <Field type="text" v-model="repairItem.emp_id"  class="ams-form-control ams-custom-control-dark"  name="empIId" id="version" placeholder="Employee Id" disabled/>
                        <ErrorMessage name="empIId" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Failure type</label>
                        <Field type="text" v-model="repairItem.fail_type" class="ams-form-control ams-custom-control-dark" placeholder="Failure Type" name="failType" id="version" />
                        <ErrorMessage name="failType" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Repaired / Replaced</label>
                        <Multiselect v-model="repairItem.repair_status" :options="options" placeholder="Repaired/Replaced" class="ams-form-control ams-custom-control-dark"></Multiselect>
                        <Field type="text" v-model="repairItem.repair_status" v-show="false" class="ams-form-control ams-custom-control-dark"  name="repaired" />
                        <ErrorMessage name="repaired" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Repairing cost</label>
                        <Field type="text" v-model="repairItem.repair_cost" class="ams-form-control ams-custom-control-dark" placeholder="Repairing Cost" name="repairCost" id="repairCost" />
                        <ErrorMessage name="repairCost" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Item status</label>
                        <Multiselect v-model="repairItem.prod_status" :options="itemstatus" placeholder="Item status" class="ams-form-control ams-custom-control-dark"></Multiselect>
                        <Field type="text" v-model="repairItem.repair_status" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="Item status" name="itemstatus" />
                        <ErrorMessage name="itemstatus" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-datepicker-md">
                        <label>Repaired date</label>
                        <!-- <datepicker v-model="repairItem.repair_date" class="ams-form-control ams-custom-control-dark" name="repairDate">
                        </datepicker> -->
                        <datepicker v-model="repairItem.repair_date" :maxDate="new Date()" name="repairDate" class="ams-form-control ams-custom-control-dark" :enableTimePicker="false" placeholder="Repaired Date" monthNameFormat="long" autoApply/>
                        <Field type="calender" v-model="repairItem.repair_date" v-show="false" class="ams-form-control ams-custom-control-dark"  name="calender" />
                        <ErrorMessage name="calender" class="ams-val-err" />
                        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                    </div>
                </div>
                <!-- <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Reselling price</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" name="Password" id="Password" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Resold to</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="Vendor" name="Productkey" id="Productkey" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Reselling date</label>
                        <datepicker class="ams-form-control ams-custom-control-dark">
                        </datepicker>
                        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                    </div>
                </div> -->
            </div>
            <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/>
            <div v-else>
                <h3 class="ams-tit">Upload Invoice</h3>
                <div class="ams-row">
                    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                        <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" name="invoice" @fileUploadSuccess="fileData =  $event" />
                        <!-- <ErrorMessage name="invoice" class="ams-val-err" /> -->
                    </div>
                </div>
            </div>
            <div class="ams-col12 ams-mt20">
                <div class="ams-form-group ams-pt20">
                    <textarea class="ams-form-control" v-model="repairItem.comment" placeholder="Comments" name="software" id="software" />
                    </div>
                </div>          
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-danger ams-mr20" @click="$router.push('/maintenance')">Cancel</button>
                <button class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(addItem)" v-if="!flag1">Save</button>
                <button class="ams-btn ams-btn-primary" @click.prevent="updateRepair()"  v-if="flag1">Update</button>

            </div>
        </form>
     </Form>

</div>
</template>

<script>
import FileUpload from "../../components/common/FileUpload.vue"
import Multiselect from '@vueform/multiselect'
// import Datepicker from 'vue3-datepicker'
import Datepicker from 'vue3-date-time-picker';
import UploadInvoice from '../../components/common/UploadInvoice.vue'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
import _ from 'lodash';

export default {
    data() {
        const repairValidate = Yup.object().shape({
        //     empId: Yup.string().trim(),
            assetName: Yup.string().required("Please select Asset / Accessory Name").nullable(),
            assetTag: Yup.string().required("Please Select Asset /Accessory tag").nullable(),
            // empIId: Yup.string().matches(/^[a-zA-Z0-9]+$/,"Special Characters Not Allowed ").required("Please Enter Employee Id").nullable(),
            failType: Yup.string().matches(/^[aA-zZ\s]+$/,"Special Characters Not Allowed ").required("Please Enter Faliure Type"),
            repaired: Yup.string().required("Please Select One").nullable(),
            repairCost: Yup.string().matches(/^[0-9]+$/,"Only Numbers Are Allowed").required("Please Enter Repair Cost").nullable(),
            itemstatus: Yup.string().required("Please Select Status").nullable(),
            calender: Yup.string().required("Please Select Repaired Date").nullable(),
        //     // invoice:Yup.string().required("please select invoice file").nullable(),
        });
        return {
            repairValidate,
            options: [
                'Repaired',
                'Replaced',
            ],
            flag1: false,
            itemstatus: {
                '111': 'Working', 
                '112': 'Damaged'
            },
            productTags: null,
            productType: null,
            repairItem: {
                pType: '',
                prod_tag: '',
                fail_type: '',
                repair_status: '',
                repair_cost: null,
                repair_date: '',
                comment: ''
            },
            utext: 'Click to Add your file',
            fileData: {},
            empList: null,
            prodVal: {}

            // emp_id: null
        };
    },
    computed: {
        productTypes() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return { ...parsedPT[1], ...parsedPT[2] }
        }
    },
    mounted() {
        let self = this
        if (self.$route.params.repairid) {
            self.getdata()
            self.getTags()
        }
        let pt = localStorage.getItem("pt");
        let parsedPT = pt && JSON.parse(pt);
        _.each(parsedPT, (e, key) => {
            _.each(e, (evalue, ekey) => {
                self.prodVal[ekey] = key
            })
        })
    },
    methods: {
        getdata() {
            let _self = this
            let repair = _self.$route.params.repairid;
            _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch('repair/REPAIR_ID', repair).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                console.log('res', res)
                if (res && res.status == 1000) {
                    _self.flag1 = true
                    let rdata = res.data
                    _self.repairItem.pType = rdata.pType
                    _self.repairItem.repair_cost = rdata.repair_cost
                    _self.repairItem.fail_type = rdata.fail_type
                    _self.repairItem.repair_date = new Date(rdata.repair_date)
                    _self.repairItem.prod_status = rdata.prod_status
                    _self.repairItem.repair_status = rdata.repair_status
                    _self.repairItem.comment = rdata.comment
                    _self.fileData = rdata.filedata
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical Error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        clearEmp(){
            let _self = this;
            _self.repairItem.emp_id = null
            _self.repairItem.prod_tag = null
        },
        addItem: function () {
            console.log("gggggggg")
            let _self = this
            let itemReq = {
                prod_tag: _self.repairItem.prod_tag,
                fail_type: _self.repairItem.fail_type,
                repair_status: _self.repairItem.repair_status,
                repair_cost: _self.repairItem.repair_cost,
                repair_date: _self.repairItem.repair_date,
                pType: _self.repairItem.pType,
                cType: _self.prodVal[_self.repairItem.pType]
            }
            if(_self.fileData && _self.fileData.filename) {
                itemReq.filedata = _self.fileData
            }
            if(_self.repairItem && _self.repairItem.comment) {
                itemReq.comment = _self.repairItem.comment
            }
            console.log('itemReq', itemReq)
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('repair/ADD_ITEM', itemReq).then(function (res) {
                console.log('RES', res)
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Item added successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/maintenance')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
             })
        },
        fetchAssetTags: async function (pid) {
            console.log('pid', pid)
            let _self = this;
            let response = await this.$store.dispatch(
                "product/FETCH_ALL_PTAG", pid);
            if (response && response.status == 1000) {
                _self.productType = pid;
                _self.productTags = response.data;
            }
        },
        getEmployees: async function (val) {
            let _self = this
            let empdata = {
                prod_tag: val
            }
            let response = await _self.$store.dispatch("product/FETCH_EMPLOYEE_ID", empdata)
            console.log('response', response)
            if (response && response.status == 1000) {
                _self.repairItem.emp_id = response.data.emp_id
            }
        },
        updateRepair(){
            let _self = this
            let upReq = {
                prod_tag: _self.repairItem.prod_tag,
                fail_type: _self.repairItem.fail_type,
                repair_status: _self.repairItem.repair_status,
                repair_cost: _self.repairItem.repair_cost,
                repair_date: _self.repairItem.repair_date,
                filedata: _self.fileData,
                comment: _self.repairItem.comment
            }
            _self.$store.dispatch('common/SET_LOADER', true)
             _self.$store.dispatch('repair/UPDATE_ITEM',upReq).then(function (res) {
                console.log('RESSSSSS', res)
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Item updated successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/maintenance')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
             })

        },
        getTags(){
           let _self = this
           _self.$store.dispatch('product/FETCH_TAGS').then(function (res) {
                if (res && res.status == 1000) {
                   _self.productTags = res.data;
                }
            })
           

        }

    },
    components: {
        FileUpload,
        Multiselect,
        Datepicker,
        Form,
        Field,
        ErrorMessage,
        UploadInvoice
    }
};
</script>
