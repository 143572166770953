<template>
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>Sl.No</th>
                <th>Ticket No.</th>
                <th>Raised by Employee ID</th>
                <th>Date of Ticket Generation</th>
                <th>Date to Close</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in ticketData" :key="i" @click="getData(a.tid)">
                <td data-title="SI.NO">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Ticket No.">{{a.tid}}</td>
                <td data-title="Raised by Employee ID">{{a.emp_id}}</td>
                <td data-title="Date of Ticket Generation"> {{getDate(a.cDate)}} </td>
                <td data-title="Date to Close"> {{getDate(a.expt_time)}} </td>
                <td class="ams-green-text" data-title="Status">Reopen</td>
                <!-- <td data-title="Action"><i class="icon-edit-box ams-f-size18 ams-fw600 ams-primary-text" @click="$router.push('/reopen-ticket')"></i></td> -->
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total > limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue"
import moment from 'moment'
import {
    pagination_limit
} from "../../../config/endpoint";

export default {
    props: ['ticketData', 'paginationData'],

    data() {
        return {};
    },
    methods: {
        getDate(val) {
            return moment(val).format('DD-MM-YYYY')
        },
        getData(val) {
            let self = this
            self.$router.push('/tickets/reopen/' + val)
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
    },
    computed: {
        limit() {
            return pagination_limit
        }
    },
    components: {
        Pagination,
    }
};
</script>
