<template>
<div class="ams-card ams-card-info ams-card-border" v-if="vendorData">
    <Form :validation-schema="vendorValidate" v-slot="{ handleSubmit }">
    <div class="ams-row ams-ven-list">
        <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <label class="ams-gray-text">Vendor name</label>
            <span>{{vendorData.ven_name}}</span>
        </div>
        <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <label class="ams-gray-text">Vendor contact number
                <button class="ams-badge ams-badge-info ams-ml15" name="showPhnum" v-if="!flag2 && !flag3" @click="disable">Update</button>
            </label>
            <Field v-if="flag3 && !flag2" type="text" class="ams-form-control" v-model="phoneNum"  name="phnum" id="phnum" />
            <span v-else>+{{vendorData.ccode}} {{vendorData.ph_num}}</span>
            <ErrorMessage v-if="flag3 && !flag2" name="phnum" class="ams-val-err" />
        </div>
        <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <label class="ams-gray-text">Vendor email Id
                <button class="ams-badge ams-badge-info ams-ml15" v-if="!flag3 && !flag2" @click="updateflags">Update</button>
            </label>
            <Field v-if="flag2" type="text" class="ams-form-control" v-model="email" name="venemail" id="venemail" />
            <span v-else>{{vendorData.email}}</span>
            <ErrorMessage v-if="flag2" name="email" class="ams-val-err" />
        </div>
        <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <label class="ams-gray-text">Registration number</label>
            <span>{{vendorData.gst_num}}</span>
        </div>
        <div class="ams-col12">
            <label class="ams-gray-text ams-mt20">Vendor address
                <button class="ams-badge ams-badge-info ams-ml15" v-if="!flag1" @click="updateflag">Update</button>
            </label>
            <input v-if="flag1" type="text" class="ams-form-control ams-mb20" v-model="vendorData.add" />
            <span v-else class="ams-mb20 ams-d-block">{{vendorData.add}}</span>
            <em class="ams-red-text">Note : both email id and contact no. cannot be updated together</em>
        </div>
    </div>
    <div class="ams-table-filter ams-text-right">
        <button class="ams-btn ams-btn-danger ams-mr20" v-if="flag1 || flag2 || flag3" @click="cancel()">Cancel</button>
        <button type="button" class="ams-btn ams-btn-outline-info" v-if="flag1 || flag2 || flag3" @click="handleSubmit(updateVendor)">Save</button>
    </div>
    </Form>
</div>
</template>

<script>
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as Yup from "yup";

export default {
    components: {
        Form,
        Field,
        ErrorMessage
    },
    data() {
        const vendorValidate = Yup.object().shape({
            // phnum: Yup.string().matches(/^[0-9\s]+$/,"Only Numbers Are Allowed").required("Please Enter Phone Number"),
            // phnum: Yup.string()
            //     .when('showPhnum', {
            //             is: true,
            //             then: Yup.string()
            //                 .matches(/^[0-9\s]+$/,"Only Numbers Are Allowed").required("Please Enter Phone Number"),
            //         }),
            // venemail: Yup.string()
            //     .when('venemail', {
            //             is: true,
            //             then: Yup.string()
            //                 .email("Please Enter Valid Email").required("Please Enter Email")
            //         })
            // email: Yup.string().email("Please Enter Valid Email").required("Please Enter Email")
        });
        return {
            vendorValidate,
            // vendorData: null,
            flag1: false,
            flag2: false,
            flag3: false,
            vendorData:{
                ven_id: "",
                ccode: "91",
                ph_num: "",
                email: "",
                add: "",
                ven_name: ""
            },
            phoneNum: "",
            email: ""
        };
    },
    mounted() {
        let self = this
        self.getVendorInfo()
    },
    methods: {
        getVendorInfo() {
            let vnme = this.$route.params.venid;
            console.log('vnme', vnme)
            let _self = this
            _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch('vendor/VENDOR_ID', vnme).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                console.log('res', res)
                if (res && res.status == 1000) {
                    _self.vendorData = res.data
                    _self.phoneNum = _self.vendorData.ph_num
                    _self.email = _self.vendorData.email
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical Error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        cancel () {
            let self = this
            self.flag1 = false
            self.flag2 = false
            self.flag3 = false

        },
        updateflag() {
            this.flag1 = true
        },
        updateflags() {
            this.flag2 = true
        },
        disable() {
            this.flag3 = true
        },
        updateVendor: function () {
            let _self = this
            let vnme = this.$route.params.venid;
            let reqObj = {
                ven_id: vnme
            }
            if (_self.vendorData.add && _self.flag1) {
                reqObj.add = _self.vendorData.add
            }
            console.log('_self.vendorData.email', _self.vendorData.email)
            console.log('_self.flag2', _self.flag2)
            if(_self.email && _self.flag2){
                console.log('entered email')
                reqObj.email= _self.email
            }
            if(_self.phoneNum  && _self.flag3){
            reqObj.ph_num = _self.phoneNum 
            }

            console.log('reqObj', reqObj)
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('vendor/UPDATE_VENDOR', reqObj).then(function (res) {
                console.log('RES', res)
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Vendor Details Updated successfully',
                        class: 'ams-error-success'
                    })
                    _self.getVendorInfo()
                    _self.flag1 = false
                    _self.flag2 = false
                    _self.flag3 = false
                // _self.$router.push('/vendor')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
             })
        }

    }
};
</script>
