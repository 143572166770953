<template>
<div class="ams-card">
    <div class="ams-row ams-d-f-a-center">
        <div class="ams-col6 ams-col-md8 ams-col-sm8 ams-col-xs12">
            <h3>This laptop is better to discard do you want to proceed ?</h3>
        </div>
        <div class="ams-col6 ams-col-md4 ams-col-sm4 ams-col-xs12 ams-text-right ams-table-filter">
            <button class="ams-btn ams-mr20 ams-btn-danger" @click="cancel()">Cancel</button>
            <button class="ams-btn ams-btn-primary" @click.prevent="saveScraped">Scrap</button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['depreciationList','pTag'],
    components: {

    },
    data() {
        return {
			//pTag:""
            // depreciationList: {},           
        }

    },
    mounted() {
    },

    methods: {
        cancel() {
            let self = this
            self.$emit('cancel-popup', '')
        },
		saveScraped(){
			// this.$emit('scrapedSave','')
            this.$parent.saveScraped()
		}
       
    }

};
</script>
