<template>
  <div class="asset-card">
    <div class="asset-ticket">
      <h4>Activity</h4>
      <!-- <h4>29 - Jul - 2021</h4> -->
    </div >
    <ul class="sa-ticket-list">
      <li v-for="(item, i) in activitydata" :key="i">
        <span v-if="(item && item.assignee != '')"> {{getName(item.assignee)}} </span>
         <span v-else> {{fn.charAt(0)}}{{ln.charAt(0)}}</span>
          <h6 v-if="(item && item.assignee != '')">{{item.assignee}}</h6>
          <h6 v-else>{{fn}} {{ln}}</h6>
          <i> changed on {{getDate(item.cDate)}} </i>
          <p class="ams-f-size12 ams-grey">
            {{item.comment}}
          </p>
      </li>
      <!-- <li v-for="(item,i) in activitydata"  :key="i">
        <span>SU</span>
          <h6>{{item.assignee}}</h6>
          <i> {{getDate(item.cDate)}} </i>
          <p class="ams-f-size12 ams-grey">
            {{item.comment}}
          </p>
      </li>
      <li v-for="(item,i) in activitydata" :key="i">
        <span>SU</span>
          <h6>{{i.assigne}}</h6>
          <i> {{getDate(item.cDate)}} </i>
          <p class="ams-f-size12 ams-grey">
            {{item.comment}}
          </p>
      </li>  -->
    </ul>
  </div>
</template>
<script>
import moment from "moment"
export default {
    props: ["activitydata"],
	data() {
		return {
      fn:"Admin",
      ln:"Admin"
		};
	},
  methods:{
    getDate:function(date){
      return moment(date).format('DD - MMM- YYYY, hh:mm A');
    },
    getName (name) {
      return name.slice('0', '1')
    }
  }
}
</script>