<template>
<div class="ams-content">
    <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/settings')"></i> Settings - Customize asset page</h3>
    <form class="ams-card">
        <div class="ams-row">
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Asset tag</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensetype" id="licensetype" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Asset Type</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensetype" id="licensetype" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Device Id</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensetype" id="licensetype" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Deployed by</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licensename" id="licensename" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Employee Id</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licenseid" id="licenseid" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Audit date</label>
                    <span class="ams-remove">Remove</span>
                    <datepicker v-model="picked" class="ams-form-control ams-custom-control-dark"></datepicker>
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Audit status</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="Productkey" id="Productkey" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Manufacturer name</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="Manufacturename" id="Manufacturename" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Serial number</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="vendorname" id="vendorname" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Model name</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="renewaldate" id="renewaldate" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Model number</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Express service tag</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Host name</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>MAC address</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>IP address</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Processor</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Speed</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Hard disk</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>RAM</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>DVD Rom</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Linked charger asset code</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Invoice number</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Cost</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Vendor name</label>
                    <span class="ams-remove">Remove</span>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="status" id="status" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>DOP ( Date of Purchase )</label>
                    <span class="ams-remove">Remove</span>
                    <datepicker v-model="picked" class="ams-form-control ams-custom-control-dark"></datepicker>
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Warranty expiry date</label>
                    <span class="ams-remove">Remove</span>
                    <datepicker v-model="picked" class="ams-form-control ams-custom-control-dark"></datepicker>
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Status</label>
                    <span class="ams-remove">Remove</span>
                    <Multiselect v-model="value" :options="options" class="ams-form-control ams-custom-control-dark"></Multiselect>
                </div>
            </div>
        </div>
    </form>
    <form class="ams-card">
        <div class="ams-row ams-d-f-a-center">
            <div class="ams-col3 ams-col-md3 ams-col-sm3 ams-col-xs12">
                <div class="ams-form-group">
                    <file-upload :show-upload="true" :show-delete="true" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm3 ams-col-xs12">
                <div class="ams-form-group">
                    <input type="text" class="ams-form-control ams-custom-control" placeholder="Field heading">
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm3 ams-col-xs12">
                <div class="ams-form-group">
                    <Multiselect v-model="value" :options="options" class="ams-form-control ams-custom-control"></Multiselect>
                </div>
            </div>
            <div class="ams-col3 ams-col-md3 ams-col-sm3 ams-col-xs12">
                <div class="ams-form-group">
                    <input type="checkbox" id="Drop down">
                    <label for="Drop down">Mark as mandatory</label>
                </div>
            </div>
        </div>
        <div class="ams-table-filter">
            <button class="ams-btn ams-btn-primary">Done</button>
        </div>

    </form>

</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import Datepicker from 'vue3-datepicker'
import FileUpload from "../../../components/common/FileUpload.vue"
import { ref } from 'vue'
const picked = ref(new Date())

export default {
    components: {
        Datepicker,
        Multiselect,
        FileUpload

    },
    data() {
        return {
            value: null,
            filterFlag: false,
            picked,
            options: [
                'Batman',
                'Robin',
                'Joker',
            ]
        };
    },
    methods: {

    }
};
</script>
