<template>

<div>
    <div class="ams-row ams-d-f-a-center ams-tit">
        <div class="ams-col10 ams-col-md10 ams-col-sm8 ams-colx-xs12">
            <h3 class="ams-tit">Asset History</h3>
            
        </div>
        <div class="ams-col2 ams-col-md2 ams-col-sm4 ams-col-xs12 ams-text-right">
            <pro-version-btn />
        </div>
    </div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead v-if="assetList && assetList.length">
            <tr>
                <th>Sl.No</th>
                <th>Asset Name</th>
                <th>Asset Tag</th>
                <th>Assignment History</th>
                <th>Repair History</th>
            </tr>
        </thead>
        <tbody>
            <tr @click="historyDetails" v-for="(a,i) in assetList" :key="i">
                <td data-title="S.No">{{i+1}}</td>
                <td data-title="Asset Name">{{a.pType}}</td>
                <td data-title="Asset Tag">{{a.prod_tag}}</td>
                <td data-title="Assignment History">{{getDate(a.assig_hist)}}</td>
                <td data-title="Repair History">Logitech Solutions</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination />
    </div>
</div>
</template>

<script>
import pagination from "../common/Pagination.vue"
import ProVersionBtn from "../pro/ProVersionBtn.vue"
import moment from "moment";


export default {
    props:['assetList'],
    components: {
        pagination,
        ProVersionBtn
    },
    data() {
        return {

        };
    },
    methods: {
        historyDetails: function(){

            this.$router.push('/assets-history-details')

        },
        getDate : function(date){

            return moment(date).format('DD-MM-YYYY');

        }

    }
};
</script>

// moment(assig_hist).format('YYYY-MM-DD')
