<template>
<div class="ams-mt10">
    <h3 class="ams-tit">License Details</h3>
    <div class="ams-row ams-a-center">
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <label>License Manufacturer name for allocate</label>
                <Multiselect @change="fetchLicenseTags" @clear="clearLic" v-model="productType" :options="productTypes" class="ams-form-control ams-custom-control-dark" :searchable="true" track-by="label" placeholder="License Name"></Multiselect>
            </div>
        </div>
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <label>License tag for allocate</label>
                <Multiselect @change="productTagSelected" v-model="productTag" :options="productTags" class="ams-form-control ams-custom-control-dark" placeholder="License Tag"></Multiselect>
            </div>
        </div>
        <div class="ams-col2 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <button @click.prevent="allocateProduct" class="ams-btn ams-btn-outline-primary ams-btn-block ams-alc-btn">
                    Allocate
                </button>
            </div>
        </div>
        <div class="ams-col8 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <ul class="ams-alloc-list" v-if="selectedProducts && selectedProducts.length">
                <li v-for="(p, i) in selectedProducts" :key="i">
                    <span><label class="ams-gray-text">Allocated Manufacturer name : </label> {{ p.productType }}</span>
                    <span><label class="ams-gray-text">Allocated License tag : </label> {{ p.productTag }}</span>
                    <span class="ams-remove-text" @click="deleteLicense(i)">Remove</span>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {    
    mounted: async function () {
        let res = await this.$store.dispatch("product/FETCH_LICENSE_INFO");
        if (res && res.status == 1000) {
            if (typeof res.data == 'object') {
                this.licenseInfo = res.data
                this.productTypes = Object.keys(res.data) || []
            }
        }

    },
    components: {
        Multiselect,
    },
    props: ['licenseList'],
    // computed: {
    //     productTypes: function () {
    //         let pt = localStorage.getItem("pt");
    //         let parsedPT = pt && JSON.parse(pt);
    //         return parsedPT && parsedPT["3"];
    //     },
    // },
    data() {
        return {
            licenseInfo: null,
            productTypes: [],
            productTags: null,
            productTag: null,
            productType: null,
            selectedProducts: [],
        };
    },

    methods: {
        fetchLicenseTags: async function (pid) {
            let _self = this;
            // _self.productTag = null
            _self.productType = pid;
            _self.productTags = (_self.licenseInfo && _self.licenseInfo[pid]) || []
        },
        productTagSelected: function (pt) {
            this.productTag = pt;
        },
        allocateProduct: function () {
            if (this.productType && this.productTag) {
                if (this.selectedProducts && this.selectedProducts.length) {
                    let selectedValues = this.selectedProducts.map(sp => sp.productTag)
                    if (selectedValues.indexOf(this.productTag)>-1) {
                        this.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Already allocated this license",
                            class: "ams-error-danger",
                        });
                    } else {
                        this.selectedProducts.push({
                            productType: this.productType,
                            productTag: this.productTag,
                        });
                        this.productType = null
                        this.productTag = null 
                    }
                } else {
                    this.selectedProducts.push({
                        productType: this.productType,
                        productTag: this.productTag,
                    });
                    this.productType = null
                    this.productTag = null
                }
            }
        },
        deleteLicense: function (i) {
            this.selectedProducts.splice(i, 1);
        },
        clearLic: function() {
            this.productTag = null
        }
    },
    watch: {
        licenseList: function (val) {
            if (val && val.length) {
                val.forEach(element => {
                    this.selectedProducts.push({
                        productType: element.sn,
                        productTag: element.tag
                    });
                });
            }
        }
    }
};
</script>
