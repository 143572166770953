<template>
<div class="ams-dialog ams-country-pop">
    <div class="ams-dialog-body">
        <i class="ams-close ams-close-circle icon-close-circ" @click="closePop"></i>
        <div class="ams-dialog-cont">
            <div class="ams-country-code ams-form-group">
                <input type="text" class="ams-form-control ams-custom-control ams-pl45" placeholder="Search country code" v-model="searchKey" required="required" autocomplete="off" />
                <i class="icon-search ams-form-icon ams-form-icon-left"></i>
            </div>
            <ul class="ams-country-list" v-if="countryCode.length">
                <li v-for="(country, i) in countryFilter" :key="i" @click="saveCode(country)">
                    <img :src="flagUrl(country.flag)">
                    {{ country.label }}
                </li>
            </ul>
            <div class="ams-text-center" v-if="loader"><img src="https://img.litedev.com/ams-icons-images/images/loader.svg" alt="loader" /></div>
        </div>
    </div>
</div>
</template>

<script>
import API from '../../servcies/api.js'

export default {
    data() {
        return {
            countryCode: [],
            searchKey: "",
            // activeIndex: 0,
            loader: false
        };
    },
    mounted() {
        let self = this
        self.loader = true
        API.ccode(function (response) {
            if (response && response.status === 1000) {
                self.countryCode = response.data
                self.loader = false
            }
        })
    },
    methods: {
        flagUrl: function (flag) {
            return 'https://img.litedev.com/flags/' + flag.toLowerCase() + '_64.png'
        },
        closePop: function () {
            this.$emit('close-pop', false)
        },
        saveCode:function(countryFilter){
            console.log('countryFilter', countryFilter)
            this.$emit('save-code',countryFilter)
            // this.activeIndex = countryFilter.label
        },
    },
    computed: {
        countryFilter() {
            let _self = this;
            // const serchKey = _self.searchKey.toLowerCase();
            // const cc = serchKey.toString().replace("+", "");
            return _self.countryCode.filter(country => {
                // if((country.label.toLowerCase().indexOf(cc) === 0)){
                // return true;
                // }
                return country.label.toLowerCase().includes(this.searchKey.toLowerCase());
            })
        },
    },
};
</script>
