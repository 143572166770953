<template>
<ul v-if='paginationData' class="ams-pagination">
    <li class="ams-pagination-item">
        <a class="ams-pagination-link" @click="setPageData(paginationData.page - 1)" v-if="paginationData.page != 1" :class="paginationData.page == 1?'ams-disabled': ''"><i class="icon-left-arrow"></i></a>
    </li>
    <template v-if="paginationData.total > limit">
    <li class="ams-pagination-item" v-for="item in paginationData.total_pages" :key="item">
        <a class="ams-pagination-link" @click="setPageData(item)" :class="paginationData.page == item?'ams-active': ''">{{item}}</a>
    </li>
    </template>
    <li class="ams-pagination-item">
        <a class="ams-pagination-link" @click="setPageData(paginationData.page + 1)" :class="paginationData.page == paginationData.total_pages?'ams-disabled': ''"><i class="icon-right-arrow"></i></a>
    </li>
</ul>
</template>

<script>
export default {
    props: ['paginationData', 'limit'],
    data() {
        return {
        }
    },
    methods: {
        setPageData: function (item) {
            let self = this
            console.log(item, 'item')
            self.$emit('set-page-data', item)
        },
    },
    computed: {
    },
};
</script>
