<template>

<div class="ams-content">
    <add-assets v-if="$route.params.action==='add' ||  $route.params.action==='edit'"/>
    <div v-if="!$route.params.action" class="ams-row ams-mb20">
        <div class="ams-col6 ams-col-md8 ams-col-sm6 ams-col-xs6">
            <h3 class="ams-tit">Assets</h3>
        </div>
        <div class="ams-col6 ams-col-md4 ams-col-sm6 ams-col-xs6 ams-text-right">
            <button type="button" class="ams-btn ams-btn-primary" @click="$router.push('/assets/add')">Add asset</button>
        </div>
    </div>
    <assets-table-filter v-if="eventid['1121'] && !$route.params.action && assetList && assetList.length" ref="atf" />
    <assets-table v-if="eventid['1121'] && !$route.params.action" :assetList="assetList" :paginationData="paginationData"/>
    <div v-if="!$route.params.action" class="ams-table-filter ams-table-upload">
        <pagination :limit="limit" v-if="paginationData && paginationData.total > limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
        <!-- <div class="ams-customize-upload">
            <file-upload :show-upload="true" :disText="utext"></file-upload>
        </div>  -->
    </div>
</div>
</template>

<script>
import AssetsTableFilter from "../../components/assets/AssetsTableFilter.vue"
import AssetsTable from "../../components/assets/AssetsTable.vue"
import Pagination from "../../components/common/Pagination.vue"
import { pagination_limit } from "../../../config/endpoint";
import AddAssets from "../../components/assets/AddAssets.vue"
// import FileUpload from "../../components/common/FileUpload.vue"
export default {
    data() {
        return {
            // utext: 'Upload excel for adding assets',
            assetList: [],
            page: 1,
            paginationData: null,
            // assetListss:null
        };
    },
    // mounted: function () {
    //     this.pageData();
    // },
    methods: {
        pageData: async function (val) {
            console.log('calling', val)
            let _self = this
            let atf = this.$refs['atf']
            _self.$store.dispatch("common/SET_LOADER", true)
            // fetch list data
            let req = {
                cType: "1",
                limit: _self.limit,
                page: _self.page,
                // pType: atf && atf.pType ? atf.pType : '',
                // prod_tag: atf && atf.prod_tag ? atf.prod_tag : ''
            }
            if (atf && atf.prod_tag)  {
                req.prod_tag = atf.prod_tag
            }
            if (atf && atf.emp_id)  {
                req.emp_id = [atf.emp_id]
            }
            if (atf && atf.pType && atf.pType.length) {
                req.pType = atf.pType
            }
            console.log('ATF', atf)
            if (atf && atf.prod_status && atf.prod_status.length) {
                req.prod_status = atf.prod_status
            }
            if (atf && atf.emp_status && atf.emp_status.length) {
                req.emp_status = atf.emp_status
            }
            console.log('REQ', req)
            let response = await _self.$store.dispatch("product/FETCH_PRODUCT_LIST", req)
            _self.$store.dispatch("common/SET_LOADER", false)
            console.log("assetlist", response);
            if (response && response.status == 1000 ) {
                _self.assetList = response.data.products;
                _self.paginationData = response.data.metadata ? response.data.metadata[0] : null
                console.log('_self.assetList.length', val, _self.assetList)
                //  _self.assetListss = response.data;
                // if(_self.assetListss && _self.assetListss.length == 0){
                //      _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                //             msg: "No Data exits",
                //             class: "ams-error-danger",
                //         });
            //     // }
             } else {
                 if(val === 'filter' && response.data && !response.data.length) {
                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "No Data exits",
                        class: "ams-error-danger",
                    });
                 }
             }

            // else {
            //     _self.assetList = []
            //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
            //         msg: "No Data exits",
            //         class: "ams-error-danger",
            //     });
            // }
        },
        setPageData: function (page) {
            let self = this
            self.page = page
            self.pageData()
        }
    },
    watch: {
        "$route.params.action": {
            immediate: true,
            handler () {
                let self = this
                console.log('self.$route.params.action', this.$route)
                if (self.$route.params.action !== 'add') {
                    self.pageData();
                }
            }
        }
    },
    computed: {
        limit: function () {
            return pagination_limit
        },
        eventid() {
            return JSON.parse(localStorage.getItem("eventData"));
        },
    },
    components: {
        AssetsTableFilter,
        AssetsTable,
        Pagination,
        AddAssets,
        // FileUpload
    }
};
</script>
