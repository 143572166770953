<template>
<div class="ams-dialog">
    <div class="ams-dialog-body ams-w30">
        <i class="ams-close ams-close-circle icon-close-circ" @click="closePop"></i>
        <div class="ams-dialog-cont ams-text-center">
            <i class="ams-sprite ams-success-icon ams-mb20"></i>
            <h4>Payment Successfull</h4>
            <p class="ams-f-size13">Payment of Rs. 750 has been successfull.
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {
        closePop:function(){
          this.$emit('close-popup','')
      },
    }
};
</script>
