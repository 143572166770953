<template>
<!-- <button type="button"  class="ams-btn ams-btn-primary ams-btn-sm ams-btn-role" @click="addProducts">Add Product</button> -->
<div class="ams-card">
    <div class="ams-row ams-d-f-a-center">
        
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <label>Category</label>
            <Multiselect v-model="pid" placeholder="Select Category" @change="getproductTypes($event)" :options="options" class="ams-form-control ams-custom-control-dark"></Multiselect>
        </div>
        <!-- <div class="ams-col9 ams-col-md8 ams-col-sm8 ams-col-xs12 ams-table-filter">
            <button class="ams-btn ams-btn-primary ams-mr20" @click="$router.push('/settings/product/add')">Add Product</button>
        </div> -->
    </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
export default {
    components: {
        Multiselect,

    },
    data() {
        return {
            options: {
                1: 'Assets',
                2: 'Accessories',
               // 3: 'Licenses'
            },
            pid: ''
        };
    },
    methods: {
            getproductTypes(pid){
                console.log('pid', pid)
                this.$parent.getData(pid)
            }
    },

};
</script>
