<template>
<div class="ams-content">
     <add-license v-if="$route.params.action==='add' |  $route.params.action==='edit'"/>
    <div v-if="!$route.params.action" class="ams-row ams-mb20">
        <div class="ams-col6 ams-col-md6 ams-col-sm8 ams-col-xs6">
            <h3>Licenses</h3>
        </div>
        <div class="ams-col6 ams-col-md6 ams-col-sm4 ams-col-xs6 ams-text-right">
            <button type="button" class="ams-btn ams-btn-primary"  @click="$router.push('/licenses/add')">Add license</button>
        </div>
    </div>
    <license-table-filter v-if="!$route.params.action && licenseList && licenseList.length" ref="atf"/>
    <license-table v-if="!$route.params.action" v-on:add-license='addLiecense' :licenseList="licenseList" :paginationData="paginationData"/>
    <div v-if="!$route.params.action" class="ams-table-filter ams-mt20">
        <pagination :limit="limit" v-if="paginationData && paginationData.total > limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
import LicenseTableFilter from "../../components/license/LicenseTableFilter"
import LicenseTable from "../../components/license/LicenseTable"
import Pagination from "../../components/common/Pagination.vue"
import {pagination_limit} from "../../../config/endpoint"
import AddLicense from "../../components/license/AddLicense.vue"
export default {
     data() {
        return {
            licenseList: [],
            page: 1,
            soft_name:'',
            renewal_date:'',
            paginationData:null
        };
    },
    mounted: function () {
        console.log('mounted')
        this.pageData();
    },
     methods: {
        pageData: async function (val) {
            let _self = this
            let atf = this.$refs['atf']
          
            _self.$store.dispatch("common/SET_LOADER", true)
            // fetch list data
            let reqObj = {
                limit: this.limit,
                page: this.page,
            }
            if (atf && atf.soft_name) {
                reqObj.soft_name = atf.soft_name
            }
             if (atf && atf.renewal_date) {
                reqObj.renewal_date= atf.renewal_date
            }
            let response = await _self.$store.dispatch("product/FETCH_LICENSE_TABLE", reqObj)
            _self.$store.dispatch("common/SET_LOADER", false)
            if (response && response.status == 1000) {
                _self.licenseList = response.data.products
                 _self.paginationData = response.data.metadata[0]
            } else {
                if (val === 'filter' && response.data && !response.data.length) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'No Data Exist',
                        class: 'ams-error-danger'
                    })
                }
            }
        },
        setPageData: function (page) {
            this.page = page
            this.pageData()
        }
    },
     watch: {
        "$route.params.action": {
            immediate: true,
            handler () {
                let self = this
                console.log('ssssssssssss', self.$route)
                if (self.$route.params.action !== 'add') {
                    self.pageData();
                }
            }
        }
    },
    computed:{
        limit:function(){
            return pagination_limit
        }
    },
    components: {
        LicenseTableFilter,
        LicenseTable,
        Pagination,
        AddLicense
    },
};
</script>
