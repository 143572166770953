<template>
	<Form class="ams-auth-form" :validation-schema="passSchema" v-slot="{ handleSubmit }">
        <div class="ams-form-group">
                <Field name="newpass" type="password" v-model="password" class="ams-form-control ams-custom-control" placeholder="Enter Password" />
                <ErrorMessage name="newpass" class="ams-val-err" />
        </div>
        <div class="ams-form-group">
                <Field name="confirmpass" type="password" v-model="confirmPass" class="ams-form-control ams-custom-control" placeholder="Confirm Password" />
                <ErrorMessage name="confirmpass" class="ams-val-err" />
        </div>
        <button type="button" class="ams-btn ams-btn-primary-grad ams-btn-block" @click="handleSubmit(signinFlag)" :disabled="dflag">Sign Up</button>
	</Form>
</template>
<script>
import API from '../../servcies/api.js'
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from "yup";
export default {
	data() {
        const passSchema = Yup.object().shape({
            newpass: Yup.string().required("Please Enter Password"),
            confirmpass: Yup.string().required("Please Enter Confirm Password")
                          .oneOf([Yup.ref('newpass'), null], 'Passwords is not same'),
        });
		return {
            passSchema,
            password: '',
            confirmPass: '',
            dflag: false
		};
	},
    methods: {
        signinFlag (){
            let self = this
            self.dflag = true
            let userVal = JSON.parse(localStorage.getItem('userInfo'))
            let ureq = {
                comp_id: userVal.comp_id,
                password: self.password
            }
            API.confirmPass(ureq, function(response) {
                console.log('res', response)
                if (response && response.status === 1000) {
                    self.$store.dispatch("SET_AUTH", true)
                }
            });
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage
    }
};
</script>
