<template>
<div>
    <div class="ams-row ams-mb20">
        <div class="ams-col10 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <h3 class="ams-tit">Check In / Check Out Data</h3>
        </div>
        <div class="ams-col2 ams-col-md6 ams-col-sm6 ams-col-xs12 ams-text-right">
            <button type="button" class="ams-btn ams-btn-primary" @click="$router.push('/checkin-checkout-form')">Add Data</button>
        </div>
    </div>
    <div v-if="checkInOut && checkInOut.length" class="ams-row">
        <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
                <input @input="empFilter" v-model="emp_id" type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Employee Id" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
    </div>
    <table v-if="checkInOut && checkInOut.length" class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>SI.No</th>
                <!-- <th>Asset Name</th> -->
                <th>Asset Tag</th>
                <th>Employee ID</th>
                <th>Checkout Date & Time</th>
                <th>Checkin Date & Time</th>
                <th>Location</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in checkInOut" :key="i">
                <td data-title="S.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <!-- <td data-title="Asset Name"></td> -->
                <td data-title="Asset Tag">{{a.prod_tag}}</td>
                <td data-title="Employee ID">{{a.emp_id}}</td>
                <td data-title="Checkout Date & Time">{{getDate(a.cout)}}</td>
                <td data-title="Checkin Date & Time">
                <span class="ams-checkin ams-primary-text" v-if="!a.cin" @click="a.ds=!a.ds">Add Checkin Date & Time</span>
                <span v-if="a.cin">{{getDate(a.cin)}}</span>
                <div class="ams-col8 ams-mt10">
                    <div class="" v-show="a.ds">
                        <!-- <datepicker v-model="picked" class="ams-form-control ams-btn-sm">                          
                        </datepicker>
                        <i class="icon-datepicker ams-form-icon ams-form-icon-right"></i> -->
                        <Datepicker :maxDate="new Date()" @closed="setCheckin(a)" v-model="a.cin" :enableTimePicker="false" monthNameFormat="long" autoApply class="ams-date-icon"/>
                    </div>
                </div>
                </td>
                <td data-title="Location">                  
                 <!-- <i class="icon-edit2 ams-form-icon-right ams-gray-light-text ams-ml20 ams-f-size20 ams-vertical-md"></i> -->
                 {{a.loc}}
                </td>
            </tr>
        </tbody>
    </table>
    <div  class="ams-table-filter ams-mt20">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
import Pagination from "../common/Pagination.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
// import Datepicker from 'vue3-datepicker'
import moment from 'moment'
// import { ref } from 'vue';
import Datepicker from 'vue3-date-time-picker';
// import 'vue3-date-time-picker/dist/main.css'

export default {
    components: {
        Pagination,
        // Datepicker
        Datepicker


    },
    data() {
        // const date = ref(new Date());
        return {
            isShowing:false,
            checkInOut: [],
            page: 1,
            paginationData: null,
            datedata: '',
            emp_id:null

        };
    },
    methods: {
        setCheckin: function (a) {
            if (a.cin) {
                let _self = this
                let data = {
                    check_id: a.check_id,
                    cin: a.cin
                }
                _self.$store.dispatch('common/SET_LOADER', true)
                _self.$store.dispatch("checkincheckout/EDIT_DATA", data).then(function (res){
                _self.$store.dispatch('common/SET_LOADER', false)
                    if (res && res.status == '1000') {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                                msg: "Updated checkin date successfully",
                                class: "ams-error-success",
                        });
                    _self.checkInOutData()
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                                msg: "Technical error",
                                class: "ams-error-danger",
                        });
                    }
                })
            }
        },
        checkInOutData: function (val) {
            let _self = this
            let reqObj = {
                page: this.page,
                limit: this.limit
            }
            if(_self.emp_id){
                reqObj.emp_id = _self.emp_id
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            _self.$store.dispatch("checkincheckout/FETCH_CHECKIN_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        console.log(res,'inout');
                        _self.checkInOut = res.data.checkoutin
                        if (_self.checkInOut && _self.checkInOut.length) {
                            _self.checkInOut.forEach(c => {
                                c.ds = false
                            });
                        }
                        _self.paginationData = res.data.metadata[0]

                    } else {
                        if (val === 'filter' && res.data && !res.data.length) {
                         _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                         msg: 'No Data Exist',
                         class: 'ams-error-danger'
                    })
                }
                    }
                });
        },
         indexMethod(pageNumber, pageSize) {
         return pageNumber * pageSize - (pageSize - 1);
        },
         getDate: function (date) {
            console.log('date njjjj', date)
            return moment(date).format('DD-MM-YYYY  h:mm:ss ');
        },
         setPageData: function (page) {
            this.page = page
            this.checkInOutData()
        },
        empFilter() {
            if (!this.emp_id.length) {
                this.checkInOutData()
                return
            }
            if (this.emp_id.length >= 3) {
                this.checkInOutData('filter')
            }
        }
    },
    mounted(){
        this.checkInOutData()
    },
    computed:{
        limit: function () {
                return pagination_limit
            },
    }
};
</script>
