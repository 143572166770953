<template>
<div>
     <!-- <report-download /> -->
    <div class="ams-card">
        <div v-if="auditList && auditList.length" class="ams-row ams-d-f-sbetween">
            <div class="ams-col4 ams-col-md4 ams-col-sm4 ams-col-xs12">
                <div class="ams-two-calender ams-d-f-a-center">
                    <label class="ams-two-cal-label">Audit Status</label>
                    <Multiselect v-model="status" @change="getAuditFilter($event)" :options="options" class="ams-form-control ams-custom-control" placeholder="Audit Status"></Multiselect>
                </div>
            </div>
            <div class="ams-col8 ams-col-md9 ams-col-sm9 ams-col-xs12 ams-text-right">
                <div class="ams-form group">
                    <a  v-if="status && status.length" class="ams-btn ams-btn-outline-info" download :href="downloadAuditDetails"><i class="icon-download ams-primary-color ams-cursor ams-mr10"></i> Download</a>
                </div>
            </div>
        </div>
    </div>
    <table v-if="auditList && auditList.length" class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>SI.No</th>
                 <th>Audit Status</th>
                <th>Audit Date</th>
                <th>Audited By</th>
                <th>Product Type</th>
                 <th>Asset / Accessory Tag</th>
                <th>Next Audit Date</th>
                <th>Days to Next Audit</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in auditList" :key="i">
                <td data-title="SI.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                 <td data-title="Audit Status">{{a.audit_status}}</td>
                <td data-title="Audit Date">{{getDate(a.audit_date)}}</td>
                 <td data-title="Audited By">{{a.audit_by}}</td>
                <td data-title="Product Type">{{(a && a.proddata && a.proddata.length) ? productTypes[a.proddata[0].pType] : '-'}}</td>
                <td data-title="Asset / Accessory Tag">{{a.prod_tag}}</td>
                <td data-title="Next Audit Date">{{compInfo.from_date ? getDate(compInfo.from_date) : '-'}}</td>
                 <td data-title="Days to Next Audit">{{nextaudit}}</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
    <div class="ams-table-filter ams-mt20">
        <pagination />
        <!--<div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
                <Multiselect v-model="value" :options="options" class="ams-form-control ams-custom-control"></Multiselect>
            </div>
        </div>-->
    </div>
</div>
</template>

<script>
//import ReportDownload from "../common/ReportDownload.vue"
import Pagination from "../common/Pagination.vue"
import Multiselect from '@vueform/multiselect'
import moment from "moment";
import {
    pagination_limit
} from "../../../config/endpoint";
import { ams_hub } from "../../../config/endpoint";

export default {
    components: {
        //ReportDownload,
        Pagination,
        Multiselect
    },
    data() {
        return {
            isShow: false,
            page: 1,
            auditList: [],
            paginationData: null,
            status:"",
            options: [
                'Audited',
                'UnAudited',
                'All',
            ],
            compInfo: null
        };
    },
    mounted() {
        this.listAudit();

    },
    computed: {
        nextaudit () {
            let self = this
            return moment(self.compInfo.from_date).diff(moment(), 'days')
        },
        limit: function () {
            return pagination_limit
        },
          downloadAuditDetails: function () {
            let _self = this
            let reqObj = {
                status:_self.status
            }
            return ams_hub+'/report/auditreport?audit_status=' +reqObj.status + '&token='+encodeURIComponent(localStorage.getItem('token'))
        },
        productTypes: function () {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return { ... parsedPT["1"], ...parsedPT["2"] };
        }
    },
    methods: {
        getAuditFilter (val) {
            let self = this
            self.listAudit(val, 'filter')
        },
        listAudit: function (val1, val2) {
            let _self = this
            let reqObj = {
                limit: parseInt(this.limit),
                page: this.page
            }
            if (val1 && val1 != 'All') {
                reqObj.audit_status = val1
            }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("reports/FETCH_AUDIT_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    console.log('RES', res)
                    if (res && res.status == 1000) {
                        _self.auditList = res.data.audit
                        _self.paginationData = res.data.metadata[0]
                        _self.compInfo = res.data.compInfo[0]
                    } else {
                        if(val2 === 'filter' && res.data && !res.data.length) {
                            _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                                msg: "No Data exits",
                                class: "ams-error-danger",
                            });
                        }
                    }
                });

        },
         getDate : function(date){
            return moment(date).format('DD-MM-YYYY');
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        setPageData: function (page) {
            this.page = page
            this.listAudit()
        },
    }
};
</script>
