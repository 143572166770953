import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import '/public/css/select.css'
import '/public/css/custom.css'
import '/public/css/responsive.css'
import ApexCharts from 'apexcharts';
import VueApexCharts from "vue3-apexcharts";
import Datepicker from 'vue3-datepicker'
import Multiselect from '@vueform/multiselect'
const VueApp = createApp(App, {
    setup() {
    }
  })
const app = createApp(App);
app.use(VueApexCharts);
// The app.use(VueApexCharts) will make <apexchart> component available everywhere.
app.config.globalProperties.$apexcharts = ApexCharts;
VueApp.use(Datepicker)
VueApp.use(Multiselect)
VueApp.use(store)
.use(router)
.mount('#app')
