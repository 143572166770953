<template>
<div class="ams-content">
    <add-employee v-if="$route.params.action==='add' || $route.params.action === 'edit'"/>
    <employee-details v-if="$route.params.action === 'list'" />
    <div v-if="!$route.params.action" class="ams-row ams-mb20">
        <div class="ams-col10 ams-col-md8 ams-col-sm6 ams-col-xs6">
            <h3 class="ams-tit">Employee</h3>
        </div>
        <div class="ams-col2 ams-col-md4 ams-col-sm6 ams-col-xs6 ams-text-right">
            <button type="button" class="ams-btn ams-btn-primary" @click="addEmployee">Add Employee</button>
        </div>
    </div>
    <div  v-if="!$route.params.action && employeeList && employeeList.length" class="ams-row">
        <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <div class="ams-form-group">
                <input @input="empnameFilter" v-model="fn" type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Employee Name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
        <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <input @input="empFilter" v-model="emp_id" type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Employee Id" />
                        <i class="icon-search ams-form-icon ams-form-icon-right"></i>
                    </div>
        </div>
    </div>    
    <!-- <EmployeeTableFilter /> -->
    <EmployeeTable v-if="!$route.params.action && employeeList && employeeList.length" :employeeList="employeeList" :pageData="pageData" :paginationData="paginationData"/>
     <DeleteConformation :employeeList="employeeList" :pageData="pageData" :paginationData="paginationData" />
    <div v-if="!$route.params.action" class="ams-table-filter ams-mt20">
        <pagination :limit="limit" v-if="paginationData && paginationData.total > limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
        <!-- <file-upload :show-upload="true" /> -->
    
    <div class="ams-customize-upload">
            <file-upload :show-upload="true" :disText="utext" :uploadFile="'Multiupload'"></file-upload>
        </div> 
   <div class="ams-ml10">
                <div class="ams-form group">
                    <a class="ams-btn ams-btn-outline-info" download :href="downloadEmployee"><i class="icon-download ams-primary-color ams-cursor ams-mr10"></i> Download</a>
                </div>
            </div>
    </div>

</div>

</template>

<script>
import FileUpload from "../../components/common/FileUpload.vue";
import Pagination from "../../components/common/Pagination.vue";
// import EmployeeTableFilter from "../../components/employee/EmployeeTableFilter";
import EmployeeTable from "../../components/employee/EmployeeTable";
import EmployeeDetails from "../../components/employee/EmployeeDetails"
import { ams_hub } from "../../../config/endpoint";
import {
    pagination_limit
} from "../../../config/endpoint"
import AddEmployee from '../../components/employee/AddEmployee.vue';
//import DeleteConfirmation from '../../components/employee/DeleteConfirmation.vue';
export default {
    mounted: function () {
        this.pageData();
    },
    computed: {
        limit: function () {
            return pagination_limit
        },
        downloadEmployee: function () {
            return ams_hub+'/emp/download?token='+encodeURIComponent(localStorage.getItem('token'))
        },
    },
    components: {
        // EmployeeTableFilter,
        EmployeeTable,
        Pagination,
        AddEmployee,
        EmployeeDetails,
        //DeleteConfirmation,
        FileUpload,
        
    },
    data() {
        return {
            employeeList: null,
            page: 1,
            paginationData: null,
            emp_id:null,
            fn:null,
            utext: 'Upload Employees'
        };
    },
    methods: {
        // tableApi(){
        //     this.pageData()
        // },
        pageData: async function (val) {
            let _self = this
            let reqObj = {
                page: this.page,
                limit: this.limit
            }
            if(_self.emp_id){
                reqObj.emp_id = _self.emp_id
            }
            if(_self.fn){
                reqObj.fn = _self.fn
            }
            _self.$store.dispatch("common/SET_LOADER", true);
            // fetch list data
            _self.$store.dispatch("employee/FETCH_EMPLOYEE_TABLE", reqObj)
                .then(function (response) {
            _self.$store.dispatch("common/SET_LOADER", false);
            console.log('emp res', response)
            if (response && response.status == 1000) {
                _self.employeeList = response.data.employees;
                _self.paginationData = response.data.metadata[0]
            } else {
               if (val === 'filter' && response.data && !response.data.length) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'No Data Exist',
                        class: 'ams-error-danger'
                    })
                }
            }
                });
        },
        setPageData: function (page) {
            this.page = page
            this.pageData()
        },
        addEmployee() {
            localStorage.removeItem('employeeDetails')
            this.$router.push('/employee/add')

        },
         empFilter() {
            if (!this.emp_id.length) {
                this.pageData()
                return
            }
            if (this.emp_id.length >= 3) {
                this.pageData('filter')
            }
        },
         empnameFilter() {
            if (!this.fn.length) {
                this.pageData()
                return
            }
            if (this.fn.length >= 3) {
                this.pageData('filter')
            }
        }
    },
    watch: {
        "$route.params.action": {
            immediate: true,
            handler() {
                let self = this
                console.log('self.$route.params.action', this.$route)
                if (self.$route.params.action !== 'add') {
                    self.pageData();
                }
            }
        }
    },
};
</script>
