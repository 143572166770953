<template>
<Form v-if="!oflag" class="ams-auth-form" :validation-schema="changeNumber" v-slot="{ handleSubmit }">
    <div class="ams-dialog">
        <div class="ams-slide-in ams-dialog-body ams-w30">
            <i class="ams-close ams-close-circle icon-close-circ ams-f-size30 ams-mt10" @click="closechangenum"></i>
            <div class="ams-dialog-cont ">
                <h3 class="ams-tit ams-text-left">Change Contact Number</h3>
                <div class="ams-row ams-d-flex">
                    <!-- <div class="ams-col4 ams-btn-block ams-col-md6 ams-col-sm12 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Country Code</label>
                        <Multiselect v-model="value" :codes="codes" class="ams-form-control ams-custom-control-dark"></Multiselect>
                    </div>
                </div> -->
                    <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
                        <div class="ams-text-left">
                            <label>New contact number</label>
                            <Field type="text" v-model="upReq.ph_num" name="number" class="ams-form-control ams-custom-control-dark" placeholder="Contact Number" />
                            <ErrorMessage name="number" class="ams-val-err ams-ml10" />
                        </div>
                    </div>
                    <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
                    <button class="ams-btn ams-btn-primary ams-btn-block ams-mt30" @click.prevent="handleSubmit(updateNumber)">Send OTP</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Form>
<!-- <otp-verify v-if="otpverify" :profileData="upReq" /> -->
<OTP v-if="oflag" v-on:close-popup="oflag = $event" :profileData="profileData" :upReq="upReq" />
</template>

<script>
// import Multiselect from '@vueform/multiselect';
// import API from '../../servcies/api.js'
// import otpVerify from '../home/OTPVerify.vue';
import OTP from "../../components/profile/OTP.vue";
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    props: ['profileData'],
    components: {
        Form,
        Field,
        ErrorMessage,
        // otpVerify,
        // Multiselect,
        OTP
    },
    data() {
        const changeNumber = Yup.object().shape({
            // ccode: Yup.string().required("Please Select Country Code").nullable(),
            number: Yup.string().min(10).max(10).matches(/^[0-9]+$/, "only numbers are not allowed ").required("Contact Number is required"),

        });
        return {
            changeNumber,
            cresCode: [],
            oflag: false,
            // otpverify: false,
            upReq: {
                ccode: "",
                ph_num: "",
                email: ""
            }
        };

    },
    // mounted() {
    //     let self = this
    //     API.ccode(function (response) {
    //         if (response && response.status === 1000) {
    //             self.cresCode = response.data
    //         }
    //     })
    // },
    methods: {
        closechangenum: function () {
            this.$emit('close-popup', '')
        },
        updateNumber() {
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch("profile/CHANGE_NUMBER", this.upReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status === 1000) {
                    // _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    //     msg: (res && res.message) || 'OTP send Successsfully',
                    //     class: 'ams-error-success'
                    // })
                    // _self.$emit('close-popup', '')
                    // _self.otpverify = true
                    _self.oflag = true
                } else {
                    _self.$emit('close-popup', '')
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })

        }
    },
    watch: {
        profileData: {
            immediate: true,
            handler() {
                let self = this
                if (self.profileData) {
                    self.upReq.ccode = self.profileData.ccode
                    self.upReq.email = self.profileData.email
                }
            }
        }
    }
};
</script>
