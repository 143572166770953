<template>
<div class="ams-content">
    <div class="ams-row">
        <div class="ams-col9 ams-col-md8 ams-col-sm8 ams-col-xs12">
            <h3 class="ams-tit">Reports</h3>
        </div>
    </div>
   <!-- <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <input type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search software name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>-->
    <div class="ams-selector" @click="selectDropDown" :class="amsdropdownview? 'ams-active': ''">
        {{ tabSelection }} <i class="icon-downarrow ams-form-icon"></i>
    </div>
    <ul class="ams-tabs ams-cust-tabs" :class="amsdropdownview? 'ams-dropd-show': 'ams-dropd-hide'">
        <!-- <li @click="changeTab('Activity Report')" v-bind:class="[tabSelection === 'Activity Report' ? 'ams-active' : '',]">
            <a><span>Activity Report
                    <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                        <p class="ams-tooltip ams-tooltip-info ams-tooltip-top">Unlock this screen with our pro version</p>
                    </i>
                </span>
            </a>
        </li> -->
        <li @click="changeTab('Audit Report')" v-bind:class="[tabSelection === 'Audit Report' ? 'ams-active' : '',]">
            <a><span>Audit Report
                    <!-- <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                        <p class="ams-tooltip ams-tooltip-info ams-tooltip-top">Unlock this screen with our pro version</p>
                    </i> -->
                </span>
            </a>
        </li>
        <li @click="changeTab('Depreciation Report')" v-bind:class="[tabSelection === 'Depreciation Report' ? 'ams-active' : '']">
            <a><span>Depreciation Report
                    <!-- <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                        <p class="ams-tooltip ams-tooltip-info ams-tooltip-top">Unlock this screen with our pro version</p>
                    </i> -->
                </span>
            </a>
        </li>
        <li @click="changeTab('License Report')" v-bind:class="[tabSelection === 'License Report' ? 'ams-active' : '']"><a><span>License Report</span></a></li>
        <li @click="changeTab('Maintenance Report')" v-bind:class="[tabSelection === 'Maintenance Report' ? 'ams-active' : '']"><a><span>Maintenance Report</span></a></li>
        <li @click="changeTab('Ticket Report')" v-bind:class="[tabSelection === 'Ticket Report' ? 'ams-active' : '']">
            <a><span>Ticket Report
                    <!-- <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                        <p class="ams-tooltip ams-tooltip-info ams-tooltip-top-left">Unlock this screen with our pro version</p>
                    </i> -->
                </span>
            </a>
        </li>
    </ul>
    <div class="ams-tab-cont">
        <!-- <report-activity-table v-if="tabSelection === 'Activity Report'" /> -->
        <report-audit-table v-if="tabSelection === 'Audit Report'" />
        <report-depreciation-table v-if="tabSelection === 'Depreciation Report'" />
        <report-license-table v-if="tabSelection === 'License Report'" />
        <report-maintenance-table v-if="tabSelection === 'Maintenance Report'" />
        <report-ticket-table v-if="tabSelection === 'Ticket Report'" />
    </div>
</div>
</template>

<script>
// import ReportActivityTable from "../../components/reports/ReportActivityTable.vue";
import ReportAuditTable from "../../components/reports/ReportAuditTable.vue";
import ReportDepreciationTable from "../../components/reports/ReportDepreciationTable.vue";
import ReportLicenseTable from "../../components/reports/ReportLicenseTable.vue";
import ReportMaintenanceTable from "../../components/reports/ReportMaintenanceTable.vue";
import ReportTicketTable from "../../components/reports/ReportTicketTable.vue";

export default {
    components: {
        // ReportActivityTable,
        ReportAuditTable,
        ReportLicenseTable,
        ReportDepreciationTable,
        ReportMaintenanceTable,
        ReportTicketTable

    },
    data() {
        return {
            tabSelection: 'Audit Report',
            stid: 'ST0',
            t_apts: 0,
            t_inc: 0,
            t_comm: 0,
            visible: false,
            amsdropdownview: false        
        };
    },
    methods: {
        changeTab(val) {
            let _self = this
            if (val != _self.tabSelection) {
                _self.tabSelection = val
            }
            _self.activeclass = undefined;
        },
        selectDropDown() {
          this.amsdropdownview = !this.amsdropdownview
        },
        tabSelecstion(option) {
            this.value = option;
        },
        setAppData(t_apts, t_inc, t_comm) {
            this.t_apts = t_apts
            this.t_inc = t_inc
            this.t_comm = t_comm
        }
    }
};
</script>
