<template>
<div>
    <h3 class="ams-tit">
        {{ $parent["productTypes"] && $parent["productTypes"][$parent["prod_id"]] }}
    </h3>
    <Form :validation-schema="accessoriesSchema" v-slot="{ handleSubmit }">

        <div class="ams-card">
            <div class="ams-row">
                <div v-for="(a, i) in accessoriesFields" :key="i" class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                    <div v-if="a.type === 'text'" class="ams-form-group">
                        <label>{{ a.label }} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                        <Field :type="a.type" v-model="assetObj[a.key]" @keydown="checkInput($event, a.input)" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" id="software" />
                        <ErrorMessage :name="a.key" class="ams-val-err" />
                    </div>
                    <div v-else-if="a.type === 'calendar'" class="ams-form-group ams-datepicker-md">
                        <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                        <!-- <datepicker v-model="assetObj[a.key]" class="ams-form-control ams-custom-control-dark" ></datepicker> -->
                        <datepicker v-model="assetObj[a.key]" class="ams-form-control ams-custom-control-dark" :maxDate="a.key === '111' ? new Date() : ''" :minDate="(a.key === '145') ? assetObj['111'] : ''" :disabled="((a.key === '145') && !assetObj['111']) ? true : false" :enableTimePicker="false" monthNameFormat="long" autoApply :placeholder="a.label"/>
                        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                        <Field type="text" v-model=assetObj[a.key] v-show="false" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" />
                        <ErrorMessage :name="a.key" class="ams-val-err" />
                    </div>
                    <div v-else-if="a.type === 'dropdown'" class="ams-form-group">
                        <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                        <multiselect v-if="!a.multiselect" v-model=assetObj[a.key] :options="options[a.key] || a.select" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :searchable="true" track-by="label"></multiselect>
                        <multiselect v-else-if="a.multiselect" v-model=assetObj[a.key] mode="tags" :options="options[a.key] || a.select" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :searchable="true" track-by="label"></multiselect>
                        <Field type="text" v-model=assetObj[a.key] v-show="false" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" />
                        <ErrorMessage :name="a.key" class="ams-val-err" />
                    </div>
                    <div v-if="a.type === 'textarea'" class="ams-col12">
                        <div class="ams-form-group">
                            <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                            <Field type="text" v-model=assetObj[a.key] @keydown="checkInput($event, a.input)" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" :id="a.key" />
                            <ErrorMessage :name="a.key" class="ams-val-err" />
                        </div>
                    </div>
                </div>
            </div>
            <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/>
            <div v-else>
                <h3 class="ams-tit">Upload Invoice</h3>
                <div class="ams-row ams-mb20">
                    <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                        <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />
                    </div>
                </div>
            </div>
            <div class="ams-table-filter">
                <button v-if="viewProduct" class="ams-btn ams-btn-danger" @click="$router.push('/accessories')">
                    Cancel
                </button>
                <button v-if="viewProduct" class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(EditAccessories)">
                    Save
                </button>
            </div>
        </div>
        <accessories-assign-deassign :viewProduct="viewProduct" :empList="empList" ref="empRef" />
        <div class="ams-table-filter">
            <a v-if="$route.params.prodid" :href="generateTag" class="ams-btn ams-btn-outline-info">Generate accessories label</a>
            <!-- <button v-if="!flag && viewProduct" class="ams-btn ams-btn-primary ams-ml20">
                Edit 
            </button> -->
            <button v-if="!$route.params.prodid" class="ams-btn ams-btn-danger" @click="$router.push('/accessories')">
                Cancel
            </button>
            <button v-if="!$route.params.prodid" class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(saveAccessories)">
                Save

            </button>
        </div>

    </Form>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
// import Datepicker from 'vue3-datepicker'
import Datepicker from 'vue3-date-time-picker';
import moment from "moment";
import UploadInvoice from "../common/UploadInvoice.vue"
import AccessoriesAssignDeassign from "./AccessoriesAssignDeassign.vue";
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate'
import * as Yup from "yup";
import _ from 'lodash';
import FileUpload from "../common/FileUpload.vue";
import validationMixins from "../../mixins/validations";
import {  ams_hub } from '../../../config/endpoint'

export default {
    mixins: [validationMixins],
    props: ["accessoriesFields", "empList", "viewProduct", "prod_id"],
    components: {
        AccessoriesAssignDeassign,
        FileUpload,
        Multiselect,
        Datepicker,
        Form,
        Field,
        ErrorMessage,
        UploadInvoice
    },
    data() {
        const accessoriesSchema = Yup.object().shape({});
        return {
            accessoriesSchema,
            filterFlag: false,
            assetObj: {
                '115': '111'
            },
            employees: [],
            emp_id: null,
            accessoriesStatusList: ["New", "Assigned", "Un Assigned", "Scrapped"],
            auditDate: null,
            dop: null,
            accessoriesStatus: null,
            options: {
                '115': {
                    '111': 'Working', 
                    '112': 'Damaged'
                },
                '120': [],
                '153': ['WIRED', 'WIRELESS']
            },
            utext: 'Click to Add your file',
            fileData: {},
        };
    },
    methods: {
        changeAsssetStatus: function (status) {
            this.accessoriesStatus = status;
        },
        dopdownData() {
            let _self = this
            _self.$store.dispatch("vendor/LIST_VENDOR").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.options['120'] = res.data
                }
            });
        },
        manufactureDropDown() {
              let _self = this
            _self.$store.dispatch("manufacture/MANUFACTURE_LIST").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.options['114'] = res.data
                    // _self.options.MANUFACTURE_NAME = res.data
                }
            });

        },

        EditAccessories: function () {
            let _self = this;
            let productReq = {
                pType: _self.$route.params.ptype,
                prod_tag: _self.$route.params.prodid,
                cType: "2",
                info: _self.assetObj,
                cost: _self.assetObj['112'],
                dop: moment(_self.assetObj['111']).format('YYYY-MM-DD'),
                // audit_date: this.auditDate && moment(this.auditDate).format('YYYY-MM-DD'),
                prod_status: _self.assetObj['115'],
                ven_id: _self.assetObj['120'],
                filedata: (_self.fileData) ? _self.fileData : {}
            }
            console.log(productReq);
            _self.$store.dispatch("common/SET_LOADER", true);
            this.$store.dispatch("product/UPDATE_PRODUCT_PROPERTIES", productReq).then(function (res) {
                console.log(res);
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "Product Added Successfully",
                        class: "ams-error-success",
                    });
                    _self.$router.push("/accessories");
                } else {
                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "Technical error",
                        class: "ams-error-danger",
                    });
                }
            });
        },
        saveAccessories: function () {
            let _self = this;
            // let assetData = this.$refs["addAccesssories"];
            //let empInfo = localStorage.getItem("employeeDetails");
            console.log('assetObj', this.assetObj)
            let empRef = _self.$refs['empRef']
            let accessoriesReq = {
                pType: _self.prod_id,
                cType: "2",
                info: _self.assetObj,
                cost: _self.assetObj['112'],
                emp_ids: empRef.emp_id ? [empRef.emp_id] : [],
                dop: moment(_self.assetObj['111']).format("YYYY-MM-DD"),
                // audit_date: moment(this.auditDate).format("YYYY-MM-DD"),
                prod_status: _self.assetObj['115'],
                ven_id: _self.assetObj['120'],
                filedata: (_self.fileData) ? _self.fileData : {}
            };
            _self.$store.dispatch("common/SET_LOADER", true);
            this.$store.dispatch("product/SAVE_PRODUCT", accessoriesReq).then(function (res) {
                console.log("res", res);
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "Product Added Successfully",
                        class: "ams-error-success",
                    });
                    _self.$router.push("/accessories");
                } else {
                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "Technical error",
                        class: "ams-error-danger",
                    });
                }
            });
        },
    },
    watch: {
        accessoriesFields: {
            immediate: true,
            handler() {
                let self = this
                let assetObj = {};
                let allKey = _.keys(self.assetObj)
                _.each(self.accessoriesFields, (a) => {
                    console.log('e', a)
                    if (a.mandatory) {
                        assetObj[a.key] = Yup.string().required('This field is Required').nullable();
                    }
                    // For Assigning values on edit for date picker
                    if (a.type === 'calendar' && self.$route.params.prodid) {
                        let valueofarr = allKey.includes(a.key)
                        if (valueofarr) {
                            self.assetObj[a.key] = new Date(self.assetObj[a.key])
                        }
                    }
                    if (a.multiselect && !self.$route.params.prodid) {
                        self.assetObj[a.key] = []
                    }
                })
                self.accessoriesSchema = Yup.object(assetObj);
            }
        }
    },
    computed: {
        generateTag () {
            let _self = this
            let tag = _self.$route.params.prodid
            let token = localStorage.getItem("token") || _self.$store.getters["user/getUserToken"] || ""
            return ams_hub + '/product/generatetag?prod_tag=' + tag + '&token=' + encodeURIComponent(token)
        }
    },
    mounted() {
        let _self=this
            _self.manufactureDropDown()
            _self.dopdownData()
    }
};
</script>
