<template>
<div>
    <div class="ams-row ams-d-f-a-center">
        <div class="ams-col8 ams-col-md8 ams-col-sm8 ams-col-xs12">
            <h3 class="ams-tit">
                <i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/accessories')"></i>Accessory Info <span v-if="$route.params.prodid"> - {{$route.params.prodid}} </span>
                <!-- {{getProduct()}}-->
            </h3>
        </div>
        <div class="ams-col4 ams-col-md4 ams-col-sm4 ams-col-xs12 ams-text-right">
            <button v-if="$route.params.prodid" @click="openaudit()" class="ams-btn-audit ams-btn ams-adt"><i class="icon-plus-sym"></i><span>Add Audit details</span></button>
            <!-- <button v-if="$route.query.view == 'true'" class="ams-btn ams-btn-primary">Edit</button> -->
        </div>
        <!-- <div class="ams-col4 ams-col-md2 ams-col-sm4 ams-col-xs12 ams-text-right">
            <button v-if="$route.query.view == 'true'" @click="auditDetFlag = true" class="ams-btn-audit ams-btn "><i class="icon-plus-sym"></i><span>Add Audit details</span></button>
            <button v-if="$route.query.view == 'true'" class="ams-btn ams-btn-primary">Edit</button>
        </div> -->
    </div>
    <form v-if="!$route.params.prodid" class="ams-card">
        <div class="ams-row">
            <!-- <div class="ams-col3">
                    <div class="ams-form-group">
                        <label>Accessories tag</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                    </div>
                </div> -->
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <label>Accessory Type</label>
                <Multiselect @change="getAccessoriesFields" @clear="clearPtype" v-model="prod_id" :options="productTypes" placeholder="Accessory Type" class="ams-form-control ams-custom-control-dark"></Multiselect>
            </div>
            <div v-if="prod_id" class="ams-form group">
                <a class="ams-btn ams-btn-outline-info" download :href="downloadProduct"><i class="icon-download ams-primary-color ams-cursor ams-mr10"></i> Download</a>
            </div>
             <div v-if="prod_id" class="ams-customize-upload">
                <file-upload :show-upload="true" :disText="utext" :uploadFile="'Multiuploadproduct'" :cType="'2'" :pType="prod_id"></file-upload>
            </div>
        </div>
    </form>
    <accessories-audit-details :viewProduct="viewProduct" :auditData="auditData" v-if="auditDetFlag" ref="auditRef" v-on:close-pop="closePop" />
    <accessories-item :empList="empList" :prod_id="prod_id" :viewProduct="viewProduct" :accessoriesFields="accessoriesFields" v-show="accessoriesFields" ref="addAccesssories" />
    <!-- <accessories-keyboard />
        <accessories-panels />
        <accessories-keystones />
        <accessories-cables />
        <accessories-cctv />
        <accessories-storage />
        <accessories-crimpers />
        <accessories-cable-testers /> -->
    <!-- <accessories-popup />     -->

    <!-- <div class="ams-table-filter">
        <button v-if="!flag && viewProduct" class="ams-btn ams-btn-outline-info">
            Generate asset label
        </button>
        <button v-if="!flag && viewProduct" class="ams-btn ams-btn-primary ams-ml20">
            Edit
        </button>
        <button v-if="flag && !viewProduct &&flag1" class="ams-btn ams-btn-danger" @click="$router.push('/licenses')">
            Cancel
        </button>
        <button v-if="flag && !viewProduct &&flag1" class="ams-btn ams-btn-primary ams-ml20" @click="saveAccessories">
            Save
        </button>
    </div> -->
</div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import AccessoriesItem from "./AccessoriesItem.vue";
import AccessoriesAuditDetails from "./AccessoriesAuditDetails.vue";
// import moment from "moment";
import async from "async";
// import AccessoriesKeyboard from "../../components/accessories/AccessoriesKeyboard"
// import AccessoriesPanels from "../../components/accessories/AccessoriesPanels"
// import AccessoriesKeystones from "../../components/accessories/AccessoriesKeystones"
// import AccessoriesCables from '../../components/accessories/AccessoriesCables.vue'
// import AccessoriesCctv from '../../components/accessories/AccessoriesCctv.vue'
// import AccessoriesStorage from '../../components/accessories/AccessoriesStorage.vue'
// import AccessoriesCrimpers from '../../components/accessories/AccessoriesCrimpers.vue'
// import AccessoriesCableTesters from '../../components/accessories/AccessoriesCableTesters.vue'
// import AccessoriesPopup from '../../components/accessories/AccessoriesPopup.vue'
//import Multiselect from '@vueform/multiselect'
import { ams_hub } from "../../../config/endpoint";
import FileUpload from "../../components/common/FileUpload.vue";

export default {
    components: {
        Multiselect,
        AccessoriesItem,
        AccessoriesAuditDetails,
        FileUpload
        // AccessoriesKeyboard,
        // AccessoriesPanels,
        // AccessoriesKeystones,
        // AccessoriesCables,
        // AccessoriesCctv,
        // AccessoriesStorage,
        // AccessoriesCrimpers,
        // AccessoriesCableTesters,
        // AccessoriesPopup
        //Multiselect
    },
    data() {
        return {
            utext: "Upload excel for adding accessories",
            auditDetFlag: false,
            prod_id: "",
            accessoriesFields: null,
            assetObj: {},
            empList: null,
            emp_id: null,
            auditDate: null,
            dop: null,
            accessoriesStatusList: ["Working", "Damaged"],
            accessoriesStatus: null,
            employess: [],
            flag: null,
            flag1: null,
            viewProduct: false,
            auditData: null
        };
    },
    mounted() {
        let _self = this;
        // if (this.$route.path == "/add-accessories") {
        //     this.flag = true;
        // }
        console.log(this.productTypes, "productTypes");
        this.getEmployees();
        if (this.$route.params.prodid) {
            this.viewProduct = this.$route.params.prodid;
            _self.$store.dispatch("common/SET_LOADER", true);
            async.series(
                [_self.getProductDetails.bind(),
                 _self.getAccessoriesFields.bind()],
                function () {
                    _self.$store.dispatch("common/SET_LOADER", false);
                }
            );
        }
    },
    computed: {
        productTypes: function () {
            let pt = localStorage.getItem("pt") && JSON.parse(localStorage.getItem("pt"));
            return pt && pt[2];
        },
        downloadProduct: function () {
            return ams_hub+'/product/download?pType=' + this.prod_id + '&cType=2&token='+encodeURIComponent(localStorage.getItem('token'))
        },
    },
    methods: {
        openaudit () {
            let self = this
            self.auditDetFlag = true
            let vm = document.body
			vm.classList.add('hide-scrollbar');
        },
        clearPtype () {
            this.accessoriesFields = null
        },
        closePop: function () {
            this.auditDetFlag = false
            let vm = document.body
			vm.classList.remove('hide-scrollbar');
        },
        getProductDetails: function (cb) {
            let _self = this;
            let query = this.$route.params;
            let assetRef = _self.$refs['addAccesssories']
            if (query.prodid && query.ptype) {
                _self.$store
                    .dispatch("product/FETCH_PRODUCT_DETAILS", {
                        prod_tag: query.prodid,
                        pType: query.ptype,
                    })
                    .then(function (res) {
                        console.log('ACc res', res)
                        if (res && res.status == 1000) {
                            assetRef.assetObj = (res.data && res.data.product && res.data.product.info) || {}
                            assetRef.fileData = (res.data && res.data.product && res.data.product.filedata) || {}
                            if (res.data && res.data.auditInfo) {
                                let adata = res.data.auditInfo
                                adata.audit_date = new Date(adata.audit_date)
                                _self.auditData = adata
                                // _self.$refs['auditRef'].auditObj = adata
                            }
                            if (res.data && res.data.empInfo && res.data.empInfo.length) {
                                assetRef.$refs['empRef'].emp_id = res.data.empInfo
                                assetRef.$refs['empRef'].empName = res.data.empInfo
                            }
                            return cb();

                            // else {
                            //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            //         msg: "Technical Error",
                            //         class: "ams-error-danger",
                            //     });
                            // return cb();
                        }
                    });
            }
        },
        getAccessoriesFields: function (prod_id) {
            this.flag1 = true;
            let _self = this;
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch("product/FETCH_PRODUCT_FIELDS", {
                    prod_id: typeof prod_id == "function" ? _self.$route.params.ptype : prod_id,
                })
                .then(function (res) {
                    console.log("res", res);
                    _self.$store.dispatch('common/SET_LOADER', false)
                    if (res && res.status == 1000) {
                        _self.flag = true;
                        _self.accessoriesFields = res.data.fields;
                        console.log("entered 178", _self.accessoriesFields);
                        if (typeof prod_id == "function") return prod_id();
                    } else {
                        // _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        //     msg: "Technical Error",
                        //     class: "ams-error-danger",
                        // });
                        if (typeof prod_id == "function") return prod_id();
                    }
                });
        },
        getProduct: function () {
            return (
                this.productTypes &&
                this.productTypes[this.prod_id] &&
                ("- " + this.productTypes[this.prod_id] || "")
            );
        },
        getEmployees: async function () {
            let _self = this;
            let empResponse = await _self.$store.dispatch("employee/FETCH_EMPLOYEE_SELECT");
            if (empResponse && empResponse.status == 1000) {
                _self.empList = empResponse.data;
            }
        },
        changeAsssetStatus: function (status) {
            this.accessoriestStatus = status;
        },
        setEmpId: function (id) {
            this.emp_id = id;
        },
        // saveAccessories: function () {
        //     let _self = this;
        //     let assetData = this.$refs["addAccesssories"];
        //     //let empInfo = localStorage.getItem("employeeDetails");
        //     let accessoriesReq = {
        //         pType: this.prod_id,
        //         cType: "2",
        //         info: assetData.assetObj,
        //         emp_ids: assetData.emp_id && [assetData.emp_id],
        //         dop: "2021-09-16" && moment("2021-09-16").format("YYYY-MM-DD"),
        //         audit_date: "2021-09-16" && moment(this.auditDate).format("YYYY-MM-DD"),
        //         prod_status: this.accessoriesStatus,
        //     };
        //     _self.$store.dispatch("common/SET_LOADER", true);
        //     this.$store.dispatch("product/SAVE_PRODUCT", accessoriesReq).then(function (res) {
        //         console.log("res", res);
        //         _self.$store.dispatch("common/SET_LOADER", false);
        //         if (res && res.status == 1000) {
        //             _self.$store.dispatch("common/SET_ALERTMESSAGE", {
        //                 msg: "Product added successfully",
        //                 class: "ams-error-success",
        //             });
        //             _self.$router.push("/accessories");
        //         } else {
        //             _self.$store.dispatch("common/SET_ALERTMESSAGE", {
        //                 msg: "Technical error",
        //                 class: "ams-error-danger",
        //             });
        //         }
        //     });
        // },
    }
};
</script>
