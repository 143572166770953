<template>
<div class="ams-content">
    <!-- <role-management v-if="$route.params.tabname === 'role' && ($route.params.action === 'add' || $route.params.action === 'edit')" />
    <create-sub-user v-if="$route.params.tabname === 'user' && ($route.params.action === 'add' || $route.params.action === 'edit')" /> -->
    <add-product v-if="$route.params.tabname === 'product' && ($route.params.action === 'add'  || $route.params.action === 'edit')" />
    <!-- <edit-product v-if="$route.params.tabname === 'product' && ($route.params.action === 'edit')" /> -->
    <add-new-field v-if="oflag" @close-popup="closepopup" @table-api="tableApi()" />
    <!-- <add-fields  v-if= "fieldList"/> -->
    <div v-if="!$route.params.action" class="ams-row ams-d-f-sbetween">
        <div class="ams-col8 ams-col-md10 ams-col-sm9 ams-col-xs6">
            <h3 class="ams-tit">Settings</h3>
        </div>
        <div v-if="tabSelection==='product'" class="">
            <button class="ams-btn ams-btn-primary" @click="$router.push('/settings/product/add')">Add Product</button>
        </div>
        <div v-if="tabSelection==='field'" class="ams-form-group">
            <button class="ams-btn ams-btn-primary" @click="openpopup()">Add New </button>
        </div>
        <!-- <div v-if="tabSelection==='user'" class="ams-col4 ams-col-md2 ams-col-sm4 ams-col-xs12 ams-table-filter">
            <button class="ams-btn ams-btn-primary" @click="$router.push('/settings/user/add')">Create User</button>
        </div> -->
    </div>
    <!-- <div class="ams-row ams-d-f-sbetween" v-if="tabSelection==='role'">
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div v-if="$route.params.action!= 'add'  && $route.params.action!= 'edit'" class="ams-form-group">
                <input type="text" @input="roleFilter" v-model="role_name" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Role Name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
        <div class="ams-col2 ams-col-md4 ams-col-sm4 ams-col-xs12 ams-text-right">
            <button v-if="$route.params.action!='add' && $route.params.action!= 'edit'" class="ams-btn ams-btn-primary ams-mb20 ams-btn-sm" @click="$router.push('/settings/role/add')">Add Role</button>
        </div>
    </div> -->
    <!-- <div class="ams-row" v-if="tabSelection==='user'">
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div  v-if="$route.params.action!= 'add' && $route.params.action!= 'edit'" class="ams-form-group">
                <input type="text" @input="userFilter" v-model="user_name" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder=" Search User Name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
    </div> -->
    <div class="ams-row" v-if="tabSelection==='field'">
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
            <div  class="ams-form-group">
                <input type="text" @input="fieldFilter" v-model="field_name" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Field Name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
    </div>
    <div v-if="!$route.params.action" class="ams-row">
        <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-selector ams-mt20" @click="selectDropDown" :class="amsdropdownview? 'ams-active': ''">
                {{ tabSelection }} <i class="icon-downarrow ams-form-icon"></i>
            </div>
            <ul class="ams-tabs ams-cust-tabs" :class="amsdropdownview? 'ams-dropd-show': 'ams-dropd-hide'">
                <!-- <li @click="changeTab('custom')" v-bind:class="[tabSelection === 'custom' ? 'ams-active' : '']"><a><span>Customize page</span></a></li> -->
                <li @click="changeTab('product')" v-bind:class="[tabSelection === 'product' ? 'ams-active' : '']"><a><span>Product Settings</span></a></li>
                <li @click="changeTab('audit')" v-bind:class="[tabSelection === 'audit' ? 'ams-active' : '']"><a><span>Audit period</span></a></li>
                <!-- <li @click="changeTab('user')" v-bind:class="[tabSelection === 'user' ? 'ams-active' : '']"><a><span>Users</span></a></li>
                <li @click="changeTab('role')" v-bind:class="[tabSelection === 'role' ? 'ams-active' : '']">
                    <a>
                        <span>Role Management
                            <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                                <p class="ams-tooltip ams-tooltip-info ams-tooltip-top-left">Unlock this screen with our pro version</p>
                            </i>
                        </span>
                    </a>
                </li> -->
                <li @click="changeTab('field')" v-bind:class="[tabSelection === 'field' ? 'ams-active' : '']"><a><span>Field Settings</span></a></li>
            </ul>
        </div>

        <div>
            <!-- <add-new-field  @click="closefields" /> -->
        </div>
        <!-- <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <input type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search subuser Id" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div> -->
    </div>
    <div v-if="!$route.params.action" class="ams-tab-cont">
        <!-- <customize v-if="tabSelection === 'custom'" /> -->
        <audit-period v-if="tabSelection === 'audit'" />
        <!-- <sub-user v-if="tabSelection === 'user'" ref="su" :user_name="user_name" /> -->
        <!-- <role-assignments v-if="tabSelection === 'role'" ref="ra" :role_name="role_name" /> -->
        <product-settings v-if="tabSelection==='product'" />
        <field-settings v-if="tabSelection==='field'" ref="fs" :field_name="field_name" />
    </div>
    <!-- <role-assignment-category @roleView="roleView" /> -->
</div>
</template>

<script>
// import Customize from "../../components/settings/Customize"
import AuditPeriod from "../../components/settings/AuditPeriod.vue"
// import SubUser from "../../components/settings/SubUser.vue"
// import RoleAssignments from "../../components/settings/RoleAssignments.vue"
// import RoleManagement from "../../components/settings/RoleManagement.vue"
import ProductSettings from "../../components/settings/ProductSettings.vue"
// import CreateSubUser from "../../components/settings/CreateSubUser.vue"
import AddProduct from "../../components/settings/AddProduct.vue"
//import EditProduct from "../../components/settings/EditProduct.vue"
import FieldSettings from "../../components/settings/FieldSettings.vue"
import AddNewField from "../../components/settings/AddNewField.vue"
//  import AddFields from"../../components/settings/AddFields.vue"

export default {
    components: {
        // Customize,
        AuditPeriod,
        // SubUser,
        // RoleAssignments,
        // RoleManagement,
        ProductSettings,
        // CreateSubUser,
        AddProduct,
       // EditProduct,
        FieldSettings,
        AddNewField,

        //  AddFields

    },
    data() {
        return {
            tabSelection: '',
            stid: 'ST0',
            t_apts: 0,
            t_inc: 0,
            t_comm: 0,
            visible: false,
            amsdropdownview: false,
            oflag: false,
            role_name: null,
            user_name: null,
            field_name: null
        };
    },
    mounted: function () {
        let self = this
        self.tabSelection = 'product'
        self.$router.push('/settings/' + self.tabSelection)
    },
    methods: {
        closepopup (event) {
            console.log('CLOSE', event)
            let self = this
            self.oflag = event
            let vm = document.body
			vm.classList.remove('hide-scrollbar');
        },
        openpopup () {
            let vm = document.body
			vm.classList.add('hide-scrollbar');
            let self = this
            self.oflag=true
        },
        tableApi() {
            let self = this
            self.$refs['fs'].fieldListing()
        },
        // closefields: function () {
        //     console.log('calling')
        //     this.$emit('close-popup', false)
        // },
        changeTab(val) {
            console.log(val, 'val')
            let _self = this
            _self.tabSelection = val
            _self.$router.push('/settings/' + val)
        },
        selectDropDown() {
            this.amsdropdownview = !this.amsdropdownview
        },
        tabSelecstion(option) {
            this.value = option;
        },
        setAppData(t_apts, t_inc, t_comm) {
            this.t_apts = t_apts
            this.t_inc = t_inc
            this.t_comm = t_comm
        },
        roleFilter() {
            let roleRef = this.$refs['ra']
            if (!this.role_name.length) {
                roleRef.roleData()
                return
            }
            if (this.role_name.length >= 3) {
                roleRef.roleData()
            }
        },
        userFilter() {
            let userRef = this.$refs['su']
            if (!this.user_name.length) {
                userRef.subuserData()
                return
            }
            if (this.user_name.length >= 3) {
                userRef.subuserData()
            }
        },
        fieldFilter() {
            let fieldRef = this.$refs['fs']
            if (!this.field_name.length) {
                fieldRef.fieldListing()
                return
            }
            if (this.field_name.length >= 3) {
                fieldRef.fieldListing('filter')
            }
        }
    }
};
</script>
