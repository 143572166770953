import http from '../util/axios'
import config from '../../config/endpoint.js'
// import moment from 'moment'
// //  Default BASE API URL
const API_URL = config.ams_hub;
// const STATIC_URL = config.oh_url;
// const PH_URL = config.ph_url;
// const COM_URL= config.com_url
/**
 * @description Api Service Module
 * @author Fahid Mohamamd
 * @date 18-08-2021
 */
const API = {
    signup: function (data, callback) {
        http.post(API_URL + '/reg/signup', data).then(response => {
           return callback(response)
        })
    },
    verifyOtp: function (data, callback) {
        http.post(API_URL + '/reg/verify', data).then(response => {
           return callback(response)
        })
    },
    confirmPass: function (data, callback) {
        http.post(API_URL + '/reg/updatePass', data).then(response => {
           return callback(response)
        })
    },

    signin: function (data, callback) {
        http.post(API_URL + '/reg/signin', data).then(response => {
           return callback(response)
        })
    },
    ccode: function (callback) {
        http.get(API_URL + '/master/list').then(response => {
           return callback(response)
        })
     },
     country: function (callback) {
        http.get(API_URL + '/master/country').then(response => {
           return callback(response)
        })
     },

    forget: function (data, callback) {
        http.post(API_URL + '/reg/forget', data).then(response => {
           return callback(response)
        })
    },
    removeComp: function (data, callback) {
      http.post(API_URL + '/reg/remove', data).then(response => {
         return callback(response)
      })
  },
}

export default API