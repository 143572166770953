<template>
<div class="ams-row">
    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12 ams-text-right">
        <div class="ams-form-group">
            <input @input="softwareFilter" v-model="soft_name" type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Software Name" />
            <i class="icon-search ams-form-icon ams-form-icon-right"></i>
        </div>
    </div>
    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12 ams-text-right">
        <div class="ams-form-group ams-datepicker-md">
            <!-- <datepicker v-model="dop" class="ams-form-control ams-custom-control ams-form-control-sm"></datepicker> -->
            <datepicker v-model="renewal_date"  @cleared="clearDate" class="ams-form-control ams-custom-control ams-form-control-sm" :enableTimePicker="false" monthNameFormat="long" autoApply placeholder="Search Renewal Date"/>
            <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
        </div>
        <!-- <button type="button" class="ams-btn ams-btn-outline-info ams-btn-sm" :class="{ active: !filterFlag }" @click="filterFlag = !filterFlag">
            <template v-if="filterFlag"><i class="icon-close"></i></template>
            <template v-else><span class="ams-italic ams-f-size16 ams-mr10">Filter</span>
                <i class="icon-filter ams-f-size25 ams-vertical-md"></i></template>
        </button> -->
    </div>
</div>
<!-- <div class="ams-dop" v-if="filterFlag">
    <div class="ams-form-group">
        <input type="radio" id="dop" name="dop" checked />
        <label for="dop" class="ams-mr20">DOP</label>
    </div>
    <div class="ams-form-group">
        <datepicker v-model="dop" class="ams-form-control ams-custom-control ams-form-control-sm">
        </datepicker>
        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
    </div>
</div> -->
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            soft_name: '',
            // filterView: false,
            filterFlag: false,
            renewal_date: ''
        };
    },
    methods: {
         clearDate () {
           this.$parent.pageData()
        },
        softwareFilter() {
            let self = this
            if(!self.soft_name.length){
               self.$parent.pageData()
               return
            }
            if (self.soft_name.length >=3) {
                self.$parent.pageData('filter')
            }
        },
         dopFilter() {
            let self = this
            if(!self.renewal_date.length){
               self.$parent.pageData()
               return
            }
            if (self.renewal_date.length ) {
                self.$parent.pageData('filter')
            }
        }
        // filterFlag:function(){
        //     this.filterView = true
        // },
    },
     watch: {
        renewal_date: {
            immediate: false,
            handler () {
                let self = this
                  console.log('pushpaaaaaaaaa', this.renewal_date)
                if(self.renewal_date) {
                     self.$parent.pageData('filter')
                    self.dopFilter()
                }
            }
        }
    }
};
</script>
