<template>
<div>
    <Form :validation-schema="scrapschema" v-slot="{ handleSubmit }">
        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/inventory/scrap')"></i>Inventory - Scraped Item </h3>
        <div class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset / Accessory Name
                            <span class="ams-red-text"> * </span>
                        </label>
                        <multiselect @change="fetchAssetTags" @clear="clearTag" v-model="scraped.productType" :options="productTypes" name="assetname" class="ams-form-control ams-custom-control-dark" :searchable="true" track-by="label"></multiselect>
                        <Field type="text" v-model="scraped.productType" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="assetname" />
                        <ErrorMessage name="assetname" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset / Accessory Tag
                            <span class="ams-red-text"> * </span>
                        </label>
                        <multiselect v-model="scraped.productTag" :options="scraped.productTags" name="assettag" class="ams-form-control ams-custom-control-dark"></multiselect>
                        <Field type="text" v-model="scraped.productTag" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="assettag" />
                        <ErrorMessage name="assettag" class="ams-val-err" />
                    </div>
                </div>
                <!-- <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Item Status</label>
                        <multiselect v-model="scraped.productTag" :options="scraped.productTags" name="assettag" class="ams-form-control ams-custom-control-dark"></multiselect>
                        <Field type="text" v-model="scraped.productTag" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="assettag" />
                        <ErrorMessage name="assettag" class="ams-val-err" />
                    </div>
                </div> -->
                <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-datepicker-md">
                        <label>DOS (Date of Scraping)
                            <span class="ams-red-text"> * </span>
                        </label>
                        <!-- <datepicker v-model="scraped.picked" name="dos" class="ams-form-control ams-custom-control-dark">
                        </datepicker> -->
                        <datepicker v-model="scraped.picked" name="dos" class="ams-form-control ams-custom-control-dark" :maxDate="new Date()" :enableTimePicker="false" monthNameFormat="long" autoApply/>
                        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                        <Field type="text" v-model="scraped.picked" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="dos" />
                        <ErrorMessage name="dos" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <div class="ams-col12 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Cause of scrapping
                        <span class="ams-red-text"> * </span>
                    </label>
                    <Field type="textarea" v-model="scraped.cause" class="ams-form-control ams-mt10" placeholder="" name="cause" id="cause" />
                    <ErrorMessage name="cause" class="ams-val-err" />
                </div>
            </div>
            <div class="ams-text-right ams-table-filter">
                <button class="ams-btn ams-btn-danger ams-mr20" @click="$router.push('/inventory/scrap')">Cancel</button>
                <button class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(saveScraped)">Save</button>
            </div>
        </div>
    </Form>
</div>
</template>
<script>
// import Datepicker from 'vue3-datepicker'
import Datepicker from 'vue3-date-time-picker';
import Multiselect from "@vueform/multiselect";
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    components: {
        Datepicker,
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        const scrapschema = Yup.object().shape({
            cause: Yup.string().required("Please Enter Cause"),
            assetname: Yup.string().required("Please Select Asset/Accessory Name").nullable(),
            assettag: Yup.string().required("Please Select Asset/Accessory Tag").nullable(),
            dos: Yup.string().required("Please Select Date of Purchase").nullable(),

        });
        return {
            filterFlag: false,
            scrapschema,
            scraped: {
                productTags: null,
                productTag: null,
                productType: null,
                cause: "",
                picked: null,

            }
        };
    },
    computed: {
        productTypes: function () {
            
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            console.log(parsedPT[1], "parseddd");
            console.log(parsedPT[2], "parseddd2222");
            return {
                ...parsedPT[1],
                ...parsedPT[2],
            }
        },

    },
    methods: {
        fetchAssetTags: async function (pid) {
            let _self = this;
            // if(_self.scraped.productType) {
            //     _self.scraped.productType = null;
            //     _self.scraped.productTag = null;
            //     _self.scraped.productTags = null;
            //     return
            // }
            let response = await this.$store.dispatch(
                "product/FETCH_ALL_PTAG",
                pid
            );
            if (response && response.status == 1000) {
                _self.scraped.productType = pid;
                _self.scraped.productTags = response.data;
            }
            
        },
        clearTag: function(){
            this.scraped.productTag = null
        },
        saveScraped: function () {

            let _self = this;
            let scrapReq = {
                prod_tag: _self.scraped.productTag,
                dos: _self.scraped.picked,
                comment: _self.scraped.cause
            };
            _self.$store.dispatch("common/SET_LOADER", true);

            this.$store
                .dispatch("inventory/SAVE_SCRAPED", scrapReq)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Scraped Item Added successfully",
                            class: "ams-error-success",
                        });
                        _self.$router.push('/inventory/scrap')
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });
        }
    }
};
</script>
