<template v-slot:default="slotProps">
<div>
    <div class="ams-row ams-d-f-a-center">
        <div class="ams-col8 ams-col-md8 ams-col-sm8 ams-col-xs12">
            <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/assets')"></i> Assets Info <span v-if="$route.params.prodid"> - {{$route.params.prodid}} </span>
                <!--{{getProduct()}}-->
            </h3>
        </div>
        <div class="ams-col4 ams-col-md4 ams-col-sm4 ams-col-xs12 ams-text-right">
            <button v-if="$route.params.prodid" @click="openaudit()" class="ams-btn-audit ams-btn ams-adt"><i class="icon-plus-sym"></i><span>Add Audit details</span></button>
            <!-- <button v-if="$route.query.view == 'true'" class="ams-btn ams-btn-primary">Edit</button> -->
        </div>
    </div>
    <form v-if="!$route.params.prodid" class="ams-card">
        <div class="ams-row">
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <label>Asset Type</label>
                <Multiselect @change="getAssetFields" v-model="prod_id" @clear="clearPtype" :options="productTypes" placeholder="Asset Type" class="ams-form-control ams-custom-control-dark"></Multiselect>
            </div>
            <div v-if="prod_id" class="ams-form group">
                <a class="ams-btn ams-btn-outline-info" download :href="downloadProduct"><i class="icon-download ams-primary-color ams-cursor ams-mr10"></i> Download</a>
            </div>
             <div v-if="prod_id" class="ams-customize-upload">
                <file-upload :show-upload="true" :disText="utext" :uploadFile="'Multiuploadproduct'" :cType="'1'" :pType="prod_id"></file-upload>
            </div>
        </div>
    </form>
    <assets-audit-details v-if="auditDetFlag" :auditData="auditData" ref="auditRef" v-on:close-pop="closePop" />
    <assets-laptop :assetFields="assetFields" :prod_id="prod_id" v-show="assetFields" ref="AddAsset" />

    <!-- <assets-lap-charger />
        <assets-monitor />
        <assets-cpu />
        <assets-router />
        <assets-access />
        <assets-firewall />
        <assets-mobile />
        <assets-mob-charger />
        <assets-tv />
        <assets-printer />
        <assets-switch /> -->
    <!-- <div class="ams-table-filter">
        <button v-if="!flag && viewProduct" class="ams-btn ams-btn-outline-info">Generate asset label</button>
        <button v-if="!flag && viewProduct" class="ams-btn ams-btn-primary ams-ml20">Edit</button>
        <button v-if="flag && !viewProduct && flag2" class="ams-btn ams-btn-danger ams-ml20" @click="$router.push('/assets')">Cancel</button>
        <button v-if="flag && !viewProduct && flag2" class="ams-btn ams-btn-primary ams-ml20" @click="slotProps.handleSubmit(saveAsset)">Save</button>
    </div> -->

</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import AssetsLaptop from "../../components/assets/AssetsLaptop.vue"
// import moment from "moment";
import async from "async";
import AssetsAuditDetails from '../../components/assets/AssetsAuditDetails.vue'
// import AssetsLapCharger from "../../components/assets/AssetsLapCharger.vue"
// import AssetsMonitor from "../../components/assets/AssetsMonitor.vue"
// import AssetsCpu from '../../components/assets/AssetsCpu.vue';
// import AssetsRouter from "../../components/assets/AssetsRouter.vue"
// import AssetsAccess from "../../components/assets/AssetsAccess.vue"
// import AssetsFirewall from "../../components/assets/AssetsFirewall.vue"
// import AssetsMobile from "../../components/assets/AssetsMobile.vue"
// import AssetsMobCharger from "../../components/assets/AssetsMobCharger.vue"
// import AssetsTv from "../../components/assets/AssetsTv.vue"
// import AssetsPrinter from '../../components/assets/AssetsPrinter.vue';
// import AssetsSwitch from '../../components/assets/AssetsSwitch.vue';
import { ams_hub } from "../../../config/endpoint";
import FileUpload from "../../components/common/FileUpload.vue";

export default {
    components: {
        AssetsAuditDetails,
        Multiselect,
        AssetsLaptop,
        FileUpload
        // AssetsLapCharger,
        // AssetsMonitor,
        // AssetsCpu,
        // AssetsRouter,
        // AssetsAccess,
        // AssetsFirewall,
        // AssetsMobile,
        // AssetsMobCharger,
        // AssetsTv,
        // AssetsPrinter,
        // AssetsSwitch
    },
    data() {
        return {
            utext: "Upload excel for adding assets",
            prod_id: "",
            assetFields: null,
            // assetInfo: {},
            // empList: null,
            // flag: false,
            // flag2: false,
            // assetObj: {},
            // employees: [],
            // emp_id: null,
            // auditDate: null,
            // dop: null,
            // prod_status:null,
            // assetStatusList: ['New', 'Assigned', 'Un Assigned', 'Damaged'],
            // assetStatus: null,
            // viewProduct: false,
            // ven_id:""
            // productTypes: [],
            auditDetFlag: false,
            auditData: null
        };
    },
    mounted() {
        let _self = this
        // if (this.$route.path == "/add-assets") {
        //     this.flag = true;

        // }
        if (_self.$route.params.prodid) {
            // this.viewProduct = true
            _self.$store.dispatch('common/SET_LOADER', true)
            async.series([
                _self.getProductDetails.bind(),
                _self.getAssetFields.bind(),
                // _self.getEmployees.bind()
            ], function () {
                _self.$store.dispatch('common/SET_LOADER', false)
            })
        } 
         
        // else {
        //     _self.getEmployees()
        // }
        
    },
    computed: {
        productTypes: function () {
            let pt = localStorage.getItem('pt') && JSON.parse(localStorage.getItem('pt'))
            return pt && pt[1]
        },
        downloadProduct: function () {
            return ams_hub+'/product/download?pType=' + this.prod_id + '&cType=1&token='+encodeURIComponent(localStorage.getItem('token'))
        },
    },
    methods: {
        openaudit () {
            let self = this
            self.auditDetFlag = true
            let vm = document.body
			vm.classList.add('hide-scrollbar');
        },
        clearPtype () {
            this.assetFields = null
        },
        closePop: function() {
            this.auditDetFlag = false
            let vm = document.body
			vm.classList.remove('hide-scrollbar');
        },
        getProductDetails: function (cb) {
            let _self = this
            let query = _self.$route.params
            let assetRef = _self.$refs['AddAsset']
            if (query.prodid && query.ptype) {
                _self.$store.dispatch('product/FETCH_PRODUCT_DETAILS', {
                    prod_tag: query.prodid,
                    pType: query.ptype
                }).then(function (res) {
                    console.log('res audit', res)
                    console.log('this', _self.$refs)
                    if (res && res.status == 1000) {
                        assetRef.assetObj = (res.data && res.data.product && res.data.product.info) || {}
                        assetRef.fileData = (res.data && res.data.product && res.data.product.filedata) || {}
                        if (res.data && res.data.auditInfo) {
                            let adata = res.data.auditInfo
                            adata.audit_date = new Date(adata.audit_date)
                            _self.auditData = adata
                            // _self.$refs['auditRef'].auditObj = adata
                        }
                        if (res.data && res.data.empInfo && res.data.empInfo.length) {
                            assetRef.$refs['empRef'].emp_id = res.data.empInfo
                            assetRef.$refs['empRef'].empName = res.data.empInfo
                        }
                        // assetRef.$refs['auditRef'].auditObj = (res.data && res.data.auditInfo && res.data.auditInfo)
                        return cb()
                    }
                })
            }
        },
        getAssetFields: function (prod_id) {
            // this.flag2=true;
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('product/FETCH_PRODUCT_FIELDS', {
                prod_id: typeof prod_id == 'function' ? _self.$route.params.ptype : prod_id
            }).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    // _self.flag = true
                    _self.assetFields = res.data.fields
                    if (typeof prod_id == 'function') return prod_id()
                } else {
                    // _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    //     msg: 'Technical Error',
                    //     class: 'ams-error-danger'
                    // })
                    if (typeof prod_id == 'function') return prod_id()
                }
            })
        },
        // getProduct: function () {
        //     return this.productTypes && this.productTypes[this.prod_id] && (('- ' + this.productTypes[this.prod_id]) || '')
        // },
        // getEmployees: async function () {
        //     let _self = this
        //     let empResponse = await _self.$store.dispatch("employee/FETCH_EMPLOYEE_SELECT")
        //     if (empResponse && empResponse.status == 1000) {
        //         _self.empList = empResponse.data
        //     }
        // },
        // changeAsssetStatus: function (st) {
        //     this.assetStatus = st
        // },
        // setEmpId: function (id) {
        //     this.emp_id = id
        // },
        // saveAsset: async function () {
        //     console.log('CHILD DATA', this.$refs['AddAsset'])
            // const isValid = await this.$refs.AddAsset.validate()
            // console.log('const', isValid)
            // let assetData = this.$refs['AddAsset']
            // console.log('assetData', assetData.emp_id)
            // let empRef = assetData.$refs['empRef']
            // let productReq = {
            //     pType: this.prod_id,
            //     cType: "1",
            //     info: assetData.assetObj,
            //     emp_ids: empRef.emp_id ? [empRef.emp_id] : [],
            //     dop: moment(assetData.assetObj.PURCHASE_DATE).format('YYYY-MM-DD'),
            //     //audit_date: null,
            //     prod_status: assetData.assetObj.STATUS,
            //     ven_id: assetData.assetObj.VENDOR_NAME
            // }
            // console.log('productReq',productReq)
            // let _self = this
            // _self.$store.dispatch('common/SET_LOADER', true)
            // this.$store.dispatch('product/SAVE_PRODUCT', productReq).then(function (res) {
            //     _self.$store.dispatch('common/SET_LOADER', false)
            //     if (res && res.status == 1000) {
            //         _self.$store.dispatch('common/SET_ALERTMESSAGE', {
            //             msg: 'Product added successfully',
            //             class: 'ams-error-success'
            //         })
            //         _self.$router.push('/assets')
            //     } else {
            //         _self.$store.dispatch('common/SET_ALERTMESSAGE', {
            //             msg: 'Technical error',
            //             class: 'ams-error-danger'
            //         })
            //     }
            // })
        // }

    }
};
</script>
