<template>
<Form class="ams-auth-form" :validation-schema="changePassword" v-slot="{ handleSubmit }">
    <div class="ams-dialog" >
        <div class="ams-dialog-body ams-w30">
            <i class="ams-close ams-close-circle icon-close-circ ams-f-size30 ams-mt10" @click="closePop"></i>
            <div class="ams-dialog-cont">
                <h3 class="ams-tit">Change Password</h3>
                <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Old Password</label>
                        <Field type="password" v-model="upReq.password" name="name" class="ams-form-control ams-custom-control-dark" placeholder="" />
                        <ErrorMessage name="name" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>New Password</label>
                        <Field type="password" v-model="upReq.newpassword" name="password" class="ams-form-control ams-custom-control-dark" placeholder="" />
                        <ErrorMessage name="password" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Confirm New Password</label>
                        <Field type="password" class="ams-form-control ams-custom-control-dark" name="newpassword" placeholder="" />
                        <ErrorMessage name="newpassword" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-table-filter">
                    <button class="ams-btn ams-btn-primary ams-btn-block ams-mt10" @click.prevent="handleSubmit(updatePassword)">Update Password</button>
                </div>

            </div>
        </div>
    </div>
</Form>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    components: {
        Form,
        Field,
        ErrorMessage
    },
    data() {
        const changePassword = Yup.object().shape({
            name: Yup.string().required("Please Enter Old password"),
            password: Yup.string().required("Please Enter New password"),
            newpassword: Yup.string().required("Please Confirm your password")
                .oneOf([Yup.ref('password'), null], 'New Password & Confirm password not matching')
        });
        return {
            upReq: {
                password: "",
                newpassword: ""
            },
            changePassword
        };
    },
    methods: {
        closePop: function () {
            this.$emit('close-popup', '')
        },
        updatePassword() {
            let _self = this
            let uinfo = JSON.parse(localStorage.getItem("userDetails"))
            this.upReq.email = uinfo.email
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch("profile/CHANGE_PASSWORD", this.upReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Passsword updated successfully',
                        class: 'ams-error-success'
                    })
                    _self.$emit('close-popup', '')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })

        }
    }
};
</script>
