/**
 * @description vendor Store Module
 * @author Harikrishna
 */

 import axios from "../../util/axios";
 export default {
     namespaced: true,
     state: {
     },
     mutations: {
     },
     actions:{
        ADD_VENDOR({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/vendor/add', payload).then(function (res) {
                return res
            })
        },
        DELETE_VENDOR({ commit }, payload) {
            console.log('commit', payload, commit)
            return axios.get('/vendor/delete?ven_id=' + payload.ven_id).then(function (res) {
                return res
            })
        },
        FETCH_VENDOR_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/vendor/table', payload).then(function (res) {
                return res
            })
        },
        VENDOR_ID({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/vendor/id?ven_id='+ payload).then(function (res) {
                return res
            })
        },
        UPDATE_VENDOR({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/vendor/update',  payload).then(function (res) {
                return res
            })
        },
        LIST_VENDOR({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/vendor/list',  payload).then(function (res) {
                return res
            })
        },
        QUOTATION_VENDOR({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/vendor/quotation',  payload).then(function (res) {
                return res
            })
        },
        PLACE_ORDER({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/vendor/porder',  payload).then(function (res) {
                return res
            })
        },
     }
    }