<template>
  <div class="ams-card ams-expend">
    <h3 class="ams-tit">Activated Plan Details</h3>
    <div class="ams-active-plan">
      <div class="ams-row">
        <div class="ams-col7 ams-col-md12 ams-col-sm12 ams-col-xs12">
        <h4>Monthly Plan</h4>
            <p>
              The plan will be auto renewed if not cancelled before expiring date
            </p>
            <span class="ams-price-tag">
              {{packData.pkg_cur}}
              <!-- <small class="icon-rupee ams-primary-text"></small>  -->
              <strong class="ams-primary-text">  {{packData.pkg_price}}</strong>/ <small> {{packData.span_unit}}</small></span>
            <small class="ams-price-vat">*VAT & Local taxes may apply</small>
            <button class="ams-btn ams-btn-outline-info ams-btn-sm ams-alc" @click="$router.push('/payment')">Renew</button>
            <button class="ams-btn ams-btn-primary ams-btn-sm ams-alc ams-ml5" @click="proPlan">Change</button>
          </div>
        <div class="ams-col5 ams-col-md12 ams-col-sm6 ams-col-xs6 ams-expiry ">
          <span class="ams-expiry-dt"> Expiry on 23 - 04 - 2021 <span class="ams-badge ams-badge-danger ams-ml10">Cancel</span></span>
          <img src="https://img.litedev.com/ams-icons-images/images/plan.svg" alt="" class="ams-img-responsive" />
        </div>
      </div>
    </div>
    <!-- <subscribe /> -->
    <pro-plans v-if="proPlanFlag" @closePopup="closePopup" />
  </div>
</template>
<script>
import ProPlans from '../pro/ProPlans.vue';
// import Subscribe from './Subscribe.vue';
export default {
  props:['packData'],
  components: {
    ProPlans,
    // Subscribe
  },
  data() {
    return {
      proPlanFlag: false,
    };
  },
  methods: {
    proPlan:function(){
      this.proPlanFlag = true
    },
    closePopup:function(){
      this.proPlanFlag = false
    }
  },
};
</script>
 