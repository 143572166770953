<template>
<div class="ams-content">
    <Form :validation-schema="checkincheckoutValidate" v-slot="{ handleSubmit }">
        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/checkin-checkout-data')"></i>Check In / Check Out data</h3>
        <form class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Employee</label>
                        <Multiselect v-model="cincout.emp_id" @change="getEmployeesId" @clear="clearPtype" name="employee" :options="empList" class="ams-form-control ams-custom-control-dark"></Multiselect>
                        <Field type="text" v-model="cincout.emp_id" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="employeeName" />
                        <ErrorMessage name="employeeName" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset tag</label>
                        <Multiselect v-model="cincout.prod_tag" name="employee" :options="productTags" class="ams-form-control ams-custom-control-dark"></Multiselect>
                        <Field type="text" v-model="cincout.prod_tag" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="assetTag" />
                        <ErrorMessage name="assetTag" class="ams-val-err" />
                    </div>
                </div>
                <!-- <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Employee Id</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="licenseid" id="licenseid" />
                    </div>
                </div> -->
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-datepicker-md">
                        <label>Checkout Date & Time</label>
                        <!-- <datepicker v-model="cincout.cout" class="ams-form-control ams-custom-control-dark" name="repairDate">
                        </datepicker> -->
                        <datepicker v-model="cincout.cout" name="repairDate" :min-date='new Date()' class="ams-form-control ams-custom-control-dark" :enableTimePicker="false"  monthNameFormat="long" autoApply />
                        <Field type="calender" v-model="cincout.cout" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="calender" />
                        <ErrorMessage name="calender" class="ams-val-err" />
                        <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                    </div>
                </div>

                <!-- <div class="ams-col3 ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Checkin Date & Time</label>
                    <datepicker v-model="cincout.cin" class="ams-form-control ams-custom-control-dark" name="repairDate">
                    </datepicker>
                    <Field type="calender" v-model="cincout.cin" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="calender" />
                    <ErrorMessage name="calender" class="ams-val-err" />
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                </div>

            </div> -->
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Location</label>
                        <Field type="text" v-model="cincout.loc" class="ams-form-control ams-custom-control-dark" placeholder="" name="location" id="ponumber" />
                        <ErrorMessage name="location" class="ams-val-err" />
                    </div>
                </div>
            </div>

            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-danger ams-mr20" @click="$router.push('/checkin-checkout-data')">Cancel</button>
                <button class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(addData)">Save</button>
            </div>
        </form>
    </Form>
</div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import Datepicker from 'vue3-date-time-picker';
import _ from "lodash";
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    components: {
        Multiselect,
        Datepicker,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        const checkincheckoutValidate = Yup.object().shape({
            employeeName: Yup.string().required("Please Select Employee").nullable(),
            assetTag: Yup.string().required("Please Select Asset tag").nullable(),
            calender: Yup.string().required("Please Select Date").nullable(),
            location: Yup.string().required("Please Enter Location"),

        });
        return {
            filterFlag: false,
            empList: null,
            productTags: [],
            emp_id: null,
            checkincheckoutValidate,
            cincout: {
                prod_tag: "",
                emp_id: "",
                cout: "",
                // cin: "",
                loc: ""

            },
          
        };
    },
    mounted() {
        this.getEmployees()

    },
    methods: {
         clearPtype () {
            let self = this
            self.cincout.prod_tag = ""    
        },
        getEmployees: async function () {

            let _self = this
            let Response = await _self.$store.dispatch("employee/FETCH_EMPLOYEE_SELECT")
            if (Response && Response.status == 1000) {
                _self.empList = Response.data
            }
        },
        getEmployeesId: async function (emp_id) {
            let _self = this
            _self.productTags = []
            let response = await _self.$store.dispatch("employee/LIST_EMPLOYEE", emp_id)
            console.log('response', response)
            if (response && response.status == 1000) {
                _self.emp = response.data.emp_id
                _self.cincout.prod_tag = ''
         _.map(response.data.tags, t => t.map(p => {
                    _self.productTags.push(p.tag)
                }))
            }
        },

        addData: function () {
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('checkincheckout/ADD_DATA', _self.cincout).then(function (res) {
                console.log('RES', res)
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Data added successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/checkin-checkout-data')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        }
    }
};
</script>
