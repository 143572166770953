
import axios from "../../util/axios";
export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions:{
        FETCH_INVENTORY_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/assign', payload).then(function (res) {
                return res
            })
        },
        INVENTORY_ID({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/inventory/id?prod_tag=' + payload).then(function (res) {
                return res
            })
        },
        FETCH_ITEMSTOCK_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/unassign', payload).then(function (res) {
                return res
            })
            },
        SAVE_SCRAPED({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/scrappred', payload).then(function (res) {
                return res
            })
        },
        FETCH_SCRAPED_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/scraptable', payload).then(function (res) {
                return res
            })
        },
        FETCH_PORDER_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/pordertable', payload).then(function (res) {
                return res
            })
        },
        SAVE_PURCHASE_ORDER({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/porder', payload).then(function (res) {
                return res
            })
        },
        UPDATE_ASSIGN_ITEM({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/updateinventory', payload).then(function (res) {
                return res
            })
        },
        PORDER_STATUS({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/inventory/porderstatus', payload).then(function (res) {
                return res
            })
        },
    }
}
