import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/home/Home.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Assets from "@/views/assets/Assets.vue";
// import AddAssets from "@/views/assets/AddAssets.vue";
import Accessories from "@/views/accessories/Accessories.vue";
//import AddAccessories from "@/views/accessories/AddAccessories.vue";
import Licenses from "@/views/licenses/Licenses.vue";
// import AddLicense from "@/views/licenses/AddLicense.vue";
import Employee from "@/views/employee/Employee.vue";
// import AddEmployee from "@/views/employee/AddEmployee.vue";
// import EmployeeDetails from "@/components/employee/EmployeeDetails.vue"
import Inventory from "@/views/inventory/Inventory.vue";
// import InventoryAssignedItem from "@/views/inventory/InventoryAssignedItem.vue";
// import InventoryItemStock from "@/components/inventory/InventoryItemStock.vue";
// import InventoryPurchase from "@/views/inventory/InventoryPurchase.vue";
// import InventoryScraped from "@/views/inventory/InventoryScraped.vue";
import Tickets from "@/views/tickets/Tickets.vue";
import TicketsForm from "@/views/tickets/TicketsForm.vue";
import Maintenance from "@/views/maintenance/Maintenance.vue";
//import AddRepairMaintenance from "@/views/maintenance/AddRepairMaintenance.vue";
import CheckInCheckOutData from "@/views/checkin-checkout/CheckInCheckOutData.vue";
import AssetsHistory from "@/views/assetshistory/AssetsHistory.vue";
import AssetsHistoryDetails from "@/views/assetshistory/AssetsHistoryDetails.vue"
import Reports from "@/views/reports/Reports.vue";
// import store from "@/store";
import Vendor from "@/views/vendor/Vendor.vue";
// import AddVendor from "@/views/vendor/AddVendor.vue";
import Profile from "@/views/profile/Profile.vue";
import Payment from "@/views/payment/Payment.vue";
import Depreciation from "@/views/depreciation/Depreciation.vue";
import Settings from "@/views/settings/Settings.vue";
import Users from "@/views/user/Users.vue";
// import ProdductSettings from "@/views/product-settings/ProductSettings.vue"
// import AddProduct from "@/components/product-settings/AddProduct.vue"
// import EditProduct from "@/components/product-settings/EditProduct.vue"
import AddCustomize from "@/views/settings/customize/AddCustomize.vue";
import SettingsAddCustomize from "@/views/settings/customize/SettingsAddCustomize.vue";
import Version from "@/views/pro/Version.vue";
// import TicketDetails from "@/views/tickets/TicketDetails.vue";
// import EditReopenTicket from "@/views/tickets/EditReopenTicket.vue";
// import EditVendorList from "@/views/vendor/EditVendorList.vue";
import CheckinCheckoutForm from "@/views/checkin-checkout/CheckinCheckoutForm.vue"
import RoleAssignmentCategory from "@/views/settings/RoleAssignmentCategory.vue"
// import RoleManagement from "@/views/settings/RoleManagement.vue"
// import CreateSubUser from "@/views/settings/CreateSubUser.vue"
import manufacture from "@/views/manufacture/manufacture.vue";
//import addmanufacture from "@/views/manufacture/addmanufacture.vue";
//import AskQuotation from "@/views/inventory/AskQuotation.vue"
import CompanyProfile from "@/views/profile/CompanyProfile.vue";


const routes = [
  { path: "/", redirect: "/home", },
  { path: "/home", name: "home", component: Home, },
  { path: "/dashboard", name: "dashboard", component: Dashboard, },
  { path: "/assets/:action?/:prodid?/:ptype?", name: "assets", component: Assets, },
  // { path: "/add-assets", name: "add-assets", component: AddAssets, },
  { path: "/accessories/:action?/:prodid?/:ptype?", name: "accessories", component: Accessories, },
 // { path: "/add-accessories", name: "add-accessories", component: AddAccessories, },
  { path: "/licenses/:action?/:prodid?/:ptype?", name: "licenses", component: Licenses, },
  // { path: "/add-license", name: "add-license", component: AddLicense, },
  { path: "/employee/:action?/:empid?", name: "employee", component: Employee, },
  // { path: "/list-employee", name: "list-employee", component: EmployeeDetails, },
  // { path: "/add-employee", name: "add-employee", component: AddEmployee, },
  { path: "/inventory/:tabname?/:prodid?", name: "inventory", component: Inventory, },
  // { path: "/inventory-assigned-item", name: "inventory-assigned-item", component: InventoryAssignedItem, },
  // { path: "/inventory-item-stock", name: "inventory-item-stock", component: InventoryItemStock, },
  // { path: "/inventory-purchase", name: "inventory-purchase", component: InventoryPurchase, },
  // { path: "/inventory-scraped", name: "inventory-scraped", component: InventoryScraped, },
  { path: "/tickets-form", name: "tickets-form", component: TicketsForm, },
  { path: "/tickets/:tabname?/:prodid?", name: "tickets", component: Tickets, },
  { path: "/maintenance/:action?/:repairid?", name: "maintenance", component: Maintenance, },
  //{ path: "/add-repair-maintenance", name: "add-repair-maintenance", component: AddRepairMaintenance, },
  { path: "/checkin-checkout-data", name: "checkin-checkout-data", component: CheckInCheckOutData, },
  { path: "/assets-history", name: "assets-history", component: AssetsHistory, },
  { path: "/assets-history-details", name: "assets-history-details", component: AssetsHistoryDetails, },
  { path: "/reports", name: "report", component:Reports, },
  { path: "/vendor/:action?/:venid?", name: "vendor", component: Vendor,},
  { path: "/manufacture/:action?/:manid?", name: "manuafcture", component: manufacture,},
  
  // { path: "/add-vendor", name: "add-vendor", component: AddVendor,},
  { path: "/profile", name: "profile", component:Profile, },
  { path: "/payment", name: "payment", component:Payment, },
  { path: "/depreciation", name: "depreciation", component: Depreciation, },
  { path: "/settings/:tabname?/:action?/:roleid?", name: "settings", component: Settings, },
  { path: "/users/:tabname?/:action?/:roleid?", name: "Users", component: Users, },
  // { path: "/product-settings", name: "product-settings", component: ProdductSettings,},
  // { path: "/add-product", name: "add-product", component: AddProduct,},
  // { path: "/edit-product", name: "edit-product", component: EditProduct,},
  { path: "/add-customize", name: "add-customize", component: AddCustomize, },
  { path: "/settings-add-customize", name: "settings-add-customize", component: SettingsAddCustomize, },
  { path: "/version", name: "version", component: Version, },
  // { path: "/ticket-details", name: "ticket-details", component: TicketDetails, },
  // { path: "/edit-reopen-ticket", name: "edit-reopen-ticket", component: EditReopenTicket, },
  // { path: "/edit-vendor-list", name: "edit-vendor-list", component: EditVendorList, },
  { path: "/checkin-checkout-form", name: "checkin-checkout-form", component: CheckinCheckoutForm, },
  { path: "/role-assignment-category", name: "role-assignment-category", component: RoleAssignmentCategory, },
  // { path: "/role-management", name: "role-management", component: RoleManagement, },
  // { path: "/create-sub-user", name: "create-sub-user", component: CreateSubUser, }, 

 // { path: "/ask-quotation", name: "ask-quotation", component: AskQuotation,},
  { path: "/company-profile", name: "company-profile", component: CompanyProfile,},

];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(function (to) {
  // if path is logout redirect to home
  // if (to.path == '/logout') {
  //   router.push('/home')
  //   // remove data from local storage
  //   localStorage.removeItem('menu')
  //   localStorage.removeItem('token')
  //   localStorage.removeItem('userDetails')
  //   // remove data from vuex store
  //   store.dispatch("common/SET_MENU", null)
  //   store.dispatch("user/SET_USERDETAILS", null)
  //   store.dispatch("user/SET_USERTOKEN", null)
  // }
  // if user already logged in, redirecting to dashboard
  if (to.path == '/' || to.path == '/home') {
    if (localStorage.getItem('token')) {
      let menuData = JSON.parse(localStorage.getItem('menu'))
      console.log('menuData', menuData[0])
      let route = menuData[0].path
      router.push(route);
      // router.push('/dashboard')
    }
  }
})

export default router;