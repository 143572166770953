<template>
<!-- <div v-if="employeeList && employeeList.length"> -->
<div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>SI.No</th>
                <th>Employee Name</th>
                <th>Employee ID</th>
                <th>Designation</th>
                <!-- <th>Company</th> -->
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(a, i) in employeeList" :key="i" @click.stop="emplist(a.emp_id, a.active)">
                <td data-title="S.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="Employee Name">{{ a.fn }} {{ a.ln }}</td>
                <td data-title="Employee ID">{{ a.emp_id }}</td>
                <td data-title="Designation">{{ a.desg }}</td>
                <!-- <td data-title="Company">{{ a.comp_id }}</td> -->
                <td data-title="Status" @click.stop="changeStatus(a.emp_id,a.active)">
                    <!-- <span class="ams-btn ams-btn-xs ams-btn-danger" v-if="a.status == false">Paused</span> -->
                    <div class="ams-switch">
                        <input :id="a.emp_id" :checked="a.active" :name="a.emp_id" type="checkbox">
                        <label :for="a.emp_id" ><span class="ams-input-box" > 
                            <!--{{ a.status ? 'Active' : 'InActive'}}--></span></label>
                    </div>
                </td>
                <td data-title="Action">
                    <!-- <i class="icon-edit-box ams-primary-text ams-mr20" @click="emplist(a.emp_id, a.status)"></i> -->
                    <i @click.stop="showPopup(a.emp_id)" class="ams-f-size20 icon-delete-1 ams-red-text"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- <employee-details v-if="$route.params.action === 'list'" /> -->
    
</div>
<delete-confirmation v-if="vflag" v-on:close-popup="closePopup" :uid='empID'/>

</template>

<script>
// import EmployeeDetails from "./EmployeeDetails.vue";
// import DeleteConfirmation from "../../components/employee/DeleteConfirmation"
import DeleteConfirmation from "../common/DeleteConfirmation.vue"
export default {
    components: {
        // EmployeeDetails,
        DeleteConfirmation
    },

    props: ["employeeList", "paginationData"],
    data() {
        return {
            vflag: false,
            // employeeDetFlag: false,
            empID:'',
        };
    },
    methods: {
        showPopup (val) {
            let self = this
            self.vflag = true
            this.empID = val
            let vm = document.body
			vm.classList.add('hide-scrollbar');
        },
        closePopup(){
              this.vflag  =  false;
            //  this.$emit('table-api','')
            let vm = document.body
			vm.classList.remove('hide-scrollbar');
        },
        emplist: function (id, status) {
            console.log('status', status)
            console.log(id, "id");
            if (status) {
                this.$router.push({
                    path: "/employee/list/" + id,
                    // query: {
                    //     id
                    // },
                });
            }
        },
        deleteFunc: function (emp_id) {
            let _self = this
            let reqObj = {
                emp_id: emp_id
            }
            console.log('EMP', emp_id)
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store.dispatch("employee/DELETE_EMPLOYEE", reqObj)
                .then(function (res) {
                    if (res && res.status == 1000) {
                        _self.$parent.pageData()
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "EMPLOYEE deleted successfully",
                            class: "ams-error-success",
                        });
                        _self.$store.dispatch("common/SET_LOADER", false);
                        _self.vflag = false
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });
        },
        changeStatus: function (emp_id, status) {
            let _self = this
            _self.$store.dispatch("common/SET_LOADER", true);
            let reqObj = {
                emp_id: emp_id,
                active: (status == true) ? false : true
            }
            _self.$store.dispatch("employee/STATUS_EMPLOYEE", reqObj)
                .then(function (res) {
                    if (res && res.status == 1000) {
                        console.log("responseeee", res);
                        _self.$parent.pageData()
                    }
                    _self.$store.dispatch("common/SET_LOADER", false);
                })

        },
         indexMethod(pageNumber, pageSize) {
            
            return pageNumber * pageSize - (pageSize - 1);
        },
    },
};
</script>
