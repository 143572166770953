<template>
<div class="ams-content">
    <add-accessories v-if="$route.params.action==='add' ||  $route.params.action==='edit'" />
    <div v-if="!$route.params.action" class="ams-row ams-mb20">
        <div class="ams-col6">
            <h3 class="ams-tit">Accessories</h3>
        </div>
        <div class="ams-col6 ams-text-right">
            <button type="button" class="ams-btn ams-btn-primary" @click="$router.push('/accessories/add')">Add Accessory</button>
        </div>
    </div>
    <accessories-table-filter v-if=" !$route.params.action && accessoriesList && accessoriesList.length" ref="accessoriestf" />
    <accessories-table v-if="!$route.params.action " :accessoriesList="accessoriesList" :paginationData="paginationData" />
    <div v-if="!$route.params.action" class="ams-table-filter ams-table-upload">
        <pagination :limit="limit" v-if="paginationData && paginationData.total >limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
        <!-- <div class="ams-customize-upload">
            <file-upload :show-upload="true" :disText="utext"></file-upload>
        </div> -->
    </div>
</div>
</template>

<script>
import AccessoriesTableFilter from "../../components/accessories/AccessoriesTableFilter"
import AccessoriesTable from "../../components/accessories/AccessoriesTable"
import Pagination from "../../components/common/Pagination.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
import AddAccessories from "../../components/accessories/AddAccessories"

// import FileUpload from "../../components/common/FileUpload.vue"

export default {
    // mounted: function () {
    //  this.pageData();
    //  },
    computed: {
        limit: function () {
            return pagination_limit
        },

    },
    components: {
        AccessoriesTableFilter,
        AccessoriesTable,
        Pagination,
        AddAccessories,
        // FileUpload
    },
    data() {
        return {
            // utext: 'Upload excel for adding accessories',
            accessoriesList: [],
            page: 1,

        };
    },
    methods: {

        pageData: async function (val) {
            let _self = this
            let atf = this.$refs['accessoriestf']
            _self.$store.dispatch("common/SET_LOADER", true)
            // fetch list data
            let reqObj = {
                cType: "2",
                limit: this.limit,
                page: this.page
            }
            // pType: accessoriestf && accessoriestf.pType,
            //     prod_tag: accessoriestf && accessoriestf.prod_tag,
            //     emp_id: accessoriestf && [accessoriestf.emp_id]
            // if (accessoriestf.pType) {
            //     reqObj.pType = accessoriestf && accessoriestf.pType
            // }
            // if(accessoriestf.emp_id) {
            //     reqObj.emp_id =[accessoriestf.emp_id]
            // }
            // if(accessoriestf.prod_tag){
            //     reqObj.prod_tag =accessoriestf && accessoriestf.prod_tag
            // }
            if (atf && atf.prod_tag) {
                reqObj.prod_tag = atf.prod_tag
            }
            if (atf && atf.emp_id) {
                reqObj.emp_id = [atf.emp_id]
            }
            if (atf && atf.pType && atf.pType.length) {
                reqObj.pType = atf.pType
            }
            console.log('ATF', atf)
            if (atf && atf.prod_status && atf.prod_status.length) {
                reqObj.prod_status = atf.prod_status
            }
            if (atf && atf.emp_status && atf.emp_status.length) {
                reqObj.emp_status = atf.emp_status
            }
            console.log(reqObj, "REQUEST")
            let response = await _self.$store.dispatch("product/FETCH_PRODUCT_LIST", reqObj)
            _self.$store.dispatch("common/SET_LOADER", false)
            if (response && response.status  == 1000) {
                // if (response.data && response.data.products) {
                    _self.accessoriesList = response.data.products
                    _self.paginationData = response.data.metadata ? response.data.metadata[0] : null
               // } 
                // else if (type === 'filter') {
                //     _self.accessoriesList = []
                //     _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                //        // msg: 'No Data exits',
                //         class: 'ams-error-danger'
                //     })
                // }

            } else {
                 if(val === 'filter' && response.data && !response.data.length) {
                    _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                        msg: "No Data exits",
                        class: "ams-error-danger",
                    });
                 }
             }

        },
        setPageData: function (page) {
            this.page = page
            this.pageData()
        }
    },
    watch: {
        "$route.params.action": {
            immediate: true,
            handler() {
                let self = this
                console.log('self.$route.params.action', this.$route)
                if (self.$route.params.action !== 'add') {
                    self.pageData();
                }
            }
        }
    },
};
</script>
