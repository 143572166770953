<template>
<Form :validation-schema="accessoriesselect" v-slot="{ handleSubmit }">
    <div class="ams-card">
        <h3 class="ams-tit">Assign / Deassign Asset</h3>
        <div class="ams-row">
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Employee Name </label>
                    <Multiselect v-model="empName" name="accessories" @change="setEmpId" :options="empList" class="ams-form-control ams-custom-control-dark" placeholder="Employee Name"></Multiselect>
                    <ErrorMessage name="accessories" class="ams-val-err" />
                </div>
                                 
            </div>
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                  <label>Employee Id</label>
                  <input v-model="emp_id" readonly="readonly" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Employee Id" name="software" id="software">
                  </div>                 
            </div>
            <!--<div class="ams-col3">
        <div class="ams-switch">
          <input type="checkbox" :checked="isEnabled" id="isEnabled" name="isEnabled" />
          <label for="isEnabled"> <span class="ams-input-box"></span> </label>
        </div>
      </div>-->
            <!-- <div class="ams-col12">
                    <div class="ams-form-group">
                        <textarea class="ams-form-control" placeholder="" name="software" id="software" />
                    </div>
                </div> -->
        </div>
        <div class="ams-table-filter">
            <button v-if="viewProduct" class="ams-btn ams-btn-danger" @click="$router.push('/accessories')">
                Cancel
            </button>
            <button v-if="viewProduct" class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(saveAccessories)">
                Save
            </button>
        </div>
    </div>
</Form>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import {
    Form,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";

export default {

    props: ["viewProduct", "empList"],
    components: {
        Multiselect,
        Form,
        ErrorMessage
    },
    data() {
        const accessoriesselect = Yup.object().shape({
            // accessories: Yup.string().required("please select assign/deassign")
        })
        return {
            emp_id: '',
            accessoriesselect,
            empName: ''
        };
    },
    methods: {
        setEmpId: function (id) {
            this.$parent.emp_id = id;
            this.emp_id = id;
        },
        saveAccessories: function () {
            let productReq = {
                prod_tag: this.$route.params.prodid,
                emp_id: this.emp_id ? [this.emp_id] : [],
                cType: '2'
            };
            let _self = this;
            _self.$store.dispatch("common/SET_LOADER", true);
            this.$store
                .dispatch("product/UPDATE_PRODUCT_ASSIGN", productReq)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Employee Assign/Deassign Updated Successfully",
                            class: "ams-error-success",
                        });
                        _self.$router.push('/accessories')
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });
        },
    },
};
</script>
