<template>
<div>
    <!-- <report-download /> -->
    <div class="ams-card">
        <div v-if="licenseList && licenseList.length" class="ams-row ams-d-f-sbetween">
            <div class="ams-col4 ams-col-md4 ams-col-sm4 ams-col-xs12">
                <div class="ams-two-calender ams-d-f-a-center">
                    <label class="ams-two-cal-label">Select DOP Date</label>
                    <datepicker v-model="date" @cleared="clearDate" range twoCalendars :enableTimePicker="false" autoApply :closeOnAutoApply="true" class="ams-form-control ams-custom-control" placeholder="Start Date - End Date" />
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                </div>
            </div>
            <div class="ams-col2 ams-col-md3 ams-col-sm3 ams-col-xs12">
                <div class="ams-form group">
                    <a v-if="date && date.length" class="ams-btn ams-btn-outline-info" download :href="downloadLicenseDetails"><i class="icon-download ams-primary-color ams-cursor ams-mr10"></i> Download</a>
                </div>
            </div>
        </div>
    </div>
    <table class="ams-table" cellspacing="0" cellpadding="0">
        <thead>
            <tr>
                <th>SI.No</th>
                <th>Software Name</th>
                <th>Product Tag</th>
                <th>Seats</th>
                <th>Remaining Seats</th>
                <th>DOP</th>
                <th>Renewal Date</th>

            </tr>
        </thead>
        <tbody>
            <tr v-for="(a,i) in licenseList" :key="i">
                <td data-title="SI.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                <td data-title="License">{{a.software_name}}</td>
                <td data-title="Product Key">{{a.prod_tag}}</td>
                <td data-title="Seats">{{a.seats}}</td>
                <td data-title="Remaining Seats">{{a.remaining_seats}}</td>
                <td data-title="DOP">{{getDate(a.dop)}}</td>
                <td data-title="Expiration Date">{{getDate(a.renewal_date)}}</td>
            </tr>
        </tbody>
    </table>
    <div class="ams-table-filter">
        <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
    </div>
</div>
</template>

<script>
// import ReportDownload from "../common/ReportDownload.vue"
import moment from "moment";
import Pagination from "../common/Pagination.vue"
// import Multiselect from '@vueform/multiselect'
import {
    pagination_limit
} from "../../../config/endpoint";
import {
    ams_hub
} from "../../../config/endpoint";
// import { computed } from '@vue/reactivity';
import Datepicker from 'vue3-date-time-picker';

export default {
    components: {
        // ReportDownload,
        Pagination,
        // Multiselect
        Datepicker
    },
    data() {
        return {
            isShow: false,
            page:1,
            date:[],
            paginationData:null,
            licenseList:[],
            options: [
                'CSV',
                'MS-WORD',
                'MS-EXCEL',
                'PDF',
            ]
        };
    },
    mounted () {
        let self = this
        self.licenseReport()
    }, 
    computed: {
        limit: function () {
            return pagination_limit
        },
        downloadLicenseDetails: function () {
            let _self = this
            let reqObj = {
                to_date: moment(_self.date[1]).format('YYYY-MM-DD'),
                from_date: moment(_self.date[0]).format('YYYY-MM-DD')
            }
            return ams_hub + '/report/licensereport?from_date=' + reqObj.from_date + '&to_date=' + reqObj.to_date + '&token=' + encodeURIComponent(localStorage.getItem('token'))
        },
    },
    methods: {
        licenseReport(val) {
            let _self = this
            let reqObj = {
                limit: this.limit,
                page: this.page
            }
            if (_self.date && _self.date.length) {
                reqObj.from_date = _self.date[0]
                reqObj.to_date = _self.date[1]
            }
            // if (val1 && val1 != 'All') {
            //     reqObj.audit_status = val1
            // }
            _self.$store.dispatch("common/SET_LOADER", true)
            this.$store
                .dispatch("reports/FETCH_LICENSE_TABLE", reqObj)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    console.log('RES', res)
                    if (res && res.status == 1000) {
                        _self.licenseList = res.data.license
                        _self.paginationData = res.data.metadata[0]

                    } else {
                        if (val === 'filter' && res.data && res.data.license && !res.data.license.length) {
                            _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                                msg: "No Data exits",
                                class: "ams-error-danger",
                            });
                        }
                    }
                });
        },
        clearDate() {
            this.licenseReport()
        },
        indexMethod(pageNumber, pageSize) {

            return pageNumber * pageSize - (pageSize - 1);
        },
        getDate: function (date) {
            return moment(date).format('DD-MM-YYYY');
        },
        setPageData: function (page) {
            this.page = page
            this.licenseReport()
        },
        // addAccessories:function(){
        //     this.$emit('add-accessories', '')
        // }
    },
    watch: {
        date: {
            immediate: false,
            handler() {
                let self = this
                if (self.date && self.date.length) {
                    self.licenseReport('filter')
                }
            }
        }
    }
};
</script>
