<template>
<div>

        <table class="ams-table" cellspacing="0" cellpadding="0">
            <thead v-if="inventoryList&&inventoryList.length">
                <tr>
                    <th>SI.No</th>
                    <th>Asset / Accessory Name</th>
                    <th>No. of items working</th>
                    <th>No. of items damaged</th>
                    <!-- <th>Vendor Email ID</th>
                    <th>Price</th>
                    <th>Invoice</th>
                    <th>No. of Items</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(a, i) in inventoryList" :key="i"    >
                    <td data-title="SI.No">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                    <td data-title="Asset / Accessory Name">{{parsedData[a.type]}}</td>
                    <td data-title="No. of items working">{{a.working ? a.working : '-'}}</td>
                    <td data-title="No. of items damaged">{{a.damaged ? a.damaged: '-'}}</td>
                    <!-- <td data-title="Vendor Email ID">{{a.ven_email ? a.ven_email : '-'}}</td>
                    <td data-title="Price">{{a.price}}</td>
                    <td data-title="Invoice"><a class="ams-href ams-primary-text" href="#">invoice 1</a></td>
                    <td data-title="No. of Items">12 / <span class="ams-220 ams-primary-text">220{{a.item}}</span><i class="icon-edit-box ams-ml20"></i></td> -->
                </tr>
            </tbody>
        </table>
        <div class="ams-table-filter ams-mt20">       
             <pagination :limit="limit" v-if="paginationData && paginationData.total>limit" :paginationData="paginationData" @setPageData="setPageData($event)"  />
        </div>
  <inventory-item-stock />
    </div>
</template>
<script>
import Pagination from "../common/Pagination.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
//import InventoryItemStock from "../../components/inventory/InventoryItemStock.vue";

export default {
	components: {
        Pagination,
        //InventoryItemStock   
	},
	data() {
		return {
             isShow: false,
              page:1,
             inventoryList:null,
             paginationData:null
		};
	},
    mounted:function() {
        this.ListInventoryStock();
    },
    computed: {
        parsedData() {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return {
                ...parsedPT[1],
                ...parsedPT[2],
                ...parsedPT[3]
            }
        },
        limit: function () {
            return pagination_limit
        }
    },
    methods: {
        //  viewInventory: function (ptag) {
        //     this.$router.push("/inventory/stock/" + ptag);
        // },
        
        ListInventoryStock: function () {
            let _self = this;

            let inventoryTable = {
                limit: this.limit,
                page: this.page,
            };
            _self.$store.dispatch("inventory/FETCH_ITEMSTOCK_TABLE", inventoryTable).then(function (res) {
                console.log('RES ITEM', res)
                if (res && res.status == 1000) {
                    _self.inventoryList = res.data.unassignItems;
                    _self.paginationData = res.data.metadata[0]
                   
                } 
            });
        },
        setPageData: function (page) {
            this.page = page
            this.ListInventoryStock()
        },
        indexMethod(pageNumber, pageSize) {
            
            return pageNumber * pageSize - (pageSize - 1);
        },

    },
};
</script>
