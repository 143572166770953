<template>
<div>
    <div class="ams-card ams-card-border ams-mt25">
        <h3 class="ams-tit">Type of Accessories</h3>
        <Form class="ams-auth-form" :validation-schema="assetValidate" v-slot="{ handleSubmit }">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-multi-icon">
                        <i class="icon-mouse ams-form-icon ams-form-icon-left"></i>
                        <Multiselect v-model="accessoriesData.pType" :options="accessoriesTypes" name="pType" class="ams-form-control" placeholder="Type of Accessories" id="version" :searchable="true" track-by="label"></Multiselect>
                        <Field type="text" v-model="accessoriesData.pType" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="pType" />
                        <ErrorMessage name="pType" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Multiselect type="text" class="ams-form-control" v-model="accessoriesData.brand" :options="brandList" placeholder="Select Brand" name="brand" id="version" :searchable="true" track-by="label"/>
                        <Field type="text" v-model="accessoriesData.brand" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="brand" />
                        <ErrorMessage name="brand" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-model="accessoriesData.mnumber" placeholder="Model Number" name="mnumber" id="version" />
                        <ErrorMessage name="mnumber" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-model="accessoriesData.quantity" placeholder="Quantity" name="quantity" id="version" />
                        <ErrorMessage name="quantity" class="ams-val-err" />
                    </div>
                </div>
                <div v-if="!bflag" class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-model="accessoriesData.price" placeholder="Enter Price" name="price" id="version" />
                        <ErrorMessage name="price" class="ams-val-err" />
                    </div>
                </div>
                <!-- <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-model="accessoriesData.ponumber" placeholder="Po number" name="Po number" id="version" />
                        <ErrorMessage name="modelnumber" class="ams-val-err" />
                    </div>
                </div> -->
                <div class="ams-col12">
                    <div class="ams-form-group">
                        <Field type="text" v-model="accessoriesData.comment" placeholder="Comment" name="comment" class="ams-form-control" id="version" />
                        <ErrorMessage name="comment" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <div class="ams-table-filter ams-mb15">
                <button type="button" class="ams-btn ams-btn-outline-info" @click="handleSubmit(addVendorAccessories)">Add</button>
            </div>
        </Form>
        <template v-for="(a,i) in accessories_item" :key="i">
            <ul class="ams-table-list ams-type-of-list">
                <li class="ams-table-list-body">
                    <div class="ams-table-list-col">
                        <!-- <input type="checkbox" id="Laptop-ass">
                        <label class="ams-vendor-checkbox" for="Laptop-ass">

                        </label> -->
                        <figure class="ams-avtar ams-avtar-md ams-mr15">
                            <i class="icon-lap"></i>
                        </figure> {{accessoriesTypes[a.pType]}}
                    </div>
                    <div class="ams-table-list-col">
                        <i class="ams-circ ams-dark-bg"></i>
                        <span class="ams-ml10">{{a.brand}}</span>
                    </div>
                    <div class="ams-table-list-col">
                        <i class="ams-circ ams-dark-bg"></i>
                        <span class="ams-ml10">{{a.mnumber}}</span>
                    </div>
                    <div class="ams-table-list-col">
                        <i class="ams-circ ams-dark-bg"></i>
                        <span class="ams-ml10">{{a.quantity}}</span>
                    </div>
                    <div v-if="!bflag" class="ams-table-list-col">
                        <i class="ams-circ ams-dark-bg"></i>
                        <span class="ams-ml10">{{a.price}}</span>
                    </div>
                    <div class="ams-table-list-col">
                        <span class="ams-remove-text" @click="removeItem(i)">Remove</span>
                    </div>
                </li>
                <li class="ams-table-list-col">
                    <div class="ams-alert ams-a-info ams-w100">{{a.comment}}</div>
                </li>
                <!-- <li class="ams-table-filter">
                    <div>
                        <input type="text" v-if="!bflag" class="ams-form-control" placeholder="Enter price" />
                    </div>
                </li> -->
            </ul>
        </template>
    </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
// import FileUpload from "../common/FileUpload.vue"

import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage,
        // FileUpload
    },
    data() {
        const assetValidate = Yup.object().shape({
            brand: Yup.string().required().label("Brand").nullable(),
            mnumber: Yup.string().required().label("Modelnumber"),
            quantity: Yup.string().matches(/^[0-9]+$/, "Only Numbers are allowed").required("Quantity is required"),
            pType: Yup.string().required().label("Accessories type").nullable(),
            //price: Yup.string().matches(/^[0-9]+$/, "Only Numbers are allowed").required("Price is required"),
            comment: Yup.string().required().label("comment")

        });
        return {
            accessories_item: [],
            accessoriesData: [{
                pType: "",
                brand: "",
                mnumber: "",
                quantity: "",
                comment: "",
                price: ""
            }],
            assetValidate,
            accessoriesObj: [],
            bflag: false,
            brandList: null

        };
    },
    mounted() {
        let _self = this
        console.log('self.$route.params', _self.$route.path)
        if (_self.$route.params.action == 'ask-quotation') {
            _self.bflag = true
        }
        _self.manufactureNameData();
    },
    computed: {
        accessoriesTypes: function () {
            let bTypes = localStorage.getItem("pt")
            let parsedData = bTypes && JSON.parse(bTypes)
            return parsedData && parsedData['2']
        }
    },
    methods: {
        addVendorAccessories: function () {
            let _self = this
            if (this.accessoriesData.pType && this.accessoriesData.brand && this.accessoriesData.mnumber && this.accessoriesData.quantity) {
                let accessoriesObj = {
                    pType: _self.accessoriesData.pType,
                    brand: _self.accessoriesData.brand,
                    mnumber: _self.accessoriesData.mnumber,
                    quantity: _self.accessoriesData.quantity,
                    comment: _self.accessoriesData.comment
                }
                if (!_self.bflag) {
                    accessoriesObj.price = _self.accessoriesData.price
                    accessoriesObj.cType = '2'
                }
                _self.accessories_item.push(accessoriesObj)
                _self.accessoriesData.pType = ''
                _self.accessoriesData.brand = ''
                _self.accessoriesData.mnumber = ''
                _self.accessoriesData.quantity = ''
                _self.accessoriesData.comment = ''
                _self.accessoriesData.price = ''

            } else {
                _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'please add accessories',
                    class: 'ams-error-danger'
                })
            }
        },
        manufactureNameData() {
            let _self = this
            _self.$store.dispatch("manufacture/MANUFACTURE_LIST").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.brandList = res.data
                }

            });
        },
        removeItem: function (i) {
            this.accessories_item.splice(i, 1)
        },
    }
};
</script>
