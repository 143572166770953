/**
 * @description Common Store Module
 * @author Fahid Mohammad
 */

export default {
    namespaced: true,
    state:{
        text: null,
        menu: null,
        loader: false,
		alertMessage: {
			msg: '',
            class: ''
		},
        display: true
    },
    mutations: {
        SET_ALERTMESSAGE: (state, alertMessage) => {
            state.alertMessage = alertMessage
        },
        SET_MENU: (state, menu) => {
            state.menu = menu
        },
        SET_LOADER: (state, loader) => {
            state.loader = loader
        },
        SET_DISPLAY: (state, display) => {
            state.display = display
        }
    },
    actions: {
        SET_ALERTMESSAGE( { commit } , payload) {
            commit('SET_ALERTMESSAGE', payload);
        },
        SET_MENU({ commit }, payload) {
            commit('SET_MENU', payload);
        },
        SET_LOADER({ commit }, payload) {
            commit('SET_LOADER', payload)
        },
        SET_DISPLAY({ commit }, payload) {
            commit('SET_DISPLAY', payload)
        }
    },
    getters:{
        getLocale: state => {
            return state.locale
        },
        getAlertMessage: state => state.alertMessage,
        getMenu: state => state.menu,
        getLoader: state => state.loader,
        getDisplay: state => state.display
    },
}
