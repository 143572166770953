<template>
<div class="ams-card ams-expend">
    <div class="ams-graph-tit">
        <h4 class="ams-value">Expenditure</h4>
            <Multiselect class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="This month"></Multiselect>
    </div>

    <apexchart type="donut" width="250" height="250" :options="chartOptions" :series="series" class="chart-graph"></apexchart>
    <ul class="ams-current ams-custom-list">
        <li>
            <i class="ams-circ ams-brd-blue"></i>
            <div class="custom-cnt">
                <span class="sa-custom-cnt-tit">PO Cost</span>
                <span v-if="exp" class="sa-custom-per">{{exp.po}}</span>
            </div>
        </li>
        <li>
            <i class="ams-circ ams-violet"></i>
            <div class="custom-cnt">
                <span class="sa-custom-cnt-tit">Repair & Maintenance</span>
                <span v-if="exp" class="sa-custom-per">{{exp.rm}}</span>
            </div>
        </li>
        <li>
            <i class="ams-circ ams-drk-pink"></i>
            <div class="custom-cnt">
                <span class="sa-custom-cnt-tit">Scrapped Item Value</span>
                <span v-if="exp" class="sa-custom-per">{{exp.sc}}</span>
            </div>
        </li>
    </ul>
    <!-- <subscribe/> -->
</div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import Multiselect from '@vueform/multiselect'
// import Subscribe from '../pro/Subscribe.vue';
export default {
    computed: {
        series: function () {
            if (this.exp) {
                return [parseInt(this.exp['po'].split(' ')[1]), parseInt(this.exp['rm'].split(' ')[1]), parseInt(this.exp['sc'].split(' ')[1])]
            }
            return [0, 0, 0]
        }
    },
    props: ["exp"],
    components: {
        apexchart: VueApexCharts,
        Multiselect,
        // Subscribe,
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: "donut",
                },
                stroke: {
                    width: 0,
                },
                colors: ["#7A00A4", "#00BDFF", "#B8005D"],
                legend: {
                    show: false,
                },
                animations: {
                    enabled: true,
                    easing: "easeinout",
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150,
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350,
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "75%",
                            labels: {
                                show: true,
                                value: {
                                    show: true,
                                    fontSize: "18px",
                                    fontWeight: 600
                                },
                                total: {
                                    showAlways: true,
                                    show: true,
                                    label: "Total Cost",
                                    fontSize: "14px",
                                    color: "#88909C"
                                },
                            },
                        },
                    },
                },
                labels: ["PO Cost", "Repair & Maintenance", "Scrapped Item Value"],
                dataLabels: {
                    enabled: false,
                },
                responsive: [{
                        breakpoint: 376,
                        options: {
                            chart: {
                                height: 240,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },

                    },
                    {
                        breakpoint: 321,
                        options: {
                            chart: {
                                height: 185,
                            },

                        },
                    },
                ],
            },
        };
    },
    watch: {
        exp: {
        immediate: true,
        handler () {
            let self = this
            if (self.exp) {
            let cur = this.exp['po'].split(' ')[0]
            self.chartOptions.plotOptions.pie.donut.labels.total.formatter = function (w) {
                return cur + " " + w.globals.seriesTotals.reduce((a, b) => {
                    let val = parseInt(a + b)
                    return val
                    }, 0).toLocaleString()
                }
            }
        }
        }
    }
};
</script>
