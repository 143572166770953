<template>
    <div>
        
        <h3 class="ams-tit ams-service"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/tickets')"></i> Service Tickets - {{tobj.tid}} </h3>
        <!-- <form class="ams-card"> -->
        <Form :validation-schema="vendorValidate" v-slot="{ handleSubmit }" class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Ticket number</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" v-model="tobj.tid" placeholder="" name="software" id="software" disabled/>
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Raised by employee Id</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" v-model="tobj.emp_id" placeholder="" name="version" id="version" disabled/>
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Date of ticket generation</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" v-model="tobj.cDate" placeholder="" name="version" id="version" disabled/>
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Assets/Accessory/License name</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" v-model="tobj.prod_name" placeholder="" name="version" id="version" disabled/>
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Assets/Accessory/License tag</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" v-model="tobj.prod_tag" placeholder="" name="version" id="version" disabled/>
                    </div>
                </div>
            </div>
            <ticket-activity v-if="tobj && tobj.tck_history" :activitydata="tobj.tck_history"  />
            <!-- <assets-accessory-details />
            <license-details /> -->
            <ticket-raised v-if="tflag" :desc="desc" :pname="pname"/>
            <priority ref="priRef" :prefData="prefData"/>
            <expected-time ref="expRef" :exptData="exptData"/>
            <close-ticket ref="closeRef" :closData="closData"/>
            <h3 class="ams-tit">Assign Ticket</h3>
            <div  class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div  class="ams-form-group">
                    <Multiselect v-model="assignee" :options="userList"  class="ams-form-control ams-custom-control-dark" :disabled="dflag" placeholder="Sub User Id"></Multiselect>
                </div>
            </div>
                    <div class="ams-table-filter">
                        <button class="ams-btn ams-btn-danger" @click="ticketDetails" v-if="!dflag">Cancel</button>
                        <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(updateTicket)" v-if="!dflag">Save</button>
                        <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="updateStatus" v-if="dflag">Reopen</button>
                    </div>
            <assign-ticket v-if="AssetFlag"/>
        <!-- </form> -->
        </Form>
    </div>
</template>
<script>
// import AssetsAccessoryDetails from "../../components/tickets/AssetsAccessoryDetails.vue"
// import LicenseDetails from "../../components/tickets/LicenseDetails.vue"
import TicketRaised from "../../components/tickets/TicketRaised.vue"
import Priority from "../../components/tickets/Priority.vue"
import ExpectedTime from "../../components/tickets/ExpectedTime.vue"
import CloseTicket from "../../components/tickets/CloseTicket.vue"
import AssignTicket from "../../components/tickets/AssignTicket.vue"
import Multiselect from '@vueform/multiselect'
import TicketActivity from "../../components/tickets/TicketActivity.vue";
import { Form } from 'vee-validate';
import * as Yup from "yup";

export default {
	data() {
        const vendorValidate = Yup.object().shape({
            expectedtime: Yup.string().required("Please Select Expected Time").nullable(),
            priority: Yup.string().required("Please Select Priority").nullable()
        });
		return {
            // options: [{
            //     value: 'R1234',
            //     name: 'RUPAM'
            // },{
            //     value: 'N1234',
            //     name: 'NAHL'
            // }],
            dflag: false,
            // options:{
            //     USER_NAME:[]
            // },
            AssetFlag: false,
            tobj: { 
                tid: '',
                emp_id: '',
                cdate: '',
                prod_tag: '',
                prod_name: '',
                desc: ''
            },
            assignee: '',
            desc: '',
            pname: '',
            tflag: false,
            prefData: null,
            exptData: null,
            closData: {},
            userList: null,
            vendorValidate
		};
	},
    mounted () {
        let self = this
        self.getDetails()
        let query = self.$route.params
        if (query.tabname === 'close') {
            self.dflag = true
        }
        let _self = this
            _self.$store.dispatch("settings/ASSIGN_USER").then(function (res) {
                if (res && res.status == 1000) {
                    _self.userList = res.data
                }
                // else {
                //     _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                //         msg: "Technical error",
                //         class: "ams-error-danger",
                //     });
                // }
            });
    },
    methods: {
        updateTicket() {
            let self = this
            let query = self.$route.params
            let priRef = self.$refs['priRef']
            console.log('priRef', priRef)
            let expRef = self.$refs['expRef']
            console.log('expRef', expRef)
            let closeRef = self.$refs['closeRef']
            console.log('closeRef', closeRef)
            let reqObj = {
                tid: query.prodid,
                priority: priRef.priority,
                expt_time: expRef.exp_time,
                // assignee: self.assignee,
                // iss_status: closeRef.iss_status,
                tck_status: closeRef.iss_status ? "CLOSE" : "PENDING"
            }
            if(closeRef.iss_status) {
                reqObj.iss_status = closeRef.iss_status
            }
            if (closeRef.comment) {
                reqObj.comment = closeRef.comment
            }
            if(self.assignee) {
                reqObj.assignee = self.assignee
            }
            console.log('req', reqObj)
            self.$store.dispatch('ticket/UPDATE_TICKET', reqObj).then(function (res) {
                console.log('RES', res)
                if (res && res.status == 1000) {
                    self.$router.push('/tickets')
                    self.$parent.getData('PENDING')
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Ticket Updated Successfully',
                        class: 'ams-error-success'
                    })
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical Error',
                        class: 'ams-error-danger'
                    })
                }
            })
            // this.AssetFlag= !this.AssetFlag;
        },
        getDetails () {
            console.log('GET DETAILS')
            let self = this
            let query = self.$route.params
            console.log('tid', self.$route)
            self.$store.dispatch('ticket/FETCH_TICKET_INFO', {
                tid: query.prodid
            }).then(function (res) {
                console.log('RES', res)
                if (res && res.status == 1000) {
                    self.tflag = true
                    self.tobj = res.data
                    self.desc = self.tobj.desc
                    self.pname = self.tobj.prod_name
                    if(self.tobj && self.tobj.tInfo) {
                        self.prefData = self.tobj.tInfo.priority
                        self.exptData = self.tobj.tInfo.expt_time
                        console.log(self.exptData,'self.exptData');
                        self.closData = self.tobj.tInfo
                        self.assignee = self.closData.assignee
                        
                     }
                    let query = self.$route.params
                    if (query.tabname === 'reopen') {
                        self.prefData = 'High'
                        self.exptData = null
                        let obj = {
                            iss_status: '',
                            comment: ''
                        }
                        self.closData = obj
                    }
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical Error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        updateStatus () {
            let self = this
            let query = self.$route.params
            let reqObj = {
                tid: query.prodid,
                tck_status: "REOPEN"
            }
            self.$store.dispatch('ticket/UPDATE_STATUS', reqObj).then(function (res) {
                console.log('RES', res)
                if (res && res.status == 1000) {
                    self.$router.push('/tickets')
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Ticket Updated Successfully',
                        class: 'ams-error-success'
                    })
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical Error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        ticketDetails:function(){
        this.$router.push('/tickets')
        }
    },
    components: {
        Multiselect,
        // AssetsAccessoryDetails,
        // LicenseDetails,
        TicketRaised,
        Priority,
        ExpectedTime,
        CloseTicket,
        AssignTicket,
        TicketActivity,
        Form
	}
};
</script>