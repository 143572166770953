<template>
<div class="ams-content">
    <role-management v-if="$route.params.tabname === 'role' && ($route.params.action === 'add' || $route.params.action === 'edit')" />
    <create-sub-user v-if="$route.params.tabname === 'user' && ($route.params.action === 'add' || $route.params.action === 'edit')" />
    <div v-if="!$route.params.action" class="ams-row ams-mb20">
        <div class="ams-col8 ams-col-md6 ams-col-sm6 ams-col-xs6">
            <h3 class="ams-tit">Users</h3>
        </div>
        <div v-if="tabSelection==='user'" class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs6 ams-text-right">
            <button class="ams-btn ams-btn-primary" @click="$router.push('/users/user/add')">Create User</button>
        </div>
        <div class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs6 ams-text-right" v-if="tabSelection==='role'">
            <button v-if="$route.params.action!='add' && $route.params.action!= 'edit'" class="ams-btn ams-btn-primary" @click="$router.push('/users/role/add')">Add Role</button>
        </div>
    </div>
    <div class="ams-row" v-if="tabSelection==='role'">
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div v-if="$route.params.action!= 'add'  && $route.params.action!= 'edit'" class="ams-form-group">
                <input type="text" @input="roleFilter" v-model="role_name" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Role Name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
    </div>
    <div class="ams-row" v-if="tabSelection==='user'">
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div  v-if="$route.params.action!= 'add' && $route.params.action!= 'edit'" class="ams-form-group">
                <input type="text" @input="userFilter" v-model="user_name" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder=" Search User Name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
    </div>
    <div v-if="!$route.params.action" class="ams-row">
        <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <div class="ams-selector" @click="selectDropDown" :class="amsdropdownview? 'ams-active': ''">
                {{ tabSelection }} <i class="icon-downarrow ams-form-icon"></i>
            </div>
            <ul class="ams-tabs ams-cust-tabs" :class="amsdropdownview? 'ams-dropd-show': 'ams-dropd-hide'">
                <li @click="changeTab('user')" v-bind:class="[tabSelection === 'user' ? 'ams-active' : '']"><a><span>Users</span></a></li>
                <li @click="changeTab('role')" v-bind:class="[tabSelection === 'role' ? 'ams-active' : '']">
                    <a>
                        <span>Role Management
                            <!-- <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                                <p class="ams-tooltip ams-tooltip-info ams-tooltip-top-left">Unlock this screen with our pro version</p>
                            </i> -->
                        </span>
                    </a>
                </li>
            </ul>
        </div>

        <div>
            <!-- <add-new-field  @click="closefields" /> -->
        </div>
        <!-- <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-form-group">
                <input type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search subuser Id" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div> -->
    </div>
    <div v-if="!$route.params.action" class="ams-tab-cont">
        <!-- <customize v-if="tabSelection === 'custom'" /> -->
        <sub-user v-if="tabSelection === 'user'" ref="su" :user_name="user_name" />
        <role-assignments v-if="tabSelection === 'role'" ref="ra" :role_name="role_name" />
    </div>
    <!-- <role-assignment-category @roleView="roleView" /> -->
</div>
</template>

<script>
import SubUser from "../../components/settings/SubUser.vue"
import RoleAssignments from "../../components/settings/RoleAssignments.vue"
import RoleManagement from "../../components/User/RoleManagement.vue"
import CreateSubUser from "../../components/User/CreateSubUser.vue"

export default {
    components: {
        SubUser,
        RoleAssignments,
        RoleManagement,
        CreateSubUser,
    },
    data() {
        return {
            tabSelection: '',
            stid: 'ST0',
            t_apts: 0,
            t_inc: 0,
            t_comm: 0,
            visible: false,
            amsdropdownview: false,
            oflag: false,
            role_name: null,
            user_name: null,
            field_name: null
        };
    },
    mounted: function () {
        let self = this
        self.tabSelection = 'user'
        self.$router.push('/users/' + self.tabSelection)
    },
    methods: {
        tableApi() {
            let self = this
            self.$refs['fs'].fieldListing()
        },
        closefields: function () {
            console.log('calling')
            this.$emit('close-popup', false)
        },
        changeTab(val) {
            console.log(val, 'val')
            let _self = this
            _self.tabSelection = val
            _self.$router.push('/users/' + val)
        },
        selectDropDown() {
            this.amsdropdownview = !this.amsdropdownview
        },
        tabSelecstion(option) {
            this.value = option;
        },
        setAppData(t_apts, t_inc, t_comm) {
            this.t_apts = t_apts
            this.t_inc = t_inc
            this.t_comm = t_comm
        },
        roleFilter() {
            let roleRef = this.$refs['ra']
            if (!this.role_name.length) {
                roleRef.roleData()
                return
            }
            if (this.role_name.length >= 3) {
                roleRef.roleData('filter')
            }
        },
        userFilter() {
            let userRef = this.$refs['su']
            if (!this.user_name.length) {
                userRef.subuserData()
                return
            }
            if (this.user_name.length >= 3) {
                userRef.subuserData('filter')
            }
        },
        fieldFilter() {
            let fieldRef = this.$refs['fs']
            if (!this.field_name.length) {
                fieldRef.fieldListing()
                return
            }
            if (this.field_name.length >= 3) {
                fieldRef.fieldListing()
            }
        }
    }
};
</script>
