<template>
<div class="ams-card ams-card-border">  
      <h3 class="ams-tit">Payment Details</h3>
      <div class="ams-row ams-no-gutter">
        <div class="ams-col4">
              <i class="ams-sprite ams-wallet-icon ams-mr10"></i> Card payment
        </div>
        <div class="ams-col8 ams-text-right">
          Debit / Credit cards<i class="ams-sprite ams-pay-icon"></i>  
        </div>
      </div>
      <div class="ams-form-group ams-mt20 ams-d-f-a-center">
        <input type="radio" id="ccard" name="card" value="ccard" />
        <label for="ccard">Credit card</label>
        <input type="radio" id="dcard" name="card" value="dcard" />
        <label for="dcard" class="ams-ml30">Debit card</label>
      </div>
          <div class="ams-form-group">         
              <label>Card number</label>
              <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" />            
          </div>
          <div class="ams-form-group">
            <div class="ams-row ams-gutter10">    
              <div class="ams-col5">
                  <label>Name on card</label>
                  <input type="text" class="ams-form-control ams-custom-control-dark" placeholder=""/>
              </div>
              <div class="ams-col2 ">
                  <label class="">Expiry</label>
                  <input type="text" class="ams-form-control ams-custom-control-dark" placeholder=""/>       
              </div> 
              <div class="ams-col2">
                  <label class="">date</label>
                  <input type="text" class="ams-form-control ams-custom-control-dark" placeholder=""/>       
              </div>  
              <div class="ams-col3">
                  <label class="">CVV</label>
                  <input type="text" class="ams-form-control ams-custom-control-dark" placeholder=""/>       
              </div>                 
            </div> 
          </div>
     <button type="button" class="ams-btn ams-btn-primary ams-btn-block" @click="OpenAsset">Pay Rs.750</button>
  <payment-success v-if="AssetFlag"  @closePopup="closePopup" />
  </div>
</template>
<script>
import PaymentSuccess from "../pro/PaymentSuccess.vue"
export default {
  components: {
    PaymentSuccess
  },
  data() {
		return {
            AssetFlag: false
		};
	},
    methods: {
        OpenAsset() {
            this.AssetFlag= !this.AssetFlag;
        },
        
    },
};
</script>
