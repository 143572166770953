/**
 * @description Product Store Module
 * @author Harikrishna
 */

import axios from "../../util/axios";
export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        FETCH_PRODUCT_LIST({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/list', payload).then(function (res) {
                return res
            })
        },
        FETCH_PRODUCT_HISTORY({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/product/history?prod_tag=' + payload.prod_tag).then(function (res) {
                return res
            })
        },
        FETCH_LICENSE_INFO({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.get('/product/selectLicense').then(function (res) {
                return res
            })
        },
        FETCH_PRODUCT_FIELDS({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/product/fields?prod_id=' + payload.prod_id).then(function (res) {
                return res
            })
        },
        SAVE_PRODUCT({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/add', payload).then(function (res) {
                return res
            })
        },
        UPDATE_PRODUCT_PROPERTIES({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/update', payload).then(function (res) {
                return res
            })
        },
        UPDATE_PRODUCT_AUDIT({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/audit', payload).then(function (res) {
                return res
            })
        },
        UPDATE_PRODUCT_ASSIGN({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/assign', payload).then(function (res) {
                return res
            })
        },
        FETCH_PRODUCT_DETAILS({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/product/id?prod_tag=' + payload.prod_tag + '&pType=' + payload.pType,).then(function (res) {
                return res
            })
        },

        FETCH_PRODUCT_TAGS({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/product/select?pType=' + payload).then(function (res) {
                return res
            })
        },
        FETCH_LICENSE_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/license/table', payload).then(function (res) {
                return res
            })
        },
        FETCH_PRODUCT_TYPES({ commit }, payload) {
            console.log('commit', commit)
            console.log('pa', payload)
            return axios.post('/product/types', payload).then(function (res) {
                return res
            })
        },

        FETCH_ALL_PTAG({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/product/select?pType=' + payload + '&skip=true').then(function (res) {
                return res
            })
        },
        FETCH_EMPLOYEE_ID({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/product/empid', payload).then(function (res) {
                return res
            })
        },
        FETCH_TAGS({ commit},payload){
            console.log('commit', commit)
            return axios.get('/product/tags',payload).then(function (res) {
                return res
            })
        },
       
        DOWNLOAD_FILE({ commit},payload){
            console.log('download', payload, commit)
            return axios.get('/product/download?fname=' + payload.fname + '&fpath=' + payload.furl).then(function (res) {
                return res
            })
        },

        GENERATE_LABEL({ commit},payload){
            console.log('download', commit, payload)
            return axios.get('/product/generatetag?prod_tag=' + payload).then(function (res) {
                return res
            })
        },
        FILE_UPLOAD({ commit }, payload) {
            console.log('commit', commit, payload)
            return axios.post('/product/uploadCSV', payload,{ headers: { "Content-Type": "multipart/form-data" }}).then(function (res) {
                return res
            })
        }
    },
    getters: {
    }
}
