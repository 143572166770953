<template>

<div v-show="licenseFields && licenseFields.length">

    <h3 class="ams-tit">
        <!-- {{ $parent["productTypes"] && $parent["productTypes"][$parent["prod_id"]] }} -->
        <i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/licenses')"></i>
        License
    </h3>
    <Form :validation-schema="licenseSchema" v-slot="{ handleSubmit }">
    <div class="ams-card">
        <div class="ams-row">
            <div v-for="(a, i) in licenseFields" :key="i" class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div v-if="a.type==='text'" class="ams-form-group">
                    <label>{{ a.label }} <span v-if="a.mandatory" class="ams-red-text"> * </span> </label>
                    <Field :type="a.type" v-model="licenseObj[a.key]" @keydown="checkInput($event, a.input)" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" id="software" :disabled="disflag"/>
                    <ErrorMessage :name="a.key" class="ams-val-err" />
                </div>
                <div v-else-if="a.type === 'calendar'" class="ams-form-group ams-datepicker-md">
                    <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                    <!-- <datepicker v-model=licenseObj[a.key]  class="ams-form-control ams-custom-control-dark"></datepicker> -->
                    <datepicker v-model=licenseObj[a.key] class="ams-form-control ams-custom-control-dark" :maxDate="a.key === '111' ? new Date() : ''" :minDate="(a.key === '129') ? licenseObj['111'] : ''" :disabled="(((a.key === '129') && !licenseObj['111']) || (disflag && a.key !== '129')) ? true : false" :enableTimePicker="false" :placeholder="a.label" monthNameFormat="long" autoApply/>
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                        <Field type="text" v-model=licenseObj[a.key] v-show="false" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" />
                        <ErrorMessage :name="a.key" class="ams-val-err" />
                </div>
                <div v-else-if="a.type === 'dropdown'" class="ams-form-group">
                    <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                    <multiselect v-model=licenseObj[a.key] :options="options[a.key]" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :searchable="true" track-by="label" :disabled="(disflag || a.key === '115')"></multiselect>
                    <Field type="text" v-model=licenseObj[a.key] v-show="false" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" />
                    <ErrorMessage :name="a.key" class="ams-val-err" />
                </div>
                <div v-if="a.type === 'textarea'" class="ams-col12">
                    <div class="ams-form-group">
                        <label>{{a.label}} <span v-if="a.mandatory" class="ams-red-text"> * </span></label>
                        <Field type="text" v-model=licenseObj[a.key] @keydown="checkInput($event, a.input)" class="ams-form-control ams-custom-control-dark" :placeholder="a.label" :name="a.key" :id="a.key" :disabled="disflag"/>
                        <ErrorMessage :name="a.key" class="ams-val-err" />
                    </div>
                </div>
            </div>
        </div>
        <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/>
        <div v-else>
        <h3 class="ams-tit">Upload Invoice</h3>
         <div class="ams-row ams-mb20">
        <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
         <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />
         </div>
         </div>
        </div>
        <div class="ams-table-filter">
            <button v-if="vflag" class="ams-btn ams-btn-danger">
                Cancel
            </button>
            <button @click.prevent="handleSubmit(saveLicenseProperties)" v-if="vflag" class="ams-btn ams-btn-primary ams-ml20">Save</button>
        </div>
    </div>
    <license-assign-deassign ref="assignLicense" :flagTwo="vflag" :totalseat="totalseat"/>

    <div class="ams-table-filter">
        <button class="ams-btn ams-btn-danger" @click="$router.push('/licenses')" v-if="!vflag">
            Cancel
        </button>
        <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(saveLicense)" v-if="!vflag">Save</button>
        <!-- <button class="ams-btn ams-btn-outline-primary" @click="$router.push('/licenses')" v-if="flagTwo">
            Renew License
        </button> -->
        <!-- <button class="ams-btn ams-btn-primary ams-ml20" @click="saveLicense
        " v-if="flagTwo">Edit</button> -->
    </div>
    </Form>
</div>
</template>

<script>
// import Multiselect from "@vueform/multiselect";
// import Datepicker from 'vue3-datepicker'
import async from 'async'
import FileUpload from "../../components/common/FileUpload.vue"
import moment from "moment";
import LicenseAssignDeassign from '../../components/license/LicenseAssignDeassign.vue';
import { license_id } from '../../../config/endpoint.js'
import Multiselect from '@vueform/multiselect'
// import Datepicker from 'vue3-datepicker'
import Datepicker from 'vue3-date-time-picker';
import { Form,Field, ErrorMessage } from 'vee-validate'
import * as Yup from "yup";
import _ from 'lodash';
import UploadInvoice from "../common/UploadInvoice.vue"
import validationMixins from "../../mixins/validations";

export default {
    mixins: [validationMixins],
    props: ["empList", "flagTwo"],
    components: {
        LicenseAssignDeassign,
        Multiselect,
        Datepicker,
        FileUpload,
        Form,
        Field,
        ErrorMessage,
        UploadInvoice
    },
    data() {
        const licenseSchema = Yup.object().shape({});
        return {
            licenseFields: null,
            filterFlag: false,
            licenseObj: {
                '115': '111'
            },
            employees: [],
            emp_id: null,
            licenseSchema,
            auditDate: null,
            dop: null,
            assetStatus: null,
            vflag: false,
            options:{
                '120': [],
                '114': [],
                '115': { '111': 'Working', '118': 'Expired'} 
            },
            utext: 'Click to Add your file',
            fileData: {},
            totalseat: '',
            disflag: false 
        };
    },
    mounted() {
        let _self = this
        // _self.manufactureDropDown()
                    //   let _self = this
            _self.$store.dispatch("manufacture/MANUFACTURE_LIST").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('menu', res)
                    // this.options['MANUFACTURER_NAME'] = ['RUPAM']
                    _self.options['114'] = res.data
                }
            });
        this.$store
            .dispatch("vendor/LIST_VENDOR")
            .then(function (res) {

                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.options['120'] = res.data

                }
            });
        // view/edit license
        if (_self.$route.params.prodid) {
        //    this.disabled="true" 
            this.disflag=true
            _self.vflag = true // for individual save and cancel enable and disable
            _self.$store.dispatch('common/SET_LOADER', true) // loader enable
            async.series([
                _self.getProductDetails.bind(), // getting selected product/license
                _self.getFields.bind() // get license fields
            ], function () {
                _self.$store.dispatch('common/SET_LOADER', false) // loader disable
            })
        }
        // add licese
        else {
            _self.getFields()
        }
    },
    watch: {
        licenseFields: {
            immediate: true,
            handler () {
                let self = this
                let licenseObj = {};
                    console.log(self.licenseFields,"ppppp");
                    let allKey = _.keys(self.licenseObj)
                // _.each(self.licenseFields, (a) => {
                    self.licenseFields&&self.licenseFields.forEach(a =>{
                    console.log('e', a)
                    if (a.mandatory) {
                        licenseObj[a.key] = Yup.string().required('This field is Required').nullable()
                    }
                     if (a.type === 'calendar' && self.$route.params.prodid) {
                        let valueofarr = allKey.includes(a.key)
                        if(valueofarr) {
                            self.licenseObj[a.key] = new Date(self.licenseObj[a.key])
                        }
                    }
                })
                self.licenseSchema = Yup.object(licenseObj);
                console.log('licenseSchema', self.licenseSchema)
            }
        }
    },
    methods: {
        manufactureDropDown() {
            //   let _self = this
            // _self.$store.dispatch("manufacture/MANUFACTURE_LIST").then(function (res) {
            //     if (res && res.status == 1000) {
            //         console.log('menu', res)
            //         // this.options['MANUFACTURER_NAME'] = ['RUPAM']
            //         // _self.options['MANUFACTURER_NAME '] = res.data
            //     }
            // });

        },
        saveLicenseProperties: function () {
            let _self = this
            let query = _self.$route.params
            let licenseReq = {
                "prod_tag": query.prodid,
                "pType": query.ptype,
                "cType": "3",
                "cost": _self.licenseObj['112'],
                "info": _self.licenseObj,
                "prod_status": _self.licenseObj['115'],
                "dop": moment(_self.licenseObj['111']).format('YYYY-MM-DD'),
                "renewal_date": moment(_self.licenseObj['129']).format('YYYY-MM-DD')
            }
            
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('product/UPDATE_PRODUCT_PROPERTIES', licenseReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Successfully updated license properties',
                        class: 'ams-error-success'
                    })
                    _self.$router.push("/licenses");
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error while updating license properties',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        getFields() {
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store
                .dispatch("product/FETCH_PRODUCT_FIELDS", {
                    prod_id: license_id
                })
                .then(function (res) {
                    console.log('res', res)
                    _self.$store.dispatch('common/SET_LOADER', false)
                    if (res && res.status == 1000) {
                        _self.licenseFields = res.data.fields;
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical Error",
                            class: "ams-error-danger",
                        });
                    }
                });
        },
        getProductDetails: function (cb) {
            let _self = this
            let query = this.$route.params
            if (query.prodid && query.ptype) {
                _self.$store.dispatch('product/FETCH_PRODUCT_DETAILS', {
                    prod_tag: query.prodid,
                    pType: query.ptype
                }).then(function (res) {
                    console.log('lic RES', res)
                    if (res && res.status == 1000 && res.data) {
                        _self.licenseObj = (res.data && res.data.product && res.data.product.info) || {}
                        _self.totalseat = _self.licenseObj['166']
                        _self.fileData = (res.data && res.data.product && res.data.product.filedata) || {}
                        if (res.data && res.data.empInfo && res.data.empInfo.length) {
                            _self.$refs['assignLicense'].emp_id = res.data.empInfo
                            _self.$refs['assignLicense'].empName = res.data.empInfo
                        }
                        return cb()
                    } else {
                        _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Technical Error',
                            class: 'ams-error-danger'
                        })
                        return cb()
                    }
                })
            }
        },
        changeAsssetStatus: function (st) {
            this.assetStatus = st
        },

        setEmpId: function (id) {
            this.emp_id = id;
        },
        saveLicense: function () {
            let _self = this;
            let emp_id = _self.$refs["assignLicense"].emp_id
            console.log('jkjk', emp_id.length)
            if (parseInt(_self.licenseObj['166']) >= emp_id.length) {
                let productReq = {
                    pType: "126",
                    cType: "3",
                    cost: _self.licenseObj['112'],
                    info: _self.licenseObj,
                    emp_ids: emp_id,
                    dop: moment(_self.licenseObj['111']).format('YYYY-MM-DD'),
                    // audit_date: '2021-09-16' && moment('2021-09-16').format('YYYY-MM-DD'),
                    prod_status: _self.licenseObj['115'],
                    renewal_date: moment(_self.licenseObj['129']).format('YYYY-MM-DD'),
                    seats: parseInt(_self.licenseObj['166']),
                    filedata: (_self.fileData) ? _self.fileData : {}
                };
                // alert("nahl")
                console.log('req', productReq)
                _self.$store.dispatch("common/SET_LOADER", true);
                this.$store.dispatch("product/SAVE_PRODUCT", productReq).then(function (res) {
                    //  alert('fff')
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Product added successfully",
                            class: "ams-error-success",
                        });
                        _self.$router.push("/licenses");
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });
            } else {
                _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    msg: "Employee is more than seat",
                    class: "ams-error-danger",
                });
            }
        },
    },
    
};
</script>
