<template>
    <div class="ams-content">
        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/settings')"></i> Settings - Role assignment</h3>
        <div class="ams-card">
            <div class="ams-setting-spacing">
                    <ul class="ams-custom-list ams-role-assign" v-for="item in 5" :key="item">
                    <li>
                        <i class="ams-circ ams-sqr-bg-yellow"></i>
                        <span>Asset Category</span>
                    </li>
                    <li>
                        <i class="icon-tick ams-blue-text"></i>
                        <label> View</label>
                    </li>
                    <li>
                        <i class="icon-tick ams-blue-text"></i>
                        <label>Add</label>
                    </li>
                    <li>
                        <i class="icon-tick ams-blue-text"></i>
                        <label for="Allocate">Allocate/ deallocate</label>
                    </li>
                    <li>
                        <i class="icon-tick ams-blue-text"></i>
                        <label for="None">None</label>
                    </li>
                </ul>                 
            </div>
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-primary" @click="$router.push('/role-assignment-category-edit')">Edit</button>
            </div>
        </div>
    </div>
</template>
<script>
export default ({
    components: {
	},
    data() {
      return{
      }  
    },
    methods: {
       
    }
})
</script>
