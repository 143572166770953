<template>
<div class="ams-content">
    <h3 class="ams-tit">Depreciation</h3>
    <!-- <depreciation-assets /> -->
    <depreciation-assets-det ref="depref" />
    <depreciation-assets-proceed v-if="show" @cancel-popup="cancel()" />
</div>
</template>

<script>
//import DepreciationAssets from "../../components/depreciation/DepreciationAssets.vue"
import DepreciationAssetsDet from "../../components/depreciation/DepreciationAssetsDet.vue"
import DepreciationAssetsProceed from "../../components/depreciation/DepreciationAssetsProceed.vue"
import moment from 'moment'

export default {
    components: {
        // DepreciationAssets,
        DepreciationAssetsDet,
        DepreciationAssetsProceed

    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        cancel() {
            let self = this
            self.show = false
            let ref1 = self.$refs['depref']
            ref1.pType = null
            ref1.depper = ''
            ref1.mcost = ''
            ref1.savlCost = null
            ref1.clearPtype()
        },
        saveScraped: function () {
            let _self = this;
            let scrapReq = {
                prod_tag: this.$refs['depref'].pTag,
                dos: moment().format('YYYY-MM-DD'),
                comment: "Scrapped",
            };
            _self.$store.dispatch("common/SET_LOADER", true);

            this.$store
                .dispatch("inventory/SAVE_SCRAPED", scrapReq)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Scraped Item Added successfully",
                            class: "ams-error-success",
                        });
                        //  _self.$router.push('/depreciation')
                        _self.cancel()
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: "Technical error",
                            class: "ams-error-danger",
                        });
                    }
                });
        }
    }

};
</script>
