<template>
    <transition>
        <div class="ams-file-upload">
            <template v-if="showUpload">
                <input type="file" ref="file" @change="onFileChange" @input-file="(x,y,z)=>inputFile(x,y,z)" class="ams-d-none" id="filename" name="filename" :data="{uploadtype:'profilepic'}" post-action="https://imgpre.litedev.com/upload"  v-if="!loader" />
                <input type="file" ref="file" @change="onUpload" @input-file="(x,y,z)=>inputFile(x,y,z)" class="ams-d-none" id="filename" name="filename" :data="{uploadtype:'profilepic'}" :post-action="ams_hub + '/emp/uploadCSV'" v-if="!loader && uploadFile == 'Multiupload'" />
                <input type="file" ref="file" @change="onProductUpload" @input-file="(x,y,z)=>inputFile(x,y,z)" class="ams-d-none" id="filename" name="filename" :data="{uploadtype:'profilepic'}" :post-action="ams_hub + '/product/uploadCSV'" v-if="!loader && uploadFile == 'Multiuploadproduct'" />
                <!-- <div v-if="uploadLogo" class="ams-file-attachment" @click="selectFile">
                    <i class="icon-folder"></i> Click to add your file 
                </div>
                <div v-else-if="!uploadLogo" class="ams-file-attachment" @click="selectFile">
                    <i class="icon-folder"></i> Add Company Logo 
                </div> -->
                <div class="ams-file-attachment" @click="selectFile">
                    <i class="icon-folder"></i> {{disText}} 
                </div>
                <img src="/static/img/loader.svg" alt="loader" class="ams-loader" v-if="loader" />
            </template>
            <ul class="ams-upload-list">
                <li class="" v-for="(filedata, index) in files" :key="index">
                    <a @click="imgGal(filedata.url)" v-if="isImage(getFileExtension(filedata.file_nme))">
                        <figure><img :src="filedata.url" class="ams-img-cover" alt="avtar"/></figure>
                        <span v-if="showName">{{ filedata.file_nme }}</span>
                    </a>
                    <!-- <a :href="filedata.url" target="_blank" download v-else>
                        <i :class="`icon-${getFileExtension(filedata.file_nme).toLowerCase()}`" class="ams-attach-icon"></i>
                        <span v-if="showName">{{ filedata.file_nme }}</span>
                    </a> -->
                    <a :href="filedata.url" target="_blank">
                        <i :class="`icon-${getFileExtension(filedata.file_nme).toLowerCase()}`" class="ams-attach-icon"></i>
                        <span v-if="showName">{{ filedata.file_nme }}</span>
                    </a>
                    <button class="ams-delete ams-btn-transparent" @click="removeFile(filedata)" v-if="showDelete">
                        <i class="icon-delete-1"></i>
                    </button>
                </li>
            </ul>
            <image-gallery v-show="imgGalPop" :imageUrl="imageUrl" v-on:close-pop="closePop"/>
        </div>
    </transition>
</template>
<script>
    import axios from "axios";
    import _ from 'lodash';
    import ImageGallery from "./ImageGallery.vue";
    export default {
        components: {
           "image-gallery": ImageGallery,
        },
        props: ["fileData", "showUpload", "showDelete", 'showName', 'companyLogo', 'fileUpload', 'disText', 'uploadFile', 'pType', 'cType'],
        data() {
            return {
                icon_addTreat: null,
                file: "",
                fileName: "",
                loader: false,
                valueadded:0,
                imgGalPop: false,
                imageUrl: null,
                // uploadLogo: false,
                uploadfiles: true
            };
        },
        computed: {
            files: function () {
                return this.fileData && this.fileData.filter(_=>_ && _.url);
            }
        },
        methods: {
            async onUpload (e) {
                let self = this
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                console.log(e, 'e')
                console.log(files, 'filesdta')
                this.fileName = files[0].name;
                    this.loader = true;
                    this.icon_addTreat = files[0].name.split(".")[1] || null;
                    this.createImage(files[0], this.fileName);
                    const formData = new FormData();
                    formData.append("file", files[0]);
                    formData.append("data", { uploadtype: "doc" });
                    console.log(formData, 'formData')
                    this.$store.dispatch("employee/FILE_UPLOAD", formData)
                        .then(function (response) {
                            console.log('RES', response)
                            if (response && response.status === 1000) {
                                this.loader = false;
                                self.$store.dispatch('common/SET_ALERTMESSAGE', {
                                    msg: 'Employee Updated Successfully',
                                    class: 'ams-error-success'
                                })
                            } else {
                                this.loader = true;
                                self.$store.dispatch('common/SET_ALERTMESSAGE', {
                                    msg: 'Employee Not Added',
                                    class: 'ams-error-danger'
                                })
                            }
                        })
            },
            async onProductUpload (e) {
                let self = this
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                console.log(e, 'e')
                console.log(files, 'filesdta')
                this.fileName = files[0].name;
                    this.loader = true;
                    this.icon_addTreat = files[0].name.split(".")[1] || null;
                    this.createImage(files[0], this.fileName);
                    const formData = new FormData();
                    formData.append("file", files[0]);
                    formData.append("data", JSON.stringify({ cType: self.cType,  pType: self.pType}));
                    console.log(formData, 'formData')
                    this.$store.dispatch("product/FILE_UPLOAD", formData)
                        .then(function (response) {
                            console.log('RES', response)
                            if (response && response.status === 1000) {
                                self.loader = false;
                                self.$store.dispatch('common/SET_ALERTMESSAGE', {
                                    msg: 'Product Updated Successfully',
                                    class: 'ams-error-success'
                                })
                            } else {
                                self.loader = true;
                                self.$store.dispatch('common/SET_ALERTMESSAGE', {
                                    msg: 'Product Not Added',
                                    class: 'ams-error-danger'
                                })
                            }
                        })
            },
            imgGal(url) {
                this.imgGalPop = !this.imgGalPop;
                this.imageUrl = url;
            },
            closePop() {
                this.imgGalPop = false
            },
            getFileExtension(fileName) {
                if(!fileName){
                    return '';
                }
                let fileType = fileName.split(".").pop();
                return fileType;
            },
            isImage(ext) {
                if(!ext){
                    return false;
                }
                const imgFtypes = ["jpg", "jpeg", "png", "gif", "bmp", "tif", "tiff", "eps","svg","raw","jfif"]
                return imgFtypes.indexOf(ext) !== -1;
            },
            selectFile() {
                this.$refs.file.click();
            },
            inputFile(newFile, oldFile) {
                // if (newFile && newFile.response && newFile.response.data && newFile.response.data[0] && newFile.response.data[0] && newFile.response.data[0].url) {
                // }
                    this.valueadded += 1
                if (newFile && !oldFile) {
                    this.$nextTick(function () {
                        this.edit = true;
                    });
                }
                if (!newFile && oldFile) {
                    this.edit = false;
                }
            },
            async onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                console.log(e, 'e')
                console.log(files, 'filesdta')
                this.fileName = files[0].name;
                // if(true) { //this.checkExisstingData(files[0].name)
                    this.loader = true;
                    this.icon_addTreat = files[0].name.split(".")[1] || null;
                    this.createImage(files[0], this.fileName);
                    // this.$parent.setImg(files[0].type)
                    const formData = new FormData();
                    formData.append("filename", files[0]);
                    formData.append("data", { uploadtype: "doc" });
                    console.log(formData, 'formData')
                    // const _self = this;
                    let response = await axios.post("https://imgpre.litedev.com/upload", formData, { headers: { "Content-Type": "multipart/form-data" } });
                    if (response && response.data && response.data.data && response.data.data[0] && response.data.data[0].url) {
                        this.$emit("file-upload-success", response.data.data[0]);
                        this.loader = false;                        
                    }
                // }
            },
            checkExisstingData(val){
                if(this.fileData && this.fileData.length > 0){
                    let values = []
                    _.map(this.fileData,function (v){
                    values.push(v.file_nme)
                    })
                    let value = values.includes(val)
                    return !value;
                }else{
                    return true
                }
            },
            createImage(file) {
                this.valueadded +=1
                // var image = new Image();
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.file = e.target.result;
                    console.log(this.file, 'this.file')
                    this.$emit("change", this.file);
                };
                reader.readAsDataURL(file);
            },
            removeFile(filedata) {
                this.$emit('remove-file',filedata)
            },
        },
    };
</script>
