<template>
        <div class="ams-row">
            <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
                <div class="ams-card-shadow ams-upload-inv">
                    <div class="ams-invoice">Invoice
                        <i class="ams-close icon-close" @click="invoiceClose()"></i>
                    </div>
                    <div class="ams-invoice-upl">
                        <span> {{fileData.filename}}</span>
                        <a :href="fileData.url" download target="_blank"><i class="icon-download"></i></a>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

export default {
    props: ['fileData'],
    methods: {
        invoiceClose () {
            let self = this
            self.$emit('invoice-close', {})
        }
    },
}
</script>
