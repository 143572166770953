<template>
<div class="ams-card ams-card-border ams-mb50"> 
    <h3 class="ams-tit">User Details</h3>   
    <ul class="ams-pay-det-list">
    <li>Name : <strong>Micheal Faradeh</strong></li>            
    <li>Company Name : <strong>Logitech Solutions . Pvt . Ltd</strong></li>            
    <li>Designation : <strong>IT Admin</strong></li>   
    <li>Email Id : <strong>itadmin@logitechsolutions.com</strong></li>
    <li>Contact Number :<strong> +91 9765 433 424</strong></li>         
    </ul>
</div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
