/**
 * @description dashboard Store Module
 * @author Harikrishna
 */

import axios from "../../util/axios";
export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        DASHBOARD_DATA({ commit }) {
            console.log('commit', commit)
            return axios.get('/dashboard/data').then(function (res) {
                return res
            })
        }
    },
    getters: {
    }
}
