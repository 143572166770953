<template>
	<div class="ams-content">
        <assets-history-table :assetList="assetList" />
		<!-- <assets-history-details /> -->
	</div>
</template>
<script>
import AssetsHistoryTable from "../../components/assetshistory/AssetsHistoryTable.vue"
//import AssetsHistoryDetails from "../../components/assetshistory/AssetsHistoryDetails.vue"

export default {
	components: {
		AssetsHistoryTable,
       // AssetsHistoryDetails

	},
	data() {
		return {
			assetList: [],
            page: 1
		};
	},
	methods: {
	},
	mounted: async function() {
		let _self= this
		// _self.$store.dispatch("common/SET_LOADER",true)
		let response = await _self.$store.dispatch("product/FETCH_PRODUCT_LIST",{
			page: 1,
			cType: "1",
			limit:10

		})
		//console.log("responseeeee",response);

		_self.$store.dispatch("common/SET_LOADER", false)
            if (response && response.status == 1000) {
                _self.assetList = response.data.products
                console.log("assetlist", response.data);
               
            } 
			// else {
            //     _self.$store.dispatch('common/SET_ALERTMESSAGE', {
            //         msg: 'Something went wrong while fetching assets',
            //         class: 'ams-error-danger'
            //     })
            // }


	},
};
</script>
