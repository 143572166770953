<template>
<Form class="ams-auth-form" :validation-schema="accessoryAudit" v-slot="{ handleSubmit }">
    <div class="ams-dialog">
    <div class="ams-dialog-body ams-db-md">
    <i class="ams-close ams-close-circle icon-close-circ" @click="closePop"></i>
        <h3 class="ams-tit">Audit Details</h3>
        <!-- <h3 class="ams-tit">Miscellaneous</h3> -->
        <div class="ams-row">
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Audited By <span class="ams-red-text"> * </span></label>
                    <Field type="text" class="ams-form-control ams-custom-control-dark" v-model="auditObj.audit_by" placeholder="Audited By" name="auditby" id="auditby" />
                    <ErrorMessage name="auditby" class="ams-val-err" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group ams-datepicker-md">
                    <label>Audit Date <span class="ams-red-text"> * </span></label>
                    <!-- <datepicker v-model="auditObj.audit_date" name="auditdate" class="ams-form-control ams-custom-control-dark">
                    </datepicker> -->
                    <datepicker v-model="auditObj.audit_date" name="auditdate" class="ams-form-control ams-custom-control-dark" placeholder="Audit Date" :enableTimePicker="false" monthNameFormat="long" autoApply/>
                    <Field type="calender" v-model="auditObj.audit_date" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="auditdate" />
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                    <ErrorMessage name="auditdate" class="ams-val-err" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Audit Status <span class="ams-red-text"> * </span></label>
                    <Multiselect name="auditstatus" v-model="auditObj.audit_status" placeholder="Audit Status" :options="assetStatusList" class="ams-form-control ams-custom-control-dark"></Multiselect>
                     <Field type="auditstatus" v-model="auditObj.audit_status" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="auditstatus" />
                    <ErrorMessage name="auditstatus" class="ams-val-err" />
                </div>
            </div>
            <div class="ams-col12">
                <div class="ams-form-group">
                    <label>Audit Comment <span class="ams-red-text"> * </span></label>
                    <Field type="textarea" class="ams-form-control ams-custom-control-dark" placeholder="Audit Comment" v-model="auditObj.comment" name="auditcomment" id="auditcomment" />
                    <ErrorMessage name="auditcomment" class="ams-val-err" />
                </div>
            </div>
        </div>
        <div class="ams-table-filter">
            <button v-if="viewProduct" class="ams-btn ams-btn-danger" @click=sample>Cancel</button>
            <button v-if="viewProduct" class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(saveAuditDetails)">Save</button>
        </div>
    </div>
    </div>
</Form>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
// import Datepicker from 'vue3-datepicker'
import Datepicker from 'vue3-date-time-picker';
import moment from 'moment'

export default {
    components: {
        Multiselect,
        Datepicker,
        Form,
        Field,
        ErrorMessage,

    },
    props: ['viewProduct', 'auditData'],
    data() {
        const accessoryAudit = Yup.object().shape({
             auditdate: Yup.string().required("Please Select Audit Date").nullable(),
            auditby: Yup.string().matches(/^[aA-zZ\s]+$/, "Only Alphabets Are Allowed").required("Please Enter Audited By").nullable(),
            auditstatus: Yup.string().required("Please Select Audit Status").nullable(),
            auditcomment: Yup.string().required("Please Enter Audit Comment").nullable(),
        })

        return {
            auditObj: {
                audit_by: null,
                audit_status: null,
                audit_date: null,
                comment: null,
            },
            accessoryAudit,
            assetStatusList: ["Audited", "UnAudited"],
        }
    },
    methods: {
        sample(){
             this.$emit('close-pop', '')
        },
        saveAuditDetails: function () {
             let _self = this
            let productReq = {
                audit_date: _self.auditObj.audit_date && moment(_self.auditObj.audit_date).format('YYYY-MM-DD'),
                audit_status: _self.auditObj.audit_status,
                audit_by: _self.auditObj.audit_by,
                prod_tag: _self.$route.params.prodid,
                comment: _self.auditObj.comment
            }
            // _self.auditObj.audit_date = _self.auditObj.audit_date
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('product/UPDATE_PRODUCT_AUDIT', productReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Product Updated Successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/accessories')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        closePop:function(){
            this.$emit('close-pop', '')
        }
    },
    watch: {
        auditData: {
            immediate: true,
            handler () {
                let self = this
                if(self.auditData) {
                    self.auditObj = self.auditData
                }
            }
        }
    }
}
</script>
