<template>
<div class="ams-card">
    <Form :validation-schema="depreciationValidate" v-slot="{ handleSubmit }">
        <!-- <h3 class="ams-tit">Asset Details</h3> -->
        <div class="ams-row">
            <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-row">
                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Asset / Accessory name</label>
                            <Multiselect @change="fetchAssetTags" @clear="clearPtype" :options="productTypes" v-model="pType" class="ams-form-control ams-custom-control-dark" name="software" id="software" placeholder="Asset / Accessory name" :searchable="true" track-by="label"/>
                            <Field type="text" v-model="pType" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="Asset / Accessory name" name="assetName" />
                            <ErrorMessage name="assetName" class="ams-val-err" />
                        </div>
                    </div>
                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Asset / Accessory Tag</label>
                            <Multiselect :options="productTags" v-model="pTag" @clear="clearPtype" @change="depreciationDetails($event)" class="ams-form-control ams-custom-control-dark" name="software" id="software" placeholder="Asset / Accessory tag"/>
                            <Field type="text" v-model="pTag" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="Asset / Accessory tag" name="assetTag" />
                            <ErrorMessage name="assetTag" class="ams-val-err" />
                        </div>
                    </div>
                    <!-- <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Asset Id</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="#632367253613" name="licensetype" id="licensetype" />
                    </div>
                </div> -->
                    <!-- <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Device type</label>
                        <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="#632367253613" name="licensename" id="licensename" />
                    </div>
                </div> -->
                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Purchase price</label>
                            <Field type="text" v-model="depreciationList.cost" class="ams-form-control ams-custom-control-dark" placeholder="Asset purchase price" readonly="readonly" name="purchasePrice" id="licensetype" disabled/>
                            <ErrorMessage name="purchasePrice" class="ams-val-err" />
                        </div>
                    </div>
                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>DOP ( Date of Purchase )</label>
                            <Field type="text" v-model="dop" class="ams-form-control ams-custom-control-dark" placeholder="DOP ( Date of Purchase )" readonly="readonly" name="purchaseDate" id="licensetype" disabled/>
                            <ErrorMessage name="purchaseDate" class="ams-val-err" />
                        </div>
                    </div>
                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Maintenance cost spent</label>
                            <Field type="text" v-model="depreciationList.repaircost" class="ams-form-control ams-custom-control-dark" placeholder="Maintenance cost spent" name="cost" id="licensetype" disabled/>
                            <ErrorMessage name="cost" class="ams-val-err" />
                        </div>
                    </div>

                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Year of usage</label>
                            <Field type="text" v-model="totalyear" class="ams-form-control ams-custom-control-dark" placeholder="Year of usage" name="usage" id="licensename" disabled/>
                            <ErrorMessage name="usage" class="ams-val-err" />
                        </div>
                    </div>

                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Depreciation percentage</label>
                            <Field type="text" v-model="depper" class="ams-form-control ams-custom-control-dark" placeholder="Depreciation percentage" name="percentage" id="licensename" />
                            <ErrorMessage name="percentage" class="ams-val-err" />
                        </div>
                    </div>

                    <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Maintenance cost required</label>
                            <Field type="text" v-model="mcost" class="ams-form-control ams-custom-control-dark" placeholder="Maintenance cost required" name="maintenanceCost" id="licensename" />
                            <ErrorMessage name="maintenanceCost" class="ams-val-err" />
                        </div>
                    </div>
                </div>
                <button @click.prevent="handleSubmit(calSalvage)" class="ams-btn ams-btn-primary ams-btn-block">Find Salvage Value</button>
            </div>
            <div v-if="savlCost" class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <div class="ams-card ams-card-shadow ams-text-center ams-mt25">
                    <h3> {{brand}} </h3>
                    <p class="ams-f-size12">Model No. # {{pTag}} </p>
                    <figure>
                        <img src="https://tse1.mm.bing.net/th?id=OIP.KCnObHAq2rxjaRPL7q6D3gHaFc&pid=Api&P=0&w=220&h=163" width="212px">
                    </figure>
                    <div class="ams-alert ams-a-success ams-depre-msg">
                        <h3>Rs. {{savlCost}} </h3>
                        <h6 class="ams-font-normal">Salvage Value</h6>
                    </div>
                    <em v-if="mflag" class="ams-d-block ams-red-text">Maitenance cost is higher than salvage better to discard</em>
                </div>
            </div>
        </div>
    </Form>
</div>
</template>

<script>
import moment from "moment";
import Multiselect from '@vueform/multiselect';
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {

    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        const depreciationValidate = Yup.object().shape({
            assetName: Yup.string().required("Please Select Asset / Accessory Name").nullable(),
            assetTag: Yup.string().required("Please Select Asset / AccessoryTag").nullable(),
            // purchasePrice: Yup.string().required("Please Enter Price").nullable(),
            // purchaseDate: Yup.string().required("Please Enter Date").nullable(),
            // cost: Yup.string().required("Please Enter Cost").nullable(),
            // usage: Yup.string().required("Please Enter Year of Usage").nullable(),
            percentage: Yup.string().matches(/^[0-9\s]+$/,"Only Numbers Are Allowed").required("Please Enter Percentage").nullable(),
            maintenanceCost: Yup.string().matches(/^[0-9\s]+$/,"Only Numbers Are Allowed").required("Please Enter cost").nullable(),
        });
        return {
            pTag: null,
            pType: null,
            depreciationList: {},
            productTags: null,
            // productType: null,
            dop: null,
            totalyear: '',
            depper: '',
            mcost: '',
            savlCost: null,
            mflag: false,
            brand: '',
            depreciationValidate
        }

    },
    mounted() {
        let _self = this;
        let depreReq = {
            prod_tag: _self.$route.query.prod_tag,
            pType: _self.$route.query.pType
        };
        _self.$store.dispatch("common/SET_LOADER", true);

        this.$store
            .dispatch("product/FETCH_PRODUCT_DETAILS", depreReq)
            .then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    console.log("responseeee", res);
                    _self.depreciationList = res.data.product.info

                }
            });

    },
    methods: {
        clearPtype () {
            console.log('clearPtype')
            let self = this
            self.pTag = null
            self.depreciationList.cost = null
            self.dop = null
            self.depreciationList.repaircost = null
            self.totalyear = null
            //  self.depper = null
            //   self.mcost = null
        },
        calSalvage() {
            let self = this
            this.$parent.show=true
            let cost = parseFloat(self.depreciationList.cost)
            let depPercen = (parseFloat(self.depper) / 100)
            let years = parseFloat(self.totalyear)
            let mainCost = parseFloat(self.mcost)
            console.log('depPercen', depPercen)
            console.log('cost', cost)
            console.log('years', years)
            console.log('mainCost', mainCost)
            let cal1 = 1 - depPercen
            console.log('cal1', cal1)
            let cal2 = parseFloat(Math.pow(cal1, years).toFixed(2))
            console.log('cal2', cal2)
            let cal3 = cost * cal2
            console.log('cal3', cal3)
            let cal4 = cal3 + (0.6 * mainCost)
            console.log('cal4', cal4)
            self.savlCost = cal4
            if (mainCost > self.savlCost) {
                console.log('higher')
                self.mflag = true
            }
        },
        getDate: function (date) {

            return moment(date).format('DD-MM-YYYY');

        },
        fetchAssetTags: async function (pid) {
            let _self = this;
            let response = await this.$store.dispatch(
                "product/FETCH_ALL_PTAG", pid);
                console.log('RES TAG', response)
            if (response && response.data && response.data.length) {
                // _self.productType = pid;
                _self.productTags = response.data;
            } 
            // else {
            //     _self.productTags = []
            // }
            _self.clearPtype()
        },
        depreciationDetails(val) {
            let _self = this;
            console.log("valll", val);
            let depreReq = {
                prod_tag: val,
                pType: _self.pType
            };
            _self.$store.dispatch("common/SET_LOADER", true);

            _self.$store.dispatch("depreciation/DEPRECIATION_DATA", depreReq).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    console.log("responseeee", res);
                    _self.depreciationList = res.data
                    _self.brand = res.data.brand
                    _self.dop = moment(_self.depreciationList.dop).format('DD-MM-YYYY');
                    let dop = moment(_self.depreciationList.dop).format('YYYY-MM-DD')
                    let calYear = moment().diff(dop, 'years', true).toFixed(2);
                    _self.totalyear = calYear
                    console.log(_self.depreciationList, "_self.depreciationList ");
                }
            });
        }
    },
    computed: {
        productTypes: function () {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return { ... parsedPT["1"], ...parsedPT["2"] };
        }
    },

};
</script>
