<template>
<div class="ams-ast ams-card ">
    <div class="ams-dash-hdng">
        <span class="ams-d-f-a-center">
            <figure class="ams-avtar ams-avtar-md ams-mr15"><i class="icon-ticket"></i></figure> Service Tickets
        </span>
        <strong v-if="ticketData" class="h2">{{ticketData.total}}</strong>
    </div>
    <apexchart type="bar" height="180" :options="options" :series="series" class="ams-servicetickets"></apexchart>
    <ul class="ams-custom-list">
        <li>
            <i class="ams-sqr ams-brd-blue"></i>
            <span class="ams-custom-cnt">Open</span>
            <strong v-if="ticketData">{{ticketData.open}}</strong>
        </li>
        <li>
            <i class="ams-sqr ams-brd-red"></i>
            <span class="ams-custom-cnt">Closed</span>
            <strong v-if="ticketData">{{ticketData.closed}}</strong>
        </li>
        <li>
            <i class="ams-sqr ams-brd-yellow"></i>
            <span class="ams-custom-cnt">Pending</span>
            <strong v-if="ticketData">{{ticketData.pending}}</strong>
        </li>
        <li>
            <i class="ams-sqr ams-brd-green"></i>
            <span class="ams-custom-cnt">Reopen</span>
            <strong v-if="ticketData">{{ticketData.reopen}}</strong>
        </li>
    </ul>
    <!-- <subscribe /> -->
</div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
// import Subscribe from "../pro/Subscribe.vue"
export default {
    props: ['ticketData'],
    components: {
        apexchart: VueApexCharts,
        // Subscribe
    },
    data() {
        return {
            options: {
                 responsive: [{
              breakpoint: 1025,
              options: {
                chart: {
                  width: 200,
                },
              },  
            }],
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded',
                        colors: {
                            ranges: [{
                                color: ['#2E93fA', '#66DA26', '#546E7A', "#279579"]
                            }],
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [300, 200, 100],
                    labels: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: true,
                        color: '#F7F7F7'
                    },
                },
                yaxis: {
                    show: true,
                    axisBorder: {
                        show: true,
                        color: '#F7F7F7',
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        align: 'left',
                        offsetX: -14,
                    }
                },
                grid: {
                    show: false,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -1
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
        }
    },
    computed: {
        series: function () {
            if (this.ticketData) {
                return [{
                    name: 'Inflation',
                    data: [this.ticketData['open'], this.ticketData['closed'], this.ticketData['pending'], this.ticketData['reopen']]
                }]
            }
            return [{
                name: 'Inflation',
                data: [0, 0, 0, 0]
            }]
        }
    }
};
</script>
