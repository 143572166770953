<template>

	<div>
        <table class="ams-table" cellspacing="0" cellpadding="0">
           <thead v-if="licenseList && licenseList.length">
                <tr>
                    <th>S.No</th>
                    <th>Software Name</th>
                    <th>Version</th>
                    <th>Seats</th>
                    <!-- <th>License Name</th>
                    <th>License ID</th> -->

                    <th>Password</th>
                    <th>User Id</th>
                    <th class="ams-green-text">Renewal Date</th>
                </tr>
            </thead>
            <tbody> 
                <tr @click.prevent="viewProduct(a.prod_tag, a.prod_id)" v-for="(a, i) in licenseList" :key="i">
                    <td data-title="SI.NO">{{i + indexMethod(paginationData.page,paginationData.limit)}}</td>
                    <td data-title="Software Name">{{a.soft_name}}</td>
                    <td data-title="Version">{{a.ver}}</td>
                    <td data-title="Seats">{{a.seats}}</td>
                    <!-- <td data-title="License Name">{{a.lname}}</td>
                    <td data-title="License ID">{{a.license_id}}</td> -->
                    <td data-title="Password"> {{a.password}} </td>
                    <td data-title="user id">{{a.user_id}}</td>
                    <td data-title="Renewal Date" class="ams-green-text">{{getDate(a.renew_date)}}</td>
                </tr>
            </tbody>
        </table>
	</div>
</template>
<script>
import moment from "moment";
export default {
    props:["licenseList","paginationData"],

	components: {
	},
	data() {
		return {
		};
	},
    methods: {
         viewProduct: function (prod_tag, ptd) {
            this.$router.push('/licenses/edit/' + prod_tag + '/' + ptd)
        },
         getDate : function(date){
            return moment(date).format('DD-MMM-YYYY');

        },
         indexMethod(pageNumber, pageSize) {
            
            return pageNumber * pageSize - (pageSize - 1);
        },
    }
};
</script>
