<template>
<div class="ams-content">
    <!-- <company-details v-if="vflag && eventid['1241']" :profileData="profileData"/> -->
    <company-profile-details/>
    <!-- <div class="ams-row ams-gutter25"> -->
    <!-- <div v-if="vflag && eventid['1241'] && uflag" class="ams-col6 ams-col-md6 ams-col-sm12 ams-col-xs12">
        <profile-details :userData="userData" :profileData="profileData"/>
    </div> -->
        <!-- <div v-if="vflag && eventid['1241']" class="ams-col6 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <user-profile-detail :userData="userData" :profileData="profileData"/>
        </div>
        <div v-if="vflag && eventid['1241']" class="ams-col6 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <active-plan :packData="packData" />
        </div> -->

    <!-- </div> -->
</div>
</template>

<script>
// import CompanyDetails from "../../components/profile/CompanyDetails.vue"
import CompanyProfileDetails from "../../components/profile/CompanyProfileDetails.vue"
// import UserProfileDetail from "../../components/profile/UserProfileDetail.vue"
// import ProfileDetails from "../../components/profile/ProfileDetails.vue"
// import ActivePlan from "../../components/pro/ActivePlan.vue"
export default {
    components: {
        // CompanyDetails,
        CompanyProfileDetails,
        // UserProfileDetail,
        // ProfileDetails,
        // ActivePlan
    },
    data() {
        return {
            // sflag: false,
            // vflag: false,
            // profileData: null,
            // userData: null,
            // packData: null
        };
    },
    mounted() {
        // let self = this
        // console.log('mounted')
        // let userDetails = localStorage.getItem('userDetails')
        // console.log('company profile userDetails', (userDetails && (JSON.parse(userDetails).superuser)))
        // self.sflag = (userDetails && (JSON.parse(userDetails).superuser))
        // console.log('sflag', self.sflag)
        // if (self.eventid['1241']) {
        //     self.companyProfile();
        // }
    },
    // methods: {
    //     companyProfile: function () {
    //         let _self = this;
    //         _self.$store.dispatch("common/SET_LOADER", true);
    //         _self.$store.dispatch("profile/COMPANY_PROFILE", ).then(function (response) {
    //             console.log('responsessssssssss', response)
    //             _self.$store.dispatch("common/SET_LOADER", false);
    //             if (response && response.status === 1000) {
    //                 _self.profileData = response.data.companyInfo;
    //                 _self.packData = response.data.packInfo;
    //                 console.log('packdataaa', _self.packData)
    //                 _self.userData = response.data.userInfo;
    //                 console.log('userdataaa', _self.userData)
    //                 _self.vflag = true
    //             }
    //         });
    //     }
    // },
    // computed: {
    //     eventid() {
    //         return JSON.parse(localStorage.getItem("eventData"));
    //     }
    // }
};
</script>
