<template>
<div class="ams-row">
    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
        <div class="ams-form-group">
            <input @input="prodFilter" v-model="prod_tag" type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Asset Tag" />
            <i class="icon-search ams-form-icon ams-form-icon-right"></i>
        </div>
    </div>
    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
        <div class="ams-form-group">
            <input @input="empFilter" v-model="emp_id" type="text" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Employee Id" />
            <i class="icon-search ams-form-icon ams-form-icon-right"></i>
        </div>
    </div>
    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
        <div class="ams-form-group">
            <Multiselect placeholder="Search Asset Type" :options="assetTypes" class="ams-form-control ams-custom-control ams-form-control-sm" :searchable="true" track-by="label">
                <template  v-slot:option="{option}">
                    <input type="checkbox" :id="option.label" :value="option.value" @change="getPageData(option.value)">
                    <label :for="option.label"> {{option.label}} </label>
                </template>
            </Multiselect>
        </div>
    </div>
    <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
        <div class="ams-form-group">
            <Multiselect placeholder="Search Asset Status" :options="assetStatusList" class="ams-form-control ams-custom-control ams-form-control-sm">
                <template  v-slot:option="{option}">
                    <input type="checkbox" :id="option.label" :value="option.value" @change="prodStatusFilter(option.value)">
                    <label :for="option.label"> {{option.label}} </label> 
                </template>
            </Multiselect>
            <!-- <Multiselect class="ams-form-control ams-custom-control ams-form-control-sm" v-model="value" mode="tags" placeholder="Asset Status" trackBy="name" label="name" :closeOnSelect="false" :search="true" :options="[
      { value: 'judy', name: 'Judy', },
      { value: 'jane', name: 'Jane', },
      { value: 'john', name: 'John',  },
      { value: 'joe', name: 'Joe',  }]">
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag is-user">
                    {{ option.name }}
                    <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                    </span>
                </div>
            </template>
        </Multiselect> -->
        </div>
    </div>
</div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
    data() {
        return {
            prod_status: [],
            emp_status: [],
            pType: [],
            prod_tag: undefined,
            emp_id: undefined,
            assetStatusList: {
                '111': 'Working', 
                '112': 'Damaged', 
                '116': 'Assigned', 
                '117': 'Un Assigned'
                }
        };
    },
    methods: {
        prodFilter() {
            if (!this.prod_tag.length) {

                this.$parent.pageData()
                return
            }
            if (this.prod_tag.length >= 3) {
                this.$parent.pageData('filter')
            }
        },
        getPageData: function (pType) {
            let self = this
            const index = self.pType.indexOf(pType);
            if (index > -1) {
                self.pType.splice(index, 1)
            } else {
                self.pType.push(pType)
            }
            self.$parent.pageData('filter')
        },
        prodStatusFilter(val) {
            let self = this
            if (val === '111' || val === '112') {
                const index = self.prod_status.indexOf(val);
                if (index > -1) {
                    self.prod_status.splice(index, 1);
                } else {
                    self.prod_status.push(val)
                }
                self.$parent.pageData('filter')
            } else if (val === '116' || val === '117') {
                const index = self.emp_status.indexOf(val);
                if (index > -1) {
                    self.emp_status.splice(index, 1)
                } else {
                    self.emp_status.push(val)
                }
                self.$parent.pageData('filter')
            }
        },
        empFilter() {
            if (!this.emp_id.length) {
                this.$parent.pageData()
                return
            }
            if (this.emp_id.length >= 3) {
                this.$parent.pageData('filter')
            }
        }
    },
    computed: {
        assetTypes: function () {
            let productTypes = localStorage.getItem("pt")
            let parsedData = productTypes && JSON.parse(productTypes)
            return parsedData && parsedData['1']
        }
    },
    components: {
        Multiselect
    }
};
</script>
