/**
 * @description Profile Store Module
 * @author Harikrishna
 */

 import axios from "../../util/axios";
 export default {
     namespaced: true,
     state: {
         ph_num: ''
     },
     mutations: {
        SET_PH_NUM: (state, ph) => {
            state.ph_num = ph
        }
     },
     actions: {
         COMPANY_PROFILE({ commit }, payload) {
             console.log('commit', commit)
             return axios.get('/company/profile', payload).then(function (res) {
                 return res
             })
         },
         
        USER_PROFILE({ commit }, payload) {
            console.log('commit', commit)
            return axios.get('/user/profile', payload).then(function (res) {
                return res
            })
        },

        EDIT_PROFILE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/company/edit',  payload).then(function (res) {
                return res
            })
        },
        CHANGE_PASSWORD({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/reg/changepass',  payload).then(function (res) {
                return res
            })
        },
        CHANGE_NUMBER({ commit }, payload) {
            console.log('commit', commit)

            return axios.post('/company/getotp',  payload).then(function (res) {
                return res
            })
        },
        CONFIRM_NUMBER({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/company/editInfo',  payload).then(function (res) {
                return res
            })
        },
        SET_PH_NUM({ commit }, payload) {
            commit('SET_PH_NUM', payload);
        }
     },
     getters: {
        getPhnum: state => {
            return state.ph_num
        }
     }
 }
 