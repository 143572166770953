<template>
<div>
    <Form :validation-schema="roleValidate" v-slot="{ handleSubmit }">
        <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/users/role')"></i> Users - Role management</h3>
        <div class="ams-card">
            <div class="ams-setting-spacing">
                <div class="ams-row">
                    <div class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs12">
                        <div class="ams-form-group">
                        <label>Role name</label>
                        <Field type="text" v-model="roleName" name="rolename" class="ams-line-control" placeholder="Enter Role Name" />
                        <ErrorMessage name="rolename" class="ams-val-err" />
                        </div>
                    </div>
                </div>
                <ul class="ams-custom-list ams-role-assign" v-for="item in features" :key="item">
                    <li>
                        <input type="checkbox" :id="item.futr_id" :value="item.futr_id" v-model="allSelected[item.futr_id]" @change="selectAll(item.futr_id, item.events)">
                        <label :for="item.futr_id"><strong> {{item.futr_name}} </strong></label>
                    </li>
                    <li v-for="e in item.events" :key="e">
                        <input type="checkbox" :id="e.event_id" :value="e.event_id" v-model="checked[e.event_id]" @change="select(item.futr_id, e.event_id)">
                        <label :for="e.event_id">{{e.event_name}} </label>
                    </li>
                </ul>
            </div>
            <div class="ams-table-filter">
                 <button class="ams-btn ams-btn-danger ams-mr20" @click.prevent="$router.push('/users/role')">Cancel</button>
                <button v-if="$route.params.roleid" class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(updateRole)">Save</button>
                <button v-else class="ams-btn ams-btn-primary" @click.prevent="handleSubmit(createrole)">Save</button>
            </div>
        </div>
    </Form>
</div>
</template>

<script>
import _ from 'lodash';
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";

export default ({
    components: {
        Form,
        Field,
        ErrorMessage

    },
    data() {
        const roleValidate = Yup.object().shape({
            rolename: Yup.string().trim().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required("Please Enter Name"),
        });
        return {
            features: [],
            allSelected: {},
            checked: {},
            structObj: {},
            roleName: '',
            roleValidate
        }
    },
    mounted() {
        let self = this
        self.getPrivilages()
        if(self.$route.params.roleid) {
            console.log('GGGGGGGG')
            self.getRoleData()
        }
    },
    methods: {
        getPrivilages () {
            let _self = this
            _self.$store.dispatch('settings/GET_FEATURES').then(function (res) {
                console.log('RES', res)
                if (res && res.status == 1000) {
                    _self.features = res.data
                }
            })
        },
        createrole () {
            let self = this
            let featdata = _.keys(self.structObj)
            let eventData = []
            if(featdata.length) {
                _.each(self.structObj, (e, keys) => {
                    eventData.push.apply(eventData, Object.values(self.structObj[keys]))
                })
                let roleReq = {
                    role_name: self.roleName,
                    futr_ids: featdata,
                    event_ids: eventData,
                }
                console.log('req', roleReq)
                self.$store.dispatch('settings/SAVE_ROLE', roleReq).then(function (res) {
                    console.log('RES', res)
                    if(res && res.status === 1000) {
                        self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Role Added successfully',
                            class: 'ams-error-success'
                        })
                        self.$router.push('/users/role')
                    }
                })
            } else {
                self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    msg: "Please Select Features",
                    class: "ams-error-danger",
                });
            }
        },
        updateRole () {
            let self = this
            let featdata = _.keys(self.structObj)
            let eventData = []
            if(featdata.length) {
                _.each(self.structObj, (e, keys) => {
                    eventData.push.apply(eventData, Object.values(self.structObj[keys]))
                })
                let roleReq = {
                    role_id: self.$route.params.roleid,
                    role_name: self.roleName,
                    futr_ids: featdata,
                    event_ids: eventData,
                }
                console.log('update req', roleReq)
                self.$store.dispatch('settings/UPDATE_ROLE', roleReq).then(function (res) {
                    console.log('update RES', res)
                    if(res && res.status === 1000) {
                        self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Role updated successfully',
                            class: 'ams-error-success'
                        })
                        self.$router.push('/users/role')
                    }
                })
            } else {
                self.$store.dispatch("common/SET_ALERTMESSAGE", {
                    msg: "Please Select Features",
                    class: "ams-error-danger",
                });
            }
        },
        selectAll(fid, event) {
            console.log('selsct all', fid)
            let self = this
            let arr = []
            if (self.allSelected[fid]) {
                _.each(event, (e) => {
                    arr.push(e.event_id)
                    self.structObj[fid] = arr
                    self.checked[e.event_id] = true
                })
            } else {
                _.each(event, (e) => {
                    self.checked[e.event_id] = false
                })
                self.structObj[fid] = []
                delete self.structObj[fid]
            }
        },
        select(fid, eid) {
            let self = this
            console.log('eid', eid, fid)
            if (self.checked[eid]) {
                (self.structObj && self.structObj[fid]) ? self.structObj[fid].push(eid) : (self.structObj[fid] = [eid]) 
                self.allSelected[fid] = true
            } else {
                _.remove(self.structObj[fid], (n) => {
                   return  n === eid
                })
                if (!self.structObj[fid].length) {
                    self.allSelected[fid] = false
                    delete self.structObj[fid]
                }
            }
        },
        getRoleData () {
            let self = this
            let reqobj = {
                rid: self.$route.params.roleid
            }
            self.$store.dispatch('settings/GET_ROLEINFO', reqobj).then(function (res) {
                console.log('role id res', res)
                if (res && res.status == 1000) {
                    let rdata =  res.data
                    self.roleName = rdata.role_name
                    _.each(rdata.futr_ids, (e) => {
                        self.allSelected[e] = true
                        self.structObj[e] = []
                    })
                    _.each(rdata.event_ids, (e) => {
                        self.checked[e] = true
                        _.each(rdata.futr_ids, (fe) => {
                            let value = e
                            let val = value.indexOf(fe)
                            if (val === 0) {
                                self.structObj[fe].push(e)
                            }
                        })
                    })
                }
            })
        }
    }
})
</script>
