<template>
  <div class="ams-main">
    <header-comp @menuBar="menuBar" v-if="display && userToken"/>
		<div class="sa-transparent" :class="{ isOpen: isOpen }" @click="close"></div>
    <div class="ams-container">
      <navigation @navBar="menuBar" v-if="display"/>
      <router-view />
    </div>
    <div v-if="alertMessage && alertMessage.msg" class="ams-error ams-error-fixed" :class="alertMessage.class">
      {{alertMessage.msg}} <i class="icon-close ams-a-close" v-on:click="resetAlertMessage()"></i>
    </div>
    <div v-if="loader" class="ams-loader"></div>
  </div>
</template>
<script>
import header from "../src/components/common/Header.vue"
import Navigation from './components/common/Navigation.vue'
var myBody = document.getElementsByTagName('body')[0];
export default {
  name: 'App',
  data(){
    return{
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      myBody.classList.remove('ams-is-active');
    },
    menuBar:function(){
      if (this.isOpen) {
        this.close();
        myBody.classList.remove('ams-is-active');
      } else {
        this.open();
        myBody.classList.add('ams-is-active');
      }
    },
    resetAlertMessage() {
      this.$store.dispatch('common/SET_ALERTMESSAGE', { 'msg': '', class: '' })
    }
  },
  watch: {
    alertMessage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.alertMessage && this.alertMessage.msg) {
          setTimeout(() => {
            this.$store.dispatch('common/SET_ALERTMESSAGE', { 'msg': '', class: '' })
          }, 5000)
        }
      }
    }
  },
  computed:{
    alertMessage: function () {
      return this.$store.getters['common/getAlertMessage']
      
    },
    loader: function () {
      return this.$store.getters['common/getLoader']
    },
    display: function () {
      return this.$store.getters['common/getDisplay']
    },
    userToken: function () {
			let userToken = this.$store.getters['user/getUserToken']
			let userStorageToken = localStorage.getItem('token')
			return (userToken) || (userStorageToken) || ''
		}
  },
  components: {
		'header-comp': header,
    Navigation
	}
}
</script>