<template>
<div class="ams-content">
    <Form :validation-schema="ticketValidate" v-slot="{ handleSubmit }">
        
        <h3 class="ams-tit ams-text-center">Service Tickets Form</h3>
        <div class="ams-card ams-card-ticketbg">
            <Form :validation-schema="employeeValidate" v-slot="{ handleSubmit }">
            <div>
                <div class="ams-row ams-d-f-a-center ams-f-size14 ams-italic">
                    <div class="ams-col3 ams-col-md3 ams-col-sm12 ams-col-xs12">
                        <div class="ams-form-group">
                        <label>Employee id</label>
                        <Field v-model="emp_id" type=text class="ams-form-control ams-custom-control-info" placeholder="" name="emp_id" id="emp_id" :disabled="searchFlag" />
                        <ErrorMessage name="emp_id" class="ams-val-err" />
                        </div>
                    </div>
                      <div v-if="!searchFlag" class="ams-col2 ams-col-md10 ams-col-sm12 ams-col-xs12">
                        <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(SearchEmployee)">search</button>
                    </div>
                  
                </div>
            </div>
            </Form>
            <div v-show="searchFlag">
                <h3 class="ams-tit ams-mt20">Basic Details</h3>
                <div class="ams-row ams-f-size14 ams-italic ams-card">
                    <div class="ams-col3 ms-col-md3 ams-col-sm12 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Employee Name</label>
                            <strong>{{emp.name}}</strong>
                        </div>
                    </div>
                    <div class="ams-col3 ms-col-md3 ams-col-sm12 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Employee id</label>
                            <strong>{{emp.emp_id}}</strong>
                        </div>
                    </div>
                    <div class="ams-col3 ms-col-md3 ams-col-sm12 ams-col-xs12">
                        <div class="ams-form-group">
                            <label>Designation</label>
                            <strong>{{emp.desg}}</strong>
                        </div>
                    </div>
                </div>
                <h3 class="ams-tit">Ticket For</h3>
                <div class="ams-row ams-f-size14 ams-italic">
                    <div class="ams-col3 ms-col-md3 ams-col-sm12 ams-col-xs12">
                        <div class="ams-form-group">
                            <Multiselect @change="fetchAssetTags" @clear="clearpType" v-model="value" :options="productTypes" class="ams-form-control" placeholder="Asset / Accessory / License" :searchable="true" track-by="label"></Multiselect>
                            <Field type="text" v-model="value" v-show="false" class="ams-form-control ams-custom-contro" placeholder="" name="assetType" />
                            <ErrorMessage name="assetType" class="ams-val-err" />
                        </div>
                    </div>
                    <div class="ams-col3 ms-col-md3 ams-col-sm12 ams-col-xs12">
                        <div class="ams-form-group">
                            <Multiselect v-model="prod_tag" @clear="clearpType" :options="productTags" class="ams-form-control" placeholder="Asset / Accessory / License Tag"></Multiselect>
                            <Field type="text" v-model="prod_tag" v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="" name="assetTag" />
                            <!-- <input type=text class="ams-form-control" placeholder="Tag( Auto fetch)" name="software" id="software" :disabled="searchFlag"/>
                           <Field type="text" v-model="prod_tag" v-show="false" class="ams-form-control" placeholder="" name="software" /> -->
                            <ErrorMessage name="assetTag" class="ams-val-err" />
                        </div>
                    </div>
                </div>
                <div  class="ams-form-group">
                    <h3 class="ams-mt10">Complaint Details</h3>
                    <textarea type="text" v-model="desc" class="ams-form-control  ams-textarea-control" name="complaint" id="complaint"/>                        
                    <Field type="text" v-model="desc" v-show="false" class="ams-form-control  ams-textarea-control" placeholder="" name="complaint" />
                    <ErrorMessage name="complaint" class="ams-val-err" />
             </div>
            <div class="ams-table-filter ams-mt20">
                <button class="ams-btn ams-btn-primary ams-ml10"  @click.prevent="handleSubmit(sendTicket)">Send</button>
            </div>
            </div>
        </div>
     </Form>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import _ from "lodash";
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
import {
    ref
} from 'vue'
const picked = ref(new Date())
export default {
    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        const ticketValidate = Yup.object().shape({
            assetType: Yup.string().required("Please Select Asset type").nullable(),
            assetTag: Yup.string().required("Please Select Asset tag").nullable(),
            complaint: Yup.string().required("Please Enter Your Complaint Details").nullable(),
        });
        const employeeValidate = Yup.object().shape({
            emp_id: Yup.string().matches(/^[a-zA-Z0-9]+$/,"Special Characters Not Allowed ").required("Please Enter Employee Id").nullable()
        })
        return {
            desc: '',
            emp_id: null,
            value: '',
            prod_tag: '',
            filterFlag: false,
            searchFlag: false,
            picked,
            emp: {
                desg: null,
                name: null,
                emp_id: null
            },
            categories: {
                "1": "Assets",
                "2": "Accessories",
                "3": "Licenses"
            },
            // productTags: [],
            productTags: [],
            productTag: null,
            productType: null,
            ticketValidate,
            employeeValidate
        };
    },
    mounted: function () {
        let self = this
        self.$store.dispatch('common/SET_DISPLAY', false)
        localStorage.setItem('token', decodeURIComponent(this.$route.query.token))
        let query = self.$route.query
        if (query.view === 'true') {
            console.log('came')
        }
        this.toggleBodyClass("addClass", "ams-ticketFormbg");
        // this.$store.dispatch('UI/SET_LOADING', false)
    },
    computed: {
        productTypes: function () {
            let pt = localStorage.getItem("pt");
            let parsedPT = pt && JSON.parse(pt);
            return {
                ...parsedPT[1],
                ...parsedPT[2],
                ...parsedPT[3]
            }
        },
    },
    methods: {
        toggleBodyClass: function (addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        clearpType (){
            let _self=this
            _self.prod_tag=null
        },
        sendTicket: function () {
            let ticketReq = {
                "prod_tag": this.prod_tag,
                "emp_id": this.emp.emp_id,
                "desc": this.desc
            }
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('ticket/RAISE_TICKET', ticketReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.searchFlag = false
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Raised your ticket successfully',
                        class: 'ams-error-success'
                    })
                    // while setting emp_id null - validation msg is coming after submittinh form for emp id

                    // _self.emp_id = null
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Form link copied',
                        class: 'Technical error while raising your ticket'
                    })
                }
            })

        },
        SearchEmployee: function () {
            let _self = this
            _self.searchFlag = false
            _self.$store.dispatch('common/SET_LOADER', true)
            this.$store.dispatch('employee/LIST_EMPLOYEE', this.emp_id).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.searchFlag = true
                    _self.emp = res.data.emp
                    _self.emp.name = res.data.emp.fn + ' ' + res.data.emp.ln
                    _.map(res.data.tags, t => t.map(p => {
                        _self.productTags.push(p.tag)
                    }))
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        // reset() {
        //     let self = this
        //     self.searchFlag = false
        //     self.emp_id = ''
        // },
        fetchAssetTags: async function (pid) {
            let _self = this;
            let response = await this.$store.dispatch(
                "product/FETCH_PRODUCT_TAGS",
                pid
            );
            if (response && response.status == 1000) {
                _self.productType = pid;
                _self.productTags = response.data;
                _self.prod_tag = ''
            }
        },
    },
    //   destroyed(){
    //     this.toggleBodyClass("removeClass", "ams-ticketFormbg");
    //   },
};
</script>
