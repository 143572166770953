<template>
<div class="ams-content">
    <inventory-assigned-item v-if="$route.params.tabname ==='assign' && $route.params.prodid"/>
    <inventory-item-stock v-if="$route.params.tabname ==='stock' && $route.params.prodid"/>
    <inventory-purchase v-if="$route.params.tabname ==='purchase' && $route.params.prodid === 'add'"/>
    <inventory-scraped v-if="$route.params.tabname ==='scrap' && $route.params.prodid === 'add'"/>
    <div class="ams-row">
        <div class="ams-col10 ams-col-md8 ams-col-sm8 ams-col-xs12">
            <h3 v-if="!$route.params.prodid" class="ams-tit">Inventory</h3>
        </div>
        <div class="ams-col2 ams-col-md4 ams-col-sm4 ams-col-xs12" v-if="tabSelection==='purchase'">
            <button  @click="openPurchaseDetails" class="ams-btn ams-btn-primary ams-btn-block ams-mb20" v-if="$route.params.prodid!='add'">Add Purchse Order</button>
        </div> 
        <div class="ams-col2 ams-col-md4 ams-col-sm4 ams-col-xs12" v-if="tabSelection==='scrap'">
            <button class="ams-btn ams-btn-primary ams-btn-block ams-mb20" v-if="$route.params.prodid!='add'" @click="openInventoryScraped">Add Scraped Item</button>
        </div>
    </div>
    <div v-if="!$route.params.prodid" class="ams-selector" @click="selectDropDown" :class="amsdropdownview? 'ams-active': ''">
        {{ tabSelection }} <i class="icon-downarrow ams-form-icon"></i>
    </div>
    <div class="ams-row">
        <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
            <ul v-if="!$route.params.prodid" class="ams-tabs ams-cust-tabs" :class="amsdropdownview? 'ams-dropd-show': 'ams-dropd-hide'">
                <li @click="changeTab('assign')" v-bind:class="[tabSelection === 'assign' ? 'ams-active' : '',]"><a><span>Assigned Item</span></a></li>
                <li @click="changeTab('stock')" v-bind:class="[tabSelection === 'stock' ? 'ams-active' : '',]"><a><span>Item in Stock</span></a></li>
                <li @click="changeTab('purchase')" v-bind:class="[tabSelection === 'purchase' ? 'ams-active' : '']"><a><span>Purchase Order</span></a>
                    <!-- <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                        <p class="ams-tooltip ams-tooltip-info ams-tooltip-top">Unlock this screen with our pro version</p>
                    </i> -->
                </li>
                <li @click="changeTab('scrap')" v-bind:class="[tabSelection === 'scrap' ? 'ams-active' : '']"><a><span>Scraped Item / Resold Item</span></a>
                    <!-- <i class="ams-sprite ams-diamond-icon ams-tooltip-pop">
                        <p class="ams-tooltip ams-tooltip-info ams-tooltip-top">Unlock this screen with our pro version</p>
                    </i> -->
                </li>
            </ul>
        </div>
        <!-- <div class="ams-col2 ams-col-md2 ams-col-sm4 ams-col-xs12 ams-text-right"> -->
            <!-- <div class="ams-form-group"> -->
                <!-- <button class="ams-btn ams-btn-outline-info" @click="$router.push('/ask-quotation')"> Ask Quotation</button> -->
            <!-- </div> -->
        <!-- </div> -->
    </div>
    <div v-if="!$route.params.prodid" class="ams-tab-cont">
        <Inventory-Assigned-Item-Table v-if="$route.params.tabname ==='assign'" />
        <Inventory-Item-Stock-Table v-else-if="tabSelection === 'stock'" />
        <Inventory-Purchase-Order-Table v-else-if="tabSelection === 'purchase'" />
        <Inventory-Scraped-Table v-else-if="tabSelection === 'scrap'" />
    </div>
</div>
</template>

<script>
import InventoryAssignedItemTable from "../../components/inventory/InventoryAssignedItemTable.vue";
import InventoryItemStockTable from "../../components/inventory/InventoryItemStockTable.vue";
import InventoryPurchaseOrderTable from "../../components/inventory/InventoryPurchaseOrderTable.vue";
import InventoryScrapedTable from "../../components/inventory/InventoryScrapedTable.vue";
import InventoryAssignedItem from "../../components/inventory/InventoryAssignedItem.vue";
import InventoryPurchase from "../../components/inventory/InventoryPurchase.vue";
import InventoryItemStock from "../../components/inventory/InventoryItemStock.vue";
import InventoryScraped from '../../components/inventory/InventoryScraped.vue';

export default {
    components: {
        InventoryAssignedItemTable,
        InventoryItemStockTable,
        InventoryPurchaseOrderTable,
        InventoryScrapedTable,
        InventoryAssignedItem,
        InventoryPurchase,
        InventoryItemStock,
        InventoryScraped
    },
    mounted: function () {
        let self = this
            // let tabSelected = localStorage.getItem("tabSelected");
            // let parsedPT = tabSelected && JSON.parse(tabSelected);
        self.tabSelection =  'assign'
        self.$router.push('/inventory/' + self.tabSelection)      
    },
    data() {
        return {
            tabSelection: '',
            stid: 'ST0',
            t_apts: 0,
            t_inc: 0,
            t_comm: 0,
            visible: false,
            amsdropdownview: false
        };
    },
    methods: {
         openPurchaseDetails: function () {
            this.$router.push('/inventory/purchase/add')
        },
         openInventoryScraped: function () {
            this.$router.push('/inventory/scrap/add')
        },
        changeTab(val) {
            let _self = this
            // if (val != _self.tabSelection) {
            _self.tabSelection = val
            _self.$router.push('/inventory/' + val)
                //  localStorage.setItem('tabSelected', JSON.stringify(_self.tabSelection))
            // }
            // _self.activeclass = undefined;
        },
        selectDropDown() {
          this.amsdropdownview = !this.amsdropdownview
        },
        tabSelecstion(option) {
            this.value = option;
        },
        setAppData(t_apts, t_inc, t_comm) {
            this.t_apts = t_apts
            this.t_inc = t_inc
            this.t_comm = t_comm
        }
    },
};
</script>
