/**
 * @description checkincheckout Store Module
 * @author Harikrishna
 */

 import axios from "../../util/axios";
 export default {
     namespaced: true,
     state: {
     },
     mutations: {
     },
     actions: {
        
        DEPRECIATION_DATA({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/depreciation/id',payload).then(function (res) {
                return res
            })
        }
         
        },
        
    }