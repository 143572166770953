/**
 * @description checkincheckout Store Module
 * @author Harikrishna
 */

import axios from "../../util/axios";
export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        FETCH_CHECKIN_TABLE({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/choi/table', payload).then(function (res) {
                return res
            })
        },
        ADD_DATA({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/choi/add', payload).then(function (res) {
                return res
            })
        },
        EDIT_DATA({ commit }, payload) {
            console.log('commit', commit)
            return axios.post('/choi/edit', payload).then(function (res) {
                return res
            })
        }

    },

}