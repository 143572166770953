<template>
<div class="ams-content">
    <add-vendor v-if="$route.params.action==='add'"/>
    <edit-vendor-list v-if="$route.params.action==='edit'"/>
    <ask-quotation v-if="$route.params.action==='ask-quotation'"/>
    <div v-if="!$route.params.action" class="ams-row">
        <div class="ams-col4 ams-col-md4 ams-col-sm12 ams-col-xs12">
            <h3 class=" ams-tit">Vendors </h3>
        </div>
        <div class="ams-col8 ams-col-md8 ams-col-sm12 ams-col-xs12 ams-text-right">
            <div class="ams-row">
                <div class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs6">
                    <div class="ams-form-group">
                        <div class="vendor-pro-version-btn">
                            <pro-version-btn />
                        </div>
                    </div>
                </div>
                <div class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs6">
                    <div class="ams-form-group">
                        <button class="ams-btn ams-btn-primary ams-btn-block" @click="$router.push('/vendor/add')">Add New Vendor</button>
                    </div>
                </div>
                <div class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <button class="ams-btn ams-btn-primary ams-btn-block" @click="$router.push('/vendor/ask-quotation')"> Ask Quotation</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ams-row">
        <div class="ams-col4 ams-col-md6 ams-col-sm6 ams-col-xs12">
            <div  v-if="$route.params.action!= 'add' && $route.params.action!= 'edit' && $route.params.action!= 'ask-quotation'" class="ams-form-group">
                <input type="text" @input="venFilter" v-model="ven_name" class="ams-form-control ams-custom-control ams-form-control-sm" placeholder="Search Vendor Name" />
                <i class="icon-search ams-form-icon ams-form-icon-right"></i>
            </div>
        </div>
    </div>
    <vendor-table v-if="!$route.params.action" ref="vt" :ven_name="ven_name" />
</div>
</template>

<script>
import VendorTable from "../../components/vendor/VendorTable.vue"
import ProVersionBtn from "../../components/pro/ProVersionBtn.vue"
import AddVendor from '../../components/vendor/AddVendor.vue';
import EditVendorList from '../../components/vendor/EditVendorList.vue';
import AskQuotation from '../../components/vendor/AskQuotation.vue'

export default {
    components: {
        VendorTable,
        ProVersionBtn,
        AddVendor,
        EditVendorList,
        AskQuotation
    },
    data() {
        return {
           ven_name:null
        };
    },
    mounted() {
       
    },
    methods: {
        venFilter() {
             let venRef = this.$refs['vt']
            if (!this.ven_name.length) {
                venRef.listVendor()
                return
            }
            if (this.ven_name.length >= 3) {
                venRef.listVendor('filter')
            }
        }

    }
};
</script>
