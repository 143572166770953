<template>
<div>
    <product-filter />
    <product-settings-table :prodTypes="prodTypes" :paginationData="paginationData" />
    <pagination :limit="limit" v-if="paginationData && paginationData.total > limit" :paginationData="paginationData" @setPageData="setPageData($event)" />
</div>
</template>

<script>
import ProductFilter from "../../components/settings/ProductFilter.vue"
import ProductSettingsTable from "../../components/settings/ProductSettingsTable.vue"
import {
    pagination_limit
} from "../../../config/endpoint";
import Pagination from "../../components/common/Pagination.vue"
export default {
    components: {
        ProductFilter,
        ProductSettingsTable,
        Pagination,
    },
    mounted() {

        this.getData()

    },

    data() {
        return {
            prodTypes: [],
            page: 1,
            paginationData: null,
            cType: undefined
        };
    },
    computed: {
        limit: function () {
            return pagination_limit
        },
    },
    methods: {
        setPageData: function (page) {
            let self = this
            self.page = page
            self.getData(self.cType)
        },
        getData(val) {
            let _self = this
            if (val != _self.cType) {
                _self.page = 1
            }
            let req = {

                limit: _self.limit,
                page: _self.page,

            }
            if (val) {
                req.cType = val
                _self.cType = val
            }
            console.log(_self.limit, _self.page, 'dfgh1')
            _self.$store.dispatch("common/SET_LOADER", true);
            this.$store.dispatch("product/FETCH_PRODUCT_TYPES", req)
                .then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        console.log("responseeee", res);
                        _self.prodTypes = res.data.productTypes
                        _self.paginationData = res.data.metadata ? res.data.metadata[0] : null
                    }
                });
        }
    },
};
</script>
