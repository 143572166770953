<template>
<div>
    <div class="ams-row ams-d-f-sbetween">
        <div class="ams-col6 ams-col-md8 ams-col-sm8 ams-col-xs12">
            <div class="ams-form-group">
            <h3>
                <i @click="$router.push('/employee')" class="icon-left-arrow ams-vertical-md ams-mr20"></i>Employee - {{employeeDetails.emp.fn}} {{employeeDetails.emp.ln}}
            </h3>
            </div>
        </div>
        <div class="ams-col1 ams-col-md1 ams-col-sm2 ams-col-xs12 ams-text-right">
            <div class="ams-form-group">
            <button class="ams-btn ams-btn-block ams-btn-primary" @click="editEmployee()">Edit</button>
            </div>
        </div>
    </div>
    <form class="ams-card">
        <div class="ams-row ams-tit">
            <div class="ams-col12 ams-col-xs12 ams-d-f-sbetween">
                <h3>Assets Details</h3>
                <span class="ams-italic">Number of assets allocated :{{(employeeDetails.tags && employeeDetails.tags['1'] && employeeDetails.tags['1'].length) || 0}} </span>
            </div>
        </div>
        <div v-if="employeeDetails.tags && employeeDetails.tags['1']" class="ams-card ams-card-border">
            <!-- <span class="ams-italic">Number of assets allocated :{{(employeeDetails.tags && employeeDetails.tags['1'] && employeeDetails.tags['1'].length) || 0}} </span> -->
            <ul class="ams-unorder-list ams-mt10">
                <li v-for="item in employeeDetails.tags['1']" :key="item"><span><em>Allocated asset name</em> : {{tagname["1"][item.type]}}</span>
                    <span><em>Allocated asset tag</em> : {{item.tag}}</span></li>
            </ul>
        </div>
        <!-- <template> -->
            <div class="ams-row ams-tit">
                <div class="ams-col12 ams-col-xs12 ams-d-f-sbetween">
                    <h3>Accessory Details</h3>
                    <span class="ams-italic">Number of accessory allocated :{{(employeeDetails.tags && employeeDetails.tags['2'] && employeeDetails.tags['2'].length) || 0}}</span>
                </div>
            </div>
            <div v-if="employeeDetails.tags && employeeDetails.tags['2']" class="ams-card ams-card-border">
                <ul class="ams-unorder-list ams-mt10">
                    <li v-for="item in employeeDetails.tags[2]" :key="item"><span><em>Allocated accessory name</em> :{{tagname["2"][item.type]}}</span>
                        <span>Allocated accessory tag :{{item.tag}}</span></li>
                </ul>
            </div>
        <!-- </template>
        <template> -->
            <div class="ams-row ams-tit">
                <div class="ams-col12 ams-col-xs12 ams-d-f-sbetween">
                    <h3>License Details</h3>
                    <span class="ams-italic">Number of license allocated :{{(employeeDetails.tags && employeeDetails.tags['3'] && employeeDetails.tags['3'].length) || 0}}</span>
                </div>
            </div>
            <div v-if="employeeDetails.tags && employeeDetails.tags['3']" class="ams-card ams-card-border">
                <ul class="ams-unorder-list ams-mt10">
                    <li v-for="item in employeeDetails.tags[3]" :key="item"><span>Allocated software name : {{item.sn}}</span>
                        <span>Allocated license tag : {{item.tag}} </span></li>
                </ul>
            </div>
        <!-- </template> -->
    </form>
</div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            employeeDetails: {
                emp: {},
                tags: {
                    1: [],
                    2: [],
                    3: [],
                },
            },
            tagname: {
                1: {},
                2: {},
                3: {}
            }

        };
    },
    mounted() {
        console.log('thisssss', this.$route)
        let id = this.$route.params.empid;

        this.tagname = JSON.parse(localStorage.getItem('pt'))

        let _self = this

        _self.$store.dispatch("common/SET_LOADER", true);

        _self.$store.dispatch('employee/LIST_EMPLOYEE', id).then(function (res) {
            _self.$store.dispatch("common/SET_LOADER", false);
            console.log('eeee', res)
            if (res && res.status == 1000) {
                _self.employeeDetails = res.data

            } else {
                _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Technical Error',
                    class: 'ams-error-danger'
                })
            }
        })

    },
    methods: {
        editEmployee() {
            localStorage.setItem('employeeDetails', JSON.stringify(this.employeeDetails))
            this.$router.push('/employee/edit')
        }
    },
};
</script>
