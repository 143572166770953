<template>
        <h3 class="ams-tit ams-mt20">Mark Priority As </h3>
        <!-- <form> -->
        <div class="ams-d-flex ams-rel">
                <Field v-model="priority" value="Critical" type="radio" id="Critical" :disabled='dflag' name="priority"/>
                <label for="Critical" class="ams-mr15"> Critical</label>
                <ErrorMessage name="priority" class="ams-val-err" />
                <Field v-model="priority" value="High" type="radio" id="high" :disabled='dflag' name="priority"/>
                <label for="high" class=" ams-mr15"> High</label>
                <Field v-model="priority" value="Low" type="radio" id="Low" :disabled='dflag' name="priority"/>
                <label for="Low"> Low</label> 
        </div>
        <!-- </form> -->
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
    props: ['prefData'],
    data () {
        return {
            priority: "",
            dflag: false
        }
    },
    mounted () {
        let self = this
        let query = self.$route.query
        if (query.type === 'close') {
            self.dflag = true
        }
    },
    watch: {
        prefData: {
            immediate: false,
            handler () {
                let self = this
                self.priority = self.prefData
            }
        }
    },
    components: {
        Field,
        ErrorMessage
    }
}
</script>
