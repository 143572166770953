<template>
<div class="ams-card ams-mb50">
    <div class="ams-row ams-d-f-a-center">
        <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <div class="ams-company-pro">
                <figure v-if="!pflag && complogo" class="ams-company-logo">
                    <div>
                        <img :src="complogo" />
                        <!-- <div class="ams-row">
                            <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs6">
                                <div class="ams-card-shadow ams-upload-inv">
                                    <div class="ams-invoice">Logo
                                        <i class="ams-close icon-close"></i>
                                    </div>
                                    <div class="ams-invoice-upl">
                                        <span> {{fileData.filename}}
                                        </span>
                                        <i class="icon-download"></i>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </figure>
                <figure v-if="pflag" class="ams-company-logo">
                    <!-- <template> -->
                            <file-upload :show-upload="true" :show-delete="true" :disText="utext" @fileUploadSuccess="fileData =  $event"></file-upload>
                            <span class="ams-file-support">Supports Jpg or Png ( 230 * 230 ) <i class="icon-cam ams-cursor"></i></span>
                             <!-- </template> -->
                </figure>

            </div>
        </div>
        <div class="ams-cmpany-tit ams-col4 ams-col-md4 ams-col-sm4 ams-col-xs12">
            <!-- <h3>Add Company Name</h3> -->
            <input v-if="bflag" type="text" v-model="cmp_name" class="ams-form-control ams-custom-control-dark" placeholder="" name="companyName" id="companyName" />
            <h3 class="ams-tit ams-mt20" v-else>{{profileData.cmp_name}}</h3>                
        </div>
        <div class="ams-col5 ams-col-md4 ams-col-sm4 ams-col-xs12 ams-text-right ams-table-filter">
            <button class="ams-btn ams-btn-danger ams-mr20" v-if="bflag" @click="cancel()">Cancel</button>
            <button class="ams-btn ams-btn-primary" v-if="bflag" @click="companyEdit">Save</button>
            <button class="ams-btn ams-btn-outline-default ams-edit-btn" v-else @click="showedit()" ><i class="icon-edit"></i></button>
        </div>
    </div>
</div>
</template>

<script>
import FileUpload from '../common/FileUpload.vue'
export default {
    props: ["profileData"],
    components: {
        FileUpload
    },
    data() {
        return {
            pflag: false,
            bflag: false,
            utext: 'Upload logo',
            fileData: {},
            complogo: '',
            cmp_name: ''
        };
    },
    methods: {
        showedit() {
            let self = this
            self.bflag = true
            self.pflag = true
        },
        cancel() {
            let self = this
            self.bflag = false
            self.pflag = false
            self.complogo = self.profileData.logo ? self.profileData.logo : ''
        },
        companyEdit() {
            let self = this
            console.log('Calling method')
            let comReq = {
                cmp_name: self.cmp_name,
                logo: self.fileData.url
            }
            console.log('commmReq', comReq)
            self.$store.dispatch('common/SET_LOADER', true)
            self.$store.dispatch('profile/EDIT_PROFILE', comReq).then(function (res) {
                console.log('comp res', res)
                self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    let obj = {
                        comp_logo: self.fileData.url ? self.fileData.url : self.profileData.logo
                    }
                    self.profileData.cmp_name = self.cmp_name
                    self.$store.dispatch("user/SET_COMPDETAILS", obj)
                    localStorage.setItem('compDetails', JSON.stringify(obj))
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Company Details updated successfully',
                        class: 'ams-error-success'
                    })
                    self.bflag = false
                    self.pflag = false
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        }
    },
    watch: {
        fileData: {
            immediate: true,
            handler() {
                console.log('complogo', this.fileData)
                if (this.fileData && this.fileData.url) {
                    this.complogo = this.fileData.url
                    this.pflag = false
                }

            }
        },
        profileData: {
            immediate: true,
            handler() {
                console.log('profiledata', this.profileData)
                if (this.profileData) {
                    this.cmp_name = this.profileData.cmp_name
                    this.complogo = this.profileData.logo ? this.profileData.logo : ''
                }

            }
        }
    }
};
</script>
