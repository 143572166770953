<template>
<div>
    <h3 class="ams-tit">
        <i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/employee')"></i>Employee - Add Employee
    </h3>
    <Form class="ams-card" :validation-schema="addemp" v-slot="{handleSubmit}">
        <div class="ams-row">
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>First Name</label>
                    <Field  name="firstname"  v-model="employee.firstName" type="text" class="ams-form-control ams-custom-control-dark" placeholder="First Name" />
                    <ErrorMessage name="firstname" class="ams-val-err" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Last Name</label>
                    <Field name="lastname" v-model="employee.lastName" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Last Name" />
                    <ErrorMessage name="lastname" class="ams-val-err" />
                    
                </div>
            </div>
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Designation</label>
                    <Field name="designation" v-model="employee.designation" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Designation" />
                     <ErrorMessage name="designation" class="ams-val-err" />
                </div>
            </div>
            <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group">
                    <label>Employee Id</label>
                    <Field name="employeeid" :disabled="disflag" v-model="employee.employeeId" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Employee Id" />
                     <ErrorMessage name="employeeid" class="ams-val-err" />
                </div>
            </div>
        </div>

        <employee-asset-details :assetlistprop="assetList" ref="assets" />
        <employee-accessory-details :accessoriesListProp="accessoriesList" ref="accessories" />
        <employee-license-details :licenseList="licenseList" ref="license" />
        <!-- <employee-status /> -->
            <div class="ams-table-filter">
        <button class="ams-btn ams-btn-danger" @click="$router.push('/employee')">Cancel</button>
        <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(addEmployeeDetails)">
            Save
        </button>
    </div>
    </Form>
    <!-- </Form> -->
</div>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
import EmployeeAssetDetails from "../../components/employee/add-employee/EmployeeAssetDetails.vue";
import EmployeeAccessoryDetails from "../../components/employee/add-employee/EmployeeAccessoryDetails.vue";
import EmployeeLicenseDetails from "../../components/employee/add-employee/EmployeeLicenseDetails.vue";
// import { readonly } from '@vue/reactivity';
// import EmployeeStatus from "../../components/employee/add-employee/EmployeeStatus.vue";
export default {
    components: {
        EmployeeAssetDetails,
        EmployeeAccessoryDetails,
        EmployeeLicenseDetails,
        // EmployeeStatus,
        Form,
        Field,
        ErrorMessage

    },
    data() {
        const addemp = Yup.object().shape({
             firstname: Yup.string().matches(/^[aA-zZ\s]+$/,"Special Characters Not Allowed ").required("Please Enter First Name"),
             lastname: Yup.string().matches(/^[aA-zZ\s]+$/,"Special Characters Not Allowed").required("Please Enter Last Name"),
             designation: Yup.string().matches(/^[aA-zZ\s]+$/,"Special Characters Not Allowed ").required("Please Enter Designation"),
             employeeid: Yup.string().required("Please Enter Employee Id")
        })
        return {
            employee: {
                firstName: "",
                lastName: "",
                designation: "",
                employeeId: "",
            },
            assetList: null,
            accessoriesList: null,
            licenseList: null,
            addemp,
            filterFlag: false,
            disflag: false,
            options: ["CSV", "MS-WORD", "MS-EXCEL", "PDF"],
        };
    },
    mounted() {
        let employeeDetails = localStorage.getItem("employeeDetails");
        if (employeeDetails && JSON.parse(employeeDetails)) {
            console.log(JSON.parse(employeeDetails));
            let parsedData = JSON.parse(employeeDetails);
            this.employee.firstName = parsedData.emp.fn;
            this.employee.lastName = parsedData.emp.ln;
            this.employee.designation = parsedData.emp.desg;
            this.employee.employeeId = parsedData.emp.emp_id;
            this.assetList = parsedData.tags && parsedData.tags["1"];
            this.accessoriesList = parsedData.tags && parsedData.tags["2"];
            this.licenseList = parsedData.tags && parsedData.tags["3"];
            console.log("assetList", this.assetList);
        }
        let self = this
        console.log('self.$route.params',self.$route.params)
        if (self.$route.params.action === 'edit' ) {
            self.disflag = true
        }
        
    },
    methods: {
        addEmployeeDetails: function () {
            let _self = this;
            let allocatedProducts = [];
            let allocatedAssets = _self.$refs["assets"].selectedProducts;
            let allocatedAccessories = _self.$refs["accessories"].selectedProducts;
            let allocatedLicense = _self.$refs["license"].selectedProducts;
            if (allocatedAssets && allocatedAssets.length) {
                allocatedProducts.push.apply(
                    allocatedProducts,
                    allocatedAssets.map((a) => a.productTag)
                );
            }
            if (allocatedAccessories && allocatedAccessories.length) {
                allocatedProducts.push.apply(
                    allocatedProducts,
                    allocatedAccessories.map((a) => a.productTag)
                );
            }
            if (allocatedLicense && allocatedLicense.length) {
                allocatedProducts.push.apply(
                    allocatedProducts,
                    allocatedLicense.map((a) => a.productTag)
                );
            }
            if (_self.$route.params.action === 'edit') {
                // this.disflag=true
                let employeeReq = {
                    emp_id: _self.employee.employeeId,
                    prod_tags: allocatedProducts,
                    fn: _self.employee.firstName,
                    ln: _self.employee.lastName,
                    desg: _self.employee.designation
                };
                _self.$store.dispatch("common/SET_LOADER", true);
                _self.$store.dispatch("employee/UPDATE_EMPLOYEE", employeeReq).then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.$router.push("/employee");
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: res.message,
                            class: "ams-error-success",
                        });
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: res.message,
                            class: "ams-error-danger",
                        });
                    }
                });

            } else {
                let employeeReq = {
                    fn: _self.employee.firstName,
                    ln: _self.employee.lastName,
                    desg: _self.employee.designation,
                    emp_id: _self.employee.employeeId,
                    prod_tags: allocatedProducts,
                };
                _self.$store.dispatch("common/SET_LOADER", true);
                _self.$store.dispatch("employee/SAVE_EMPLOYEE", employeeReq).then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.$router.push("/employee");
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: res.message,
                            class: "ams-error-success",
                        });
                    } else {
                        _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                            msg: res.message,
                            class: "ams-error-danger",
                        });
                    }
                });
            }
        },
    },
};
</script>
