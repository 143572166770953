<template>
<div>
    <div class="ams-card">
        <h3 class="ams-tit">Profile Details</h3>
        <avatar v-if="userData && profileData" :userData="userData" :profileData="profileData" :editflag="uflag" :ppic="ppic" @editActions="uflag = $event" ref="avatar" />
        <div v-if="!uflag" class="ams-row ams-profile-view">
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs6">
                <label>Contact number</label>
                <strong>+ {{ profileData.ccode }} {{contactNum}} </strong>
            </div>
            <div class="ams-col3 ams-col-md6 ams-col-sm6 ams-col-xs6 ams-mt15">
                <label></label>
                <span class="ams-change ams-primary-text ams-cursor" @click="openPopup">Change</span>
                <change-contact-number v-on:close-popup="oflag = $event" v-if="oflag && profileData" :profileData="profileData" />
                <!-- <OTP/>  -->

            </div>

            <contact-updated-successfully />
            <!-- <div class="ams-col6">
        <label>Country</label>
        <strong>India</strong>
      </div> -->
            <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <label>Country</label>
                <strong>{{ profileData.country }}</strong>
            </div>
            <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <label>Email ID</label>
                <strong>{{ profileData.email }}</strong>
            </div>
            <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                <label>Currency</label>
                <strong>{{ profileData.cur }}</strong>
            </div>

            <div class="ams-col ams-col-md6 ams-col-sm6 ams-col-xs12">
                <label>Business ID</label>
                <strong> {{profileData.busns_id ? profileData.busns_id: 'NA'}} </strong>
            </div>
        </div>

        <div v-if="!uflag" class="">
            <div class="ams-a-warning ams-warning-bg">
                <div class="ams-alert">All communication will happen through business id
                </div>
            </div>
        </div>
        <Form v-if="uflag" class="ams-auth-form" :validation-schema="updateProfileValidate" v-slot="{ handleSubmit }">
            <div class="ams-row">
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>First Name</label>
                        <Field v-model="userobj.name" name="name" type="text" class="ams-form-control ams-custom-control-dark" placeholder="First Name" />
                        <ErrorMessage name="name" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Designation</label>
                        <Field v-model="userobj.desg" type="text" name="designation" class="ams-form-control ams-custom-control-dark" placeholder="Designation" />
                        <ErrorMessage name="designation" class="ams-val-err" />

                    </div>
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label class="ams-label">Business ID</label>
                        <Field v-model="userobj.busns_id" type="text" name="businessId" class="ams-form-control ams-custom-control-dark" placeholder="Business ID" />
                        <ErrorMessage name="businessId" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label class="ams-label">Email ID</label>
                        <input v-model="email" type="text" class="ams-form-control ams-custom-control-dark" placeholder="" disabled />
                    </div>
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Country</label>
                        <!-- <Multiselect v-model="userobj.country" :options="countryCode" :searchable="true" track-by="label" placeholder="Select Country" class="ams-form-control ams-custom-control-dark"></Multiselect> -->
                        <Field type="text" v-model="userobj.country" disabled class="ams-form-control ams-custom-control-dark" placeholder="Country" name="country" />
                        <ErrorMessage name="country" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Currency</label>
                        <!-- <Multiselect v-model="userobj.cur" :options="cur" :searchable="true" track-by="label" placeholder="Select Currency" class="ams-form-control ams-custom-control-dark"></Multiselect> -->
                        <Field type="text" v-model="userobj.cur" disabled class="ams-form-control ams-custom-control-dark" placeholder="Currency" name="currency" />
                        <ErrorMessage name="currency" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label class="ams-label">Contact number</label>
                        <input v-model="ph_num" type="text" class="ams-form-control ams-custom-control-dark" placeholder="" disabled />
                    </div>
                </div>

                <!-- <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label class="ams-label">Country</label>
                        <Multiselect v-model="value" :options="options" class="ams-form-control ams-custom-control-dark" placeholder="Others"></Multiselect>                   
                   </div>                
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label class="ams-label">Language</label>
                        <Multiselect v-model="value" :options="options" class="ams-form-control ams-custom-control-dark" placeholder="Others"></Multiselect>                   
                   </div>                
                </div>
                <div class="ams-col6 ams-col-md6 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label class="ams-label">Currency</label>
                        <Multiselect v-model="value" :options="options" class="ams-form-control ams-custom-control-dark" placeholder="Others"></Multiselect>                   
                   </div>                
                </div> -->

                <div class="ams-table-filter ams-col12 ams-text-right ams-col-md6 ams-col-sm12 ams-col-xs12">
                    <button @click="cancel()" class="ams-btn ams-btn-danger ams-mr20">
                        Cancel
                    </button>
                    <button @click.prevent="handleSubmit(updateProfile)" class="ams-btn ams-btn-primary">
                        Save
                    </button>
                </div>
            </div>
        </Form>
    </div>
</div>
</template>

<script>
import Avatar from "../common/Avatar.vue";
import ChangeContactNumber from "../../components/profile/ChangeContactNumber.vue";
// import OTP from "../../components/profile/OTP.vue";
//import Multiselect from "@vueform/multiselect";
//import API from '../../servcies/api.js';
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
// import ContactUpdatedSuccessfully from "../../components/profile/ContactUpdatedSuccessfully.vue"

export default {
    props: ["userData", "profileData"],
    components: {
        Form,
        Field,
        ErrorMessage,
        Avatar,
        ChangeContactNumber,
        //  OTP,
        // ContactUpdatedSuccessfully
       // Multiselect,

    },
    data() {
        const updateProfileValidate = Yup.object().shape({
            name: Yup.string().required("Please Enter Name"),
            // country: Yup.string().required("Please Select Country").nullable(),
            // currency: Yup.string().required("Please Select Currency").nullable(),
            designation: Yup.string().required("Please Enter Designation"),
            businessId: Yup.string().email("Please Enter Valid Email").required("Please Enter Business ID"),

        });
        return {
            oflag: false,
            uflag: false,
            userobj: {},
            email: '',
            ph_num: '',
            ppic: '',
            countryCode: [],
            // cur: ["SAR", "INR", "USD"],
            updateProfileValidate,
        };
    },
    // mounted() {
    //     let self = this
    //     API.country(function (response) {
    //         if (response && response.status === 1000) {
    //             self.countryCode = response.data
    //         }
    //     })
    // },
    methods: {
        openPopup() {
            this.oflag = true
        },
        closePop: function () {
            this.changepassword = false,
                this.changecontactnumber = false,
                this.contactupdatedsuccessfully = false

        },
        cancel() {
            let self = this
            self.uflag = false
            self.$refs['avatar'].profilePic = self.userData.profile_pic ? self.userData.profile_pic : ''
        },
        updateProfile() {
            console.log('update')
            let self = this;
            let ureq = {
                desg: self.userobj.desg,
                name: self.userobj.name,
                busns_id: self.userobj.busns_id
            }
            if (self.$refs['avatar'].profilePic) {
                ureq.profile_pic = self.$refs['avatar'].profilePic
            }
            console.log('ures', ureq)
            self.$store.dispatch("common/SET_LOADER", true);
            self.$store.dispatch("profile/EDIT_PROFILE", ureq).then(function (response) {
                console.log("responsessssssssss", response);
                if (response && response.status === 1000) {
                    self.$parent.companyProfile()
                    // self.$refs['avatar'].profilePic = self.userDsata.profile_pic ? self.userData.profile_pic : ''
                    self.uflag = false
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Profile updated successfully',
                        class: 'ams-error-success'
                    })
                    self.$store.dispatch("common/SET_LOADER", false);
                } else {
                    self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical error',
                        class: 'ams-error-danger'
                    })
                    self.$store.dispatch("common/SET_LOADER", false);
                }
            });
        },
    },
    computed: {
        contactNum() {
            let cnum = this.$store.getters['profile/getPhnum']
            if(cnum) {
                return this.$store.getters['profile/getPhnum']
            } else {
                return this.profileData.ph_num
            }
        }
    },
    watch: {
        uflag: {
            immediate: true,
            handler() {
                let self = this;
                if (self.uflag) {
                    self.userobj.desg = self.profileData.desg;
                    self.userobj.name = self.profileData.name;
                    self.userobj.busns_id = self.profileData.busns_id
                    self.userobj.country = self.profileData.country
                    self.userobj.cur = self.profileData.cur
                    self.email = self.profileData.email;
                    self.ph_num = '+' + self.profileData.ccode + ' ' + self.profileData.ph_num;
                    self.ppic = self.userData.profile_pic ? self.userData.profile_pic : ''
                }
            },
        },   
        userData: {
          immediate: true,
          handler() {
            let self = this;
            if (self.userData) {
                self.ppic = self.userData.profile_pic ? self.userData.profile_pic : ''
            }
          },
        },
    },
};
</script>
