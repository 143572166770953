<template>
    <div class="ams-dialog" @click.self="closePop">
        <div class="ams-dialog-body ams-w30">
            <i class="ams-close ams-close-circle icon-close-circ" @click="closePop"></i>
            <form class="ams-dialog-cont">
                <h3 class="ams-tit">For customized enterprise plan</h3>
                <div class="ams-form-group">
                    <label>Entity name</label>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>
                <div class="ams-form-group">
                    <label>Contact number</label>
                    <input type="number" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>
                <div class="ams-form-group">
                    <label>Email Id</label>
                    <input type="text" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" />
                </div>
                <button type="button" class="ams-btn ams-btn-primary ams-btn-block">Send details</button>
            </form>
        </div>
    </div>
</template>
<script>
export default {
  components: {
      },
  data() {
    return {
    };
  },
  methods: {
      closePop:function(){
          this.$emit('close-popup','')
      },
      
  },
};
</script>