import { createStore } from "vuex";
import common from './modules/common'
import user from "./modules/user";
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import product from "./modules/product";
import employee from "./modules/employee";
import ticket from "./modules/ticket";
import dashboard from "./modules/dashboard";
import vendor from "./modules/vendor";
import inventory from "./modules/inventory";
import settings from "./modules/settings";
import repair from "./modules/repair";
import checkincheckout from "./modules/checkincheckout";
import depreciation from "./modules/depreciation";
import profile from "./modules/profile";
import manufacture from "./modules/manufacture";
import reports from "./modules/reports";
const store =  createStore({
   modules: {
      common,
      user,
      product,
      employee,
      ticket,
      vendor,
      inventory,
      settings,
      dashboard,
      repair,
      checkincheckout,
      depreciation,
      profile,
      manufacture,
      reports
   },
   state:{
      name: "Vue",
      isAuth: false
   },
   actions,
   mutations,
   getters
});

export default store;