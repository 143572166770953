<template>
<div>
    <div class="ams-card ams-card-border">
        <h3 class="ams-tit">Type of Assets</h3>
        <Form class="ams-auth-form" :validation-schema="assetValidate" v-slot="{ handleSubmit }">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-multi-icon">
                        <i class="icon-lap ams-form-icon ams-form-icon-left"></i>
                        <Multiselect name="pType" id="pType" v-model="assetData.pType" :options="assetsTypes" class="ams-form-control" placeholder="Type of Assets" :searchable="true" track-by="label"></Multiselect>
                        <Field type="text" class="ams-form-control" v-model="assetData.pType" v-show="false" placeholder="Type of Assets" name="pType" id="brand" />
                        <ErrorMessage name="pType" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Multiselect type="text" class="ams-form-control" v-model="assetData.brand" :options="brandList" placeholder="Select Brand" name="brand" id="brand" :searchable="true" track-by="label"/>
                        <Field type="text" class="ams-form-control" v-model="assetData.brand" v-show="false" placeholder="Select brand" name="brand" id="brand" />
                        <ErrorMessage name="brand" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-model="assetData.mnumber" placeholder="Model Number" name="mnumber" id="mnumber" />
                        <ErrorMessage name="mnumber" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-model="assetData.quantity" placeholder="Quantity" name="quantity" id="quantity" />
                        <ErrorMessage name="quantity" class="ams-val-err" />
                    </div>
                </div>
                <div v-if="!bflag" class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-model="assetData.price" placeholder="Enter Price" name="price" id="price" />
                        <ErrorMessage name="price" class="ams-val-err" />
                    </div>
                </div>
                <!-- <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <Field type="text" class="ams-form-control" v-if="!bflag" v-model="assetData.ponumber" placeholder="Po number" name="ponumber" id="ponumber" />
                        <ErrorMessage name="modelnumber" class="ams-val-err" />
                    </div>
                </div> -->
                <div class="ams-col12">
                    <div class="ams-form-group">
                        <!-- <textarea type="text" v-model="assetData.comment" placeholder="Comment" name="comment" class="ams-form-control" /> -->
                        <Field type="text" v-model="assetData.comment" placeholder="Comment" name="comment" class="ams-form-control" />
                        <ErrorMessage name="comment" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <div class="ams-table-filter ams-mb20">
                <button type="button" class="ams-btn ams-btn-outline-info" @click="handleSubmit(addVendorAssets)">Add</button>
            </div>
        </Form>
        <template v-for="(a, i) in asset_item" :key="i" >
        <ul class="ams-table-list ams-type-of-list">
            <li class="ams-table-list-body">
                <div class="ams-table-list-col">
                    <!-- <input type="checkbox" id="LaptopAssests">
                    <label class="ams-vendor-checkbox" for="LaptopAssests">

                    </label> -->
                    <figure class="ams-avtar ams-avtar-md ams-mr15">
                            <i class="icon-lap"></i>
                        </figure> {{assetsTypes[a.pType]}}
                </div>
                <div class="ams-table-list-col">
                    <i class="ams-circ ams-dark-bg"></i> <span class="ams-ml10">{{a.brand}}</span>
                </div>
                <div class="ams-table-list-col">
                    <i class="ams-circ ams-dark-bg"></i>
                    <span class="ams-ml10">{{a.mnumber}}</span>
                </div>
                <div class="ams-table-list-col">
                    <i class="ams-circ ams-dark-bg"></i>
                    <span class="ams-ml10">{{a.quantity}}</span>
                </div>
                  <div  v-if="!bflag" class="ams-table-list-col">
                    <i class="ams-circ ams-dark-bg"></i>
                    <span class="ams-ml10">{{a.price}}</span>
                </div>
                  <!-- <div  v-if="!bflag" class="ams-table-list-col">
                    <i class="ams-circ ams-dark-bg"></i>
                    <span class="ams-ml10">{{a.ponumber}}</span>
                </div> -->
                 <!-- <div class="ams-table-list-col">
                    <i class="ams-circ ams-dark-bg"></i>
                    <span class="ams-ml10">{{a.comment}}</span>
                </div> -->
                <div class="ams-table-list-col">
                    <span class="ams-remove-text" @click="remove(i)">Remove</span>
                </div>
            </li>
            <li class="ams-table-list-col">
                <div class="ams-alert ams-a-info ams-w100">{{a.comment}}</div>
             </li>
            <!-- <li class="ams-table-filter ams-table-list-col">
             <div>
                    <input type="text" v-if="!bflag" class="ams-form-control" placeholder="Enter price" />
                </div>
            </li> -->
        </ul>
        </template>

    </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    data() {
         const assetValidate = Yup.object().shape({
            brand: Yup.string().required().label("Brand").nullable(),
            mnumber: Yup.string().required().label("Modelnumber"),
            quantity: Yup.string().matches(/^[0-9]+$/, "Only Numbers are allowed").required("quantity is requried"),
            pType: Yup.string().required().label("Asset type").nullable(),
           // price: Yup.string().matches(/^[0-9]+$/, "Only Numbers are allowed").required("Price is required"),
            comment: Yup.string().required().label("comment"),
        });
        return {
            asset_item: [],
            assetData: {
                pType: "",
                brand: "",
                mnumber: "",
                quantity: "",
                comment: "",
                price: ""
            },
            brandList: null,
            assetValidate,
            bflag: false,

        };
    },
    mounted() {
        let self = this
        console.log('self.$route.params', self.$route)
        if (self.$route.params.action == "ask-quotation") {
            self.bflag = true
        }
        console.log("ddd", self.bflag);
        self.manufactureNameData();
    },
    computed: {
        assetsTypes: function () {
            let aTypes = localStorage.getItem("pt")
            let parsedData = aTypes && JSON.parse(aTypes)
            return parsedData && parsedData['1']
        }
    },
    methods: {
        addVendorAssets() {
            let _self = this
            if (this.assetData.pType && this.assetData.brand && this.assetData.mnumber && this.assetData.quantity) {
                let assetObj = {
                    pType: _self.assetData.pType,
                    brand: _self.assetData.brand,
                    mnumber: _self.assetData.mnumber,
                    quantity: _self.assetData.quantity,
                    comment: _self.assetData.comment
                }
                if (!_self.bflag) {
                    assetObj.price = _self.assetData.price
                    assetObj.cType = '1'
                }
                _self.asset_item.push(assetObj)
                _self.assetData.pType = ''
                _self.assetData.brand = ''
                _self.assetData.mnumber = ''
                _self.assetData.quantity = ''
                _self.assetData.comment = ''
                _self.assetData.price = ''
            } else {
                _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                    msg: 'Please add assets',
                    class: 'ams-error-danger'
                })
            }
        },
        manufactureNameData() {
            let _self = this
            _self.$store.dispatch("manufacture/MANUFACTURE_LIST").then(function (res) {
                if (res && res.status == 1000) {
                    console.log('res', res)
                    _self.brandList = res.data
                }

            });
        },
        remove: function (i) {
            this.asset_item.splice(i, 1);
        }
    }
};
</script>
