<template>
    <header class="ams-header">
		<div class="ams-menu-bar" @click="menuBar"><i class="icon-menu-bar"></i></div>
		<a v-if="compLogo" :href="getRoute()" class="ams-logo">
			<img :src="compLogo ? compLogo : ''" alt="logo" class="ams-img-cover" /></a>
		<ul class="ams-nav">
			<li class="ams-dropdown">
			<span class="ams-h-xs">{{userName}} </span> 
			<figure @click="$router.push('/profile')" class="ams-avtar ams-avtar-md">
				<img :src="profilePhoto ? profilePhoto : imgunknown" class="ams-img-cover" alt="avtar" />
			</figure>
			<i class="icon-downarrow"></i>
				<div class="ams-dropdown-content">
					<span v-if="eventid['1241']" @click="$router.push('/company-profile')">Company Profile</span>
					<span @click="openChangepass">Change password</span>
					<span @click="logout()">Log out</span>
				</div>
			</li>
		</ul>
		<change-password v-if="changepassword" v-on:close-popup="closePop" />
    </header>
</template>
<script>
import ChangePassword from "../profile/ChangePassword.vue"
export default {
	data() {
		return {
			seen: false,
			changepassword: false,
			imgunknown: 'https://img.litedev.com/images/unknown-avtar.png'
		};
	},
	mounted() {
	},
	unmounted() {
	},
	methods: {
		openChangepass () {
			let vm = document.body
			vm.classList.add('hide-scrollbar');
			this.changepassword = true;
			this.seen = false
		},
		menuBar:function(){
			this.$emit('menu-bar','')
		
		},
		getRoute () {
			// let self = this
			if (localStorage.getItem('menu')) {
				let menuData = JSON.parse(localStorage.getItem('menu'))
				console.log('menuData', menuData[0])
				let route = menuData[0].path
				console.log('route', route)
				return route;
			}
		},
		logout () {
			let self = this
			// if (to.path == '/logout') {
				// remove data from local storage
			localStorage.removeItem('menu')
			localStorage.removeItem('token')
			localStorage.removeItem('userDetails')
			localStorage.removeItem('eventData')
			localStorage.removeItem('features')
			localStorage.removeItem('pt')
			localStorage.removeItem('compDetails')
				// remove data from vuex store
			self.$store.dispatch("common/SET_MENU", null)
			self.$store.dispatch("user/SET_USERDETAILS", null)
			self.$store.dispatch("user/SET_COMPDETAILS", null)
			self.$store.dispatch("user/SET_USERTOKEN", null)
			self.$router.push('/home')
			// }
		},
		closePop:function() {
			this.changepassword = false
			let vm = document.body
			vm.classList.remove('hide-scrollbar');
		}
	},
	computed: {
		userName: function () {
			let userDetails = this.$store.getters['user/getUserDetails']
			let userStorageDetails = localStorage.getItem('userDetails')
			return (userDetails && userDetails.fn) || (userStorageDetails && (JSON.parse(userStorageDetails).fn)) || ''
		},
		profilePhoto: function () {
			let userDetails = this.$store.getters['user/getUserDetails']
			let userStorageDetails = localStorage.getItem('userDetails')
			return (userDetails && userDetails.profile_pic) || (userStorageDetails && (JSON.parse(userStorageDetails).profile_pic)) || ''
		},
		eventid() {
            return JSON.parse(localStorage.getItem("eventData"));
        },
		compLogo() {
			let compDetails = this.$store.getters['user/getCompDetails']
			let compStorageDetails = localStorage.getItem('compDetails')
			console.log('compStorageDetails', compStorageDetails)
			return (compDetails && compDetails.comp_logo) || (compStorageDetails && (JSON.parse(compStorageDetails).comp_logo)) || ''
		}
	},
	components: {
		ChangePassword,
	}
};
</script>
