<template>
  <div class="ams-card ams-expend">
    <div class="ams-graph-tit">
    <h4 class="ams-value">Current Value</h4></div>
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series" width="250" height="250" font-size="18px"
     class="chart-graph"
    ></apexchart>
     <ul class="ams-current ams-custom-list ">
      <li>
        <i class="ams-circ ams-drk-blue"></i>
        <div class="custom-cnt">
          <span class="sa-custom-cnt-tit">Asset</span>
          <span v-if="pv" class="sa-custom-per">{{pv.as}}</span>
        </div>
      </li>
      <li>
        <i class="ams-circ ams-brd-red"></i>
        <div class="custom-cnt">
          <span class="sa-custom-cnt-tit">Accessories</span>
          <span v-if="pv" class="sa-custom-per">{{pv.ac}}</span>
        </div>
      </li>
      <li>
        <i class="ams-circ ams-brd-yellow"></i>
        <div class="custom-cnt">
          <span class="sa-custom-cnt-tit">License</span>
          <span v-if="pv" class="sa-custom-per">{{pv.li}}</span>
        </div>
      </li>
    </ul> 
    <!-- <ul class="sa-custom-list">
        <li>
                <i class="sa-circ sa-brd-red"></i>
                <span class="sa-custom-cnt">Today Appointments</span>
                <strong>100</strong>
            </li>
            <li>
                <i class="ams-circ ams-brd-red"></i>
                <span class="ams-custom-cnt">Today Appointments</span>
                <strong>100</strong>
            </li>
            <li>
                <i class="ams-circ ams-brd-yellow"></i>
                <span class="ams-custom-cnt">Today Appointments</span>
                <strong>100</strong>
            </li>
        </ul> -->

  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  computed: {
    series: function () {
      if (this.pv) {
        console.log(this.pv['as'].split(' ')[1])
        return [parseInt(this.pv['as'].split(' ')[1]), parseInt(this.pv['ac'].split(' ')[1]), parseInt(this.pv['li'].split(' ')[1])]
      }
      return [0, 0, 0]
    }
  },
  props: ['pv'],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    
    return {
      // series: [44, 55, 41],
      chartOptions: {
        chart: {
          
events: {
    animationEnd: function(ctx) {
      ctx.toggleDataPointSelection(2)
    }
  },
          type: "donut",
        },
        stroke: {
          width: 0,
        },
        colors: ["#FFD700", "#DB0000", "#2500D5"],
        legend: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
              labels: {
                show: true,
                value: {
                  show: true,
                  fontSize: "18px",
                  fontWeight: 600
                },
                total: {
                  showAlways: true,
                  show: true,
                  label: "Total Cost",
                  fontSize: "14px",
                  color: "#88909C"
                },
              },
            },
          },
        },
        labels: ["Assets", "Accessories", "License"],
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 376,
            options: {
              chart: {
                height: 230,
              },
              
            },
          },
           {
           breakpoint: 321,
            options: {
              chart: {
                // width: 100,
                 height: 180,
              },
             
            },
          },
        ],
      },
    };
  },
  watch: {
    pv: {
      immediate: true,
      handler () {
        let self = this
        if (self.pv) {
          let cur = this.pv['as'].split(' ')[0]
          // let val = (parseInt(this.pv['as'].split(' ')[1]) + parseInt(this.pv['ac'].split(' ')[1]) + parseInt(this.pv['li'].split(' ')[1])).toLocaleString()
          // console.log('VAL', val.toString())
          self.chartOptions.plotOptions.pie.donut.labels.total.formatter = function (w) {
                    return cur + " " + w.globals.seriesTotals.reduce((a, b) => {
                      let val = parseInt(a + b)
                      return val
                    }, 0).toLocaleString()
                  }
        }
      }
    }
  }
  
};
</script>
