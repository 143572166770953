<template>
        <h3 class="ams-tit ams-mt20">Expected Date to Close</h3>
        <form>
        <div class="ams-row">
            <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                <div class="ams-form-group ams-datepicker-md">
                    <!-- <input type="text" v-model="exp_time" class="ams-form-control ams-custom-control-dark" placeholder="" name="software" id="software" :disabled='dflag'/> -->
                    <datepicker v-model="exp_time" format="dd-MM-yyyy" class="ams-form-control ams-custom-control-dark" :minDate="new Date()" name="expectedtime" monthNameFormat="long" autoApply placeholder="Expected Time to Close"/>
                    <Field type="text" v-model="exp_time"  v-show="false" class="ams-form-control ams-custom-control-dark" placeholder="Expected Time to Close" name="expectedtime" />
                    <ErrorMessage name="expectedtime" class="ams-val-err" />
                    <i class="icon-calendar ams-form-icon ams-form-icon-right"></i>
                </div>
            </div>
        </div>
        </form>
</template>
<script>
import Datepicker from 'vue3-date-time-picker';
import { Field, ErrorMessage} from 'vee-validate';

export default {
    
    props: ['exptData'],
    data () {
        return  {
            exp_time:null,
            dflag: false
        }
    },
    components:{
        Datepicker,
        Field,
        ErrorMessage
    },
    mounted () {
        let self = this
        let query = self.$route.query
        if (query.type === 'close') {
            self.dflag = true
        }
    },
    watch: {
        exptData: {
            immediate: true,
            handler() {
                let self = this
                self.exp_time =  self.exptData
            }
        }
    }
}
</script>
