<template>
<div class="ams-card">
    <ul class="ams-report-list">
      <!--   <li v-if="tn">
            <i class="ams-sprite ams-notific"></i>
            <strong>{{tn.date}}</strong>
            New ticket notification
         <form class="ams-dialog ams-premium-popup ams-report-popup">
                <div class="ams-mt20">
                    <i class="ams-sprite ams-diamond-icon"></i>
                    <span class="ams-d-block"><strong class="ams-primary-text">Pro</strong> version</span>
                </div>
                </form> 
            <subscribe />
        </li> -->
          <li>
            <i class="icon-notification ams-f-size30 ams-notification">
                <span> {{openTickets}} </span>
            </i>
            <strong></strong>
            New ticket notification
        </li>
        <li>
            <i class="ams-sprite ams-f-size30 ams-liecense-ren">
            </i>
            <strong> {{getDate(renewal_date)}} </strong>
            Upcoming license renewal date
        </li>
        <li>
            <i class="ams-sprite ams-employees"></i>
            <strong>{{ae}}</strong>
            Active employees
        </li>
        <li>
            <i class="ams-sprite ams-purchase-ord"></i>
            <strong></strong>
            Partially accounted P O
            <!-- <form class="ams-dialog ams-premium-popup ams-report-popup">
                <div class="ams-mt20">
                    <i class="ams-sprite ams-diamond-icon"></i>
                    <span class="ams-d-block"><strong class="ams-primary-text">Pro</strong> version</span>
                </div>
            </form> -->
            <!-- <subscribe /> -->
        </li>
        <li>
            <i class="ams-sprite ams-act-vendors"></i>
            <strong>{{vendors}}</strong>
            Active vendors
        </li>
         <li>
            <i class="ams-sprite ams-audit-dt"></i>
            <strong> {{getDate(audit)}} </strong>
            Upcoming audit date
        </li>
    </ul>
</div>
</template>

<script>
// import Subscribe from '../pro/Subscribe.vue';
import moment from 'moment'
export default {
    components: { 
        // Subscribe 
    },
    props: ["tn", "ae", "rpo", "vendors","numbers", "openTickets", "audit", "renewal_date"],
    data() {
        return {};
    },
    // computed: {
    //     auditDate() {
    //         let self = this
    //         if (self.audit) {
    //             return moment(self.audit).format('DD-MM-YYYY')
    //         } else {
    //             return ''
    //         }
    //     }
    // },
    methods: {
        getDate(date) {
            return moment(date).format('DD-MM-YYYY')
        }
    },
    mounted() {},
    unmounted() {}
};
</script>
