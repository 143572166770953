<template>
<Form class="ams-auth-form" :validation-schema="fieldValidate" v-slot="{ handleSubmit }">
    <!-- <h3 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/vendor')"></i>Ask Quotation</h3> -->
    <div class="ams-dialog">
        <div class="ams-dialog-body ams-db-sm">
            <i class="ams-close ams-close-circle icon-close-circ" @click="closefields"></i>
            <div class="ams-row ams-d-f-a-center">
                <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Field heading</label>
                    </div>
                </div>
                <div class="ams-col9 ams-col-md8 ams-col-sm8 ams-col-xs12">
                    <div class="ams-form-group">
                    <Field type="text" v-model="fieldObj.field_name" class="ams-form-control ams-custom-control-dark" name="fieldHeading" id="auditby" placeholder="Field Heading" />
                    <ErrorMessage name="fieldHeading" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Field type</label>
                    </div>
                </div>
                <div class="ams-col9 ams-col-md8 ams-col-sm8 ams-col-xs12 ams-mt10">
                    <Multiselect name="fieldtype" @change="getfieldType($event)" :disabled="oflag" v-model="fieldObj.field_type" :options="fieldTypeList" placeholder="Field Type" class="ams-form-control ams-custom-control-dark"></Multiselect>
                    <Field type="text" v-show="false" v-model="fieldObj.field_type" class="ams-form-control ams-custom-control-dark" placeholder="Field Type" name="fieldType" />
                    <ErrorMessage name="fieldType" class="ams-val-err ams-pl10" />
                </div>
                <div class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12 ams-mt20">
                    <div v-if="fieldObj.field_type === 'text' || fieldObj.field_type == 'textarea'">
                        <div class="ams-row ams-mb20">
                            <div class="ams-col3 ams-col-md4 ams-col-sm12 ams-col-xs12">
                                <label class="ams-field">Input type</label>
                            </div>
                            <div class="ams-col9 ams-col-md8 ams-col-sm6 ams-col-xs12">
                                <Multiselect name="inputType" :disabled="oflag || tflag" v-model="fieldObj.input_type" :options="inputTypeList" placeholder="Input Type" class="ams-form-control ams-custom-control-dark">
                                    <!-- <template v-slot:option="{option}">
                                        <input type="checkbox" />
                                        <label>{{option.label}}</label>
                                    </template> -->
                                </Multiselect>
                                <Field type="text" v-show="false" v-model="fieldObj.input_type" class="ams-form-control ams-custom-control-dark" placeholder="Input Type" name="inputType" />
                                <ErrorMessage name="inputType" class="ams-val-err" />
                            </div>
                        </div>
                    </div>
                    <div v-if="fieldObj.field_type === 'dropdown'" class="ams-col12 ams-col-md12 ams-col-sm12 ams-col-xs12">
                        <div class="ams-row">
                            <div class="ams-col9 ams-col-md12 ams-col-sm12 ams-col-xs12 ams-d-f-j-center ams-ml10">
                                <div class="ams-form-group">
                                    <input type="checkbox" v-model="multiselect" id="Drop down" :disabled="oflag">
                                    <label for="Drop down">Is It Multiselect ? </label>
                                </div>
                            </div>
                        </div>
                        <div class="ams-row">
                            <div class="ams-col3 ams-col-md4 ams-col-sm4 ams-col-xs12">
                                <label>Field Values</label>
                            </div>
                            <div class="ams-col9 ams-col-md8 ams-col-sm8 ams-col-xs12">
                                <div class="ams-form-group ams-d-flex">
                                    <textarea v-model="ftext" type="text" class="ams-form-control ams-textarea-control ams-form-addfield" name="fieldvalue" id="" />
                                    <Field type="text" v-show="false" v-model="ftext" class="ams-form-control ams-custom-control-dark" placeholder="" name="fieldvalue" />
                                    <ErrorMessage name="fieldvalue" class="ams-val-err" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Input type <span class="ams-red-text"></span></label>
                        <Multiselect name="inputType" :disabled="oflag == true" v-model="fieldObj.input_type" :options="inputTypeList" placeholder="Input Type" class="ams-form-control ams-custom-control-dark" :searchable="true" track-by="label">
                        <Field type="text" v-show="false" v-model="fieldObj.input_type" class="ams-form-control ams-custom-control-dark" placeholder="Input Type" name="inputType" />
                        <ErrorMessage name="inputType" class="ams-val-err" />
                        <template  v-slot:option="{option}">
                            <input type="checkbox" />
                            <label>{{option.label}}</label>
                        </template>
                        </Multiselect>
                    </div>
                </div> -->              
            </div>
            <div class="ams-row">
                <div class="ams-col9 ams-col-md12 ams-col-sm12 ams-col-xs12 ams-d-f-j-center ams-ml20">
                    <div class="ams-form-group">
                        <input type="checkbox" v-model="fieldObj.mand" id="Drop down" :disabled="oflag">
                        <label for="Drop down">Mark as mandatory</label>
                    </div>
                </div>
            </div>   
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-danger" @click.prevent="sample">Cancel</button>
                <button class="ams-btn ams-btn-lg ams-btn-primary ams-ml20" @click.prevent="handleSubmit(fieldDetails)" v-if="$route.params.tabname === 'product'">Add</button>
                <button class="ams-btn ams-btn-lg ams-btn-primary ams-ml20" @click.prevent="handleSubmit(fieldDetails)" v-else-if="!uflag">Add</button>
                <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="updateField" v-else-if="uflag">Update</button>
            </div>
        </div>
    </div>
</Form>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
// import Datepicker from 'vue3-datepicker'
//import moment from 'moment'

export default {
    props: ['field_id'],
    data() {
        const fieldValidate = Yup.object().shape({
            fieldHeading: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Special Characters Not Allowed ").required("Please Enter Field Heading").nullable(),
            fieldType: Yup.string().required("Please Select Field Type").nullable(),
            inputType: Yup.string()
                .when('fieldType', {
                    is: (value) => (value === 'text' || value === 'textarea') ? true : false,
                    then: Yup.string()
                        .required('Please Select Input Type'),
                }),
            fieldvalue: Yup.string()
                .when('fieldType', {
                        is: (value) => (value === 'dropdown') ? true : false,
                        then: Yup.string()
                            .required('Please Provide Field Values'),
                    })
        })

        return {
            ftext: '',
            tflag: false,
            oflag: false,
            fId: null,
            fieldObj: {
                field_name: "",
                field_type: "",
                input_type: "",
                mand: false
                //  field_id: ""
            },
            multiselect: false,
            uflag: false,
            fieldValidate,
            fieldTypeList: {
                "text": "Free text",
                "calendar": "Calendar",
                "checkbox": "Checkbox",
                "radio": "Radiobutton",
                "dropdown": "Dropdown",
                "textarea": "Textarea"
            },
            inputTypeList: {
                "alpha": "Alphabet",
                "number": "Numeric",
                "alpha_num": "Alpha Numeric",
                "any": "Any"
            },
        }
    },
    mounted() {
        if (this.fId) {
            this.oflag = true
            this.getEditData()
        }
        // let self = this
        // console.log('MOUNYTEd')
        // if (self.$route.params.field_id) {
        //     self.getEditData()
        //     console.log('rrrrrrrr', self.$route.params.field_id)
        // // }
        // if (this.fId) {
        //     console.log('cal aapi', this.fId)
        //     let _self = this
        //     _self.$store.dispatch("common/SET_LOADER", true);
        //     _self.$store.dispatch('settings/FIELD_ID', this.field_id).then(function (res) {
        //         _self.$store.dispatch("common/SET_LOADER", false);
        //         if (res && res.status == 1000) {
        //             _self.uflag = true
        //             let rdata = res.data
        //             _self.fieldObj.field_name = rdata.field_name
        //             _self.fieldObj.field_type = rdata.field_type
        //             _self.fieldObj.input_type = rdata.input_type
        //             _self.fieldObj.mand = rdata.mand
        //         } else {
        //             _self.$store.dispatch('common/SET_ALERTMESSAGE', {
        //                 msg: 'Technical Error',
        //                 class: 'ams-error-danger'
        //             })
        //         }
        //     })

        // }
    },
    methods: {
        getfieldType(val) {
            if (val === 'calendar' || val === 'checkbox' || val === 'radio' || val === 'dropdown') {
                this.tflag = true
            } else {
                this.tflag = false
            }
        },
        sample() {
            this.$emit('close-popup', '')
        },
        fieldDetails: function (val) {
            console.log('val', val)
            let _self = this;
            let req = {
                field_name: _self.fieldObj.field_name,
                field_type: _self.fieldObj.field_type,
                mand: _self.fieldObj.mand
            }
            if (_self.fieldObj.input_type) {
                req.input_type = _self.fieldObj.input_type
            }
            if(_self.ftext) {
                let opp = _self.ftext.split(/\r?\n/);
                req.select = opp
                req.multiselect = _self.multiselect
            }
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('settings/ADD_FIELD', req).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    console.log('qwerty', res)
                    let obj = res.data
                    console.log('obj', obj)
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Field Added Successfully',
                        class: 'ams-error-success'
                    })
                    if (_self.$route.params.tabname === 'product') {
                        _self.$parent.allfields.unshift(res.data)
                    }
                    _self.$emit('table-api', '')
                    _self.$emit('close-popup', false)
                    // this.$parent.fieldListing()
                    //_self.oflag=false
                    // _self.$emit('table-api', '')

                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })

        },
        updateField() {
            let _self = this
            let updateReq = {
                field_id: _self.field_id,
                field_name: _self.fieldObj.field_name,
                field_type: _self.fieldObj.field_type,
                input_type: _self.fieldObj.input_type,
                // mand: _self.fieldObj.mand
            }
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('settings/FIELD_UPDATE', updateReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Field updated successfully',
                        class: 'ams-error-success'
                    })
                    _self.$emit('close-popup', false)
                    _self.$emit('table-api', '')
                    //this.$parent.fieldListing()
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })

        },

        getEditData: function () {

            if (this.fId) {
                console.log('cal aapi', this.fId)
                let _self = this
                _self.$store.dispatch("common/SET_LOADER", true);
                _self.$store.dispatch('settings/FIELD_ID', this.field_id).then(function (res) {
                    _self.$store.dispatch("common/SET_LOADER", false);
                    if (res && res.status == 1000) {
                        _self.uflag = true
                        let rdata = res.data
                        _self.fieldObj.field_name = rdata.field_name
                        _self.fieldObj.field_type = rdata.field_type
                        _self.fieldObj.input_type = rdata.input_type
                        _self.fieldObj.mand = rdata.mand
                    } else {
                        _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                            msg: 'Technical Error',
                            class: 'ams-error-danger'
                        })
                    }
                })

            }

        },
        closefields: function () {
            console.log('calling')
            this.$emit('close-popup', false)
        },
    },
    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    watch: {
        field_id: {
            immediate: true,
            handler() {
                let self = this
                if (self.field_id) {
                    self.fId = self.field_id
                }
            }
        }
    }
}
</script>
