<template>
<div>
    <Form :validation-schema="manufactureValidate" v-slot="{ handleSubmit }">
        <h4 class="ams-tit"><i class="icon-left-arrow ams-vertical-md ams-mr20" @click="$router.push('/manufacture')"></i> Manufacturers- Add New Manufacture</h4>
        <div class="ams-card">
            <div class="ams-row">
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12" >
                    <div class="ams-form-group">
                        <label>Name</label>
                        <Field v-model="manufacture.man_name" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Name" name="manufacturename" id="gstnumber" :disabled="hidename" />
                        <ErrorMessage name="manufacturename" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group ams-phn-no">
                        <label>Support number</label>
                        <!-- <div class="ams-phone-no"> -->
                         <!-- <input @click="openPopup()" placeholder="+91" :value="crescode" readonly class="ams-cursor"/> -->
                        <Field type="text" class="ams-phone-no" v-model="manufacture.ph_num" placeholder="Contact number" name="manufacturenumber" />
                        <!-- </div> -->
                        <ErrorMessage name="manufacturenumber" class="ams-val-err" />
                    </div>
                </div>
                <div class="ams-col3 ams-col-md3 ams-col-sm6 ams-col-xs12">
                    <div class="ams-form-group">
                        <label>Support Email</label>
                        <Field v-model="manufacture.email" type="text" class="ams-form-control ams-custom-control-dark" placeholder="Email" name="manufactureemail" />
                        <ErrorMessage name="manufactureemail" class="ams-val-err" />
                    </div>
                </div>
            </div>
            <!-- <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/> -->
            <div class="ams-file-upload-img">
            <img v-if="fileData && fileData.filename" :src="fileData.url" />
            <div v-else>
                <label class="">Logo</label>
                <div class="ams-row ams-mb20">
                    <div class="ams-col3 ams-col-md4 ams-col-sm6 ams-col-xs12">
                        <file-upload :show-upload="true" :show-delete="true" :file-upload="true" :disText="utext" @fileUploadSuccess="fileData =  $event" />
                    </div>
                </div>
            </div>
            </div>
            
        <!-- <upload-invoice  v-if="fileData && fileData.filename" :fileData="fileData" @invoice-close="fileData = $event"/> -->
       
        
            
            <div class="ams-table-filter">
                <button class="ams-btn ams-btn-danger" @click="$router.push('/manufacture')">Cancel</button>
                <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(addManufacture)" v-if="!uflag">Save</button>
                 <button class="ams-btn ams-btn-primary ams-ml20" @click.prevent="handleSubmit(updateManufacture)" v-if="uflag">Update</button>
            </div>
        </div>
    </Form>
</div>
<country-code v-if="ccflag" @save-code="saveCode" @close-pop="ccflag = false" />
</template>

<script>
import FileUpload from '../common/FileUpload.vue'
import CountryCode from "../../components/home/CountryCode.vue"

// import UploadInvoice from '../common/UploadInvoice.vue'

import {
    Form,
    Field,
    ErrorMessage
} from 'vee-validate';
import * as Yup from "yup";
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        FileUpload,
        CountryCode
    },
    data() {
        const manufactureValidate = Yup.object().shape({
            manufacturename: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Special Characters Not Allowed ").required('Please Enter Name'),
            manufacturenumber: Yup.string().min(10).max(10).matches(/^[0-9]+$/, "Only Numbers Are Allowed ").required("Support Number is required"),
            manufactureemail: Yup.string().email("Please Enter Valid Email").required("Please Enter Email"),

        });
        return {
            fileData: {},
            crescode:"+91",
            utext: 'Upload logo',
            manufacture: {
                man_name: "",
                ph_num: "",
                email: "", 
                ccode:"91",
                man_logo: ""

            },
            uflag: false,
            ccflag: false,
            hidename:false,
            manufactureValidate
        }
    },
    mounted() {
        let self = this
        if (self.$route.params.manid) {
            console.log('matchdayyy', self.$route.params.manid)
            self.getEditData()
        }
        console.log('self.$route.params',self.$route.params)
        if (self.$route.params.action === 'edit' ) {
            self.hidename = true
        }

    },
    methods: {
        addManufacture: function () {
            let _self = this
            let req = {
                man_name: _self.manufacture.man_name,
                ph_num: _self.manufacture.ph_num,
                email: _self.manufacture.email, 
                ccode: _self.manufacture.ccode,
            }
            if (_self.fileData && _self.fileData.url) {
                req.man_logo = _self.fileData.url
            }
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('manufacture/ADD_MANUFACTURE', req).then(function (res) {
                console.log('RES', res)
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    console.log('qwerty', res)
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Manufacture added successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/manufacture')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })
        },
        saveCode: function (countryFilter) {
            console.log(countryFilter, 'countryFilter')
            let val = countryFilter.label.split(' ')
            this.crescode = val[0]
            this.manufacture.ccode= countryFilter.value.toString()
            this.ccflag = false
        },
        openPopup: function(){
            this.ccflag=true
        },
        getEditData: function () {
            let _self = this
            let user = _self.$route.params.manid;
            _self.$store.dispatch("common/SET_LOADER", true);
            _self.$store.dispatch('manufacture/MANUFACTURE_ID', user).then(function (res) {
                _self.$store.dispatch("common/SET_LOADER", false);
                if (res && res.status == 1000) {
                    _self.uflag = true
                    let rdata = res.data
                    console.log(rdata,'rdata')
                    _self.manufacture.man_name = rdata.man_name
                    _self.manufacture.ph_num = rdata.ph_num
                    _self.manufacture.email = rdata.email
                    _self.fileData.url =  rdata.man_logo
                    _self.fileData.filename = rdata.man_logo
                    
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Technical Error',
                        class: 'ams-error-danger'
                    })
                }
            })

        },
        updateManufacture() {
            let _self = this
            let updateReq = {
                man_id: _self.$route.params.manid,
                man_name: _self.manufacture.man_name,
                ph_num: _self.manufacture.ph_num,
                email: _self.manufacture.email,
                status: _self.status
            }
            _self.$store.dispatch('common/SET_LOADER', true)
            _self.$store.dispatch('manufacture/UPDATE_MANUFACTURE', updateReq).then(function (res) {
                _self.$store.dispatch('common/SET_LOADER', false)
                if (res && res.status == 1000) {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: 'Manufacture updated successfully',
                        class: 'ams-error-success'
                    })
                    _self.$router.push('/manufacture')
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', {
                        msg: (res && res.message) || 'Technical error',
                        class: 'ams-error-danger'
                    })
                }
            })

        },

    }

}
</script>
