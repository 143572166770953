<template>
<nav class="ams-navigation">
    <ul class="ams-nav-bar">
        <router-link v-slot="{ href, navigate, isActive, isExactActive }" custom v-for="item in menu" :to="item.path" :key="item">
            <li v-if="item.path != '/profile'" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" @click="nav">
                <a :href="href" @click="navigate"> <i :class="item.icon"></i> <span>{{item.info}}</span></a>
            </li>
        </router-link>
    </ul>
</nav>
</template>
<script>
export default {
	data() {
		return {
		};
	},
	mounted() {
	},
	unmounted() {
	},
	methods: {
		nav: function() {
			this.$emit('nav-bar', '')
		}
	},
	computed: {
		menu: function () {
			return this.$store.getters['common/getMenu'] || (localStorage.getItem('menu') && JSON.parse(localStorage.getItem('menu'))) || []
		}
	},
	components: {
	}
};
</script>
