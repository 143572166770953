<template>
	<Form class="ams-auth-form" :validation-schema="signinschema" v-if="!forgotFlag" v-slot="{ handleSubmit }">
        <div class="ams-form-group">
            <i class="ams-f-size12 icon-email ams-form-icon ams-form-icon-left"></i>
            <Field name="email" v-model="user.email" type="text"  class="ams-form-control ams-custom-control" placeholder="Email Id"/>
            <ErrorMessage name="email" class="ams-val-err" />
        </div>
        <div class="ams-form-group">
            <i class="icon-password ams-form-icon ams-form-icon-left"></i>
            <Field name="password" v-model="user.password" type="password" class="ams-form-control ams-custom-control" placeholder="Pasword" />
            <ErrorMessage name="password" class="ams-val-err" />
        </div>
        <div class="ams-form-group">
            <button type="button" class="ams-btn ams-btn-primary-grad ams-btn-block" @click="handleSubmit(signinFlag)">Sign in</button>
        </div>
        <span class="ams-forgot" @click="forgotPass">Forgot Password</span>
	</Form>
    <forgot-password v-if="forgotFlag" @forgetEvent="forgotFlag = $event" v-on:send-register="sendRegisterFlag" />
</template>
<script>
/*eslint-disable*/
import forgotPassword from './ForgotPassword.vue'
import API from '../../servcies/api.js'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as Yup from "yup";
export default {
	components: {
        forgotPassword,
        Field,
        Form,
        ErrorMessage,
	},
	data() {
        const signinschema = Yup.object().shape({
            email: Yup.string().email("Please Enter Valid Email").required("Please Enter Email"),
            password: Yup.string().required("Please Enter Password"),
        });
		return {
            forgotFlag: false,
            user: {
                email: '',
                password: ''
            },
            signinschema,
		};
	},
    methods: {
        signinFlag(){
            let _self = this
            _self.$store.dispatch('common/SET_LOADER', true)
            API.signin(_self.user, function(response) {
                setTimeout(() => {
                    _self.$store.dispatch('common/SET_LOADER', false)
                }, 1000);
                if (response && response.status === 1000) {
                   // _self.$store.dispatch('common/SET_ALERTMESSAGE', {msg: 'You have logged in successfully', class: 'ams-error-success'})
                    localStorage.removeItem('userInfo')
                    _self.$store.dispatch('common/SET_MENU', response.data.menu)
                    _self.$store.dispatch('user/SET_USERDETAILS', response.data.userDetails)
                    _self.$store.dispatch('user/SET_COMPDETAILS', response.data.compDetails)
                    _self.$store.dispatch('user/SET_USERTOKEN', response.data.token)
                    localStorage.setItem('userDetails', JSON.stringify(response.data.userDetails))
                    localStorage.setItem('compDetails', JSON.stringify(response.data.compDetails))
                    localStorage.setItem('eventData', JSON.stringify(response.data.events))
                    localStorage.setItem('features', JSON.stringify(response.data.features))
                    localStorage.setItem('menu', JSON.stringify(response.data.menu))
                    localStorage.setItem('pt', JSON.stringify(response.data.productTypes))
                    localStorage.setItem('token', response.data.token)
                    let route = response.data.menu[0].path
                    _self.$router.push(route);
                } else {
                    _self.$store.dispatch('common/SET_ALERTMESSAGE', { msg: response.message || 'Something went wrong. Please try again', class: 'ams-error-danger'})
                }
            });
        },
        forgotPass:function(){
            this.forgotFlag = true
        },
        sendRegisterFlag:function(){
        }
    }
};
</script>
