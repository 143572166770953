<template>
  <div class="ams-dialog" v-if="!cflag">
    <div class="ams-dialog-body ams-w20">
      <i
        class="ams-close ams-close-circle icon-close-circ ams-f-size30 ams-mt10"
        @click="closePop"
      ></i>
      <div class="ams-dialog-cont">
        <h3 class="ams-tit">Enter OTP</h3>
        <div class="ams-row ams-d-flex">
          <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
              <input
                ref="digit_0"
                type="tel"
                v-model="digit_0"
                class="ams-form-control ams-custom-control ams-text-center"
                @input="focusInput('0')"
              />
            </div>
          </div>
          <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
              <input
                ref="digit_1"
                type="tel"
                v-model="digit_1"
                class="ams-form-control ams-custom-control ams-text-center"
                @input="focusInput('1')"
                @keyup.delete="$refs.digit_0.focus()"
              />
            </div>
          </div>
          <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
              <input
                ref="digit_2"
                type="tel"
                v-model="digit_2"
                class="ams-form-control ams-custom-control ams-text-center"
                @input="focusInput('2')"
                @keyup.delete="$refs.digit_1.focus()"
              />
            </div>
          </div>
          <div class="ams-col3 ams-col-md6 ams-col-sm12 ams-col-xs12">
            <div class="ams-form-group">
              <input
                ref="digit_3"
                type="tel"
                maxlength="1"
                v-model="digit_3"
                class="ams-form-control ams-custom-control ams-text-center"
                @keyup.delete="$refs.digit_2.focus()"
              />
            </div>
          </div>
        </div>
        <div class="">
          <div class="ams-text-right ams-mtb20">
            <button
              :disabled="btnflag"
              class="ams-btn ams-btn-primary ams-btn-block"
              @click.prevent="confirmOTP"
            >
              Confirm
            </button>
            <small
              >You will get OTP in -
              <span class="ams-blue-text">{{ timer }}</span></small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <contact-updated-successfully
    v-if="cflag"
    v-on:close-popup="cflag = $event"
  />
</template>

<script>
// import {
//     Form,
//     Field,
//     ErrorMessage
// } from 'vee-validate';
// import * as Yup from "yup";
import ContactUpdatedSuccessfully from "../../components/profile/ContactUpdatedSuccessfully.vue";
export default {
  props: ["profileData", "upReq"],
  components: {
    // Form,
    // Field,
    // ErrorMessage,
    ContactUpdatedSuccessfully,
  },
  data() {
    // const changePassword = Yup.object().shape({
    //     name: Yup.string().required("Please Enter Old password"),
    //     password: Yup.string().required("Please Enter New password"),
    //     newpassword: Yup.string().required("Please Confirm your password")
    //         .oneOf([Yup.ref('password'), null], 'New Password & Confirm password not matching')
    // });
    return {
      //    upReq:{
      //         ccode:"",
      //         ph_num:"",
      //         email: "",
      //         otp:""
      //     },
      // changePassword,
      digit_0: '',
      digit_1: '',
      digit_2: '',
      digit_3: '',
      countDown: 500,
      cflag: false,
      one: true,
      two: true,
      three: true,
      four: true
    };
  },
  methods: {
    closePop: function () {
      this.$parent.closechangenum();
      this.$emit("close-popup", false);
    },
    focusInput(fd) {
      if (this["digit_" + fd].length > 1) {
        let val = this["digit_" + fd].charAt(1);
        this["digit_" + fd] = this["digit_" + fd].slice(0, 1);
        this["digit_" + (1 + parseInt(fd))] = val;
        this.$refs["digit_" + (1 + parseInt(fd))].focus();
      }
      if (this["digit_" + fd] != "" && parseInt(this["digit_" + fd]) > -1) {
        this.$refs["digit_" + (1 + parseInt(fd))].focus();
      } else {
        this["digit_" + fd] = null;
      }
    },
    otpCountdown() {
      this.otpTimer = setInterval(() => {
        this.countDown--;
      }, 1000);
    },
    confirmOTP() {
      let _self = this;
      if (_self.otpCode) {
        let otpReq = {
          ccode: _self.upReq.ccode,
          ph_num: _self.upReq.ph_num,
          email: _self.upReq.email,
          otp: _self.otpCode,
        };
        console.log(otpReq);
        _self.$store.dispatch("common/SET_LOADER", true);
        _self.$store
          .dispatch("profile/CONFIRM_NUMBER", otpReq)
          .then(function (res) {
            _self.$store.dispatch("common/SET_LOADER", false);
            if (res && res.status == 1000) {
              console.log('RESSSSS', res)
              _self.$store.dispatch("profile/SET_PH_NUM", _self.upReq.ph_num)
              // _self.$store.dispatch('common/SET_ALERTMESSAGE', {
              //     msg: 'Contact Number updated successfully',
              //     class: 'ams-error-success'
              // })
              // _self.$emit('close-popup', '')
              _self.cflag = true;
            } else {
              _self.$store.dispatch("common/SET_ALERTMESSAGE", {
                msg: (res && res.message) || "Technical error",
                class: "ams-error-danger",
              });
            }
          });
      }
    },
  },

  computed: {
    timer() {
        if(this.countDown >= 0) {
            var minutes = Math.floor(this.countDown / 60);
            var seconds = this.countDown - minutes * 60;
            let time = `0${minutes}:${seconds >= 10 ? seconds : "0" + seconds}`;
            return time
        } else {
            return '00:00'
        }
    },
    otpCode() {
      let self = this;
      if (self.digit_0 && self.digit_1 && self.digit_2 && self.digit_3) {
        return `${this.digit_0}${this.digit_1}${this.digit_2}${this.digit_3}`;
      } else {
        return "";
      }
    },
    btnflag () {
      let self = this
      if (!self.one && !self.two && !self.three && !self.four) {
        return false
      } else {
        return true
      }
    }
    
    // watch: {
    //     countDown: function (val) {
    //         if (val <= 0) {
    //             clearInterval(this.otpTimer)
    //         }
    //     },

    // }
  },
  watch: {
    timer: {
      immediate: true,
      handler() {
        let self = this;
        if (self.timer === "00:00") {
          console.log("COUNTER STOP");
          self.$emit("close-popup", false);
        } else {
          console.log("STILL RUNNING");
        }
      },
    },
    digit_0: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_0) {
          self.one = false
        } else {
          self.one = true
        }
      }
    },
    digit_1: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_1) {
          self.two = false
        } else {
          self.two = true
        }
      }
    },
    digit_2: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_2) {
          self.three = false
        } else {
          self.three = true
        }
      }
    },
    digit_3: {
      immediate: true,
      handler () {
        let self = this
        if (self.digit_3) {
          self.four = false
        } else {
          self.four = true
        }
      }
    },
  },
  created() {
    this.otpCountdown();
  },
};
</script>
